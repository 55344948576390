import DataTable from "components/DataTable";
import useFetchRequest from "hooks/useFetchRequest";
// import numeral from "numeral";
import YASkeleton from "components/YASkeleton";
import MDTypography from "components/MDTypography";
import { useImmer } from "use-immer";
// import { useAppController } from "context";
// import { normalizeCurrency } from "utils/table";

const AssetSpendView = (props) => {
    const { yearFilter, monthFilter, containerHeight, setAction } = props;
    const [filtersState, setFiltersState] = useImmer({
        globalFilter: undefined,
        filters: [],
    });
    // const [controller] = useAppController();
    // const { appDef: { settings } } = controller;
    // const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState((draft) => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    };
    const { response: uploadedFiles, error: _err, loading: loading } = useFetchRequest(`/api/dataflow/capabilities/${yearFilter}/${monthFilter}`)

    let columns = [
            { Header: "File", accessor: "originalFileName",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "From Asset", accessor: "fromAsset",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Code", accessor: "fromCode",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "To Asset", accessor: "toAsset",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Code", accessor: "toCode",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Portion", accessor: "portion",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            // { Header: "From Amount", accessor: "amount",dataType: "currency",align:"right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0.00')}</MDTypography> } },
            // { Header: "To Amount", accessor: "spend",dataType: "currency",align:"right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0.00')}</MDTypography> } },
    ];
            
    if (loading) return <YASkeleton variant="loading" />;

    if (loading === false && uploadedFiles === null) {
        return (
            <div>
                {_err}
                no data
            </div>
        );
    }
    const rows = uploadedFiles.map((row) => {
        let r = {};
        row.id = `${row.id}`
        Object.keys(row).forEach((k) => {
            r[k.replace(/\./g, "__")] = row[k];
        });
        return r;
    });

    const handleAction = () => {
        setAction('addAssetMapping')
    }

    const getMenuActions = () => {
        let actions = [];
        actions.push({ label: "Add Mapping", onClick: handleAction })
        return actions;
    }
    
    return (
        <DataTable
            variant="tile"
            table={{ columns, rows }}
            containerMaxHeight={containerHeight}
            showTotalEntries={true}
            newStyle1={true}
            isSorted={true}
            noEndBorder
            entriesPerPage={true}
            menuActionRender={getMenuActions()}
            canSearch={true}
            filtersState={filtersState}
            onFiltersStateUpdate={handleOnFiltersStateUpdate}
            canFilter={true}
            viewTable={true}
        />
    );
};

export default AssetSpendView;
