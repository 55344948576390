import { Stack } from "@mui/material";
import AnimatedComponent from "components/AnimatedComponent";
import { AnimatedComponentChildren } from "components/AnimatedComponent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const initQuestions = [
    "What are the top 3 apps by spend",
    "Show me business units who spent more than 10k on storage last year",
    "What's the average monthly spend on vendor 'microsoft'"
];

const questionButtonStyles = () => ({
    border: "1px solid #ddd",
    py: 1,
    px: 1.5,
    borderRadius: "16px",
    fontWeight: "light",
    whiteSpace: "pre-wrap",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#f3f0f0"
    }
});

const WelcomeMessage = ({ onQuestionClick }) => {

    return (
        <Stack width={"100%"}>
            <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2} height="100%">
                <MDTypography variant="h3" component="span" fontWeight="light">👋&nbsp;&nbsp;Hi!&nbsp;&nbsp;How can I help you?</MDTypography>
                <MDTypography mt={1} mb={4} fontSize="16px" component="span" fontWeight="light">I&apos;m here to assist you with any information you need.</MDTypography>
            </MDBox>
            <AnimatedComponentChildren>
                <MDBox display="flex" flexDirection="column" gap={1.5} alignItems="flex-start" justifyContent="center" px={3} py={4}>
                    <MDTypography variant="button" component="span" fontWeight="light" px={1.5}>Try these..</MDTypography>
                    {
                        initQuestions.map((q, i) => (
                            <MDTypography
                                key={`q${i}`}
                                variant="caption"
                                component="span"
                                sx={() => questionButtonStyles()}
                                onClick={() => onQuestionClick(q)}
                            >
                                {q}
                            </MDTypography>
                        ))
                    }
                </MDBox>
            </AnimatedComponentChildren>
        </Stack>
    );
}

export default AnimatedComponent(WelcomeMessage);