import numeral from "numeral"

export const normalizeCurrency = (currencyStrVal,significantPlaces) =>  {
    let res
    if(significantPlaces){
        res = numeral(Number(currencyStrVal).toFixed(significantPlaces));
    }
    else{
        res = numeral(Number(currencyStrVal).toFixed(10));
    }
    return res
}
export const toExcelHeader = (index) => {
    if (index <= 0) {
        throw new Error("index must be 1 or greater");
    }
    index--;
    var charCodeOfA = ("a").charCodeAt(0); // you could hard code to 97
    var charCodeOfZ = ("z").charCodeAt(0); // you could hard code to 122
    var excelStr = "";
    var base24Str = (index).toString(charCodeOfZ - charCodeOfA + 1);
    for (var base24StrIndex = 0; base24StrIndex < base24Str.length; base24StrIndex++) {
        var base24Char = base24Str[base24StrIndex];
        var alphabetIndex = (base24Char * 1 == base24Char) ? base24Char : (base24Char.charCodeAt(0) - charCodeOfA + 10);
        // bizarre thing, A==1 in first digit, A==0 in other digits
        if (base24StrIndex == 0) {
            alphabetIndex -= 1;
        }
        excelStr += String.fromCharCode(charCodeOfA * 1 + alphabetIndex * 1);
    }
    return excelStr.toUpperCase();
}

export const pickColorNum = (value) => {
    let sum = 0;
    let value1=  value?.toUpperCase();
    for(let i =0; i<value1.length;i++) {
      sum += value1[i]?.charCodeAt();
    }
    let num = Math.abs((sum + value1.length)%36);
    return num
}
export const normalizeCurrency_CF = (currencyStrVal,significantPlaces) =>  {
    let res
    if(significantPlaces){
        Number(currencyStrVal) < 0.01 && Number(currencyStrVal) > -1 ? res = 0 : res = numeral(Number(currencyStrVal).toFixed(significantPlaces));
    }
    else{
        Number(currencyStrVal) < 0.01 && Number(currencyStrVal) > -1 ? res = 0 : res = numeral(Number(currencyStrVal).toFixed(4));
    }
    return res
}


export const toExcelHeaderArray = (rows) => {
    var excelHeaderArr = [];
    for (var index = 1; index <= rows; index++) {
        excelHeaderArr.push(toExcelHeader(index));
    }
    return excelHeaderArr;
}