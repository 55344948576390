import {
  Card,
  Icon,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  InputAdornment
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { parseJsonString } from "utils";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getDomain } from "utils";
import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import Rule from "../Rule";

const Condition = (props) => {
  const {
    k,
    toAsset,
    portion,
    assetRule,
    splitType,
    onConditionChange,
    onConditionDelete,
    errors,
    conditions,
    // selectedToCode,
    isEditing,
    setIsEditing,
    options,
    fromAsset,
    loading,
    handleAlert,
    control,
    watch,
    assetList,
    showrule,
    setShowrule,
    ruleError
  } = props;

  const [toAssetVal, setToAssetVal] = useState(toAsset);
  const [portionVal, setPortionVal] = useState(portion);
  const [assetVal, setAssetVal] = useState(null)
  const [toCondition, settoCondition ] = useState(assetRule)

  const handleClearFromAssetSelection = () => {
    settoCondition(null)
  }
  
  const toAssetList = options?.filter(item => {
    if (item.name !== fromAsset) {
      return item
    }
  })?.map(item => (
    <MenuItem key={item.name} value={item.value}>
      {item.label}
    </MenuItem>
  ));
  
  useEffect(() => {
    setToAssetVal(toAsset)
    setPortionVal(portion)
    settoCondition(assetRule)
  }, [toAsset, portion, assetRule]);

  useEffect(() => {
    if (!isEditing)
      setPortionVal(null)
  }, [splitType]);

  useEffect(() => {
    if (onConditionChange) {
      onConditionChange({ k, toAsset: toAssetVal, portion: portionVal, toAssetRule: toCondition })
      let assetVal = assetList?.find(elem => elem.displayName === toAssetVal)?.name
      setAssetVal(assetVal)
    }
  }, [ toAssetVal, portionVal, toCondition]);

  useEffect(() => {
    if (onConditionChange && !isEditing) {
      onConditionChange({ k, toAsset: toAssetVal, portion: portionVal, toAssetRule: toCondition })
      let assetVal = assetList?.find(elem => elem.displayName === toAssetVal)?.name
      setAssetVal(assetVal)
    }
  }, [toAssetVal, assetList, toCondition]);

  useEffect(() => {
    if (splitType === 'equally') {
      setPortionVal(Number.parseFloat(100/(conditions.length)))
    }
  }, [conditions, splitType])

  let handleFormConditions = (value) => {
    if (assetVal) {
      setShowrule(false)
      settoCondition(value)
      setShowrule(true)
    }
  }
  let handleDelete = (k) => {
    onConditionDelete(k)
  }
  let handleAssetSelet = (value) => {
    setToAssetVal(value)
    settoCondition(null)
    setIsEditing(false)
  }
  if (loading) return <YASkeleton variant="dropdown" />

  return (
    <>
      <MDBox display="flex" justifyContent="center" alignItems="center">
        <TextField
          name="toAsset"
          label="To Asset"
          select
          value={toAssetVal}
          size="small"
          onChange={({ target: { value } }) => { handleAssetSelet(value) }}
          variant="outlined"
          sx={{ margin: 0.5, minWidth: 250, maxWidth: 250 }}
          error={errors?.toAsset}
        >
          {toAssetList}
        </TextField>
        <MDBox mx={1.5}>
          <TextField
            name="portion"
            label={"Portion"}
            value={portionVal || ""}
            size="small"
            onChange={({ target: { value } }) => {
              setPortionVal(isNaN((value).trim()) ? null : value.trim() > 0 ? (value).trim() : null);
            }}
            onBlur={({ target: { value } }) => {
              setPortionVal(isNaN((value).trim()) ? null : value.trim() > 0 ? (value).trim() : null);
            }}
            variant="outlined"
            sx={{ margin: 0.5, minWidth: 150, maxWidth: 150 }}
            error={errors?.value1}
            disabled={!toAssetVal || splitType === 'equally'}
            InputProps={{
              endAdornment: <InputAdornment disableTypography position="end">%</InputAdornment>,
            }}
          />
          {
            conditions.length > 1 &&
            <>
              <IconButton mr={1} onClick={() => handleDelete(k)} size="small">
                <Icon color="error">delete</Icon>
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  &nbsp;Delete
                </MDTypography>
              </IconButton>
            </>
          }
        </MDBox>
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="center">
        {showrule && <MDBox display="flex" flexDirection="column" >
          <Rule
            watch={watch}
            control={control}
            fromAsset={assetVal}
            fieldDef={{
              name: "rule",
              displayName: "Rule",
              disableFilters: true,
              required: true,
              unique: true,
              dataSource: {
                type: "custom",
                parentFields: ["fromAsset"],
                url: "/api/dataflow/resource/:asset"
              }
            }}
            errorMessage={!toCondition && ruleError && ruleError["toAssetRule"] && ruleError["toAssetRule"]?.message}
            handleAlert={handleAlert}
            condition={toCondition}
            onConditionChange={handleFormConditions}
            onClearAssetSelection={handleClearFromAssetSelection}
            multipleRules={true}
          />
        </MDBox>}
      </MDBox>
    </>
  );
};

const DEFAULT_CONDITION_ARR = [
  {
    k: 1,
    toAsset: null,
    portion: null,
    toAssetRule: null,
  },
];

const ToMultipleAssetModal = (props) => {
  const { conditionJson, onConditionClose, onChange, onConditionSave, splitType, setSplitType, isEditing, setIsEditing, dataSource, fromAsset, fromAssetChange, handleAlert, setValue, control, watch, errorsSet, assetList } = props;
  let conditionsArr = parseJsonString(conditionJson)?.toAssets || DEFAULT_CONDITION_ARR;
  const [errors, setErrors] = useState({});
  const [conditions, setConditions] = useImmer(conditionsArr);
  const [ calcPortion, setCalcPortion ] = useState({allocated: 0, remaining: 0})
  const selectedtoAsset = conditions?.map(c => c.toAsset);
  const [options, setOptions] = useState(dataSource.type === "static" ? dataSource.data : []);
  const [loading, setLoading] = useState(dataSource.type !== "static");
  const [showrule, setShowrule] = useState(true)
  let [ruleError, setRuleError] = useState(null)

  useEffect(() => {
    async function getOptions() {
        setLoading(true);
        const domain = getDomain();
        let get_url = `${domain}${dataSource.url}`
        let q = ("nc=" + Math.random()).replace(".", "");
        get_url += get_url.indexOf("?") > 0 ? `&${q}` : `?${q}`
        const response = await Axios.get(get_url);
        const modRes = response.data.map((a) => {
            if(!a.value) {
                a.value = a.displayName
                a.label = a.displayName
            }
            return a
        })
        setOptions(modRes);
        setLoading(false);
    }
    if (dataSource.type !== "static")
        getOptions();
  }, [fromAssetChange]);

  const handleConditionChange = (conditionValue) => {
    if (conditionValue)
      setConditions(draft => {
        let updatedCondition = draft.find(c => c.k === conditionValue.k);
        if (updatedCondition) {
          updatedCondition.toAsset = conditionValue.toAsset;
          updatedCondition.portion = conditionValue.portion;
          updatedCondition.toAssetRule = conditionValue.toAssetRule;
        }
      })
      setRuleError(null)
  };
  
  useEffect(() => {
    let totalPortion = 0
    conditions.map( a => {
      totalPortion = Number(totalPortion) + Number(a.portion)
    })
    setCalcPortion({allocated: Number(totalPortion), remaining: Number(Number(100) - Number(totalPortion))})
  },[conditions])

  const handleNewConditionClick = () => {
    setConditions((prevConditions) => {
      const conditionKey =
        prevConditions?.length === 0
          ? 1
          : prevConditions[prevConditions.length - 1].k + 1;
      return [
        ...prevConditions,
        { k: conditionKey, toAsset: null, portion: null, toAssetRule: null },
      ];
    });
  };

  const handleConditionClose = () => {
    if (onConditionClose) onConditionClose();
  };

  const handleConditionDelete = (k) => {
    setConditions(draft => {
      return draft.filter(c => c.k !== k);
    })
  };

  const handleConditionSave = () => {
    let errorsObj = {};
    conditions?.forEach(c => {
      if (!c.toAsset || !c.portion || !c.toAssetRule) {
        errorsObj[c.k] = {
          k: c.k,
          toAsset: !c.toAsset,
          // toCode: !c.toCode,
          portion: !c.portion,
          toAssetRule : !c.toAssetRule
        };
        if(!c.toAssetRule)
          {
            setRuleError({
              "toAssetRule": {
                  "type": "manual",
                  "message": "To Asset Rule is required.",
                  "ref": {
                      "name": "toAssetRule",
                      "value": null
                  }
              }
          })
          }
      }
    });

    if (Object.keys(errorsObj).length === 0) {
      if (onConditionSave) {
        onConditionSave(JSON.stringify({splitType: splitType, toAssets: conditions}));
        onChange(JSON.stringify({splitType: splitType, toAssets: conditions}))
      }
    }
    console.error(errorsObj)
    setIsEditing(false)
    setErrors(errorsObj);
  };

  const  data = [
    { value: "equally", label: "Equally", name: 'equally' },
    { value: "manually", label: "Manually", name: 'manually' },
    // { value: "services", label: "Services", name: 'services' }
  ]
  
  const toAssetList = data?.map(item => (
    <MenuItem key={item.name} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <Modal open={true} onClose={handleConditionClose}>
      <MDBox
        p={3}
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Card sx={{ minWidth: "1000px", overflow: "hidden" }}>
          <MDBox
            px={3}
            pt={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox>
              <MDTypography variant="h6" component="span">
                Select To Assets
              </MDTypography>
            </MDBox>
            <MDBox>
              <TextField
                name="splitType"
                label="Split Type"
                select
                value={splitType}
                size="small"
                onChange={({ target: { value } }) => { setIsEditing(false); setSplitType(value) }}
                variant="outlined"
                sx={{ margin: 0.5, minWidth: 250, maxWidth: 250 }}
                error={errors?.field}
              >
                {toAssetList}
              </TextField>
            </MDBox>
            <MDBox display="flex">
              <IconButton onClick={handleConditionClose} title="Close">
                <Icon>close</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
          {selectedtoAsset && conditions.length > 0 && (
            <MDBox
              sx={{
                p: 2,
                display: "flex",
                mx: 3,
                my: 1,
                borderRadius: 2,
                border: "2px dashed #ddd",
                maxHeight: 500
              }}
              overflow={"hidden"}
            >
            <MDBox flex={1}>
              <PerfectScrollbar>
                {conditions?.map((c, i) => (
                  <MDBox p={2}
                    key={`c${i}`}
                    sx={{
                      borderRadius: 2,
                      border: "2px dashed #ddd",
                      mx: 3,
                      my: 3,
                    }}
                  >
                    <Condition
                      key={`c${i}`}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      errors={errors[c.k]}
                      toAsset={c.toAsset}
                      portion={c.portion}
                      assetRule ={c.toAssetRule}
                      splitType={splitType}
                      onConditionChange={handleConditionChange}
                      onConditionDelete={handleConditionDelete}
                      conditions={conditions}
                      condition = {[c]}
                      options={options}
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      fromAsset={fromAsset}
                      loading={loading}
                      handleAlert={handleAlert}
                      errorsSet={errorsSet}
                      assetList={assetList}
                      showrule={showrule}
                      setShowrule={setShowrule}
                      ruleError={ruleError}
                      {...c}
                    />
                  </MDBox>
                ))}
              </PerfectScrollbar>
            </MDBox>
          </MDBox>
          )}
          {conditions.length === 0 &&
            <MDBox
              sx={{
                p: 2,
                alignItems: "center",
                mx: 3,
                my: 1,
                borderRadius: 2,
                border: "2px dashed #ddd",
                maxHeight: 600
              }}
              overflow={"hidden"}
            >
              <MDBox textAlign="center">
                <MDTypography variant="subtitle2" fontWeight="medium" color={errors?.towerSingle ? "error" : "dark"}>Choose a Asset and assign  portion percentage and rule to it.</MDTypography>
              </MDBox>
            </MDBox>
          }
          <MDBox textAlign="center">
            <MDButton
              size="large"
              startIcon={<Icon fontSize="medium">add</Icon>}
              sx={{ mt: 1, ml: 1 }}
              variant="text"
              color="info"
              onClick={handleNewConditionClick}
            >
              Add To Asset
            </MDButton>
          </MDBox>
          <MDBox p={3} textAlign="right">
            {splitType !== "equally" && 
              <MDBox pt={1} width="100%" textAlign="center">
                  <MDTypography variant="caption" color="info">Allocated: {calcPortion.allocated}%</MDTypography> |
                  <MDTypography variant="caption" color="info"> Remaining: {calcPortion.remaining}% </MDTypography>
              </MDBox>
            }
            <MDButton
              name="save"
              variant="gradient"
              color="info"
              onClick={handleConditionSave}
              disabled = { splitType !== "equally" && calcPortion.allocated !== 100}
            >
              Save
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
};

export default ToMultipleAssetModal;