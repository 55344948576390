import { Icon, Autocomplete, CircularProgress } from "@mui/material";
import useFetchRequest from "hooks/useFetchRequest";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import fetchRequest from "utils/fetchRequest";
import MDInput from "components/MDInput";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { backgroundProcessCheck } from '../../utils';
import { generateUUID } from 'utils';
import { NotificationType, NotificationStatus } from 'constants';


const CopyFiles = (props) => {

  const { showAlert, showSnackbar, addNotification } = useYADialog();
  const { categoryFilter, typeFilter, variant, yearFilter, yearFilterName, monthFilter, monthFilterName, setBeforeUpload, subCategoryFilter,handleUploadDialogClose } = props
  const { response: categories, error: _err, loading: loading } = useFetchRequest(`/api/dataflow/categories/`);
  const [yearNameId, setYearNameId] = useState()
  const [monthNameId, setMonthNameId] = useState()
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allMonths, setAllMonths] = useState([]);
  const [existing, setExisting] = useState(0);
  const [first, setFirst] = useState(true);
  var existingRules
  useEffect(() => {
    if (categories !== null) {
      let prevMonthNumber = (new Date().getMonth());
      if (prevMonthNumber === 0) {
        if (categories.years?.length > 1) {
          prevMonthNumber = categories.months[0]?.id;
          const prevMonth = categories.months.find(m => m.id === prevMonthNumber);
          setYearNameId(yearFilter);
          setMonthNameId(prevMonth?.id);
        }
      }
      else {
        const prevMonth = categories.months.find(m => m.id === prevMonthNumber);
        setYearNameId(yearFilter);
        setMonthNameId(prevMonth?.id);
      }
    }
  }, [categories]);

  useEffect(() => {
    if (yearNameId != undefined) {
      getAllMonths();
    }
  }, [yearNameId])

  useEffect(() => {
    if (monthNameId != undefined) {
      getExistingRule();
    }
  }, [yearNameId, monthNameId])

  async function getAllMonths() {
    var [err, data] = categoryFilter !== "asset" && categoryFilter !== "teamRules" && !subCategoryFilter ? await fetchRequest.get(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}${typeFilter}Monthly/${yearNameId}`) :
      subCategoryFilter === 'Asset Rule' ? await fetchRequest.get(`/api/dataflow/existingAssetRulesMonthly/${yearNameId}`) : subCategoryFilter === 'Solution Rule' ? await fetchRequest.get(`/api/dataflow/solutionOfferingRuleMonthly/${yearNameId}`) :
      categoryFilter === "teamRules" ? await fetchRequest.get(`/api/dataflow/existingTeamRulesMonthly/${yearNameId}`) :
      await fetchRequest.get(`/api/dataflow/assetMonthly/${yearNameId}/${typeFilter.toLowerCase().replaceAll(' ', '')}`);
    if (!err) {
      setAllMonths(data);
    }
    else console.error(err);
    if (first) {
      var [errf, dataf] = categoryFilter !== "asset" && categoryFilter !== "teamRules" && !subCategoryFilter ?
        await fetchRequest.get(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}${typeFilter}YearlyData`)
        : subCategoryFilter === 'Asset Rule' ? await fetchRequest.get('/api/dataflow/getAssetRuleYearlyData')
          : subCategoryFilter === 'Solution Rule' ? await fetchRequest.get('/api/dataflow/getSORuleYearlyData')
          : categoryFilter === 'asset' ? await fetchRequest.get(`/api/dataflow/getAssetYearlyData/${typeFilter.toLowerCase().replaceAll(' ', '')} `)
            : categoryFilter === 'teamRules' ? await fetchRequest.get(`/api/dataflow/getTeamRuleYearlyData`) : ''
      if(!errf){
        let allData = dataf?.map((item) => ({ id: item.id, name: item.name, data: JSON.parse(item.data) }))?.map((items) => {
          return items.data?.map((item) => ({ ...item, yearNameId: items.id, monthNameId: item.id, yearName : Number(items.name.replace('-', '')) }))
        }).flat()?.sort((a, b)=> a.yearName - b.yearName);

        let idx= allData.findIndex(obj=> obj.yearNameId === yearFilter && obj.monthNameId === monthFilter);
        let yearMonthId
        let previousObjs
        if(idx > 0) {
          previousObjs = allData?.slice(0, idx);
          yearMonthId = previousObjs?.reverse()?.find((obj) => obj.count > 0);
        }
        if (!yearMonthId){
          yearMonthId = allData?.find((items) => items.count > 0)
        }

        if (!yearMonthId) {
          yearMonthId = {
            yearNameId  : categories.years[categories.years?.length - 1]?.id,
            monthNameId : 1
          }
        }
        setYearNameId(yearMonthId?.yearNameId);
        setMonthNameId(yearMonthId?.monthNameId);
        setFirst(false);
      }
      else {
        console.error(err);
    
      }
  }

  }


  async function getExistingRule() {
    var [err, data] = (categoryFilter === 'Solution' && !subCategoryFilter) ? await fetchRequest.get(`/api/dataflow/offeringRules/${yearNameId}/${monthNameId}`) :  (categoryFilter === 'Solution' && subCategoryFilter) ? await fetchRequest.get(`/api/dataflow/solutionOfferingRules/${yearNameId}/${monthNameId}`) : categoryFilter !== "asset" && categoryFilter !== "teamRules" && !subCategoryFilter ?
    await fetchRequest.get(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}Mapping/${yearNameId}/${monthNameId}`) :
    subCategoryFilter ? await fetchRequest.get(`/api/dataflow/existingAssetRulesMonthly/${yearNameId}/${monthNameId}`) :
    categoryFilter === "teamRules" ? await fetchRequest.get(`/api/dataflow/existingTeamRulesMonthly/${yearNameId}/${monthNameId}`) :
    await fetchRequest.get(`/api/dataflow/assetRecordCount/${typeFilter.toLowerCase().replaceAll(' ', '')}/${yearNameId}/${monthNameId}`);
    if (!err) {
      existingRules = data?.length;
      setExisting(existingRules)
    }
    else {
      console.error(err);
    }
  }
  var sameYearMonth = false;
  if (monthFilter === monthNameId && yearFilter === yearNameId) {
    sameYearMonth = true;
  }

  const copyFiles = async () => {
    const requestId = generateUUID();
    setIsSubmitting(true);
    var [err, data] = await fetchRequest.post(`/api/dataflow/copyFiles/${typeFilter.toLowerCase().replaceAll(' ', '')}/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`, { requestId, typeFilter })
    if (err) {
      if (err.data?.message) {
        showSnackbar(err.data?.message, "error")
      } else {
        showAlert("Copy Files", "Something went wrong. Contact your administrator.");
      }
    }
    else if (data) {
      setSuccess(true);
      const notification = ({ requestId, type: NotificationType.ProcessFile, message: `${typeFilter}  file is processing in ${yearFilterName} ${monthFilterName}.`, status: NotificationStatus.Processing, persist: true, details: { alertId: requestId } });
      addNotification(notification);
      setBeforeUpload(false);
      handleUploadDialogClose()
    }
    setIsSubmitting(false);
  }

  const deleteCPRules = async () => {

    let [err,] = await fetchRequest.delete(`/api/dataflow/costpoolRules/${yearFilter}/${monthFilter}`)
    if (err) {
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
        } else {
          showAlert("Copy Mapping", "Something went wrong. Contact your administrator.");
        }
      }
    }
  }

  const deleteCloudTeamRules = async () => {

    let [err,] = await fetchRequest.delete(`/api/dataflow/cloudTeamRule/${yearFilter}/${monthFilter}`)
    if (err) {
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
        } else {
          showAlert("Copy Rules", "Something went wrong. Contact your administrator.");
        }
      }
    }
  }

  const deleteTRRules = async () => {

    let [err,] = await fetchRequest.delete(`/api/dataflow/towerRules/${yearFilter}/${monthFilter}`)
    if (err) {
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
        } else {
          showAlert("Copy Rules", "Something went wrong. Contact your administrator.");
        }
      }
    }
  }

  const deleteSolRules = async () => {

    let [err,] = await fetchRequest.delete(`/api/dataflow/solutionRules/${yearFilter}/${monthFilter}`)
    if (err) {
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
        } else {
          showAlert("Copy Rules", "Something went wrong. Contact your administrator.");
        }
      }
    }
  }

  const deleteOfferingRules = async () => {
    let [err, ] = await fetchRequest.delete(`/api/dataflow/offeringRules/${yearFilter}/${monthFilter}`)
     if (err) {
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
        } else {
          showAlert("Copy Mapping", "Something went wrong. Contact your administrator.");
        }
      }
    }
  }

  const deleteBURules = async () => {

    let [err,] = await fetchRequest.delete(`/api/dataflow/businessUnitRules/${yearFilter}/${monthFilter}`)
    if (err) {
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
        } else {
          showAlert("Copy Rules", "Something went wrong. Contact your administrator.");
        }
      }
    }
  }

  const copyCPRules = async () => {
    let bgData = await backgroundProcessCheck(monthFilter, yearFilter)
    if (bgData.length > 0) {
      showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
    } else {
      setIsSubmitting(true);
      await deleteCPRules();
      var [err, data] = await fetchRequest.post(`/api/dataflow/copyCostPoolMapping/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`)

      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          setSuccess(false);
        } else {
          showAlert("Copy Mapping", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        setSuccess(true);
        // handleUploadDialogClose()
      }
      setIsSubmitting(false);
    }
  }

  const copyTRRules = async () => {
    let bgData = await backgroundProcessCheck(monthFilter, yearFilter)
    if (bgData.length > 0) {
      showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
    } else {
      setIsSubmitting(true);
      await deleteTRRules();
      var [err, data] = await fetchRequest.post(`/api/dataflow/copyTowerMapping/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          setSuccess(false);
        } else {
          showAlert("Copy Mapping", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        setSuccess(true);
        // handleUploadDialogClose()
      }
      setIsSubmitting(false);
    }
  }

  const copySRRules = async () => {
    setIsSubmitting(true);
    await deleteSolRules();
    var [err, data] = await fetchRequest.post(`/api/dataflow/copySolutionMapping/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`)
    if (err) {
      if (err.data?.message) {
        showSnackbar(err.data?.message, "error")
        setSuccess(false);
      } else {
        showAlert("Copy Rules", "Something went wrong. Contact your administrator.");
        setIsSubmitting(false);
      }
    }
    else if (data) {
      setSuccess(true);
      // handleUploadDialogClose()
    }
    setIsSubmitting(false);
  }

   const copyOfferingRules = async () => {
    setIsSubmitting(true);
    await deleteOfferingRules();
    var [err, data] = await fetchRequest.post(`/api/dataflow/copyOfferingRules/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`)
    if(err) {
      if(err.data?.message) {
        showSnackbar(err.data?.message, "error")
        setSuccess(false)
      }else {
        showAlert("Copy Mapping", "Something went wrong. Contact your administrator")
      }
    }
    else if (data) {
      setSuccess(true);
    }
    setIsSubmitting(false);
   }
   
   const copySolutionOfferingRules = async () => {
    setIsSubmitting(true);
    var [err, data] = await fetchRequest.post(`/api/dataflow/copySolutionOfferingRules/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`)
    if(err) {
      if(err.data?.message) {
        showSnackbar(err.data?.message, "error")
        setSuccess(false)
      }else {
        showAlert("Copy Rules", "Something went wrong. Contact your administrator")
      }
    }
    else if (data) {
      setSuccess(true);
    }
    setIsSubmitting(false);
   }
  const copyBURules = async () => {
    let bgData = await backgroundProcessCheck(monthFilter, yearFilter)
    if (bgData.length > 0) {
      showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
    } else {
      setIsSubmitting(true);
      await deleteBURules();
      var [err, data] = await fetchRequest.post(`/api/dataflow/copyBusinessUnitMapping/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          setSuccess(false);
        } else {
          showAlert("Copy Rules", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        setSuccess(true);
        // handleUploadDialogClose()
      }
      setIsSubmitting(false);
    }
  }

  const copyAssetRules = async () => {
    setIsSubmitting(true);
    // await deleteSolRules();
    var [err, data] = await fetchRequest.post(`/api/dataflow/copyAssetRules/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`)
    if (err) {
      if (err.data?.message) {
        showSnackbar(err.data?.message, "error")
        setSuccess(false);
      } else {
        showAlert("Copy Rules", "Something went wrong. Contact your administrator.");
      }
    }
    else if (data) {
      setSuccess(true);
      // handleUploadDialogClose()
    }
    setIsSubmitting(false);
  }

  const copyCloudTeamsRules = async () => {
    setIsSubmitting(true);
    await deleteCloudTeamRules();
    var [err, data] = await fetchRequest.post(`/api/dataflow/copyCloudTeamRules/${yearNameId}/${monthNameId}/${yearFilter}/${monthFilter}`)
    if (err) {
      if (err.data?.message) {
        showSnackbar(err.data?.message, "error")
        setSuccess(false);
      } else {
        showAlert("Copy Rules", "Something went wrong. Contact your administrator.");
      }
    }
    else if (data) {
      setSuccess(true);
      // handleUploadDialogClose()
    }
    setIsSubmitting(false);
  }

  if (loading) return <YASkeleton variant="loading" />

  if (loading === false && categories === null) {
    return (
      <div>
        no data
      </div>
    );
  }
  if (_err)
    console.error(_err)

  if (success)
    return (
      <MDBox sx={{ height: "290px" }} display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" px={5}>
        <MDBox sx={() => ({
          height: 70,
          width: 70,
          borderRadius: "50%",
          backgroundColor: "#4CAF50",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        })
        }>
          <Icon
            fontSize="60"
            sx={{
              fontSize: 40,
              color: "#fff"
            }}>done</Icon>
        </MDBox>
        {variant === "copyFiles" && <MDTypography variant="subtitle2" fontWeight="light" color={"text"} mt={2}>
          {typeFilter} are being processed in the background.
        </MDTypography>}
        {variant !== "copyFiles" && <MDTypography variant="subtitle2" fontWeight="light" color={"text"} mt={2}>
        {`${subCategoryFilter ? "Rules" : "Mapping"} copied successfully. Applying in the background, see Background Processes for status` }
        </MDTypography>}
      </MDBox>
    );

  return (<>
    <MDBox sx={{ height: "290px" }} display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
      <MDTypography mb={1} variant="subtitle1" fontWeight="light" color="text" component="span" >
        {/* Copying {variant === "copyFiles" ? "files" : "rules"} from */}
      </MDTypography>
      {variant === "copyFiles" && (
        <MDTypography variant="button" fontWeight="light" color="text" component="span" >
          {/* Will copy all the files in the background (except spend) */}
        </MDTypography>
      )
      }
      <MDBox mt={2} display="flex">
        <Autocomplete
          disableClearable={true}
          value={yearNameId}
          options={categories.years}
          onChange={(event, newValue) => {
            setYearNameId(newValue.id)
          }}
          color="text"
          fontWeight="medium"
          sx={{
            ml: 1.5,
            "& .MuiOutlinedInput-root": {
              height: 42,
              width: 132,
              boxShadow: "0 8px 16px #1a488e1f"
            },
            "& .MuiOutlinedInput-input": {
              fontSize: 14
            },
            "& .MuiOutlinedInput-input.MuiAutocomplete-input": {
              padding: .5
            }
          }}
          isOptionEqualToValue={(option, value) => {
            return option.id === value
          }}
          getOptionLabel={option => {
            if (typeof option === "number")
              return categories.years.find(op => op.id === option)?.name;
            return option.name
          }}
          renderInput={(params) => <MDInput label="Year" {...params} />}
        />
        <Autocomplete
          disableClearable={true}
          value={monthNameId}
          options={allMonths}
          onChange={(event, newValue) => {
            setMonthNameId(newValue.id)
          }}
          getOptionDisabled={(option) =>
            option.rules ? option.rules : option.count === 0
            // !~~option.amount > 0 || !~~option.amount < 0
          }
          color="text"
          fontWeight="medium"
          sx={{
            ml: 1.5,
            "& .MuiOutlinedInput-root": {
              height: 42,
              width: 120,
              boxShadow: "0 8px 16px #1a488e1f"
            },
            "& .MuiOutlinedInput-input": {
              fontSize: 14
            },
            "& .MuiOutlinedInput-input.MuiAutocomplete-input": {
              padding: .5
            }
          }}
          isOptionEqualToValue={(option, value) => {
            return option.id === value
          }}
          renderOption={(props, option) => (
            option.rules ? (
              (option.rules > 0 && option.spendAmount !== null && ~~option.amount === ~~option.spendAmount) ?
                (<MDBox component="li"  {...props}>
                  <Icon fontSize="small" baseClassName={"material-icons"} style={{ color: '#05A614' }} sx={{ pl: "0px", pr: "20px", mt: 0.15, fontSize: "14px !important" }}>{'circle'}</Icon> {option.name}
                </MDBox>) :
                (option.rules > 0 && !(~~option.spendAmount < ~~option.amount)) ?
                  (<MDBox component="li"  {...props} >
                    <Icon fontSize="small" baseClassName={"material-icons"} style={{ color: '#14A0DD' }} sx={{ pl: "0px", pr: "20px", mt: 0.15, fontSize: "14px !important" }}>{'circle'}</Icon> {option.name}
                  </MDBox>) :
                  (option.rules > 0 && ~~option.spendAmount < ~~option.amount) ?
                    (<MDBox component="li"  {...props} >
                      <Icon fontSize="small" baseClassName={"material-icons"} style={{ color: '#DE0E3E' }} sx={{ pl: "0px", pr: "20px", mt: 0.15, fontSize: "14px !important" }}>{'circle'}</Icon> {option.name}
                    </MDBox>) :
                    (<MDBox component="li"  {...props} >
                      <Icon fontSize="small" baseClassName={"material-icons"} style={{ color: '#435EC3' }} sx={{ pl: "0px", pr: "20px", mt: 0.15, fontSize: "14px !important" }}>{'radio_button_unchecked'}</Icon> {option.name}
                    </MDBox>)) : (
              (option.count > 0) ?
                (
                  <MDBox component="li" {...props}>
                    <Icon fontSize="small" baseClassName={"material-icons"} style={{ color: '#05A614' }} sx={{ pl: "0px", pr: "20px", mt: 0.15, fontSize: "14px !important" }}>{'circle'}</Icon>{option.name}
                  </MDBox>
                ) : (
                  <MDBox component="li" {...props}>
                    <Icon fontSize="Small" baseClassName={"material-icons"} style={{ color: '#435EC3' }} sx={{ pl: "0px", pr: "20px", mt: 0.15, fontSize: "14px !important" }}>{'radio_button_unchecked'}</Icon> {option.name}
                  </MDBox>
                )
            )

          )}
          getOptionLabel={option => {
            if (typeof option === "number")
              return categories.months.find(op => op.id === option)?.name;
            return option.name
          }}
          renderInput={(params) => <MDInput label="Month"{...params} />}
        />
      </MDBox>
      <MDBox mt={1} >
        <Icon sx={{ marginTop: 2, color: "#7b809a", fontSize: "38px!important" }}>south</Icon>
      </MDBox>
      <MDBox shadow="xl" mt={1} sx={{ borderRadius: '8px', border: "1px solid #cdcdcd" }}>
        <MDTypography mx={4} my={4} variant="h4" fontWeight="medium" color="dark" component="span" >
          {`${yearFilterName}                ${monthFilterName}`}
        </MDTypography>
      </MDBox>
    </MDBox>
    <MDBox textAlign="right">
      {variant === "copyFiles" && <MDButton name="copyFiles" variant="gradient" color="info" sx={{ mt: 2 }} disabled={isSubmitting || !existing > 0 || sameYearMonth} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copyFiles}>Copy {typeFilter}</MDButton>}
      {variant === "copyCPRules" && <MDButton name="copyFiles" variant="gradient" color={"info"} sx={{ mt: 2 }} disabled={isSubmitting || !existing > 0 || sameYearMonth} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copyCPRules} >Copy Mapping</MDButton>}
      {variant === "copyTRRules" && <MDButton name="copyFiles" variant="gradient" color={"info"} sx={{ mt: 2 }} disabled={isSubmitting || !existing > 0 || sameYearMonth} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copyTRRules} >Copy Mapping</MDButton>}
      {variant === "copySRRules" && <MDButton name="copyFiles" variant="gradient" color="info" sx={{ mt: 2 }} disabled={isSubmitting } startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copySRRules}>Copy Rules</MDButton>}
      {variant === "copyOfferingRules" && <MDButton name="copyFiles" variant="gradient" color="info" sx={{ mt: 2 }} disabled={isSubmitting || !existing > 0 || sameYearMonth} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copyOfferingRules}>Copy Mapping</MDButton>}
      {variant === "copySolutionOfferingRules" && <MDButton name="copyFiles" variant="gradient" color="info" sx={{ mt: 2 }} disabled={isSubmitting || !existing > 0 || sameYearMonth} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copySolutionOfferingRules}>Copy Rules</MDButton>}
      {variant === "copyBURules" && <MDButton name="copyFiles" variant="gradient" color={"info"} sx={{ mt: 2 }} disabled={isSubmitting || !existing > 0 || sameYearMonth} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copyBURules} >Copy Mapping</MDButton>}
      {variant === "copyAssetRules" && <MDButton name="copyFiles" variant="gradient" color={"info"} sx={{ mt: 2 }} disabled={isSubmitting || !existing > 0 || sameYearMonth} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copyAssetRules} >Copy Rules</MDButton>}
      {variant === "copyTeamRules" && <MDButton name="copyFiles" variant="gradient" color={"info"} sx={{ mt: 2 }} disabled={isSubmitting || !existing > 0 || sameYearMonth} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} onClick={copyCloudTeamsRules} >Copy Rules</MDButton>}

    </MDBox>
  </>
  )
}

export default CopyFiles