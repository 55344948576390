import RGL, { WidthProvider } from 'react-grid-layout';
import MDBox from "components/MDBox";
import Highcharts from 'highcharts/highcharts.src.js';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import _ from 'lodash';
// import _ from 'lodash';
// eslint-disable-next-line no-undef
require("highcharts/modules/exporting.src.js")(Highcharts);
// eslint-disable-next-line no-undef
require("highcharts/modules/offline-exporting.src.js")(Highcharts);
// const ReactGridLayout = withResizeDetector(Responsive);
// const ReactGridLayout1 = withResizeDetector(Responsive);
// const ReactGridLayout1 = Responsive;
const ReactGridLayout2 = WidthProvider(RGL);

const margins = { xl: [16, 16], lg: [8, 8], md: [8, 8], sm: [8, 8], xs: [8, 8], xxs: [8, 8] };
const breakpointCols = { xl: 12, lg: 12, md: 8, sm: 4, xs: 4, xxs: 2 };
// const breakpoints = { xl: 1400, lg: 1200, md: 1024, sm: 768, xs: 0 };
const breakpointsArr = ["xl", "lg", "md", "sm", "xs"].reverse();

const getCurrentLayout = (currentBreakpoint, layouts) => {
  if (layouts[currentBreakpoint])
    return layouts[currentBreakpoint];

  if (currentBreakpoint === "lg")
    return [];

  if (currentBreakpoint === "xl")
    return layouts["lg"] || [];

  let currentLayout = [];
  const currentBreakpointIndex = breakpointsArr.indexOf(currentBreakpoint);
  for (let index = currentBreakpointIndex; index < breakpointsArr.length; index++) {
    if (layouts[breakpointsArr[index]]) {
      currentLayout = layouts[breakpointsArr[index]];
      break;
    }
  }
  return currentLayout || [];
};

// const breakpointss = [["xl", 1400], ["lg", 1200], ["md", 1024], ["sm", 768], ["xs", 480]];
const getCurrentBreakpoint = (containerWidth) => {
  if (containerWidth <= 480)
    return "xs";
  else if (containerWidth > 480 && containerWidth <= 768)
    return "sm";
  else if (containerWidth > 768 && containerWidth <= 1024)
    return "md";
  else if (containerWidth > 1024 && containerWidth <= 1200)
    return "lg";
  return "xl";
};

const CONTAINER_PADDING = 16;
export const ResponsiveDashboardContainer1 = ({ dashboardLayouts, children }) => {
  const { width, ref } = useResizeDetector();
  const [layoutConfig, setLayoutConfig] = useState({ key: _.uniqueId(), width: null, currentBreakpoint: "xs", currentLayout: [] });

  useEffect(() => {
    // if (layoutConfig?.width !== width) {
    const currentBreakpoint = getCurrentBreakpoint(width + CONTAINER_PADDING);
    setLayoutConfig({
      key: _.uniqueId(),
      width,
      currentBreakpoint,
      currentLayout: dashboardLayouts ? getCurrentLayout(currentBreakpoint, dashboardLayouts) : []
    })
    // }
  }, [width, dashboardLayouts]);

  // console.log(layoutConfig.currentBreakpoint, width, getCurrentBreakpoint(width))

  return (
    <MDBox ref={ref}>
      {
        !layoutConfig?.width &&
        <div></div>
      }
      {
        layoutConfig?.width &&
        <ReactGridLayout2
          key={layoutConfig?.key}
          rowHeight={50}
          width={layoutConfig?.width}
          layout={layoutConfig?.currentLayout}
          preventCollision={true}
          allowOverlap={false}
          cols={breakpointCols[layoutConfig?.currentBreakpoint]}
          autoSize={true}
          compactType={null}
          margin={[8, 8]}
          containerPadding={[16, 16]}
          isDraggable={false}
          isResizable={false}
          useCSSTransforms={false}
          measureBeforeMount={true}
        >
          {children}
        </ReactGridLayout2>
      }
    </MDBox>
  );

}

export const CustomDashboardResponsiveContainer = ({ dashboardLayouts, children }) => {
  const { width, ref } = useResizeDetector();
  const [layoutConfig, setLayoutConfig] = useState({ key: _.uniqueId(), width: null, currentBreakpoint: "xs", currentLayout: [] });

  useEffect(() => {
    // if (layoutConfig?.width !== width) {
    const currentBreakpoint = getCurrentBreakpoint(width + CONTAINER_PADDING);
    setLayoutConfig({
      key: _.uniqueId(),
      width,
      currentBreakpoint,
      currentLayout: dashboardLayouts ? getCurrentLayout(currentBreakpoint, dashboardLayouts) : []
    })
    // }
  }, [width, dashboardLayouts]);

  return (
    <MDBox ref={ref}>
      {
        !layoutConfig?.width &&
        <div></div>
      }
      {
        layoutConfig?.width &&
        <ReactGridLayout2
          key={layoutConfig?.key}
          rowHeight={40}
          width={layoutConfig?.width}
          layout={layoutConfig?.currentLayout}
          preventCollision={true}
          allowOverlap={false}
          cols={breakpointCols[layoutConfig?.currentBreakpoint]}
          autoSize={true}
          compactType={null}
          margin={margins[layoutConfig?.currentBreakpoint]}
          containerPadding={[16, 16]}
          isDraggable={false}
          isResizable={false}
          useCSSTransforms={false}
          measureBeforeMount={true}
        >
          {children}
        </ReactGridLayout2>
      }
    </MDBox>
  );
}

const ResponsiveDashboardContainer = ({ currentLayout, currentBreakpoint, transformScale, children, onLayoutsChange }) => {

  const [layoutConfig, setLayoutConfig] = useState({ currentBreakpoint: currentBreakpoint });
  useEffect(() => {
    if (currentBreakpoint !== layoutConfig.currentBreakpoint) {
      setLayoutConfig({
        currentBreakpoint: currentBreakpoint,
      });
    }
  }, [currentBreakpoint]);

  const onLayoutChange = (layout) => {
    if (onLayoutsChange)
      onLayoutsChange(layout);
  };

  // const onDrop = (layout, layoutItem, _event) => {
  //   console.log(layout, layoutItem, _event);
  //   alert(`Dropped element props:\n${JSON.stringify(layoutItem, ['x', 'y', 'w', 'h'], 2)}`);
  // };

  return (
    <MDBox px={0}>
      <ReactGridLayout2
        transformScale={transformScale}
        draggableHandle=".drag-handle"
        rowHeight={40}
        layout={currentLayout}
        // preventCollision={true}
        allowOverlap={false}
        cols={breakpointCols[layoutConfig?.currentBreakpoint]}
        autoSize={true}
        compactType={'vertical'}
        margin={margins[layoutConfig?.currentBreakpoint]}
        containerPadding={[16, 16]}
        isDraggable={onLayoutsChange ? true : false}
        isResizable={onLayoutsChange ? true : false}
        // isDroppable={true}
        useCSSTransforms={false}
        measureBeforeMount={true}
        onLayoutChange={onLayoutChange}
        // onDrop={onDrop}
      >
        {children}
      </ReactGridLayout2>
    </MDBox>
  );

}

export default ResponsiveDashboardContainer;