export const monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const getFiscalMonthsArray = (startMonth) => {
    const monthIndex = monthsArray.indexOf(startMonth || "Jan");
    let fiscalMonthsArray = [];
    monthsArray.forEach((m, i) => {
        if (i >= monthIndex) {
            fiscalMonthsArray.push(m);
        }
    });
    monthsArray.forEach((m, i) => {
        if (i < monthIndex) {
            fiscalMonthsArray.push(m);
        }
    });

    return fiscalMonthsArray;
};

const applyVariables = (amount, variables) => {
    var percentageAmount = amount * (variables / 100)
    return parseFloat(Number(amount + percentageAmount));
};

const removeVariables = (amount, variables) => {
    var percentageAmount = amount * (variables / 100)
    return parseFloat(Number(amount - percentageAmount));
};

export {
    getFiscalMonthsArray,
    applyVariables,
    removeVariables
}