import fetchRequest from "utils/fetchRequest";
import InterfaceForm from "pages/Interfaces/components/InterfaceForm";
import DataStoreMappingeName from "pages/Interfaces/components/DataStoreMappingeName";

export const DataStoreInterfaceHandler = async (modelData, showAlert, showCustomForm, disableInterfaceForm, handleMappingNext) => {
    const interfaceHandle = async (dataStoreMapping) => {
        let interfaceCheck
        const [err1, data] = await fetchRequest.get('/api/interface/list')
        if (!err1) {
            interfaceCheck = data.find(obj => obj.name === dataStoreMapping.Name)
        }
        if (interfaceCheck) {
            showCustomForm("Edit Integration", () => <InterfaceForm mode="edit" interfaceId={interfaceCheck.id} handleMappingNext={handleMappingNext} />, null, null, null, 'sm');
        } else {
            showCustomForm("New Integration", () => <InterfaceForm dataStoreMapping={dataStoreMapping} handleMappingNext={handleMappingNext} />, null, null, null, 'sm');
        }
    }
    let sourceDataCheck
    const [err1, data] = await fetchRequest.get('/api/master/data-store-mapping/list')
    if (!err1) {
        sourceDataCheck = data.find(obj => obj.name === modelData.Name)
    }
    else {
        showAlert("Automate", err1?.data?.message || "Something went wrong. Contact your administrator.");
        console.error(err1);
    }
    if (sourceDataCheck) {
        let showInterfaceForm = async (sourceName) => {
            let newModelData = modelData
            newModelData.Name = sourceName
            const [error,] = await fetchRequest.post('/api/master/data-store-mapping/new', JSON.stringify(newModelData));
            if (error) {
                showAlert("Automate", error?.data?.message || "Something went wrong. Contact your administrator.");
                console.error(error);
            } else {
                if (handleMappingNext)
                    handleMappingNext()
            }
        }
        if (!disableInterfaceForm) {
            interfaceHandle(modelData)
        } else {
            showCustomForm("New Data Mapping Template", () => <DataStoreMappingeName resourceData={data} showInterfaceForm={showInterfaceForm} />, null, null, null, 'sm');
        }
    } else {
        const [error,] = await fetchRequest.post('/api/master/data-store-mapping/new', JSON.stringify(modelData));
        if (!error) {
            if (!disableInterfaceForm)
                interfaceHandle(modelData)

            if (handleMappingNext)
                handleMappingNext()
            // navigate("/admin/interfaces")
        } else {
            showAlert("Automate", error?.data?.message || "Something went wrong. Contact your administrator.");
            console.error(error);
        }
    }
}
