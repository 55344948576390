import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const { size, fontWeightMedium } = typography;
const { dark } = colors;

const tab = {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flex: "1 1 auto",
      textAlign: "center",
      // maxWidth: "unset !important",
      // minWidth: "unset !important",
      minHeight: "40px!important",
      height: "40px!important",
      fontSize: size.sm,
      fontWeight: fontWeightMedium,
      textTransform: "none",
      lineHeight: "inherit",
      // padding: pxToRem(4),
      borderRadius: pxToRem(24),
      color: dark.main,
      // opacity: "1 !important",

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      }
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};

export default tab;
