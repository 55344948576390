import { Stack } from "@mui/material";
import { AnimatedComponentChildren } from "components/AnimatedComponent";
import { AnimatedComponentInline } from "components/AnimatedComponent";
import MDTypography from "components/MDTypography";
import TextAnswer from "./TextAnswer";
import TableAnswer from "./TableAnswer";
import GraphAnswer from "./GraphAnswer";
import { useState } from "react";
import { words, capitalize } from "lodash";

const isCurrenyColumn = (columnName) => {

    if (["cost centre", "cost center", "costcentre", "costcenter", "cost pool", "costpool"].some(s => columnName.toLowerCase().indexOf(s) !== -1))
        return false;

    return ["spend", "spent", "expense", "expenditure", "variance", "budget", "amount", "cost"].some(s => columnName.toLowerCase().indexOf(s) !== -1)
};

const getColumnHeader = (columnName) => {
    return (words(columnName || "") || []).map(c => capitalize(c)).join(" ")
}

const getColumns = (data) => {
    if (!data || !Array.isArray(data) || data?.length === 0)
        return [];
    return Object.keys(data[0]).map((c) => {
        return ({
            columnLabel: getColumnHeader(c),
            columnName: c,
            columnType: isCurrenyColumn(c) ? "currency" : "string"
        })
    })
};

const getVizOptions = (data) => {

    const columns = getColumns(data);

    let vizOptions = {
        chartType: 'column-chart',
        axis: [],
        values: [],
        legend: [],
        columns: columns || [],
        config: {
            theme_colors: "greenLand",
            legend_disable: true,
            legend_floating: true,
            legend_position: "top",
            legend_symbol: "circle"
        }
    };

    const measures = columns
        .filter(c => ["number", "currency"].includes(c.columnType))
        .map(c => ({
            name: c.columnName,
            title: c.columnLabel,
            type: "MEASURE"
        }))

    const dimensions = columns
        .filter(c => !["number", "currency"].includes(c.columnType))
        .map(c => ({
            name: c.columnName,
            title: c.columnLabel,
            type: "MEASURE"
        }))

    const areTimelineDimensions = (dimensions || []).map(d => d.name.toLowerCase()).some(d => d.indexOf("year") !== -1 || d.indexOf("month") !== -1);

    if (measures?.length === 0 || dimensions?.length > 2)
        vizOptions.chartType = "data-table";
    // else if (dimensions?.length > 1 && measures?.length > 1)
    //     vizOptions.chartType = "data-table";
    else if (dimensions?.length === 2 && areTimelineDimensions && measures?.length === 2)
        vizOptions.chartType = "line-chart";
    else if (dimensions?.length === 1 && areTimelineDimensions && measures?.length > 1)
        vizOptions.chartType = "line-chart";
    else if (dimensions?.length === 2)
        vizOptions.chartType = "stacked-column-chart";


    vizOptions.values = [].concat(measures);

    dimensions.forEach((c) => {
        if (vizOptions.axis?.length === 0 || vizOptions.chartType === "line-chart")
            vizOptions.axis.push(c);
        else {
            vizOptions.legend.push(c);
            // vizOptions.config.legend_disable = false;
        }
    });

    return vizOptions;
}

const AnswerRenderer = ({ answer }) => {
    const [showDataTable, setShowDataTable] = useState(false);
    const vizOptions = getVizOptions(answer.data)

    if (vizOptions.chartType === "data-table")
        return <AnimatedComponentChildren>
            <Stack mt={1} spacing={2} alignItems={"flex-start"}>
                <TableAnswer data={answer.data} vizOptions={vizOptions} />
                {
                    answer.summary && <TextAnswer answer={answer.summary} />
                }
            </Stack>
        </AnimatedComponentChildren>

    return <AnimatedComponentChildren>
        <Stack mt={1} spacing={2} alignItems={"flex-start"}>
            {
                (showDataTable) && <TableAnswer data={answer.data} vizOptions={vizOptions} />
            }
            {
                (!showDataTable) && <GraphAnswer data={answer.data} vizOptions={vizOptions} />
            }
            <MDTypography
                variant="caption"
                fontWeight="medium"
                color="info"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                    setShowDataTable(prev => !prev)
                }}
            >
                {!showDataTable ? "Show Data" : "Show Graph"}
            </MDTypography>
            {
                answer.summary && <TextAnswer answer={answer.summary} />
            }
        </Stack>
    </AnimatedComponentChildren>
}

export default AnimatedComponentInline(AnswerRenderer);