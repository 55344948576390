import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import App from './App';
import { AppControllerProvider } from './context';
import ErrorBoundary from 'components/ErrorBoundary';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import theme from 'assets/theme';
import YADialogProvider from 'components/YADialog/YADialogProvider';
import { SocketProvider } from 'context/SocketContext';
import './assets/font/material_Icons_Outlined.woff2'
import './assets/font/material_Icons_round.woff2'
import './assets/font/material_Icons_Sharp.woff2'
import './assets/font/material_Icons_Two_Tone.woff2'
import './assets/font/material_Icons.woff2'
import './assets/font/materialicons.css'
import versionFileDef from './version.json';
import NotificationMessage from 'components/NotificationMessage';
import { NotificationsProvider } from 'context/NotificationsContext';

if (versionFileDef) {
  console.info("Version", versionFileDef.version)
}

ReactDOM.render(
  <React.StrictMode>
    <SocketProvider>
      <BrowserRouter>
        <AppControllerProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                Components={{
                  notification: NotificationMessage,
                }}
              >
                <NotificationsProvider>
                  <YADialogProvider>
                    <CssBaseline />
                    <ErrorBoundary root>
                      <App />
                    </ErrorBoundary>
                  </YADialogProvider>
                </NotificationsProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </AppControllerProvider>
      </BrowserRouter>
    </SocketProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
