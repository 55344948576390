import MDBox from 'components/MDBox';
import DataTable from 'components/DataTable';
import MDTypography from 'components/MDTypography';
import { Icon, Card, ListItem, ListItemAvatar, ListItemText, IconButton, Tooltip } from '@mui/material';
// import { Link } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import fetchRequest from 'utils/fetchRequest';
import MDButton from 'components/MDButton';
import { useYADialog } from 'components/YADialog';
import PageHeader from 'components/PageHeader';
import AnimatedRoute from 'components/AnimatedRoute';
import YASkeleton from 'components/YASkeleton';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';
import MDAvatar from 'components/MDAvatar';
// import RowMenu from 'components/RowMenu';
import MDBadge from "components/MDBadge";
import InterfaceForm from './components/InterfaceForm';
import { parseCronExpression } from 'utils';
import { generateUUID } from 'utils';
import { NotificationType } from 'constants';
import { NotificationStatus } from 'constants';
import { useNotificationSubscription } from 'hooks/useNotificationManager';
// import { formatInterfaceMetricValue } from 'utils';

const auditFieldStyles = ({ typography: { size } }) => ({
    "& .MuiListItemText-primary, & .MuiListItemText-secondary": {
        fontSize: size.xs,
        lineHeight: size.sm
    },
    "& .MuiListItemAvatar-root": {
        minWidth: "inherit",
        mr: .5
    }
})

const buildRows = (data) => {
    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, '__')] = r[k];
            });
            rows.push(row);
        });
    }
    return rows;
};

const Interfaces = () => {
    const [step, setStep] = useState('LOADING');
    const handleError = useHandleError();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [refresh, setRefresh] = useState(null);

    const { showAlert, showPrompt, showSnackbar, showCustomForm, addNotification } = useYADialog();

    useNotificationSubscription(NotificationType.Interface, (notification) => {
        console.log(notification)
        setRefresh(Math.random());
    });
    
    const handleClose = () => {
        setRefresh(Math.random());
    };

    const handleAddButtonClick = useCallback(
        () => {
            showCustomForm("New Integration", () => <InterfaceForm onClose={handleClose} />, null, null, null, 'sm');
        },
        []
    );

    const handleEdit = (pkId) => {
        showCustomForm("Edit Integration", () => <InterfaceForm mode="edit" interfaceId={pkId} onClose={handleClose} />, null, null, null, 'sm');
    };

    const handleDelete = (pkId) => {
        showPrompt('Delete', 'Are you sure you want to delete?', async () => {
            const [err, data] = await fetchRequest.delete(`/api/interface/${pkId}`);
            if (err) {
                showAlert('Delete', err?.data?.message || 'Something went wrong. Contact your administrator.');
            }
            else
                if (data && data.result === true) {
                    showSnackbar('Integration deleted successfully', 'success');
                    handleClose();
                }
                else if (data && data.result === false) {
                    showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
                }
        });
    };


    const handleRun = (pkId) => {
        const requestId = generateUUID();

        showPrompt('Run', 'Are you sure you want to run the integration?', async () => {
            const [err, data] = await fetchRequest.post(`/api/interface/${pkId}/run`, { requestId });
            if (err) {
                showAlert('Run', err?.data?.message || 'Something went wrong. Contact your administrator.');
            }
            else
                if (data && data.result === true) {
                    const notification = ({ requestId, type: NotificationType.Interface, message: "Running Integration", status: NotificationStatus.Processing, persist: true, details: { interfaceId: pkId } });
                    addNotification(notification);
                    handleClose();
                }
                else if (data && data.result === false) {
                    showAlert('Run', data.message || 'Something went wrong. Contact your administrator.');
                }
        });
    };

    const handleActivate = (pkId) => {
        showPrompt('Activate', 'Are you sure you want to activate the Integration?', async () => {
            const [err, data] = await fetchRequest.post(`/api/interface/${pkId}/activate`);
            if (err) {
                showAlert('Activate', err?.data?.message || 'Something went wrong. Contact your administrator.');
            }
            else
                if (data && data.result === true) {
                    showSnackbar('Integration activated successfully', 'success');
                    handleClose();
                }
                else if (data && data.result === false) {
                    showAlert('Activate', data.message || 'Something went wrong. Contact your administrator.');
                }
        });
    };

    const handleDeactivate = (pkId) => {
        showPrompt('Deactivate', 'Are you sure you want to deactivate the Integration?', async () => {
            const [err, data] = await fetchRequest.post(`/api/interface/${pkId}/deactivate`);
            if (err) {
                showAlert('Deactivate', err?.data?.message || 'Something went wrong. Contact your administrator.');
            }
            else
                if (data && data.result === true) {
                    showSnackbar('Integration deactivated successfully', 'success');
                    handleClose();
                }
                else if (data && data.result === false) {
                    showAlert('Deactivate', data.message || 'Something went wrong. Contact your administrator.');
                }
        });
    };

    const columns = useMemo(() => ([
        // {
        //     Header: "Name", accessor: "name", width: 250, Cell: ({ cell: { value, row: { original } } }) => {
        //         return <Link to={`/interfaces/${original["id"]}/interface-details`}><MDTypography
        //             display="flex" alignItems="center" variant="caption" color="info" fontWeight="medium"
        //             sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
        //         >{value}</MDTypography></Link>
        //     }
        // },
        {
            Header: "Actions", disableSorting: true, accessor: "id", width: 135, Cell: ({ cell: { row: { original } } }) => {
                // let options = [];
                if (original?.active) {
                    // options.push(...[
                    //     {
                    //         label: "Run Once", onClick: () => {
                    //             handleRun(original?.id)
                    //         }
                    //     },
                    //     {
                    //         label: "Deactivate", onClick: () => {
                    //             handleDeactivate(original?.id)
                    //         }
                    //     },
                    //     {
                    //         label: "Delete", onClick: () => {
                    //             handleDelete(original?.id)
                    //         }
                    //     }
                    // ]);
                    return(
                        <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
                            <IconButton sx={{ padding: 0, paddingRight: 1 }}>
                                <Icon fontSize='small' color="disabled">edit_off</Icon>
                            </IconButton>
                            <Tooltip title="Run Once" placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleRun(original?.id)}>
                                    <Icon fontSize='small'>play_arrow</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Deactivate" placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleDeactivate(original?.id)}>
                                    <Icon fontSize='small'>toggle_off</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleDelete(original?.id)}>
                                    <Icon fontSize='small' color='error'>delete</Icon>
                                </IconButton>
                            </Tooltip>
                            {/* <Tooltip title="Delete" placement="top">
                            <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onDelete(r[pkColumn])}>
                                <Icon fontSize='small' color="error">delete</Icon>
                            </IconButton>
                            </Tooltip> */}
                            
                        </MDBox>
                    )
                }
                else {
                    // options.push(...[
                    //     {
                    //         label: "Edit", onClick: () => {
                    //             handleEdit(original?.id)
                    //         }
                    //     },
                    //     {
                    //         label: "Run Once", onClick: () => {
                    //             handleRun(original?.id)
                    //         }
                    //     },
                    //     {
                    //         label: "Activate", onClick: () => {
                    //             handleActivate(original?.id)
                    //         }
                    //     },
                    //     {
                    //         label: "Delete", onClick: () => {
                    //             handleDelete(original?.id)
                    //         }
                    //     }
                    // ]);
                    return(
                        <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
                            <Tooltip title="Edit" placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleEdit(original?.id)}>
                                    <Icon fontSize='small'>edit</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Run Once" placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleRun(original?.id)}>
                                    <Icon fontSize='small'>play_arrow</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Activate" placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleActivate(original?.id)}>
                                    <Icon fontSize='small'>toggle_on</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top">
                                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleDelete(original?.id)}>
                                    <Icon fontSize='small' color='error'>delete</Icon>
                                </IconButton>
                            </Tooltip>
                            
                        </MDBox>
                    )
                }

                // return <MDBox onClick={e => e.preventDefault()}>
                //     <RowMenu key={original?.name} options={options} />
                // </MDBox>
            }
        },
        {
            Header: "Name", accessor: "name", width: 250, Cell: ({ cell: { value } }) => {
                return <MDTypography display="flex" alignItems="center" variant="caption">{value}</MDTypography>
            }
        },
        {
            Header: "Data Mapping Template", accessor: "dataStoreMapping__name", width: 250, Cell: ({ cell: { value } }) => {
                return <MDTypography display="flex" alignItems="center" variant="caption">{value}</MDTypography>
            }
        },
        {
            Header: "Triggers On", accessor: "type", Cell: ({ cell: { row: { original } } }) => {
                return <MDTypography mt={.5} display="flex" alignItems="center" variant="caption">{parseCronExpression(original["triggerCronExpression"])}</MDTypography>
            }
        },
        {
            Header: "Last Run", accessor: "lastRunAt", Cell: ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">{value ? moment(value).fromNow() : ""}</MDTypography>
            }
        },
        {
            Header: "Last Run Status", accessor: "lastRunStatus", Cell: ({ cell: { value } }) => {
                if ((value || "") === "")
                    return <MDTypography variant="caption"></MDTypography>
                return <MDBadge container badgeContent={value} color={value.toLowerCase() === 'failed' ? 'error' : value.toLowerCase() === 'partial' ? 'warning' : value.toLowerCase()} variant="gradient" size="xs" />
            }
        },
        {
            Header: "Active", accessor: "active", Cell: ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">{value === true ? "Yes" : "No"}</MDTypography>
            }
        },
        {
            Header: "Created By", accessor: "createdAt", Cell: ({ cell: { row: { original } } }) => {
                return <ListItem component="div" sx={theme => auditFieldStyles(theme)}>
                    <ListItemAvatar>
                        <MDAvatar name={original["createdByUser__name"]} size="xs" sx={{ mr: .75 }} />
                    </ListItemAvatar>
                    <ListItemText primary={original["createdByUser__name"]} secondary={original["createdAt"] ? moment(original["createdAt"]).format("MMM DD YYYY HH:mm:ss") : ""} />
                </ListItem>
            }
        },
    ]), []);

    useEffect(() => {
        async function getList() {
            setLoading(true);
            var [err, data] = await fetchRequest.get(`/api/interface/list`);
            if (err) {
                handleError(err);
            } else {
                if (data && Array.isArray(data) && data.length > 0) {
                    // var [err1, data1] = await fetchRequest.get(`/api/dataflow/uploadedFileList/`);
                    setRows(buildRows(data));
                    setStep('LOADED');
                } else {
                    setRows([]);
                    setStep('EMPTY');
                }
            }
            setLoading(false);
        }
        getList();
    }, [refresh]);

    const renderPrimaryActions = () =>
        <>
            <MDButton data-testid={"refresh"} variant="gradient" color="info" startIcon={<Icon>refresh</Icon>} onClick={() => setRefresh(Math.random())}>
                Refresh
            </MDButton>
            &nbsp; &nbsp;
            <MDButton
                variant="outlined"
                color="info"
                startIcon={<Icon>add</Icon>}
                onClick={handleAddButtonClick}
            >
                New Integration
            </MDButton>
        </>
    const renderAddButton = () => <MDButton
        variant="outlined"
        color="info"
        startIcon={<Icon>add</Icon>}
        onClick={handleAddButtonClick}
    >
        New Integration
    </MDButton>

    if (step === 'LOADING') {
        return <YASkeleton variant="dashboard-loading" />;
    }

    return (
        <>
            <PageHeader
                title={"Integrations"}
                subtitle={"Manage your integration configurations"}
                // primaryActionComponent={renderPrimaryActions}
            />
            <MDBox p={3} pt={1}>
                {step === 'EMPTY' && (
                    <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="calc(100vh - 300px)"
                    >
                        <EmptyState
                            size="large"
                            image={new_item_img}
                            title={`No Integrations Yet`}
                            description={`Click on the '+ add new' button to add a new Integration.`}
                            actions={renderAddButton}
                        />
                    </MDBox>
                )}
                {step === 'LOADED' &&
                    <>
                        <Card sx={{ height: '100%' }} px={0}>
                            <DataTable
                                table={{ columns, rows }}
                                showTotalEntries={true}
                                isSorted={true}
                                noEndBorder
                                newStyle1={true}
                                entriesPerPage={true}
                                canSearch={true}
                                // canFilter={true}
                                loading={loading}
                                primaryRender={renderPrimaryActions()}
                            />
                        </Card>
                    </>
                }

            </MDBox>
        </>
    );
};

export default AnimatedRoute(Interfaces);
