import { useState, createRef } from "react";
import { Card, Stack } from "@mui/material";
import { useDashboardContext } from "components/DashboardContext";
import { useMemo } from "react";
import DashboardItem from 'components/DashboardItem_CF';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { pickColorNum } from 'utils/table';
import colors from "assets/theme/base/cloudForecasts/colors";

const CommentsListRenderer_CF = ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet }) => {
    const forcastUpdates = resultSet ? resultSet.loadResponses ? resultSet.loadResponses[0].data && resultSet.loadResponses[0].data.length > 0 ? resultSet.loadResponses[0].data : [] : [] : []
    const [state,] = useDashboardContext();
    const chartRef = createRef();
    const nodata = forcastUpdates?.length === 0;
    const CommentTextArea = props => {
        const { text } = props;
        const textOverflowed = (text || "").length > 110;
        const [expanded, setExpanded] = useState(false);
        return (
            <MDTypography component="p" color="dark" variant="button" sx={{ whiteSpace: "normal" }}>
                {!textOverflowed && text}
                {textOverflowed && (
                    <>
                        {expanded ? text : `${(text || "").substring(0, 110)}...`}
                        <MDTypography
                            component="span"
                            variant="caption"
                            color="info"
                            ml={1}
                            sx={{ cursor: "pointer", whiteSpace: "nowrap" }}
                            onClick={() => setExpanded(s => !s)}
                        >
                            {expanded ? "Show less" : "Show more"}
                        </MDTypography>
                    </>
                )}
            </MDTypography>
        );
    }
    const avatarStyles = (color) => ({
        marginRight: 0.75,
        background: color ? color[0] : '#454545',
        fontWeight: 'bold',
        color: color ? color[1] : '#ffffff',
    })
    const avatarColorPick = (value) => {
        let colorval = pickColorNum(value)
        let color = colors.avatarColors[colorval]
        return (avatarStyles(color))
    }

    const renderComments = useMemo(() => {
        return forcastUpdates?.map((col) =>
        (<>
            <MDBox mb={1} >
                <Card pt={1.5} sx={{ width: "99%", border: "none", boxShadow: "0px 2px 8px rgba(48, 53, 109, 0.1);", borderRadius: "12px" }}>
                    <MDBox p={1.5} className="commentBox">
                        <Stack
                            spacing={1}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Stack spacing={1} direction="row" alignItems="center">
                                <>
                                    <MDAvatar name={vizOptions.userName ? col[vizOptions.userName] ? col[vizOptions.userName] : "" : ""} size="xs" />
                                    <MDTypography variant="caption" color="dark" fontWeight="medium">
                                        {vizOptions.userName ? col[vizOptions.userName] ? col[vizOptions.userName] : "" : ""}
                                    </MDTypography>
                                </>
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {vizOptions.createDate ? col[vizOptions.createDate] ? moment(col[vizOptions.createDate]).format("MMM DD YYYY, hh:mm A") : "" : ""}
                                </MDTypography>
                                {
                                    vizOptions.teamName &&
                                        <>
                                            <MDAvatar name={vizOptions.teamName ? col[vizOptions.teamName] ? col[vizOptions.teamName].trim().replaceAll("  ", "") : "" : ""} size="xs" sx={() => avatarColorPick(vizOptions.teamName ? col[vizOptions.teamName] ? col[vizOptions.teamName].trim().replaceAll("  ", "") : "" : "")} />
                                                <MDTypography variant="caption" color="dark" fontWeight="medium">
                                                    {vizOptions.teamName ? col[vizOptions.teamName] ? col[vizOptions.teamName] : "" : ""}
                                            </MDTypography>
                                        </>
                                }
                            </Stack>
                        </Stack>
                        <MDBox px={4}>
                            <CommentTextArea text={vizOptions.comment ? col[vizOptions.comment] ? col[vizOptions.comment] : "" : ""} />
                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
        </>))
    }, [forcastUpdates, state.filters])

    if (loading)
        return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>
    return (<>
        <DashboardItem nodata={nodata} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} noOptions={(vizOptions.compactView || vizOptions.noOptions) ? true : false} >
            <PerfectScrollbar>
                <MDBox m={2} pb={4}>
                    {renderComments}
                </MDBox>
            </PerfectScrollbar>
        </DashboardItem>
    </>)
}


export default CommentsListRenderer_CF;