import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Icon, IconButton, Checkbox, FormControlLabel } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import YADialogForm from "components/YADialogForm";
import YADrawerForm from "components/YADrawerForm";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import ReportPopup from "components/ReportPopup";
import YADialogCustomForm from "components/YADialogCustomForm";
import YADrawerCustomForm from "components/YADrawerCustomForm";
import DataloadDialog from "components/DataloadDialog";
import { useNavigate } from "react-router-dom";
import colors from 'assets/theme/base/colors';
import AskMeDialog from "components/AskMeDialog";
import DashboardCopilotExplain from "components/DashboardCopilotExplain";
import YADrawer from "components/YADrawer";
export const YADialogType = {
    "ALERT": "alert",
    "PROMPT": "prompt",
    "FORM": "form",
    "CUSTOM_FORM": "custom_form",
    "DRAWER": "drawer",
    "CUSTOM_DRAWER_FORM": "custom_drawer_form",
    "SEARCH": "search",
    "REPORT": "report",
    "UPLOAD_DIALOG": "upload_dialog",
    "DASHBOARD_COPILOT": "dashboard_copilot"
}

const YADialogContainer = (props) => {
    const { domRoot, dialogs, onRequestClose } = props;
    const [ checked, setChecked ] = useState(false);
    let navigate = useNavigate()
    const handleRequestClose = useCallback((dialog, returnValue) => {
        if (onRequestClose) {
            onRequestClose(dialog);
        }
        if (dialog.onClose) {
            dialog.onClose(returnValue);
        }
        setChecked(false)
    }, []);

    let navigateToPage = (linkTo, dialog) => {
        handleRequestClose(dialog)
        linkTo && linkTo !== "" && navigate(linkTo, { state: {} })
    }
    const handlePromptSuccess = (dialog, checked) => {
        if (dialog.onSuccess) {
            dialog.onSuccess(checked);
        }
        setChecked(false)
        handleRequestClose(dialog);
    }

    const handlePromptFailure = (dialog) => {
        if (dialog.onFailure) {
            dialog.onFailure();
        }
        if (onRequestClose) {
            onRequestClose(dialog);
        }
        setChecked(false)
    }

    const renderDialog = (dialog) => {
        const { id, type, title, message, hyperlink, hypertext, reportId, filters, checkBoxProps, variant } = dialog;
        const key = id || new Date().getTime();

        if (type === YADialogType.FORM) {
            return (
                <YADialogForm key={key} dialog={dialog} onClose={() => handleRequestClose(dialog)} />
            );
        }
        else if (type === YADialogType.CUSTOM_FORM) {
            return (
                <YADialogCustomForm key={key} dialog={dialog} onClose={() => handleRequestClose(dialog)} />
            );
        }
        else if (type === YADialogType.DRAWER) {
            return (
                <YADrawerForm key={key} dialog={dialog} onClose={() => handleRequestClose(dialog)} />
            );
        }
        else if (type === YADialogType.CUSTOM_DRAWER_FORM) {
            return (
                <YADrawerCustomForm key={key} dialog={dialog} onClose={() => handleRequestClose(dialog)} />
            );
        }
        else if (type === YADialogType.UPLOAD_DIALOG) {
            return (
                <DataloadDialog key={key} title={title} uploadConfig={dialog.uploadConfig} onClose={(returnValue) => handleRequestClose(dialog, returnValue)} />
            );
        }
        else if (type === YADialogType.SEARCH) {
            return (
                (<YADrawer key={key} open={true} hideHeader={true} topPadded={false} width={600} onClose={() => handleRequestClose(dialog)}>
                    <AskMeDialog onClose={() => handleRequestClose(dialog)} />
                </YADrawer>)
            );
        }
        else if (type === YADialogType.DASHBOARD_COPILOT) {
            return (
                (<YADrawer key={key} open={true} hideHeader={true} topPadded={false} width={500} onClose={() => handleRequestClose(dialog)}>
                    <DashboardCopilotExplain config={dialog.config} onClose={() => handleRequestClose(dialog)} />
                </YADrawer>)
            );
        }
        else if (type === YADialogType.REPORT) {

            return (<Modal key={key} open={true} onClose={() => handleRequestClose(dialog)}>
                <MDBox p={3} height="100%" width="100%" overflow="auto">
                    <ReportPopup reportId={reportId} filters={filters} onClose={() => handleRequestClose(dialog)} />
                </MDBox>
            </Modal>)
        }
        else if (type === YADialogType.PROMPT) {

            return (<Dialog
                key={key}
                open={true}
                onClose={() => handleRequestClose(dialog)}
                maxWidth={variant}
            >
                {
                    dialog.cancelButtonText &&
                    <IconButton onClick={() => { handleRequestClose(dialog) }} sx={{ position: "absolute", right: 1, top: 2, backgroundColor: "white!important" }}>
                        <Icon>close</Icon>
                    </IconButton>
                }
                <DialogTitle sx={{ paddingLeft: 3 }}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText maxWidth={variant??450} sx={{ width: variant ? 'fit-content' : undefined, paddingLeft: 1 }}>
                        {message}
                    </DialogContentText>
                    {checkBoxProps && <FormControlLabel 
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(e) => {setChecked(e.target.checked)}}
                                inputProps={{ 'aria-label': 'controlled'}}
                                sx={{pr: 0, mr: 1, "& .MuiSvgIcon-root": { height: 16, width: 1, border: "1.5px solid #c5c9cc", borderRadius: "4px" } }}
                            />
                        } 
                        label={checkBoxProps.label} 
                    />}

                </DialogContent>
                <DialogActions>
                    {!dialog.cancelButtonHidden && <Button onClick={() => handlePromptFailure(dialog)}>
                        {dialog.cancelButtonText || "Cancel"}
                    </Button>}
                    <Button onClick={() => handlePromptSuccess(dialog, checked)}>
                        {dialog.okButtonText || "Ok"}
                    </Button>
                </DialogActions>
            </Dialog>)
        }

        return (
            <Dialog
                key={key}
                open={true}
                onClose={() => handleRequestClose(dialog)}
            >
                <DialogTitle sx={{ paddingLeft: 3 }}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText maxWidth={500} sx={{ paddingLeft: 1 }}>
                        {message}
                    </DialogContentText>
                </DialogContent>

                {
                    hyperlink && hyperlink !== "" &&
                    <MDBox display="flex" pt={0} mr={1} flexDirection="row" justifyContent="flex-end">
                        <MDTypography style={{ bottom: '1px', right: '15px' }} variant="button" px={0.5} py={0.5} fontWeight="medium" whiteSpace="nowrap" sx={{ "&:hover": { cursor: 'pointer', backgroundColor: colors.linkBackColour ? colors.linkBackColour : "light" }, color: colors.linkColour, borderRadius: "5px" }} color={colors.linkColour ? colors.linkColour : "dark"} onClick={() => { navigateToPage(hyperlink, dialog) }}>
                            {hypertext}&nbsp;
                        </MDTypography>
                    </MDBox>
                }
                <DialogActions>
                    <Button onClick={() => handleRequestClose(dialog)}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );

    };

    const dialogElements = dialogs && dialogs.length > 0 ? (
        dialogs.map(d => renderDialog(d))
    ) : null;

    return domRoot ? createPortal(dialogElements, domRoot) : dialogElements
};

export default YADialogContainer;