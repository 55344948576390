import MDBox from "components/MDBox";
import CloudSpendDetails from "./CloudSpendDetails"

const CloudSpend = (props) => {
    const { yearFilter, monthFilter, yearFilterName, monthFilterName } = props
    return (
        <MDBox width="100%" px={1} pt={1} >
            <CloudSpendDetails 
                yearFilter={yearFilter} 
                yearFilterName={yearFilterName} 
                monthFilter={monthFilter} 
                monthFilterName={monthFilterName}
            />
        </MDBox>
    )
};

export default CloudSpend;