import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import RichTextarea from "components/RichTextarea";
import YAScrollbar from "components/YAScrollbar";

const textStyles = (textColorVal) => ({
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& .MuiTypography-root": {
        color: textColorVal
    }
});

const TextRenderer = ({ vizOptions }) => {
    const { config } = vizOptions;
    let configValue = config || {};

    const textColorVal = configValue["text_color"] || "#333333";
    const cardTitle = configValue["card_title"];
    const cardSubtitle = configValue["card_subtitle"];
    const cardBodyText = configValue["card_body_text"];

    return (
        <MDBox sx={() => textStyles(textColorVal)}>
            <MDBox display="flex" flexDirection="column" overflow="hidden">
                <MDTypography variant="button" component="span" color="text" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" fontWeight="medium">
                    {cardTitle}
                </MDTypography>
                {
                    cardSubtitle && (
                        <MDTypography variant="caption" color="text" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" mt={0.1}>
                            {cardSubtitle}
                        </MDTypography>
                    )
                }
            </MDBox>
            <MDBox display="flex" mt={1} flexDirection="column" flex={1} overflow="hidden">
                <YAScrollbar>
                    <RichTextarea value={cardBodyText} />
                </YAScrollbar>
            </MDBox>
        </MDBox>
    );
}

export default TextRenderer