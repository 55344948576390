import { CircularProgress, Icon, IconButton, Stack } from "@mui/material";
import { AnimatedComponentInline } from "components/AnimatedComponent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useCallback, useEffect, useState } from "react";
// import useLocalStorage from "hooks/useLocalStorage";
// import _ from "lodash";
import moment from "moment";
import fetchRequest from "utils/fetchRequest";
import EmptyState from "components/EmptyState";

const conversationBoxStyles = () => ({
    mb: 2,
    justifyContent: "space-between",
    backgroundColor: "#efefef",
    borderRadius: "8px",
    cursor: "pointer",
    "& .MuiIconButton-root": {
        display: "none",
    },
    "&:hover .MuiIconButton-root": {
        display: "inline-flex",
    }
})

const ConversationBox = AnimatedComponentInline(({ session, onChatSelection, onChatDelete }) => (
    <Stack direction="row" sx={() => conversationBoxStyles()}>
        <MDTypography p={1.75} width="100%" variant="caption" whiteSpace="pre-wrap" onClick={() => { onChatSelection(session.sessionUid) }}>{session.initialQuestionText}</MDTypography>
        <MDTypography p={1.5} variant="caption">{session.updatedAt ? moment(session.updatedAt).fromNow() : ""}
            <IconButton onClick={() => { onChatDelete(session.id) }} title="Delete Converasation" size="small" sx={{ ml: 2, p: 0 }}><Icon>delete</Icon></IconButton>
        </MDTypography>
    </Stack>
))

const ConversationHistory = ({ onNewChat, onChatSelection, onClose }) => {

    const [sessions, setSessions] = useState({ loading: true, data: null, error: null });

    useEffect(async () => {

        const [error, response] = await fetchRequest.get(`/api/askme/sessions/`)

        if (error)
            setSessions(prev => ({ ...prev, loading: false, error }))
        else {
            setSessions(prev => ({ ...prev, data: response, loading: false }))
        }


    }, [])

    const handleDeleteConversation = useCallback(async (id) => {

        const [error,] = await fetchRequest.delete(`/api/askme/sessions/${id}`)

        if (error)
            console.error(error)
        else {
            setSessions(prev => ({ ...prev, data: prev.data?.filter(s => s.id !== id) }))
        }

    }, []);

    const render = () => {
        if (sessions.loading)
            return <MDBox display="flex" alignItems="center" justifyContent="center" height="100%">
                <CircularProgress size={40} />
            </MDBox>

        if ((sessions.data || [])?.length === 0)
            return <MDBox display="flex" alignItems="center" justifyContent="center" height="100%">
                <EmptyState
                    size="large"
                    title={"No Conversations Yet"}
                />
            </MDBox>

        if (sessions.error) {
            console.error(sessions.error)
            return <MDBox display="flex" alignItems="center" justifyContent="center" height="100%">
                <EmptyState
                    size="large"
                    title={"Something went wrong!"}
                />
            </MDBox>
        }

        return (
            <MDBox p={4} width="100%">
                {
                    (sessions.data || []).map(session => {
                        return <ConversationBox key={session.sessionUid} session={session} onChatSelection={onChatSelection} onChatDelete={handleDeleteConversation} />
                    })
                }
            </MDBox>
        )
    }

    return (
        <>
            <MDBox width="100%">
                <MDBox
                    pl={3}
                    pr={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    height={48}
                >
                    <MDTypography variant="subtitle2" fontWeight={"medium"}>Ask YäRKEN History</MDTypography>
                    <Stack direction={"row"} spacing={1}>
                        <IconButton title="New Chat" onClick={onNewChat}>
                            <Icon>add</Icon>
                        </IconButton>
                        <IconButton onClick={onClose} title="Close">
                            <Icon>close</Icon>
                        </IconButton>
                    </Stack>
                </MDBox>
            </MDBox>
            <MDBox height="100%" width="100%" overflow="auto">
                {render()}
            </MDBox>
        </>
    );
}

export default ConversationHistory;