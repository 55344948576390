import { Card, Icon, IconButton, MenuItem, Modal, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import { useState } from "react";
import ListRule from "../ListRule";

const BURuleModal = (props) => {
    const { enableAssetDistribution, yearFilter, monthFilter, selectedBusinessUnits, businessUnits, onAddRuleSave, onAddRuleClose, item, rowId, ruleName } = props;
    const [tierAllocation, setTierAllocation] = useState(item.tierAllocation ? item.tierAllocation : [0, 0, 0, 0, 0]);
    const [businessUnitSingle, setbusinessUnitSingle] = useState(item.name ? item.name : "");
    const [destinationTable, setDestinationTable] = useState(item.destinationTable ? (item.destinationTable === "multiple" ? "" : item.destinationTable) : "");
    const [usage, setUsage] = useState(item.usage ? parseFloat(item.usage) : "");

    const [splitStrategy, setSplitStrategy] = useState(item.splitStrategy ? item.splitStrategy : null);
    const [weightColumn, setWeightColumn] = useState(item.weight ? item.weight : "");
    const [condition, setCondition] = useState(item.condition ? item.condition : "");
    const [errors, setErrors] = useState({});
    const [distributionType, setDestributionType] = useState("")
    const [showListField, setShowListField] = useState(false);

    const handleClearAssetSelection = () => {
        setErrors({});
        setDestinationTable("");
        setSplitStrategy(null);
        setWeightColumn("");
        setCondition("");
        setTierAllocation([0, 0, 0, 0, 0]);
    }

    const handleOnSplitStrategyChange = (strategyValue) => {
        setTierAllocation([0, 0, 0, 0, 0]);
        setWeightColumn("");
        setSplitStrategy(strategyValue)
    }

    const handleAddRuleClose = () => {
        if (onAddRuleClose)
            onAddRuleClose()
    }

    const validateData = () => {
        let err = false;
        let e = {};

        if (businessUnitSingle === "") {
            e.businessUnitSingle = true;
            err = true;
        }
        let validateUsageText = /^([0-9]{1,2}\.[0-9]{1,2})$|^([0-9]{1,2})$/
        if ((isNaN(parseFloat(usage)) || usage === "" || (parseFloat(usage) <= 0 ))) {
            e.usage = true
            err = true;
        }
        if (!(validateUsageText.test(usage))) {
            e.usage = true
            err = true;
            if (parseFloat(usage) > 0) {
                e.usage = false;
                err = false;
            }
        }
        // if ((usage === "" || !usage.match(/^\d+(\.\d+)?$/))) {
        //     e.usage = true
        //     err = true;
        // }
        if (splitStrategy === "byWeight" && weightColumn === "") {
            e.weightColumn = true;
            err = true;
        }

        return [err, e];
    }

    const saveRule = async () => {
        const [err, e] = validateData();

        if (!err) {
            let sub = _.find(businessUnits, { 'code': businessUnitSingle.split(" | ")[0], 'id': parseInt(businessUnitSingle.split(" | ")[1]) })
            const allocation = { name: businessUnitSingle, desc: sub["name"], usage: parseFloat(usage).toFixed(2), destinationTable: destinationTable, condition: condition, splitStrategy: splitStrategy, weight: weightColumn, tierAllocation: tierAllocation };
            if (onAddRuleSave) {
                onAddRuleSave(allocation, rowId);
                handleAddRuleClose();
            }
        } else {
            setErrors(e);
        }
    }
    const newselectedBusinessUnits = businessUnitSingle !== "" ? selectedBusinessUnits.filter(item => item !== businessUnitSingle) : selectedBusinessUnits
    const readOnly = (ruleName && ruleName !== "")

    return (
        <Modal open={true} onClose={handleAddRuleClose}>
            <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Card sx={{ minHeight: "600px", width: "600px", overflow: 'hidden' }}>
                    <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox>
                            <MDTypography variant="h6" component="span" color="text">Add Business Unit</MDTypography>
                        </MDBox>
                        <MDBox display="flex">
                            <IconButton onClick={handleAddRuleClose} title="Close">
                                <Icon>close</Icon>
                            </IconButton>
                        </MDBox>
                    </MDBox>
                    <MDBox height="100%" textAlign="center" pb={2} >
                        <MDBox pt={2} height="478px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ overflowY: "auto" }}>
                            <MDTypography variant="subtitle2" fontWeight="medium" color={errors?.businessUnitSingle ? "error" : "dark"}>Choose a Business Unit and assign a usage to it.</MDTypography>
                            <MDBox pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                                <TextField focused label="Business Unit" sx={{ minWidth: 300 }} name="businessUnit" select margin="dense" variant="outlined"
                                    onChange={(e) => { setErrors({}); setbusinessUnitSingle(e.target.value) }}
                                    value={businessUnitSingle}
                                    error={errors?.businessUnitSingle}
                                    disabled={readOnly}
                                >
                                    {businessUnits?.filter((item) => !newselectedBusinessUnits?.includes(`${item.code} | ${item.id}`)).map((item) => <MenuItem key={`${item.id}`} value={`${item.code} | ${item.id}`}>{item["name"]}</MenuItem>)}
                                </TextField>
                                <TextField focused
                                    label="Usage" sx={{ width: 100, marginLeft: 0.5, textAlign: "center", paddingRight: 0 }} id="usage" name="usage" value={usage}
                                    disabled={readOnly}
                                    error={errors?.usage}
                                    onChange={(e) => { setErrors({}); setUsage(e.target.value) }} margin="dense" variant="outlined" >
                                </TextField>
                            </MDBox>
                            {errors && errors.usage && <MDTypography mt={1} variant="caption" color="error">Usage is required and it should be numeric</MDTypography>}
                            {
                                enableAssetDistribution && businessUnitSingle && businessUnitSingle !== "" && distributionType === "" && (
                                    <MDBox display="flex" flexDirection="row">
                                        <MDButton
                                            size="small"
                                            sx={{ mt: 1, ml: 1, textTransform: "none" }}
                                            variant="outlined"
                                            color="info"
                                            onClick={() => { setDestributionType("asset"); setShowListField(true) }}>Distribute to Asset?</MDButton>
                                    </MDBox>
                                )
                            }
                            {distributionType === "asset" && (
                                <ListRule
                                    yearFilter={yearFilter}
                                    monthFilter={monthFilter}
                                    destinationTable={destinationTable}
                                    splitStrategy={splitStrategy}
                                    weightColumn={weightColumn}
                                    tierAllocation={tierAllocation}
                                    condition={condition}
                                    onDestinationTableChange={setDestinationTable}
                                    onSplitStrategyChange={handleOnSplitStrategyChange}
                                    onWeightColumnChange={setWeightColumn}
                                    onTierAllocationChange={setTierAllocation}
                                    onConditionChange={setCondition}
                                    onClearAssetSelection={handleClearAssetSelection}
                                    errors={errors}
                                    showListField={showListField}
                                />
                            )}
                        </MDBox>
                        <MDBox pt={1} pr={2} sx={{ backgroundColor: "white", textAlign: "right" }}>
                            <MDButton name="saveRules" variant="gradient" color="info" onClick={saveRule}>Save Rule</MDButton>
                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
        </Modal>
    );
};

export default BURuleModal;