import { useCallback, useEffect } from "react";
import MDBox from "components/MDBox";
import useFetchRequest from "hooks/useFetchRequest";
import { useImmer } from "use-immer";
import DataFieldsPanel from "pages/Analytics1/components/DataFieldsPanel";
import { CircularProgress, Icon } from "@mui/material";

const CubeColumnsSelectorDrawer = props => {
    const { cubeId, dropType, filter, onFieldSelectionChange } = props;
    const { response, error, loading } = useFetchRequest(`/api/cube/${cubeId}`);
    const [cubes, setCubes] = useImmer([]);

    useEffect(() => {
        if (!loading) {
            if (error !== null) {
                console.error(error)
            }
            else if (response !== null) {
                setCubes(response?.cubes);
            }
        }
    }, [loading, response]);

    const handleOnSelection = useCallback(({ type, name, title, dataType, decimalPoints, hideTotal }, checked) => {
        onFieldSelectionChange(dropType, { type, name, title, dataType, decimalPoints, hideTotal }, checked)
    }, []);

    if (loading)
        return <MDBox height="100vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </MDBox>

    if (error)
        return <MDBox height="100vh" display="flex" alignItems="center" justifyContent="center">
            <Icon
                fontSize="60"
                color="error"
                sx={{
                    fontSize: 40
                }}>warning_amber</Icon>
        </MDBox>

    return <MDBox height="100vh" pt={2} display="flex" flexDirection="column">
        <MDBox flex={1}>
            <DataFieldsPanel cubes={cubes} onSelection={handleOnSelection} filter={filter} />
        </MDBox>
        {/* <MDBox height={200}>
        </MDBox> */}
    </MDBox>
}

export default CubeColumnsSelectorDrawer;