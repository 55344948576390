import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import FilteredUploadedFiles from "components/FilteredUploadedFiles";
import CompanionMetricsManage from "./components/CompanionMetricsManage";
import CompanionMetricsView from "./components/CompanionMetricsView";
import FilteredUploadRules from 'components/FilteredUploadRules';
import { useState } from "react";

const CompanionMetricPage = () => {
    const [typeFilter, setTypeFilter] = useState("manage")

    const tabStyles = (_theme, { selected }) => ({
        color: selected ? "#435EC3" : "#adadad",
        textTransform: "none",
        backgroundColor: "#F7F8FD",
        "& .MuiButtonBase-root": {
            fontSize: "18px!important",
            transform: "none",
            backgroundColor: "#435EC3",
            
        },
        "&::after": selected ? {
            content: '""',
            position: "absolute",
            bottom: 0,
            height: 4,
            width: "60%",
            borderRadius: "0px",
            backgroundColor: "#435EC3"
        } : {}
    });

    return (
        <MDBox width="100%" px={1} pt={1} >
            <MDBox display="block" maxHeight="calc(100vh - 68px)" width="100%" overflow="auto">
                <PageHeader title="Companion Metrics" subtitle="Screen to manage Companion Metrics" />
                <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", borderBottom: "1px solid #edeef3", borderTop: "1px solid #e3e3e3", display: "inline-flex" }} justifyContent="space-between">
                    <MDBox display="flex">
                    <MDButton data-testid={"metrics"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "manage" })} onClick={() => setTypeFilter("manage")}>
                        <Icon color="dark" sx={{ marginRight: "6px" }} fontSize="50%">bookmarks</Icon>
                        METRICS
                    </MDButton>
                    <MDButton data-testid = {"manage"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "view" })} onClick={() => setTypeFilter("view")} >
                        <Icon color="dark" sx={{ marginRight: "6px" }} fontSize="50%">compare</Icon>
                        COMPANION METRICS
                    </MDButton>
                    <MDButton data-testid = {"upload"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "upload" })} onClick={() => setTypeFilter("upload")} >
                        <Icon color="dark" sx={{ marginRight: "6px" }} fontSize="50%">file_present</Icon>
                        UPLOADED FILES
                    </MDButton>
                    <MDButton data-testid = {"uploadRule"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "uploadRule" })} onClick={() => setTypeFilter("uploadRule")} >
                        <Icon color="dark" sx={{ marginRight: "6px" }} fontSize="50%">fact_check</Icon>
                        UPLOAD RULES
                    </MDButton>
                    </MDBox>
                </MDBox>
                {typeFilter === 'manage' && <CompanionMetricsManage masterId={"companion-metric-lookups"}/>}
                {typeFilter === 'upload' && <FilteredUploadedFiles containerHeight="calc(100vh - 370px)" canFilter={true} fileName={['Metrics', 'Companion Metric']} master={true} />}
                {typeFilter === 'view' && <CompanionMetricsView masterId={"companion-metric"}/>}
                {typeFilter === 'uploadRule' && <FilteredUploadRules containerHeight="calc(100vh - 500px)" tableName={["companionMetric", "companionMetricLookup"]} />}
            </MDBox>
        </MDBox>
    )
};

export default AnimatedRoute(CompanionMetricPage);