import PropTypes from "prop-types";
import { styled } from "@mui/material";
import { autocompleteClasses } from '@mui/material/Autocomplete';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'center',
      padding: '4px 8px',
      marginBottom: 2,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      }
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const PopperComponent = (props) => {
  // const { disablePortal, anchorEl, open, ...other } = props;
  const { ...other } = props;
  // console.log(disablePortal, anchorEl, open)
  return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

export default PopperComponent;
