import MDBox from 'components/MDBox';
import AnimatedRoute from 'components/AnimatedRoute';
import MDTypography from 'components/MDTypography';

const CapsuleText = (props) => {
    const { leftSideText,rightSideText, colorText } = props
    return (
        <>
        <MDBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center','& > *': { mr: 2 },}}>

            <MDBox display="flex" flexDirection="row" sx={{ border: `2px solid #3FA2F6`,borderRadius : 2}}>
                <MDTypography px={2} py={1} component="span" fontWeight={"medium"} sx={{ color: "#FFFFFF", bgcolor: "#3FA2F6", fontSize: "13px"}} >{leftSideText}</MDTypography>
                <MDTypography px={2} py={1} component="span" color= {colorText ? colorText : "#000000"} fontWeight={"medium"} sx={{  bgcolor: "#ffffff", fontSize: "13px", borderRadius : 2}} >{rightSideText}</MDTypography>
            </MDBox>

        </MDBox>
        </>
    )
}


export default AnimatedRoute(CapsuleText);