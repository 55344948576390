import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Icon, MenuItem, Popper, ClickAwayListener } from "@mui/material";
import ViewRenderer from "../ViewRenderer";
import { useState, useRef } from "react";
import { styled } from '@mui/material/styles';

const TBMTaxonomy = (props) => {
    const { selectedCategoryDef } = props;
    const [typeFilter, setTypeFilter] = useState(selectedCategoryDef?.categories[0]?.name || null);
    const [subMenuFilter, setSubMenuFilter] = useState(selectedCategoryDef?.categories[0]?.subMenu[0]?.name || null);
    const [subMenu, setSubMenu] = useState(selectedCategoryDef?.categories[0]?.subMenu || null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopper, setOpenPopper] = useState(false);
    const popperRef = useRef(null);

    const StyledPopper = styled(Popper)(({ theme }) => ({
        border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
        boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
        borderRadius: 4,
        padding: 8,
        zIndex: theme.zIndex.modal,
        fontSize: 13,
        color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    }));

    const tabStyles1 = (_theme, { selected }) => ({
        color: selected ? "#435EC3" : "#adadad",
        textTransform: "none",
        backgroundColor: "#F7F8FD",
        "& .MuiButtonBase-root": {
            fontSize: "18px!important",
            transform: "none",
            backgroundColor: "#435EC3",
        },
        "&::after": selected
            ? {
                content: '""',
                position: "absolute",
                bottom: 0,
                height: 4,
                width: "60%",
                borderRadius: "0px",
                backgroundColor: "#435EC3",
            }
            : {},
    });

    const handleMouseEnter = (event, element) => {
        setSubMenu(element.subMenu);
        setAnchorEl(event.currentTarget);
        setOpenPopper(true);
    };

    const handleMouseLeave = () => {
        setOpenPopper(false);
    };

    const findCategoryName = (itemName, categories) => {
        for (const category of categories) {
            if (category.subMenu.some(subMenuItem => subMenuItem.name === itemName)) {
                return category.name;
            }
        }
        return null;
    };

    const handleSubMenuClick = (item) => {
        const typeFilterName = findCategoryName(item.name, selectedCategoryDef.categories);
        if (typeFilterName)
            setTypeFilter(typeFilterName);

        setSubMenuFilter(item.name);
        setOpenPopper(false);
    };

    return (
        <MDBox width="100%" position="relative">
            <MDBox display="block" width="100%" overflow="auto">
                <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", borderBottom: "1px solid #edeef3", borderTop: "1px solid #e3e3e3", display: "inline-flex", marginBottom: "10px" }} justifyContent="space-between">
                    <MDBox display="flex" position="relative" zIndex="1">
                        {selectedCategoryDef?.categories?.map((element) => (
                            <MDButton
                                key={element.id}
                                data-testid={element.name}
                                sx={(theme) => tabStyles1(theme, { selected: typeFilter === element.name })}
                                onMouseEnter={(event) => handleMouseEnter(event, element)}
                            >
                                <Icon color="dark" sx={{ marginRight: "6px" }} fontSize="50%">{element.icon || "pie_chart"}</Icon>
                                {element.displayName}
                                <Icon fontSize="50%" sx={{color: "#435EC3", fontSize: "16px!important" }}>keyboard_arrow_down</Icon>
                            </MDButton>
                        ))}
                        <StyledPopper
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl) && openPopper}
                            placement="bottom-start"
                            ref={popperRef}
                            disablePortal={false}
                            onMouseEnter={() => setOpenPopper(true)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <ClickAwayListener onClickAway={handleMouseLeave}>
                                <MDBox>
                                    {subMenu?.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            selected={subMenuFilter === item.name}
                                            onClick={() => handleSubMenuClick(item)}
                                        >
                                            {item.displayName}
                                        </MenuItem>
                                    ))}
                                </MDBox>
                            </ClickAwayListener>
                        </StyledPopper>
                    </MDBox>
                </MDBox>
            </MDBox>
            {subMenuFilter && typeFilter === 'cost-pool' && (
                <MDBox position="absolute" top="100%" left="0" right="0" zIndex="0">
                    <ViewRenderer masterId={subMenuFilter} />
                </MDBox>
            )}
            {subMenuFilter && typeFilter === 'tower' && (
                <MDBox position="absolute" top="100%" left="0" right="0" zIndex="0">
                    <ViewRenderer masterId={subMenuFilter} />
                </MDBox>
            )}
            {subMenuFilter && typeFilter === 'solutions' && (
                <MDBox position="absolute" top="100%" left="0" right="0" zIndex="0">
                    <ViewRenderer masterId={subMenuFilter} />
                </MDBox>
            )}
        </MDBox>
    );
};

export default TBMTaxonomy;
