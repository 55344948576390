import ChartRenderer from "components/ChartRenderer";
import { useEffect, useState } from "react";
import fetchRequest from "utils/fetchRequest";
// import FilterContainer from "components/FilterContainer";
import { useImmer } from "use-immer";
// import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import MDBox from "components/MDBox";
import YASkeleton from "components/YASkeleton";
import useHandleError from "hooks/useHandleError";
// import colors from "assets/theme/base/colors";
import { ResponsiveDashboardContainer1 } from "components/ResponsiveDashboardContainer";
import { DashboardContextProvider, initDashboardContext, useDashboardContext } from "components/DashboardContext";
import { applyDefaultFilters, } from "utils/dashboard";
import _ from 'lodash';

const RenderChart = (props) => {
  const { teamFilter, menuItem, rollingMonthsResponse } = props;
  const handleError = useHandleError();
  const [loading, setLoading] = useState(true);
  const [dashboardDef, setDashboardDef] = useImmer(null);
  const [filtersCleared, setFiltersCleared] = useState(false)
  const [, dispatch] = useDashboardContext();
  const dashboardId = menuItem == 'Financial Year' ? 'forecast-overview' : 'forecast-overview-rolling'

  useEffect(() => {
    async function getDashboardDef() {
      var [err, data] = await fetchRequest.get(`/api/forecast/${dashboardId}`)
      if (err) {
        handleError(err);
      }
      else {
        let newDashboardDef = applyDefaultFilters(data);
        setDashboardDef(newDashboardDef);
        initDashboardContext(dispatch, newDashboardDef.filters, newDashboardDef.variables);
        setLoading(false);
        setFiltersCleared(false)
      }
    }
    getDashboardDef();
  }, [dashboardId, filtersCleared, loading]);

  if (loading) {
    return <YASkeleton variant="dashboard-loading" />;
  }
  let addCubeOptionsFilters = (item) => {
    let cubeOptions
    if (item.cubeOptions) {
      cubeOptions = { ...item.cubeOptions };
      let cubeFilters = [];
      let yearFilterDef = dashboardDef?.filters?.find(item => item.queryName === "Years.year")
      if (yearFilterDef) {
        let yearFilterObj = {
          "type": "DIMENSION",
          "name": yearFilterDef.queryName,
          "title": "Year",
          "dataType": "string",
          "operator": "equals",
          "values": yearFilterDef.values
        };
        cubeFilters.push(yearFilterObj);

      }
      if (teamFilter) {
        let teamFilterObj = {
          "type": "DIMENSION",
          "name": "CloudTeams.name",
          "title": "Team",
          "dataType": "string",
          "operator": "equals",
          "values": [teamFilter]
        };
        cubeFilters.push(teamFilterObj);
      }
      cubeOptions.vizData = { ...cubeOptions.vizData, filters: cubeFilters };
    }
    return cubeOptions
  }
  const dashboardItem = (item) => {
    let cubeOptions = addCubeOptionsFilters(item)
    return (
      <div key={item.id}>
        <ChartRenderer title={item.name} subtitle={item.desc} chartHelpContextKey={item.helpContextKey} vizState={item.vizState} vizOptions={item.vizOptions} cubeOptions ={cubeOptions} />
      </div>
    )
  };

  const { layouts, widgets } = dashboardDef;

  const addTeamFilterForOwner = {
    "member": "CloudTeams.name",
    "operator": "equals",
    "values": [teamFilter]
  }
  const addTeamFilterForMember = {
    "member": "CloudTeams.name",
    "operator": "equals",
    "values": [teamFilter]
  }
  const rollingYearMonthFilter = {
    "member": "CloudSpendWithForecasts.transactionDate",
    "operator": "inDateRange",
    "values": [rollingMonthsResponse?.[0]?.transactionDate,rollingMonthsResponse?.[rollingMonthsResponse?.length-1]?.transactionDate]
  }
  widgets.map((i) => {
    const { vizState } = i;
    const { query } = vizState;
    const { filters } = query;
    if(teamFilter){
      if(!_.includes(filters,rollingYearMonthFilter)){
        rollingMonthsResponse ? filters.push(rollingYearMonthFilter) : ""
      }
      if(_.filter(filters, function(o) { if (o["member"] === addTeamFilterForOwner["member"]) return o}).length === 0) {
        filters.push(addTeamFilterForOwner);
      } else {
        _.remove(filters , item => item === addTeamFilterForOwner)
      }
    }
    if(teamFilter){
      if(!_.includes(filters,rollingYearMonthFilter)){
        rollingMonthsResponse ? filters.push(rollingYearMonthFilter) : ""
      }
      if(_.filter(filters, function(o) { if (o["member"] === addTeamFilterForMember["member"]) return o}).length === 0) {
        filters.push(addTeamFilterForMember);
      } else {
        _.remove(filters , item => item === addTeamFilterForMember)
      }
    }
  })

  return (
    <MDBox  minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0, md: 6, sm: 6, xs: 6 }}>
      <MDBox px={1} pb={2}>
        <ResponsiveDashboardContainer1 dashboardLayouts={layouts}>
          {widgets.map(dashboardItem)}
        </ResponsiveDashboardContainer1>
      </MDBox>
    </MDBox>
  );
};

const DashboardPageWithContext = (props) => <DashboardContextProvider><RenderChart {...props} /></DashboardContextProvider>

export default AnimatedRoute(DashboardPageWithContext);
