import { DataFieldTypes } from "components/DataField";

const themeSettings = {
    type: "group", title: "Theme", settings: {
        group: [
            { type: "dropdown", name: "theme_colors", label: "Colors", defaultValue: "default", optionSetName: "colorThemesOptions" },
            { type: "switch", name: "theme_colors_reverse", label: "Reverse Colors?" },
        ]
    }
};

const commonTopNSetting = {
    type: "group", title: "Limit Items", settings: {
        group: [
            { type: "integer", name: "top_n", label: "Show Top", defaultValue: 2000, minValue: 1, maxValue: 50000 },
        ]
    }
};

const tableCardSettings = {
    type: "group", title: "Card Settings", category: "editor", settings: {
        group: [
            { type: "textbox", name: "card_title", label: "Title" },
            { type: "textbox", name: "card_subtitle", label: "Sub Title" },
            { type: "switch", name: "hide_card_header", label: "Hide Header" },
            { type: "dropdown", name: "card_border_radius", label: "Border Radius Size", defaultValue: "small", optionSetName: "genericSizesOptions" },
            { type: "switch", name: "card_remove_border", label: "Remove Border" },
        ]
    }
};

const defaultCardSettings = {
    type: "group", title: "Card Settings", category: "editor", settings: {
        group: [
            { type: "textbox", name: "card_title", label: "Title" },
            { type: "textbox", name: "card_subtitle", label: "Sub Title" },
            { type: "switch", name: "hide_card_header", label: "Hide Header" },
            { type: "dropdown", name: "card_border_radius", label: "Border Radius Size", defaultValue: "small", optionSetName: "genericSizesOptions" },
            { type: "switch", name: "card_remove_border", label: "Remove Border" },
            { type: "colorpicker", name: "card_background_color", label: "Background Color", defaultValue: "#ffffff" },
            { type: "colorpicker", name: "text_color", label: "Text Color", defaultValue: "#666666" },
        ]
    }
};

const horizRefLineSettings = {
    type: "group", title: "Reference Line", settings: {
        group: [
            { type: "switch", name: "refLine_enable", label: "Enable", defaultValue: false },
            { type: "integer", name: "refLine_value", label: "Value (Number)" },
            { type: "dropdown", name: "refLine_style", label: "Line Style", defaultValue: "dash", optionSetName: "dashStyleOptions" },
            { type: "dropdown", name: "refLine_z_position", label: "Show Line", defaultValue: "front", optionSetName: "refLineZPositionOptions" },
            { type: "colorpicker", name: "refLine_color", label: "Line Color", defaultValue: "#666666" },
            { type: "textbox", name: "refLine_label", label: "Label" },
            { type: "dropdown", name: "refLine_label_horiz_position", label: "Horizontal Label Position", defaultValue: "right", optionSetName: "horizAlignOptions" },
            { type: "integer", name: "refLine_x_value", label: "X Margin (Number)" },
            { type: "integer", name: "refLine_y_value", label: "Y Margin (Number)" },
        ]
    }
};

const vertRefLineSettings = {
    type: "group", title: "Reference Line", settings: {
        group: [
            { type: "switch", name: "refLine_enable", label: "Enable", defaultValue: false },
            { type: "integer", name: "refLine_value", label: "Value (Number)" },
            { type: "dropdown", name: "refLine_style", label: "Line Style", defaultValue: "dash", optionSetName: "dashStyleOptions" },
            { type: "dropdown", name: "refLine_z_position", label: "Show Line", defaultValue: "front", optionSetName: "refLineZPositionOptions" },
            { type: "colorpicker", name: "refLine_color", label: "Line Color", defaultValue: "#666666" },
            { type: "textbox", name: "refLine_label", label: "Label" },
            { type: "dropdown", name: "refLine_label_horiz_position", label: "Horizontal Label Position", defaultValue: "right", optionSetName: "horizAlignOptions" },
            { type: "dropdown", name: "refLine_label_vert_position", label: "Vertical Label Position", defaultValue: "bottom", optionSetName: "vertAlignOptions" },
            { type: "integer", name: "refLine_x_value", label: "X Margin (Number)" },
            { type: "integer", name: "refLine_y_value", label: "Y Margin (Number)" },
        ]
    }
};

export const horizRefLineLabelPostion = {
    "left": 8,
    "center": 0,
    "right": -8,
};

export const vertRefLineLabelPostion = {
    "top": 8,
    "middle": 0,
    "bottom": -8
};

export const chartTypeSettingDefs = [
    {
        type: "text", icon: "title", title: "Text",
        category: "editor",
        configItems: [],
        settingItems: [
            {
                type: "group", title: "Card Settings", category: "editor", settings: {
                    group: [
                        { type: "textbox", name: "card_title", label: "Title" },
                        { type: "textbox", name: "card_subtitle", label: "Sub Title" },
                        { type: "switch", name: "card_remove_border", label: "Remove Border" },
                        { type: "dropdown", name: "card_border_radius", label: "Border Radius Size", defaultValue: "small", optionSetName: "genericSizesOptions" },
                        { type: "colorpicker", name: "card_background_color", label: "Background Color", defaultValue: "#ffffff" },
                        { type: "colorpicker", name: "text_color", label: "Text Color", defaultValue: "#333333" },
                    ]
                }
            },
            {
                type: "group", title: "Body Text", category: "editor", settings: {
                    group: [
                        { type: "textarea", name: "card_body_text", label: "Enter your text here. Press return/enter key to add a line break." },
                    ]
                }
            },
        ]
    },
    {
        type: "header", icon: "title", title: "header",
        category: "editor",
        configItems: [],
        settingItems: [
            {
                type: "group", title: "Basic Settings", category: "editor", settings: {
                    group: [
                        { type: "textbox", name: "card_title", label: "Header Text" },
                        { type: "textbox", name: "card_subtitle", label: "Sub Header Text" },
                        { type: "dropdown", name: "header_fontsize", label: "Header Text Font Size", defaultValue: "button", optionSetName: "headerFontsizeOptions" },
                        { type: "colorpicker", name: "text_color", label: "Text Color", defaultValue: "#333333" },
                        { type: "colorpicker", name: "card_border_color", label: "Border Color", defaultValue: "#333333" },
                        { type: "switch", name: "card_remove_border", label: "Remove Border" },
                    ]
                }
            },
            {
                type: "group", title: "Layout", category: "editor", settings: {
                    group: [
                        { type: "dropdown", name: "horiz_align", label: "Align", defaultValue: "flex-start", optionSetName: "horizontalAlignOptions" },
                        { type: "dropdown", name: "vert_align", label: "Vertical Align", defaultValue: "center", optionSetName: "verticalAlignOptions" },
                    ]
                }
            }
        ]
    },
    {
        type: "table", icon: "table_chart", title: "Data Table",
        configItems: [
            { type: "values", title: "Values", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            tableCardSettings,
            commonTopNSetting,
            {
                type: "singleSelection", name: "grandTotalType", title: "Grand Totals", settings: {
                    defaultValue: "hideGrandTotals",
                    options: [
                        { value: "hideGrandTotals", label: "Do not show grand totals" },
                        { value: "showGrandTotals", label: "Show grand totals" },
                    ]
                }
            },
            {
                type: "group", title: "Layout Settings", category: "editor", settings: {
                    group: [
                        { type: "dropdown", name: "paddingSize", label: "Padding Size", defaultValue: "small", optionSetName: "genericSizesOptions" },
                        { type: "switch", name: "horizBorders", label: "Horizontal Borders", defaultValue: true },
                        { type: "switch", name: "vertBorders", label: "Vertical Borders", defaultValue: true },
                        { type: "switch", name: "altRowBorders", label: "Alteranate Row Colors" },
                    ]
                }
            }
        ]
    },
    {
        type: "pivot-table", icon: "pivot_table_chart", title: "Pivot Table",
        configItems: [
            { type: "rows", title: "Rows", accept: [DataFieldTypes.DIMENSION] },
            { type: "columns", title: "Columns", accept: [DataFieldTypes.DIMENSION] },
            { type: "values", title: "Values", accept: [DataFieldTypes.MEASURE] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            tableCardSettings,
            commonTopNSetting,
            {
                type: "singleSelection", name: "grandTotalType", title: "Grand Totals", settings: {
                    defaultValue: "hideGrandTotals",
                    options: [
                        { value: "hideGrandTotals", label: "Do not show grand totals" },
                        { value: "showGrandTotals", label: "Show grand totals" },
                        { value: "showRowGrandTotals", label: "Show for rows only" },
                        { value: "showColGrandTotals", label: "Show for columns only" },
                    ]
                }
            },
            {
                type: "group", title: "Layout Settings", category: "editor", settings: {
                    group: [
                        { type: "dropdown", name: "paddingSize", label: "Padding Size", defaultValue: "small", optionSetName: "genericSizesOptions" },
                        { type: "switch", name: "horizBorders", label: "Horizontal Borders", defaultValue: true },
                        { type: "switch", name: "vertBorders", label: "Vertical Borders", defaultValue: true },
                        { type: "switch", name: "altRowBorders", label: "Alteranate Row Colors" },
                    ]
                }
            }
        ]
    },
    {
        type: "stats", icon: "123", title: "Metric",
        configItems: [
            { type: "values", title: "Value", accept: [DataFieldTypes.MEASURE], singleItem: true },
            { type: "axis", title: "Delta Value", accept: [DataFieldTypes.MEASURE], singleItem: true },
            { type: "legend", title: "Delta Percentage", accept: [DataFieldTypes.MEASURE], singleItem: true },
            // { type: "legend", title: "Legend", accept: [DataFieldTypes.DIMENSION], singleItem: true },
            // { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            {
                type: "group", title: "Card Settings", category: "editor", settings: {
                    group: [
                        { type: "switch", name: "card_remove_border", label: "Remove Border" },
                        { type: "dropdown", name: "card_border_radius", label: "Border Radius Size", defaultValue: "small", optionSetName: "genericSizesOptions" },
                        { type: "colorpicker", name: "card_background_color", label: "Background Color", defaultValue: "#ffffff" },
                        { type: "colorpicker", name: "text_color", label: "Text Color", defaultValue: "#333333" },
                    ]
                }
            },
            {
                type: "group", title: "Basic Settings", settings: {
                    group: [
                        { type: "textbox", name: "header_title", label: "Title" },
                        { type: "switch", name: "hide_header_title", label: "Hide Title" },
                        { type: "dropdown", category: "editor", name: "metric_fontsize", label: "Font Size", defaultValue: "h4", optionSetName: "metricFontsizeOptions" },
                        { type: "switch", name: "show_abs_value", label: "Show Original Value" },
                        { type: "switch", name: "show_decimalpoints", label: "Show Decimal Points" },
                        { type: "switch", name: "show_delta_indicator", label: "Show Trend Arrow" },
                        { type: "switch", name: "negate_color_logic", label: "Negate Color Logic" },
                        { type: "textbox", name: "value_label", label: "Value Label" },
                        { type: "textbox", name: "footer_label", label: "Footer Label" },
                    ]
                }
            },
            {
                type: "group", title: "Layout", category: "editor", settings: {
                    group: [
                        { type: "dropdown", name: "flex_direction", label: "Layout Direction", defaultValue: "column", optionSetName: "flexDirectionOptions" },
                        { type: "dropdown", name: "horiz_align", label: "Align", defaultValue: "flex-start", optionSetName: "horizontalAlignOptions" },
                        { type: "dropdown", name: "vert_align", label: "Vertical Align", defaultValue: "space-between", optionSetName: "verticalAlignOptions" },
                    ]
                }
            }
        ]
    },
    {
        type: "bar-chart", icon: "bar_chart", rotateIcon90deg: true, title: "Bar Chart",
        configItems: [
            { type: "values", title: "X Axis", accept: [DataFieldTypes.MEASURE] },
            { type: "axis", title: "Y Axis", accept: [DataFieldTypes.DIMENSION] },
            { type: "legend", title: "Break down by", accept: [DataFieldTypes.DIMENSION] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_symbol", label: "Symbol Shape", defaultValue: "circle", optionSetName: "legendSymbolOptions" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "X Axis", settings: {
                    group: [
                        { type: "switch", name: "xAxis_disable", label: "Disable X axis" },
                        { type: "switch", name: "xAxis_secondary", label: "Is Secondary Axis?" },
                    ]
                }
            },
            {
                type: "group", title: "Y Axis", settings: {
                    group: [
                        { type: "switch", name: "yAxis_disable", label: "Disable Y axis" },
                        { type: "switch", name: "yAxisLine_hide", label: "Hide Axis Line" },
                        { type: "switch", name: "yAxis_reverse", label: "Reverse Axis" },
                        { type: "switch", name: "yAxis_secondary", label: "Is Secondary Axis?" },
                        { type: "dropdown", name: "tick_interval", label: "Tick Interval", defaultValue: 1, optionSetName: "tickIntervalOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                    ]
                }
            },
            {
                type: "group", title: "Tooltip", settings: {
                    group: [
                        { type: "switch", name: "tooltip_shared_disable", label: "Disable Shared Tooltip" },
                    ]
                }
            },
            vertRefLineSettings
        ]
    },
    {
        type: "stacked-bar-chart", icon: "stacked_bar_chart", rotateIcon90deg: true, title: "Stacked Bar",
        configItems: [
            { type: "values", title: "X Axis", accept: [DataFieldTypes.MEASURE] },
            { type: "axis", title: "Y Axis", accept: [DataFieldTypes.DIMENSION] },
            { type: "legend", title: "Break down by", accept: [DataFieldTypes.DIMENSION] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_symbol", label: "Symbol Shape", defaultValue: "circle", optionSetName: "legendSymbolOptions" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "X Axis", settings: {
                    group: [
                        { type: "switch", name: "xAxis_disable", label: "Disable X axis" },
                        { type: "switch", name: "xAxis_secondary", label: "Is Secondary Axis?" },
                    ]
                }
            },
            {
                type: "group", title: "Y Axis", settings: {
                    group: [
                        { type: "switch", name: "yAxis_disable", label: "Disable Y axis" },
                        { type: "switch", name: "yAxisLine_hide", label: "Hide Axis Line" },
                        { type: "switch", name: "yAxis_reverse", label: "Reverse Axis" },
                        { type: "switch", name: "yAxis_secondary", label: "Is Secondary Axis?" },
                        { type: "dropdown", name: "tick_interval", label: "Tick Interval", defaultValue: 1, optionSetName: "tickIntervalOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Stacking", settings: {
                    group: [
                        { type: "switch", name: "percentage_stacking", label: "100% Stacked Chart" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                        { type: "switch", name: "stackdatalabels_enable", label: "Enable Stack Labels" },
                    ]
                }
            },
            {
                type: "group", title: "Tooltip", settings: {
                    group: [
                        { type: "switch", name: "tooltip_shared_disable", label: "Disable Shared Tooltip" },
                    ]
                }
            },
            vertRefLineSettings
        ]
    },
    {
        type: "column-chart", icon: "bar_chart", title: "Column Chart",
        configItems: [
            { type: "axis", title: "X Axis", accept: [DataFieldTypes.DIMENSION] },
            { type: "values", title: "Y Axis", accept: [DataFieldTypes.MEASURE] },
            { type: "legend", title: "Break down by", accept: [DataFieldTypes.DIMENSION] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_symbol", label: "Symbol Shape", defaultValue: "circle", optionSetName: "legendSymbolOptions" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "X Axis", settings: {
                    group: [
                        { type: "switch", name: "xAxis_disable", label: "Disable X axis" },
                        { type: "switch", name: "xAxisLine_hide", label: "Hide Axis Line" },
                        { type: "switch", name: "xAxis_reverse", label: "Reverse Axis" },
                        { type: "switch", name: "xAxis_secondary", label: "Is Secondary Axis?" },
                        { type: "dropdown", name: "tick_interval", label: "Tick Interval", defaultValue: 1, optionSetName: "tickIntervalOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Y Axis", settings: {
                    group: [
                        { type: "switch", name: "yAxis_disable", label: "Disable Y axis" },
                        { type: "switch", name: "yAxis_secondary", label: "Is Secondary Axis?" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                    ]
                }
            },
            {
                type: "group", title: "Tooltip", settings: {
                    group: [
                        { type: "switch", name: "tooltip_shared_disable", label: "Disable Shared Tooltip" },
                    ]
                }
            },
            horizRefLineSettings
        ]
    },
    {
        type: "stacked-column-chart", icon: "stacked_bar_chart", title: "Stacked Column",
        configItems: [
            { type: "axis", title: "X Axis", accept: [DataFieldTypes.DIMENSION] },
            { type: "values", title: "Y Axis", accept: [DataFieldTypes.MEASURE] },
            { type: "legend", title: "Break down by", accept: [DataFieldTypes.DIMENSION] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Stacking", settings: {
                    group: [
                        { type: "switch", name: "percentage_stacking", label: "100% Stacked Chart" },
                    ]
                }
            },
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_symbol", label: "Symbol Shape", defaultValue: "circle", optionSetName: "legendSymbolOptions" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "X Axis", settings: {
                    group: [
                        { type: "switch", name: "xAxis_disable", label: "Disable X axis" },
                        { type: "switch", name: "xAxisLine_hide", label: "Hide Axis Line" },
                        { type: "switch", name: "xAxis_reverse", label: "Reverse Axis" },
                        { type: "switch", name: "xAxis_secondary", label: "Is Secondary Axis?" },
                        { type: "dropdown", name: "tick_interval", label: "Tick Interval", defaultValue: 1, optionSetName: "tickIntervalOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Y Axis", settings: {
                    group: [
                        { type: "switch", name: "yAxis_disable", label: "Disable Y axis" },
                        { type: "switch", name: "yAxis_secondary", label: "Is Secondary Axis?" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                        { type: "switch", name: "stackdatalabels_enable", label: "Enable Stack Labels" },
                    ]
                }
            },
            {
                type: "group", title: "Tooltip", settings: {
                    group: [
                        { type: "switch", name: "tooltip_shared_disable", label: "Disable Shared Tooltip" },
                    ]
                }
            },
            horizRefLineSettings
        ]
    },
    {
        type: "line-chart", icon: "timeline", title: "Line Chart",
        configItems: [
            { type: "axis", title: "Axis", accept: [DataFieldTypes.DIMENSION], singleItem: false },
            // { type: "legend", title: "Legend", accept: [DataFieldTypes.DIMENSION], singleItem: true },
            { type: "values", title: "Value", accept: [DataFieldTypes.MEASURE] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_symbol", label: "Symbol Shape", defaultValue: "circle", optionSetName: "legendSymbolOptions" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "X Axis", settings: {
                    group: [
                        { type: "switch", name: "xAxis_disable", label: "Disable X axis" },
                        { type: "switch", name: "xAxisLine_hide", label: "Hide Axis Line" },
                        { type: "switch", name: "xAxis_reverse", label: "Reverse Axis" },
                        { type: "switch", name: "xAxis_secondary", label: "Is Secondary Axis?" },
                        { type: "dropdown", name: "tick_interval", label: "Tick Interval", defaultValue: 1, optionSetName: "tickIntervalOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Y Axis", settings: {
                    group: [
                        { type: "switch", name: "yAxis_disable", label: "Disable Y axis" },
                        { type: "switch", name: "yAxis_secondary", label: "Is Secondary Axis?" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datamarkers_enable", label: "Enable Markers" },
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                    ]
                }
            },
            horizRefLineSettings
        ]
    },
    {
        type: "spline-chart", icon: "show_chart", title: "Spline Chart",
        configItems: [
            { type: "axis", title: "Axis", accept: [DataFieldTypes.DIMENSION], singleItem: false },
            // { type: "legend", title: "Legend", accept: [DataFieldTypes.DIMENSION], singleItem: true },
            { type: "values", title: "Value", accept: [DataFieldTypes.MEASURE] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_symbol", label: "Symbol Shape", defaultValue: "circle", optionSetName: "legendSymbolOptions" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "X Axis", settings: {
                    group: [
                        { type: "switch", name: "xAxis_disable", label: "Disable X axis" },
                        { type: "switch", name: "xAxisLine_hide", label: "Hide Axis Line" },
                        { type: "switch", name: "xAxis_reverse", label: "Reverse Axis" },
                        { type: "switch", name: "xAxis_secondary", label: "Is Secondary Axis?" },
                        { type: "dropdown", name: "tick_interval", label: "Tick Interval", defaultValue: 1, optionSetName: "tickIntervalOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Y Axis", settings: {
                    group: [
                        { type: "switch", name: "yAxis_disable", label: "Disable Y axis" },
                        { type: "switch", name: "yAxis_secondary", label: "Is Secondary Axis?" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datamarkers_enable", label: "Enable Markers" },
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                    ]
                }
            },
            horizRefLineSettings
        ]
    },
    {
        type: "area-chart", icon: "area_chart", title: "Area Chart",
        configItems: [
            { type: "axis", title: "X Axis", accept: [DataFieldTypes.DIMENSION] },
            { type: "values", title: "Y Axis", accept: [DataFieldTypes.MEASURE] },
            { type: "legend", title: "Break down by", accept: [DataFieldTypes.DIMENSION] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Stacking", settings: {
                    group: [
                        { type: "switch", name: "percentage_stacking", label: "Percentage Area Chart?" },
                    ]
                }
            },
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_symbol", label: "Symbol Shape", defaultValue: "circle", optionSetName: "legendSymbolOptions" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "X Axis", settings: {
                    group: [
                        { type: "switch", name: "xAxis_disable", label: "Disable X axis" },
                        { type: "switch", name: "xAxisLine_hide", label: "Hide Axis Line" },
                        { type: "switch", name: "xAxis_reverse", label: "Reverse Axis" },
                        { type: "switch", name: "xAxis_secondary", label: "Is Secondary Axis?" },
                        { type: "dropdown", name: "tick_interval", label: "Tick Interval", defaultValue: 1, optionSetName: "tickIntervalOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Y Axis", settings: {
                    group: [
                        { type: "switch", name: "yAxis_disable", label: "Disable Y axis" },
                        { type: "switch", name: "yAxis_secondary", label: "Is Secondary Axis?" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                    ]
                }
            },
            {
                type: "group", title: "Tooltip", settings: {
                    group: [
                        { type: "switch", name: "tooltip_shared_disable", label: "Disable Shared Tooltip" },
                    ]
                }
            }
        ]
    },
    {
        type: "pie-chart", icon: "pie_chart", title: "Pie Chart",
        configItems: [
            { type: "values", title: "Value", accept: [DataFieldTypes.MEASURE] },
            { type: "axis", title: "Group by", accept: [DataFieldTypes.DIMENSION] },
            // { type: "legend", title: "Legend", accept: [DataFieldTypes.DIMENSION], singleItem: true },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                        { type: "switch", name: "datalabels_inside", label: "Keep Inside" },
                        { type: "dropdown", name: "datalabels_filter_percent", label: "Hide Labels if", defaultValue: 1, optionSetName: "datalabelsFilterPercentOptions" },
                    ]
                }
            }
        ]
    },
    {
        type: "donut-chart", icon: "donut_large", title: "Donut Chart",
        configItems: [
            { type: "values", title: "Value", accept: [DataFieldTypes.MEASURE] },
            { type: "axis", title: "Group by", accept: [DataFieldTypes.DIMENSION] },
            // { type: "legend", title: "Legend", accept: [DataFieldTypes.DIMENSION], singleItem: true },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Radius", settings: {
                    group: [
                        { type: "dropdown", name: "donut_innerRadius", label: "Inner Radius", defaultValue: "50%", optionSetName: "donutInnerRadiusOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "Data Labels", settings: {
                    group: [
                        { type: "switch", name: "datalabels_enable", label: "Enable Labels" },
                        { type: "switch", name: "datalabels_inside", label: "Keep Inside" },
                        { type: "dropdown", name: "datalabels_filter_percent", label: "Hide Labels if", defaultValue: 1, optionSetName: "datalabelsFilterPercentOptions" },
                    ]
                }
            }
        ]
    },
    {
        type: "treemap-chart", icon: "dashboard", title: "Tree Map",
        configItems: [
            { type: "values", title: "Value", accept: [DataFieldTypes.MEASURE], singleItem: true },
            { type: "axis", title: "Group by", accept: [DataFieldTypes.DIMENSION], singleItem: true },
            // { type: "legend", title: "Legend", accept: [DataFieldTypes.DIMENSION], singleItem: true },
            // { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
        ]
    },
    {
        type: "sankey-chart", icon: "ssid_chart", title: "Sankey",
        configItems: [
            { type: "values", title: "Value", accept: [DataFieldTypes.MEASURE], singleItem: true },
            { type: "axis", title: "Group by", accept: [DataFieldTypes.DIMENSION] },
            // { type: "legend", title: "Legend", accept: [DataFieldTypes.DIMENSION], singleItem: true },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            themeSettings,
            {
                type: "group", title: "Nodes", settings: {
                    group: [
                        { type: "textbox", name: "sankeySeriesName", label: "Tooltip Header" },
                        // { type: "textbox", name: "sankeyNodeWidth", label: "Node Width" },
                        // { type: "textbox", name: "sankeyNodePadding", label: "Node Padding" },
                        // { type: "textbox", name: "sankeyMinLinkWidth", label: "Minimum Link Width" },
                    ]
                }
            },
            {
                type: "group", title: "Labels", settings: {
                    group: [
                        { type: "switch", name: "labels_hide", label: "Hide Category Labels" },
                        { type: "dropdown", name: "labels_position", label: "Labels Postion", defaultValue: "bottom", optionSetName: "sankeyLabelPositionOptions" },
                    ]
                }
            }
        ]
    },
    {
        type: "combination-chart", icon: "insert_chart", title: "Combination Chart",
        configItems: [
            { type: "axis", title: "X Axis", accept: [DataFieldTypes.DIMENSION] },
            { type: "values", title: "Column Chart Y Axis", accept: [DataFieldTypes.MEASURE] },
            { type: "values1", title: "Line Chart Y Axis", accept: [DataFieldTypes.MEASURE] },
            { type: "sort", title: "Sort Order", accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE] },
        ],
        settingItems: [
            defaultCardSettings,
            commonTopNSetting,
            themeSettings,
            {
                type: "group", title: "Legend", settings: {
                    group: [
                        { type: "switch", name: "legend_disable", label: "Disable Legend" },
                        { type: "dropdown", name: "legend_symbol", label: "Symbol Shape", defaultValue: "circle", optionSetName: "legendSymbolOptions" },
                        { type: "dropdown", name: "legend_position", label: "Position", defaultValue: "bottom", optionSetName: "legendPositionOptions" },
                        { type: "switch", name: "legend_floating", label: "Is Floating?" },
                    ]
                }
            },
            {
                type: "group", title: "X Axis", settings: {
                    group: [
                        { type: "dropdown", name: "tick_interval", label: "Tick Interval", defaultValue: 1, optionSetName: "tickIntervalOptions" },
                    ]
                }
            },
            {
                type: "group", title: "Column Chart Y Axis", settings: {
                    group: [
                        { type: "textbox", name: "yAxis1_title", label: "Axis Title" },
                    ]
                }
            },
            {
                type: "group", title: "Line Chart Y Axis", settings: {
                    group: [
                        { type: "textbox", name: "yAxis2_title", label: "Axis Title" },
                    ]
                }
            },
            {
                type: "group", title: "Tooltip", settings: {
                    group: [
                        { type: "switch", name: "tooltip_shared_disable", label: "Disable Shared Tooltip" },
                    ]
                }
            },
        ]
    },
];

export const colorThemes = {
    "default": ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
    "greenLand": ['#01B8AA', '#374649', '#FD625E', '#F2C80F', '#5F6B6D', '#8AD4EB', '#FE9666', '#A66999'],
    "cityPark": ['#73B761', '#4A588A', '#ECC846', '#CD4C46', '#71AFE2', '#8D6FD1', '#EE9E64', '#95DABB'],
    "classroom": ['#4A8DDC', '#4C5D8A', '#F3C911', '#DC5B57', '#33AE81', '#95C8F0', '#DD915F', '#9A64A0'],
    "colorblindSafe": ['#074650', '#009292', '#FE6DB6', '#FEB5DA', '#480091', '#B66DFF', '#B5DAFE', '#6DB6FF'],
    "highContrast": ['#107C10', '#002050', '#A80000', '#5C2D91', '#004B50', '#0078D7', '#D83B01', '#B4009E'],
    "sunset": ['#B6B0FF', '#3049AD', '#FF994E', '#C83D95', '#FFBBED', '#42F9F9', '#00B2D9', '#FFD86C'],
    "twilight": ['#F17925', '#004753', '#CCAA14', '#4B4C4E', '#D82C20', '#A3D0D4', '#536F18', '#46ABB0'],
    "monoBlue": ['#001d52', '#002c66', '#00397a', '#01458d', '#024fa1', '#045cb4', '#0466c8', '#0470dc', '#057af0', '#001d52'],
    "redGreen": ['#dc4a46', '#33ae81']
};

export const chartwiseDefaultColorThemes = {
    "bar-chart": ['#dedede', '#435cc8', "#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
    "column-chart": ['#dedede', '#435cc8', "#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
    "combination-chart": ['#dedede', '#435cc8', "#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
    "pie-chart": ['#5A69ED', '#EE9672', "#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
    "donut-chart": ['#5A69ED', '#EE9672', "#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
    "treemap-chart": ['#171C4A', '#1D235C', '#222A6E', '#283180', '#2D3791', '#333EA3', '#3945B5', '#3E4CC7', '#4453D9', '#4A5AED', '#4F60FC', '#5A69ED', '#6B78ED', '#7B87ED', '#8C96ED', '#9CA4ED', '#ADB3ED', '#BEC2ED', '#CED1ED', '#DFE0ED'],
    "sankey-chart": ['#dedede', '#D8726E', '#EB918D', '#FBBAB7', '#EE7D50', '#CC6745', '#EEA183', '#EEC7B7', '#D1960A', '#EBB842', '#F3CF56', '#F2DF9D', '#3443BC', '#4F60FD', '#7B87ED', '#ADB3ED', '#1857B6', '#5397FD', '#7EAAED', '#ADC7ED']
};

export const colorThemesOptionSet = [
    { value: "default", label: "Default" },
    { value: "greenLand", label: "Green Land" },
    { value: "cityPark", label: "City Park" },
    { value: "classroom", label: "Class Room" },
    { value: "colorblindSafe", label: "Colorblind Safe" },
    { value: "highContrast", label: "High Contrast" },
    { value: "sunset", label: "Sunset" },
    { value: "twilight", label: "Twilight" },
    // { value: "monoBlue", label: "Monochrome (Blue)" },
    { value: "redGreen", label: "Red & Green" },
    { value: "custom", label: "Custom" },
];

export const highChartTypeForChartType = {
    "bar-chart": "bar",
    "stacked-bar-chart": "bar",
    "column-chart": "column",
    "stacked-column-chart": "column",
    "line-chart": "line",
    "spline-chart" :"spline",
    "area-chart": "areaspline",
    "pie-chart": "pie",
    "donut-chart": "pie",
    "sankey-chart": "sankey",
    "treemap-chart": "treemap",
    "combination-chart": "insert_chart",
};

export const borderRadiusSizes = {
    "small": "4px",
    "medium": "8px",
    "large": "12px"
};

export const legendPosition = {
    top: { align: "center", verticalAlign: "top" },
    topLeft: { align: "left", verticalAlign: "top" },
    topRight: { align: "right", verticalAlign: "top" },
    bottom: { align: "center", verticalAlign: "bottom" },
    bottomLeft: { align: "left", verticalAlign: "bottom" },
    bottomRight: { align: "right", verticalAlign: "bottom" },
    right: { align: "right", verticalAlign: "middle" },
    rightTop: { align: "right", verticalAlign: "top" },
    rightBottom: { align: "right", verticalAlign: "bottom" },
    left: { align: "left", verticalAlign: "middle" },
    leftTop: { align: "left", verticalAlign: "top" },
    leftBottom: { align: "left", verticalAlign: "bottom" },
};

export const legendSymbolOptionSet = [
    { value: "circle", label: "Circle" },
    { value: "square", label: "Square" },
];

export const legendPositionOptionSet = [
    { value: "top", label: "Top Center" },
    { value: "topRight", label: "Top Right" },
    { value: "topLeft", label: "Top Left" },
    { value: "bottom", label: "Bottom Center" },
    { value: "bottomRight", label: "Bottom Right" },
    { value: "bottomLeft", label: "Bottom Left" },
    { value: "right", label: "Right Center" },
    { value: "rightTop", label: "Right Top" },
    { value: "rightBottom", label: "Right Bottom" },
    { value: "left", label: "Left Center" },
    { value: "leftTop", label: "Left Top" },
    { value: "leftBottom", label: "Left Bottom" }
];

export const datalabelsFilterPercentOptionSet = [
    { value: 1, label: "Less than 1%" },
    { value: 5, label: "Less than 5%" },
    { value: 10, label: "Less than 10%" },
    { value: 15, label: "Less than 15%" },
    { value: 20, label: "Less than 20%" },
];

export const cardBackgroundColorOptionSet = [
    { value: "#ffffff", label: "Default" },
    { value: "#f8f9fb", label: "Soft Color 1" },
    { value: "#58eee2", label: "Soft Color 2" },
    { value: "#ffd2d0", label: "Soft Color 3" },
    { value: "#00fa9a", label: "Soft Color 4" },
    { value: "#fef1b7", label: "Soft Color 5" },
    { value: "#c6ddfa", label: "Soft Color 6" },
];

export const flexDirectionOptionSet = [
    { value: "column", label: "Vertical" },
    { value: "column-reverse", label: "Vertical Reverse" },
    { value: "row", label: "Horizontal" },
    { value: "row-reverse", label: "Horizontal Reverse" },
];

export const horizontalAlignOptionSet = [
    { value: "flex-start", label: "Left" },
    { value: "center", label: "Center" },
    { value: "flex-end", label: "Right" },
];

export const verticalAlignOptionSet = [
    { value: "flex-start", label: "Top" },
    { value: "flex-end", label: "Bottom" },
    { value: "center", label: "Center" },
    { value: "space-between", label: "Stretch" },
];

export const sankeyLabelPositionOptionSet = [
    { value: "top", label: "Top" },
    { value: "bottom", label: "Bottom" },
];

export const donutInnerRadiusOptionSet = [
    { value: "50%", label: "50%" },
    { value: "60%", label: "60%" },
    { value: "70%", label: "70%" },
    { value: "80%", label: "80%" },
    { value: "90%", label: "90%" },
];

export const metricFontsizeOptionSet = [
    { value: "h4", label: "Small" },
    { value: "h3", label: "Medium" },
    { value: "h2", label: "Large" },
    { value: "h1", label: "Extra Large" },
];

export const headerFontsizeOptionSet = [
    { value: "button", label: "Small" },
    { value: "h4", label: "Medium" },
    { value: "h3", label: "Large" },
    { value: "h2", label: "Extra Large" },
];

export const genericSizesOptionSet = [
    { value: "small", label: "Small" },
    { value: "medium", label: "Medium" },
    { value: "large", label: "Large" },
];

export const refLineZPositionOptionSet = [
    { value: "front", label: "In Front" },
    { value: "behind", label: "Behind" },
];

export const dashStyleOptionSet = [
    { value: "dash", label: "Dashed" },
    { value: "solid", label: "Solid" },
];

export const vertAlignOptionSet = [
    { value: "top", label: "Top" },
    { value: "middle", label: "Middle" },
    { value: "bottom", label: "Bottom" },
];

export const horzAlignOptionSet = [
    { value: "left", label: "Left" },
    { value: "center", label: "Center" },
    { value: "right", label: "Right" },
];

export const tickIntervalOptionSet = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
];

export const settingOptionSets = {
    legendSymbolOptions: legendSymbolOptionSet,
    legendPositionOptions: legendPositionOptionSet,
    colorThemesOptions: colorThemesOptionSet,
    datalabelsFilterPercentOptions: datalabelsFilterPercentOptionSet,
    cardBackgroundColorOptions: cardBackgroundColorOptionSet,
    flexDirectionOptions: flexDirectionOptionSet,
    horizontalAlignOptions: horizontalAlignOptionSet,
    verticalAlignOptions: verticalAlignOptionSet,
    sankeyLabelPositionOptions: sankeyLabelPositionOptionSet,
    donutInnerRadiusOptions: donutInnerRadiusOptionSet,
    metricFontsizeOptions: metricFontsizeOptionSet,
    headerFontsizeOptions: headerFontsizeOptionSet,
    genericSizesOptions: genericSizesOptionSet,
    dashStyleOptions: dashStyleOptionSet,
    vertAlignOptions: vertAlignOptionSet,
    horizAlignOptions: horzAlignOptionSet,
    refLineZPositionOptions: refLineZPositionOptionSet,
    tickIntervalOptions: tickIntervalOptionSet
};

export const providerNameVariations = {
    'AMAZON WEB SERVICES': 'AWS',
    'AMAZON': 'AWS',
    'AWS': 'AWS',
    'MICROSOFT AZURE': 'AZURE',
    'AZURE': 'AZURE',
    'AZURE MARKET PLACE': 'AZURE_MARKET_PLACE',
    'GOOGLE CLOUD PLATFORM': 'GCP',
    'GOOGLE': 'GCP',
    'GCP': 'GCP',
    'IBM CLOUD': 'IBM',
    'IBM': 'IBM',
    'ORACLE CLOUD': 'ORACLE',
    'ORACLE': 'ORACLE',
    'ALIBABA CLOUD': 'ALIBABA',
    'ALIBABA': 'ALIBABA',
    'AMAZON WEB SERVICE': 'AWS',
    'MICROSOFT': 'AZURE',
    'MICROSOFT CLOUD': 'AZURE',
    'MICROSOFT AZURE CLOUD': 'AZURE',
    'MICROSOFT AZURE PLATFORM': 'AZURE',
    'MICROSOFT PLATFORM': 'AZURE',
    'GOOGLE CLOUD': 'GCP',
    'GOOGLE PLATFORM': 'GCP',
    'IBM PLATFORM': 'IBM',
    'IBM CLOUD PLATFORM': 'IBM',
    'ORACLE PLATFORM': 'ORACLE',
    'ORACLE CLOUD PLATFORM': 'ORACLE',
    'ALIBABA CLOUD PLATFORM': 'ALIBABA',
    'ALIBABA PLATFORM': 'ALIBABA',
    'AZURE MARKET': 'AZURE_MARKET_PLACE',
    'MARKET PLACE AZURE': 'AZURE_MARKET_PLACE',
    'MICROSOFT MARKET PLACE AZURE': 'AZURE_MARKET_PLACE',
    'AZURE PLATFORM MARKET': 'AZURE_MARKET_PLACE'
};
