import { useState, useCallback } from 'react';
import { io } from 'socket.io-client';

const socketUrl = window.location.origin;

export const useInitializeSocket = () => {
    const [socket, setSocket] = useState(null);
    const [socketConnected, setSocketConnected] = useState(false);

    const disconnectSocket = useCallback(() => {
        if (socket) {
            if (socket.disconnect) {
                socket.disconnect();
            }
            setSocket(null);
        }
    }, [socket]);

    const initializeSocket = useCallback(() => {

        // if (socket) {
        //     disconnectSocket();
        // }

        if (!socket) {
            const newSocket = io(socketUrl, {
                path: "/ws",
                // autoConnect: false,
                reconnectionDelayMax: 10000,
                transports: ['websocket']
            });

            newSocket.on('connect_error', (error) => {
                // eslint-disable-next-line no-console
                console.error(error.message);
            });

            newSocket.on('initSocket', () => {
                setSocketConnected(true);
            });

            newSocket.on('disconnect', () => {
                console.error("socket disconnected");
                setSocketConnected(false);
            });

            setSocket(newSocket);
        }
    }, [socket, disconnectSocket]);

    return {
        socket,
        socketConnected,
        initializeSocket,
        disconnectSocket,
    };
};