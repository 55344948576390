import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon, Card, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import MDButton from "components/MDButton";
import { useYADialog } from "components/YADialog";
import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import moment from 'moment';
import numeral from 'numeral';
import { useAppController } from 'context';
import { ActiveStatusReverse, Roleformat } from 'utils';
import { useImmer } from 'use-immer';
import TeamMemberPopup from "components/TeamMemberPopup";

const buildColumns = (masterDef, defaultDateFormat) => {
    const columns = [];
    if (Array.isArray(masterDef.fields) && masterDef.fields.length > 0) {
      masterDef.fields.forEach((f) => {
        let col = { align: f.align || 'left' };
        let accessor = f.schemaName;
        if (f.type === 'dropdown' && f.dataSource.type === ('dynamic' || 'custom')) {
          accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
        }
        col['Header'] = f.displayName;
        col['accessor'] = accessor;
        col['Cell'] = ({ cell: { value } }) => {
          if (f.type === "currency")
            return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
          else if (f.type === "datepicker")
            if (f.view === "year")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format("YYYY") : ""}</MDTypography>
            else
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
  
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value}</MDTypography>
        };
        col['dataType'] = f.filterType || f.type
        col['disableFilters'] = f.disableFilters || false,
          columns.push(col);
      });
    }
    if (!masterDef.readonly)
      columns.push({
        Header: '',
        accessor: 'actions',
        align: 'left',
        disableSorting: true,
        disableFilters: true,
      });
    return columns;
  };
  
  const buildRows = (pkColumn, data, onEdit, onDelete, masterDef, onAdd, selectedCategoryDef, additonallinkData) => {
    masterDef && masterDef.schemaName && masterDef.schemaName === "user" ? data = ActiveStatusReverse(data) : data
    masterDef && masterDef.schemaName && masterDef.schemaName === "user" ? data = Roleformat(data, masterDef) : data
    let addmember = (selectedCategoryDef, row) => {
      let dataCheck
      if (additonallinkData) {
        let dataKey = `${selectedCategoryDef.name}.name`
        dataCheck = additonallinkData.find(item => item[dataKey] === row?.name)
      }
      let formdetails = { "selectedCategoryDef": selectedCategoryDef, "row": row }
      if (selectedCategoryDef && selectedCategoryDef.additonallink) {
  
        return (
          <MDTypography
            display="flex"
            alignItems="center"
            ml={3}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onAdd(formdetails)}
          >
            <Icon fontSize="small" color={dataCheck ? 'success' : "info"}>
              {
                selectedCategoryDef.additonallinkicon ? selectedCategoryDef.additonallinkicon : 'add'
              }
            </Icon>
            &nbsp; {selectedCategoryDef.additionallinktext ? selectedCategoryDef.additionallinktext : 'Add'}
          </MDTypography>
        )
      }
    }
    let editEnable = (r) => {
      if (onEdit) {
        return (
          <>
            {selectedCategoryDef.name == "cloudTeamMapping" ?
              <MDTypography
                display="flex"
                alignItems="center"
                component="a"
                href="#"
                onClick={() => onEdit(r[pkColumn])}
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                <Icon fontSize="small" color="info">add</Icon>&nbsp;Add Exception
              </MDTypography>
              :
              <MDTypography
                display="flex"
                alignItems="center"
                component="a"
                href="#"
                onClick={() => onEdit(r[pkColumn])}
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                <Icon fontSize="small">edit</Icon>&nbsp;Edit
              </MDTypography>
  
            }
          </>
        )
      }
    }
    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
      data.forEach((r) => {
        let row = {};
        if (r)
          Object.keys(r).forEach((k) => {
            row[k.replace(/\./g, '__')] = r[k];
          });
        row['actions'] =
          r?.taxonomy === true ? (
            <span></span>
          ) : (
            <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
              {editEnable(r)}
              {
                addmember(selectedCategoryDef, r)
              }
              {masterDef?.name !== 'tenant' &&
                <MDTypography
                  display="flex"
                  alignItems="center"
                  ml={3}
                  component="a"
                  href="#"
                  onClick={() => onDelete(r[pkColumn])}
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  <Icon fontSize="small" color="error">
                    delete
                  </Icon>
                  &nbsp;Delete
                </MDTypography>
              }
            </MDBox>
          );
        rows.push(row);
      });
    }
    return rows;
  };

const Masters = (props) => {
    const { masterId, selectedCategoryDef } = props;
    const [step, setStep] = useState('LOADING');
    const handleError = useHandleError();
    const [masterDef, setMasterDef] = useState(null);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [showTeamMember, setshowTeamMember] = useState(false)
    const [controller,] = useAppController();
    const { appDef } = controller;
    const defaultDateFormat = (appDef?.settings && appDef?.settings?.dateFormat) || "DD/MM/YYYY";
  
    const { showForm, showAlert, showPrompt, showSnackbar } = useYADialog();
    let filters = [
      {
        id: 'disabled',
        value: {
          type: 'switch',
          operator: 'eq',
          session: false,
          values: ["Yes"]
        }
      }
    ]
    const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: filters });
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
      setFiltersState(draft => {
        draft.globalFilter = latestGlobalFilter;
        draft.filters = latestFilters;
      });
    }
  
  
    const handleAdd = (item) => {
      setRowData(item)
      setshowTeamMember(true)
  
    }
  
    const handleTeamMemberClose = () => {
      setshowTeamMember(false)
      setRefresh(Math.random());
    }
  
    const handleClose = () => {
      setRefresh(Math.random());
    };
  
    const handleEdit = (pkId) => {
      showForm(
        `Edit ${masterDef.singularDisplayName || masterDef.displayName}`,
        masterDef,
        handleClose,
        'edit',
        pkId,
        masterDef.alertMessage
      );
    };
  
    const deleteMaster = async (pkId) => {
      const [err, data] = await fetchRequest.delete(`/api/master/${masterId}/${pkId}`);
      if (err) {
        if (err.data.message === "AdminRoleChangeFail") {
          showAlert("Attention", "Assign another admin before deleting this user.")
        } else
          showAlert('Delete', 'Something went wrong. Contact your administrator.');
      }
      else
        if (data && data.result === true) {
          showSnackbar('Data deleted successfully', 'success');
          handleClose();
        }
        else if (data && data.result === false) {
          showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
        }
    };
  
    const handleDeleteSuccess = (pkId) => {
      deleteMaster(pkId);
    };
  
    const handleDelete = (pkId) => {
      showPrompt('Delete', 'Are you sure you want to delete?', () => handleDeleteSuccess(pkId));
    };
  
    useEffect(() => {
      async function getMasterDef() {
        var [err, data] = await fetchRequest.get(`/api/master/${masterId}`);
        if (err) {
          handleError(err);
        } else {
          setMasterDef(data);
          setColumns(buildColumns(data, defaultDateFormat));
        }
      }
      getMasterDef();
    }, [masterId, selectedCategoryDef]);
  
    useEffect(() => {
      let additonallinkData
      async function getList() {
        if (selectedCategoryDef.additonallink) {
          let [err, data] = await fetchRequest.get(`/api/master/${selectedCategoryDef.additonallink}/list`);
          if (err) {
            handleError(err);
          } else {
            if (data && Array.isArray(data)) {
              additonallinkData = data
            }
          }
        }
        var [err, data] = await fetchRequest.get(`/api/master/${masterId}/list`);
        if (err) {
          handleError(err);
        } else {
          if (data && Array.isArray(data) && data.length > 0) {
            setRows(buildRows(masterDef.pkColumn || 'id', data, handleEdit, handleDelete, masterDef, handleAdd, selectedCategoryDef, additonallinkData));
            setStep('LOADED');
          } else {
            setStep('EMPTY');
          }
        }
      }
      if (masterDef) {
        getList();
      }
    }, [masterId, masterDef, refresh]);
  
  
    if (step === 'LOADING') {
      return <YASkeleton variant="loading" />;
    }
  
    const { displayName, singularDisplayName, message, canFilter } = masterDef;
  
    const handleAddButtonClick = () => {
      showForm(`New ${singularDisplayName || displayName}`, masterDef, handleClose);
    };
  
    const renderPrimaryActions = !masterDef.readonly && masterId !== "tenant" ? (
      <MDButton
        data-testid={"addnew"}
        variant="outlined"
        color="info"
        startIcon={<Icon>add</Icon>}
        onClick={handleAddButtonClick}
      >
        Add New
      </MDButton>
    ) : undefined;
  
    const renderAddButton = () =>
      !masterDef.readonly ? (
        <MDButton
          data-testid={"addnew"}
          variant="outlined"
          color="info"
          startIcon={<Icon>add</Icon>}
          onClick={handleAddButtonClick}
        >
          Add New
        </MDButton>
      ) : undefined;
  
    return (
      <MDBox flex={1} display="flex" flexDirection="column" px={4} py={3}>
        {showTeamMember && <TeamMemberPopup handleTeamMemberClose={handleTeamMemberClose} data={rowData} buildColumns={buildColumns} buildRows={buildRows} defaultDateFormat />}
        {
          message &&
          <Alert severity="warning"
            sx={{ marginTop: 1, marginBottom: 1, fontSize: "14px", textAlign: "left" }}
          >{message}</Alert>
        }
        <MDBox pt={1}>
          {step === 'LOADED' && (
            <Card sx={{ height: '100%' }} px={0}>
              <DataTable
                table={{ columns, rows }}
                showTotalEntries={true}
                isSelectable={selectedCategoryDef?.name == "cloudTeamMapping" ? true : false}
                isSorted={true}
                newStyle1={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                canFilter={canFilter}
                hideFooterForMinRecords={true}
                primaryActions={renderPrimaryActions}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
              />
            </Card>
          )}
          {step === 'EMPTY' && (
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight="calc(100vh - 300px)"
            >
              <EmptyState
                size="large"
                image={new_item_img}
                title={`No ${displayName} Yet`}
                description={
                  !masterDef.readonly && masterId !== "tenant" ?
                    `Click on the '+ add new' button to add a new ${(
                      singularDisplayName || displayName
                    ).toLowerCase()}.`
                    : undefined
                }
                actions={masterId !== 'tenant' && renderAddButton}
              />
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    );
};

const Tenants = () => {
    let selectedCategoryDef = {name: 'tenant', displayName: 'Tenants', desc: 'Manage your users', type: 'master', master: 'tenant'}
    return (
        <>
            <PageHeader title="Tenants" subtitle="Tenant ID of azure active directory" />
            <Masters masterId={'tenant'} selectedCategoryDef={selectedCategoryDef} />
        </>
    );
}

export default AnimatedRoute(Tenants);
