import _ from "lodash";
import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
import Dropdown from "../Dropdown";
// import DynamicDropdown from "../DynamicDropdown";
import Rule from "../Rule";
import Integer from "components/YAForm/components/Integer";
import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import fetchRequest from "utils/fetchRequest";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";

const TeamRuleForm = (props) => {
    const { pkId, mode, onClose, yearNameId, monthNameId } = props;
    const { showSnackbar, showAlert } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const [fromSource, setfromSource] = useState(null)
    const [, setErrors] = useState({});
    const [fromSourceChange, setfromSourceChange] = useState(false)
    const [onClear, setOnClear] = useState(false);
    const [fromCondition, setFromCondition] = useState(null);
    // const [toCondition, setToCondition] = useState(null);
    
    const [loading, setLoading] = useState(false);
    const [flag,setFlag] = useState(false)
    const domain = getDomain();

    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    async function getNewRule() {
        setLoading(true);
        const response = await Axios.post(`${domain}/api/teamRules/list/${yearNameId}/${monthNameId}?${("nc=" + Math.random()).replace(".", "")}`, null);
        
        const sortedRules = _.sortBy(response.data, ['sequence']);

        if (sortedRules?.length > 0) {
            setValue("sequence", sortedRules[sortedRules?.length -1]?.sequence + 1);
            setValue("fromSource", "cloudConsumption");
            setValue("rule", null);
            setValue("teamId", null);
            setLoading(false);
        } else {
            setValue("sequence", 1);
            setValue("fromSource", "cloudConsumption");
            setValue("rule", null);
            setValue("teamId", null);
            setLoading(false);
        }
    }

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/teamRules/teamRuleItem/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);

        setValue("sequence", response.data["sequence"]);
        setValue("fromSource", response.data["fromSource"]);
        setfromSource(response.data["fromSource"]);
        setValue("rule", response.data["rule"]);
        setValue("teamId", response.data["teamId"]);
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            getNewRule();
        }
    }, [mode]);

    const onSubmit = async data => {
        let url = mode === "edit" ? `/api/teamRules/${pkId}` : `/api/teamRules/new/${yearNameId}/${monthNameId}`;
        let [err, response] = await fetchRequest.post(url,data)
        if (err) {
            if (err.data?.message) {
                handleClose();
                onDialogClose();
                showSnackbar(err.data?.message, "error")
            } else {
                handleClose();
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
        }
        else if (!err && response && response.result === false) {
            if (Array.isArray(response.errors) && response.errors.length > 0) {
                response.errors.forEach((e) => {
                    setError(e.field, { type: "manual", message: e.message });
                });
            }
        }
        else {
            handleClose();
            onDialogClose();
            showSnackbar(response.message, "success");
        }
    };

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    if(flag == true){
        setFlag(false)
    }

    const handleClearAssetSelection = () => {
        setFromCondition(null);
    }

    const onfromSourceChange = (value) => {
        setfromSource(value)
        value === fromSource ? setfromSourceChange(false) : setfromSourceChange(true)
        setFromCondition(null)
        // setValue("")
    }

    let handleAlert = (e, reverseSet) => {
        if (reverseSet && !e) {
            showAlert("Alert", "This action will result in the revocation of user access to the application");
        }
    }

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={1000}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }

        return (
            <MDBox pt={1} px={2} minWidth={600} overflow="auto">
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox>
                            <MDBox mb={3}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue}
                                    setfromSource={setfromSource}
                                    setfromSourceChange={setfromSourceChange}
                                    disabled={isSubmitting}
                                    control={control} 
                                    fieldDef={{ name: "fromSource", displayName: "From Source", "required": true, 
                                        dataSource: { 
                                            type: "static",
                                            data: [
                                                { value: "cloudConsumption", label: "Cloud Consumption", name: 'cloudConsumption' }
                                            ],
                                        } 
                                    }} 
                                    errorMessage={errors["fromSource"] && errors["fromSource"].message} 
                                />
                            </MDBox>
                            <MDBox mb={3}>
                                <Rule 
                                    watch={watch}
                                    setValue={setValue}
                                    control={control}
                                    fromSource={fromSource}
                                    disabled={isSubmitting}
                                    fieldDef={{
                                        name: "rule",
                                        displayName: "Rule",
                                        disableFilters: true,
                                        required: true,
                                        unique: true,
                                        dataSource: {
                                            type: "custom",
                                            parentFields: ["fromSource"],
                                            url: "/api/dataflow/resource/:source"
                                        }
                                    }}
                                    errorMessage={errors["rule"] && errors["rule"].message} 
                                    handleAlert={handleAlert}
                                    condition={fromCondition}
                                    onfromSourceChange={onfromSourceChange}
                                    fromSourceChange={fromSourceChange}
                                    setOnClear={setOnClear}
                                    onClear={onClear}
                                    onConditionChange={setFromCondition}
                                    onClearAssetSelection={handleClearAssetSelection}
                                    errors={errors}
                                    setErrors={setErrors}
                                    yearNameId={yearNameId}
                                    monthNameId={monthNameId}
                                />
                            </MDBox>
                            <MDBox mb={3}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    control={control} 
                                    disabled={isSubmitting}
                                    fieldDef={{ name: "teamId", displayName: "Team", "required": true, 
                                        dataSource: { 
                                            type: "custom",
                                            url: "/api/teamRules/teams/list",
                                        } 
                                    }} 
                                    errorMessage={errors["teamId"] && errors["teamId"].message} 
                                />
                            </MDBox>
                            <MDBox mb={3}>
                                <Integer watch={watch} disabled={isSubmitting} setValue={setValue} control={control} fieldDef={{ name: "sequence", displayName: "Sequence", "required": true }} errorMessage={errors["sequence"] && errors["sequence"].message} />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};

export default TeamRuleForm;