import TableRow from "@mui/material/TableRow";
import DataTableBodyCell from "./DataTableBodyCell";
import { useSortable } from "@dnd-kit/sortable";

export const StaticTableRow = ({ row, maxWidth }) => {
    const {
        isDragging
      } = useSortable({
        id: row.original.id
      });
  return (
    <TableRow
        {...row.getRowProps()}
        sx={{
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            cursor: isDragging ? "grabbing" : "grab",
          },
          outline: "solid",
          overflow: "hidden",
          boxShadow: "rgb(0 0 0 / 10%) 0px 20px 25px -5px,rgb(0 0 0 / 30%) 0px 10px 10px -5px",
          maxWidth: maxWidth,
          maxHeight: "55px",
        }}
    >
      {(
        row.cells.map((cell) => {
          return (
            <DataTableBodyCell
              sx={{
                background: "white",
                overflow: "hidden"
              }}
              key={row.id}
              align={cell.column.align ? cell.column.align : "left"}
              {...cell.getCellProps()}
            >
              {cell.render("Cell")}
            </DataTableBodyCell>
          );
        })
      )}
    </TableRow>
  );
};