import { Controller } from "react-hook-form";
import { Icon, IconButton, Tooltip, Card,CircularProgress,Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useState } from "react";
import useYADialog from "components/YADialog/useYADialog";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import PreviewStep from "components/DataUpload/components/PreviewStep";
import MappingStep from "components/DataUpload/components/MappingStep";
import DataTable from "components/DataTable";
import fetchRequest from "utils/fetchRequest";

const buildMappingColumns = () => {
    let columns = [
      { Header: "Destination", accessor: "destination", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" alignItems="center" fontWeight="medium" color="dark">{value}</MDTypography> } },
      { Header: "Source", accessor: "source", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" alignItems="center" fontWeight="small" color="dark">{value}</MDTypography> } }
    ]
    return columns;
  }
  const buildMappingRows = (item, def) => {
    let rows = item.map(i => {
      let destination = "";
      if (def.type == "Assets") {
        def.fields.map(j => {
          if (j.name == i.destinationColumn) {
            destination = j.displayName;
          }
          if (!destination) {
            if (j.schemaName == i.destinationColumn)
              destination = j.displayName;
          }
        })
      }
      else {
        def.fields.map(j => {
          if (j.name == i.destinationColumn) {
            destination = j.displayName;
          }
        })
      }
      return {
        "destination": destination,
        "source": i.sourceColumn
      }
    })
    return rows
  }

const Mapping = (props) => {
  const { control, destinationTable, formValues, fieldDef: { name, toolTip, displayName }, errorMessage, dataStoreName, mode, disableEdit } = props;
    
    const [activeStep, setActiveStep] = useState("upload");
        const [fileChecking, setFileChecking] = useState(false);
        // const [selectedFile, setSelectedFile] = useState(null);
        // const [fileName, setFileName] = useState(null);
        const [ws, setWs] = useState(null);
        const [sourceFields, setSourceFields] = useState([]);
        const [mappingFields, setMappingFields] = useState([]);
        const [open, setOpen] = useState(false);
        const [openMapping, setOpenMapping] = useState(false);
        const [mappingRows, setMappingRows] = useState([])
        const [mappingColumns, setMappingColumns] = useState([])
        const [edit, setEdit] = useState(false)
        const { showAlert, showPrompt } = useYADialog();
  
        const handleOnMappingBack = () => {
          setActiveStep("preview");
        }
     
        const handleOnMappingNext = async(mappingFields1) => {      
          setMappingFields(mappingFields1);
          setOpen(false)
          setEdit(false)
        }

        const { MappingFields } = formValues

        let mp = []
        if(MappingFields?.length > 0 && typeof MappingFields === 'object'){
            mp = MappingFields
        }
        else if(MappingFields?.length > 0 && typeof MappingFields === 'string'){
            mp = JSON.parse(MappingFields)
        }

      
        const handleUploadDialogClose = () => {
            setOpen(false)
        }
      
        const handleNext = () => {
          switch (activeStep) {
            case "preview": {
              setActiveStep("mapping");
              break;
            }
            case "mapping": {
              setActiveStep("");
              break;
            }
            case "finish": {
              break;
            }
      
            default:
              break;
          }
        };
      
        const handleBack = () => {
          switch (activeStep) {
            case "preview": {
              setOpen(false)
              setActiveStep("upload");
              break;
            }
            case "mapping": {
              setActiveStep("preview");
              break;
            }
            case "finish": {
              break;
            }
      
            default:
              break;
          }
        };
      
        let enableBackBtn = false;
        let enableNextBtn = false;
      
        switch (activeStep) {
          case "preview": {
            enableBackBtn = true;
            if (ws?.length > 0)
              enableNextBtn = true;
            break;
          }
          case "mapping": {
            enableBackBtn = true;
            if (mappingFields?.length > 0)
              enableNextBtn = true;
            break;
          }
          case "finish": {
            enableBackBtn = false;
            enableNextBtn = false;
            break;
          }
      
          default:
            break;
        }


        const viewMapping = async () => {

            var [err, data] = await fetchRequest.get(`/api/dataload/def/${destinationTable}`);
            if (err)
              console.log(err);
            // setDestinationTable(row.name)
            setMappingColumns(buildMappingColumns());
            setMappingRows(buildMappingRows(mp, data));
            setOpenMapping(true);
          }
          const handleEditMapping = ()=>{
           if(mode === "edit")
           {
            const interfaceCheck = async () => {
              let dependentInterface
              const [err1, data] = await fetchRequest.get('/api/interface/list')
              if (!err1) {
                  dependentInterface = data.find(obj => obj["dataStoreMapping.name"] === dataStoreName)
              }
              if (dependentInterface) {
                  showPrompt('Alert', `This mapping is being used in automation. Do you want to edit?`, async () => {
                    setEdit(true)
                });

              } else {
                setEdit(true)
              }
          }
          interfaceCheck()
           }else{
            setEdit(true)
           }
          }
        const uploadBoxStyles = (theme, { isDragReject, isDragAccept }) => ({
          "@keyframes myEffect": {
            from: {
              transform: "scale(1.0)"
            },
            to: {
              transform: "scale(1.1)"
            }
          },
          display: (isDragReject || isDragAccept) ? "block" : "none",
          position: "absolute",
          height: 110,
          width: 110,
          top: 410,
          left: 120,
          borderRadius: "50%",
          backgroundColor: isDragReject ? "red" : isDragAccept ? "green" : "inherit",
          border: "4px solid #aca394",
          zIndex: 1,
          animation: "myEffect .7s infinite alternate",
        });
        const dropBoxStyles = ({ palette: { white, info }, functions: { pxToRem } }, { isDragActive }) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: errorMessage ? `${pxToRem(2)} dashed #ddd` : `${pxToRem(2)} dashed #ddd`,
            borderRadius: pxToRem(6),
            py: 3,
            maxWidth: 350,
            minHeight:140,
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#f0f8ff",
            },
            "& .dropBox-icon": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                height: pxToRem(56),
                width: pxToRem(56),
                fontSize: pxToRem(56),
                mb: 1.5,
                color: info.main,
                ...(isDragActive && {
                    fontSize: pxToRem(36),
                    color: white.main,
                    backgroundColor: info.main,
                })
            }
        })

        const { getRootProps, getInputProps, isDragAccept, isDragReject,isDragActive } = useDropzone({
          accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12, text/csv",
          onDrop: async (acceptedFiles) => {
            try {
              setFileChecking(true);
      
              var reader = new FileReader();
              reader.onload = function (e) {
                let data1 = new Uint8Array(e.target.result);
                let wb = XLSX.read(data1, { type: 'array', sheetRows: 20000 });
                let wsheet = wb.Sheets[wb.SheetNames[0]];
                let rows = XLSX.utils.sheet_to_json(wsheet, { defval: null });
                let columns = rows?.length > 0 ? Object.keys(rows[0]) : [];
      
                // if (onFileUpload) {
                //     onFileUpload(
                //         acceptedFiles[0].name,
                //         rows,
                //         columns
                //     );
                // }

                setWs(rows);
                setSourceFields(columns);
                setActiveStep("preview");
                setOpen(true)
      
      
                setTimeout(() => {
                  setFileChecking(false);
                }, 1000);
              };
              reader.readAsArrayBuffer(acceptedFiles[0]);
            
            } catch (err) {
              showAlert("Upload a valid .xls, .xlsx or .csv file");
              console.error("Upload a valid .xls, .xlsx or .csv file", err);
              setFileChecking(false);
            }
          }
        });

        return (
            <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) =>
            <>
            <MDBox>
                <MDBox sx={{fontSize : "14px"}}>{"Mapping Fields*"}</MDBox>
    
          {/* <Card
            sx={{
              minHeight: "100px",
              minWidth: "100px",
              margin: "10px",
              display: "inline-block",
              overflow: "hidden",
              position: "relative",
              backgroundColor: "#F4F4F4",
              border: "1px dashed #ADADAD",
              "&:hover": {
                border: "1px dashed #495dbd",
      
              }
            }}
            > */}

            <MDBox  pt={2} display="flex" justifyContent="space-between" alignItems="flex-start">
              <MDBox display="flex" width="100%" flexDirection="column" justifyContent="space-between" >
                <MDBox color="text" pt={0} mt={0} display="flex" justifyContent="space-between" flexDirection="row">
                  <MDTypography  variant="h6" component="span" color="#4c4c4c" display="flex">{""}</MDTypography>
                </MDBox>
                { (destinationTable && mp.length === 0) || edit ?
                <MDBox {...getRootProps({ className: 'dropzone' })} pt={2} sx={theme => dropBoxStyles(theme, { isDragActive })} alignItems="center" display="flex">
                  <input {...getInputProps()} />
                  <MDBox sx={{ position: "absolute", display: "flex", flexDirection: "column", zIndex: 2, mt: 11, width: 100, left: 125, top: 350 }}>
                    {isDragReject && (
                      <>
                        <MDTypography variant="button" color="white" component="span" fontWeight="medium" textAlign="center">
                          Invalid File!
                        </MDTypography>
                        <MDTypography variant="button" color="white" component="span" textAlign="center">
                          only .xls, .xslx or .csv files
                        </MDTypography>
                      </>
                    )
      
                    }
                    {isDragAccept && (
                      <>
                        <MDTypography variant="button" color="white" component="span" fontWeight="medium" textAlign="center">
                          Valid File!
                        </MDTypography>
                        <MDTypography variant="button" color="white" component="span" textAlign="center">
                          proceed. drop it here...
                        </MDTypography>
                      </>
                    )
                    }
                  </MDBox>
                  <MDBox sx={(theme) => uploadBoxStyles(theme, { isDragReject, isDragAccept })}></MDBox>
      
                  {fileChecking && (
                    <CircularProgress
                      size={30}
                      sx={() => ({
                        color: "#1A73E8",
                        backgroundColor: "transparent",
                        position: 'relative',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                      })}
                    />
                  )}
      
                  {(!fileChecking && !isDragReject && !isDragAccept) && (
                    <>
                    <MDBox className="dropBox-icon">
                    <Icon>cloud_upload</Icon>
                </MDBox>
                <MDTypography variant="button" color="dark">{"Drag & drop a file here or click to select a file"}</MDTypography>
                </>
                  )}
                </MDBox>
                : !destinationTable ? 
                <MDBox sx={{ fontSize: "13px"}}>choose Destination Table</MDBox> : ""
            }

              </MDBox>
            </MDBox>
            { (mp.length > 0 && !edit)  &&
                    <MDBox display="flex" alignItems="center">
                      <MDTypography onClick={viewMapping} alignItems="center" sx={{ fontSize: "14px", color: "#551A8B", "&:hover": { textDecoration: 'underline' }, cursor: 'pointer' }}>View Mapping</MDTypography>&nbsp;
                      <Tooltip title="Edit" placement="top">
                        {!edit && <IconButton disabled={disableEdit} sx={{ marginBottom: "1px" }} onClick={() => handleEditMapping()}><Icon fontSize="small" color={disableEdit ? "disable" : "info"}>edit</Icon></IconButton>}
                      </Tooltip>
                    </MDBox>}
            {/* <MDTypography
              display="flex"
              alignItems="center"
              component="a"
              href="#"
              onClick={() => onEdit(r[pkColumn])}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              <Icon fontSize="small">edit</Icon>&nbsp;Edit
            </MDTypography> */}
            { errorMessage && <MDBox sx={{color: "#F44335",fontSize: "13px"}}>{errorMessage}</MDBox>}


      
            <Modal open={open} onClose={handleUploadDialogClose}>
              <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Card
                  sx={{
                    overflow: 'hidden',
                    width: (activeStep === "preview") ? "calc(100vw - 32px)" : undefined,
                    height: (activeStep === "preview") ? "calc(100vh - 32px)" : undefined
                  }}
                >
                  <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <MDBox>
                      <MDTypography variant="h6" component="span" color="text">
                        {`Mapping for ${destinationTable}`}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex">
                      <IconButton onClick={handleUploadDialogClose} title="Close">
                        <Icon>close</Icon>
                      </IconButton>
                    </MDBox>
                  </MDBox>
      
                  {
                    activeStep === "preview" && (
                      <PreviewStep uploadSubType={destinationTable} ws={ws} dataStoreMapping = {true} />
                    )
                  }
                  {
                    activeStep === "mapping" && (
                            <MappingStep uploadSubType={destinationTable} dataStoreMapping={true} onChange={onChange} value={value} sourceFields={sourceFields} onMappingBack={handleOnMappingBack} onMappingNext={handleOnMappingNext} disableDataStoreCreate={true} />
                    )
                  }
                  {
                    activeStep !== "mapping" && (
                      <MDBox px={2.5} pb={2} pt={1} display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox>
                          {
                            enableBackBtn && (
                              <MDButton
                                size="medium"
                                color="info"
                                startIcon={<Icon>arrow_back_ios</Icon>}
                                onClick={handleBack}
                              >
                                Prev
                              </MDButton>
                            )
                          }
                        </MDBox>
                        {
                          activeStep === "preview" && <MDTypography color="text" variant="subtitle2" fontWeight="medium">Data Preview</MDTypography>
                        }
                        <MDBox>
                          {
                            enableNextBtn && (
                              <MDButton
                                size="medium"
                                color="info"
                                endIcon={<Icon>arrow_forward_ios</Icon>}
                                onClick={handleNext}
                              >
                                Next
                              </MDButton>
                            )
                          }
                        </MDBox>
                      </MDBox>
                    )
                  }
                </Card>
              </MDBox>
            </Modal>
          {/* </Card> */}

          <Modal open={openMapping} onClose={() => setOpenMapping(false)}>
                  <MDBox pt={20} pl={50} pr={50} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                      <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox>
                          <MDTypography variant="h6" component="span" color="text">
                            Mapping for {destinationTable}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex">
                          <IconButton onClick={() => setOpenMapping(false)} title="Close">
                            <Icon>close</Icon>
                          </IconButton>
                        </MDBox>
                      </MDBox>
                      <DataTable
                        table={{ columns: mappingColumns, rows: mappingRows }}
                        containerMaxHeight={474}
                        showTotalEntries={true}
                        entriesPerPage={true}
                      >
                      </DataTable>
                    </Card>
                  </MDBox>
                </Modal>

                {toolTip?.length >= 0 && 
                            <IconButton className="helpIcon"
                                sx={({ palette: { text } }) => ({
                                // marginLeft: .15,
                                // marginBottom: 1,
                                marginRight: -2,
                                color: "#979191",
                                visibility: 'hidden',
                                "&:hover": {
                                    color: text.main
                                }
                                })}
                                size="small"
                            //   onClick={() => {
                            //     // showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, pageName) :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp') :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                            //     window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                            //   }}
                            >
                                <Tooltip placement="left" title={toolTip ? toolTip : displayName}>
                                    <Icon>help</Icon>
                                </Tooltip>
                            </IconButton>
                        }

          </MDBox>
          </>}
        />
        )



}



export default Mapping;