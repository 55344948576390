export const tableStyles = (
    {
        palette: { black, info },
        functions: { rgba },
        borders: { borderWidth }
    },
    {
        vertBorders
    }
) => {

    const borderStyles = (element) => {
        if (element === "header")
            return {
                borderBottom: `${borderWidth[1]} solid ${rgba(black.main, 0.05)}`,
                borderRight: vertBorders ? `${borderWidth[1]} solid ${rgba(black.main, 0.25)}` : 'none',
            };
    };

    return {
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
        "& .MuiTableHead-root": {
            position: "sticky",
            top: 0,
            zIndex: 1
        },
        "& .MuiTableCell-head": {
            border: "none",
            position: "relative",
            backgroundColor: "#fafafa",
        },
        "& .MuiTableCell-head:hover": {
            "& .resizer": {
                display: 'inline-block',
                width: 2,
                height: '100%',
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translateX(50%)',
                zIndex: 1,
                touchAction: 'none'
            },
            "& .resizer:hover": {
                background: info.main
            },
            ...borderStyles("header")
        },
        "& .MuiTableRow-root:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
    }
};