import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import Textbox from "components/YAForm/components/Textbox";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import { useYADialog } from "components/YADialog";
import fetchRequest from "utils/fetchRequest";
import Switch from "components/YAForm/components/Switch";
import YASkeleton from "components/YASkeleton";
import Dropdown from "components/YAForm/components/Dropdown";
import { parseJsonString } from "utils";
import { useDropzone } from "react-dropzone";
import MDTypography from "components/MDTypography";

const dropBoxStyles = ({ palette: { white, info }, functions: { pxToRem } }, { isDragActive }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `${pxToRem(2)} dashed #ddd`,
    borderRadius: pxToRem(6),
    py: 3,
    my: 1,
    maxWidth: 600,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#f0f8ff",
    },
    "& .dropBox-icon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        height: pxToRem(56),
        width: pxToRem(56),
        fontSize: pxToRem(56),
        mb: 1.5,
        color: info.main,
        ...(isDragActive && {
            fontSize: pxToRem(36),
            color: white.main,
            backgroundColor: info.main,
        })
    }
})

const NotificationChannelForm = (props) => {
    const { notificationChannelId, mode, onClose } = props;
    const [loading, setLoading] = useState(false);
    const { showSnackbar, showAlert } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const [importing, setImporting] = useState(false);
    const [validFile, setValidFile] = useState(true);
    const [notificationChannelFile, setNotificationChannelFile] = useState(null);

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        maxFiles: 1,
        onDrop: async (acceptedFiles) => {
            try {
                var reader = new FileReader();
                reader.onload = function (e) {
                    const parsedFile = parseJsonString(e.target.result);                    
                    if ( parsedFile?.notificationChannels) {
                        setValidFile(true);
                        setNotificationChannelFile(acceptedFiles[0]);
                    }
                    else
                        setValidFile(false);
                };

                const fileExtension = (acceptedFiles[0]?.name || "").split(".").pop().toLowerCase();
                if (fileExtension !== "json")
                    setValidFile(false);
                else {
                    reader.readAsText(acceptedFiles[0]);
                }
            } catch (err) {
                showAlert("Upload a valid .json file");
                setValidFile(false);
                console.error("Upload a valid .json file", err);
                // setFileChecking(false);
            }
        }
    });

    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    async function getFormData() {
        setLoading(true);
        const [error, data] = await fetchRequest.get(`/api/notificationChannel/${notificationChannelId}`);
        if (error)
            console.error(error);

        const channelConfig = parseJsonString(data["config"]);
        setValue("webhookUrl", channelConfig?.webhookUrl);
        setValue("name", data["name"]);
        setValue("desc", data["desc"]);
        setValue("type", data["type"]);
        setValue("enabled", data["enabled"] || false);
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            setValue("enabled", true);
        }
    }, [mode]);

    const handleClose = useCallback((data) => {
        if (onClose) onClose(data);
    }, []);

    const onSubmit = async formdata => {
        if (mode === "import") {
            setImporting(true);
            const data = new FormData();
            data.append("notificationChannelFile", notificationChannelFile);
            const [err, response] = await fetchRequest.post(`/api/notificationChannel/import`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setImporting(false);
            if (err) {
                showAlert('Import Notification Channels', 'Something went wrong. Contact your administrator.');
            }
            else
                if (response && response.result === true) {
                    handleClose({ dashboardId: response.dashboardId });
                    onDialogClose();
                    showSnackbar(response.message || 'Notification Channels imported successfully', 'success');
                }
                else if (response && response.result === false) {
                    showAlert('Import Notification Channels', response.message || 'Something went wrong. Contact your administrator.');
                }
        } else {
            const url = mode === "edit" ?
            `/api/notificationChannel/${notificationChannelId}`
            : `/api/notificationChannel/new`;

            const [error, data] = await fetchRequest.post(url, JSON.stringify(formdata));
            if (!error) {
                if (data && data.result === false) {
                    if (Array.isArray(data.errors) && data.errors.length > 0) {
                        data.errors.forEach((e) => {
                            setError(e.field, { type: "manual", message: e.message });
                        });
                    }
                }
                else {
                    handleClose({ notificationChannelId: data.notificationChannelId });
                    onDialogClose();
                    showSnackbar(data.message, "success");
                }
            }
            else {
                showAlert("Create Dashboard", error?.data?.message || "Something went wrong. Contact your administrator.");
                console.error(error);
            }
        }
    };

    if (loading) {
        return (
            <MDBox pt={1} px={3} pb={2} minWidth={400}>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
            </MDBox>
        )
    }

    return (
        <MDBox pt={1} px={3} pb={2} minWidth={400}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            {
                mode === 'import' && <MDBox mb={3}>
                    <MDTypography mt={2} mb={1} variant="caption" color="text" fontWeight="medium">Select Notification Channel File</MDTypography>
                    <MDBox {...getRootProps({ className: 'dropzone' })} sx={theme => dropBoxStyles(theme, { isDragActive })}>
                        <input {...getInputProps()} />
                        <MDBox className="dropBox-icon">
                            <Icon>cloud_upload</Icon>
                        </MDBox>
                        <MDTypography variant="button" color="dark">{"Drag & drop your file here or click to select a file"}</MDTypography>
                        <MDTypography variant="caption" color="text" component="span" textAlign="center" mt={.5}>
                            {".json files only"}
                        </MDTypography>
                    </MDBox>
                    {
                        validFile && <MDTypography variant="button" color="dark" fontWeight="medium">{notificationChannelFile?.name}</MDTypography>
                    }
                    {
                        !validFile && <MDTypography variant="button" color="error" fontWeight="medium">Select a valid Notification Channel file.</MDTypography>
                    }
                </MDBox>
            }
            { mode !== 'import' && 
            <>
                <MDBox>
                    <MDBox mb={3}>
                        <Textbox setValue={setValue} control={control} fieldDef={{ toolTip: "The unique name assigned to the notification channel.", impact:"" , severity: "", name: "name", displayName: "Channel Name", required: true }} errorMessage={errors["name"] && errors["name"].message} />
                    </MDBox>
                </MDBox>
                <MDBox>
                    <MDBox mb={3}>
                        <Textbox setValue={setValue} control={control} fieldDef={{ toolTip: "A summary or details about the notification channel.", impact:"Description will not be visible in the Notification Channels screen." , severity: "Low", name: "desc", displayName: "Description" }} errorMessage={errors["desc"] && errors["desc"].message} />
                    </MDBox>
                </MDBox>
                <MDBox>
                    <MDBox mb={3}>
                        <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ toolTip: "The channel on which you want to send the notifications, for example, Microsoft Teams.", impact:"" , severity: "", name: "type", displayName: "Channel type", required: true, dataSource: { type: "static", data: [{ value: "WebhookTeams", label: "Webhook (Microsoft Teams)" }, { value: "WebhookSlack", label: "Webhook (Slack)" }] } }} errorMessage={errors["type"] && errors["type"].message} />
                    </MDBox>
                </MDBox>
                <MDBox>
                    <MDBox mb={3}>
                        <Textbox setValue={setValue} control={control} fieldDef={{ toolTip: "The unique URL endpoint for a webhook in Microsoft Teams. This URL is used to send notification to a Teams channel.", impact:"" , severity: "", name: "webhookUrl", displayName: "Webhook Url", required: true }} errorMessage={errors["webhookUrl"] && errors["webhookUrl"].message} />
                    </MDBox>
                </MDBox>
                <MDBox mb={1}>
                    <Switch setValue={setValue} control={control} fieldDef={{ toolTip: "", impact:"" , severity: "", name: "enabled", displayName: "Enabled" }} errorMessage={errors["enabled"] && errors["enabled"].message} />
                </MDBox>
            </>
            }
            <MDBox mt={4} mb={1} textAlign="right">
            {
                mode !== 'import' &&
                <MDButton type="submit" disabled={isSubmitting} variant="gradient" color="info" startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                    Save
                </MDButton>
            }
            {
                mode === 'import' &&
                <MDButton type="submit" disabled={importing || !(notificationChannelFile && validFile)} variant="gradient" color="info" startIcon={importing ? <CircularProgress color="white" size={15} /> : <Icon>upload</Icon>}>
                    Import
                </MDButton>
            }
            </MDBox>
            </form>
        </MDBox>
    );
};

export default NotificationChannelForm;