import { CircularProgress, Icon, Alert } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import Textbox from "components/YAForm/components/Textbox";
import Dropdown from "../Dropdown";
import Mapping from "../Mapping";
// import MDTypography from "components/MDTypography";

const DataStoreMappingForm = (props) => {
    const { pkId, mode, onClose, masterDef } = props;
    const { showSnackbar} = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const [destinationTable, setDestinationTable] = useState(null);
    const [loading, setLoading] = useState(false);
    const domain = getDomain();
    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();
    const {_formValues } = control
    const { fields } = masterDef
    const [dataStoreName, setDataStoreName] = useState(null)
    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/interface/dataStoreMapping/edit/${pkId}`);        
        setValue(fields[0].name, response.data["name"]);
        setValue(fields[1].name, response.data["fileType"]);
        setValue(fields[2].name, response.data["destinationTable"]);
        // setValue(fields[3].name, response.data["defaultFields"]);
        setDestinationTable(response.data["destinationTable"]);
        setValue(fields[4].name, response.data["mappingFields"]);
        setDataStoreName(response.data["name"])
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
    }, [mode]);

    const onSubmit = async data => {
        let url = mode === "edit" ? `${domain}/api/interface/dataStoreMapping/edit/${pkId}` : `${domain}/api/interface/dataStoreMapping/new`;
        const response = await Axios.post(url, data);
        if (response.data && response.data.result === false) {
            if (Array.isArray(response.data.errors) && response.data.errors.length > 0) {
                response.data.errors.forEach((e) => {
                    setError(e.field, { type: "manual", message: e.message });
                });
            }
        }
        else {
            handleClose();
            onDialogClose();
            showSnackbar(response.data.message, "success");
        }
    };

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    const onDestinationTableChange = (value) => {
        let tableField = fields[2].dataSource?.data?.find(obj => obj.value === value)
        if(tableField && tableField.fileType)
        {
            setValue(fields[1].name, tableField.fileType );
        }
        setDestinationTable(value)
    }

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={1000}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }
        let checkDisable = () => {
            if (destinationTable !== "cloudConsumption") {
                return false
            } else {
                if (mode !== "edit") {
                    return true
                } else {
                    return false
                }
            }
        }

        return (
            <MDBox pt={1} px={2} minWidth={600} overflow="auto">
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox>
                            <MDBox mb={3}>
                            <Textbox watch={watch} setValue={setValue} control={control} fieldDef={fields[0]} errorMessage={errors[fields[0].name] && errors[fields[0].name].message} />
                            </MDBox>
                            <MDBox mb={3}>
                            <Dropdown watch={watch} destinationTable = {destinationTable} setDestinationTable = {onDestinationTableChange} setValue={setValue} control={control} fieldDef={fields[2]} errorMessage={errors[fields[2].name] && errors[fields[2].name].message} />
                            </MDBox>
                            <MDBox mb={3}>
                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={fields[1]} errorMessage={errors[fields[1].name] && errors[fields[1].name].message} fileTypeDisable = {true} />
                            </MDBox>
                            {/* <MDBox mb={3}>
                            <Textbox watch={watch} setValue={setValue} control={control} fieldDef={fields[3]} errorMessage={errors[fields[3].name] && errors[fields[3].name].message} />
                            </MDBox> */}
                            {destinationTable !== "cloudConsumption" &&
                                <MDBox mb={3}>
                                    <Mapping watch={watch} setValue={setValue} destinationTable={destinationTable} control={control} mode={mode} formValues={_formValues} fieldDef={fields[4]} errorMessage={errors[fields[4].name] && errors[fields[4].name].message} dataStoreName={dataStoreName} disableEdit={destinationTable === "cloudConsumption" && mode === "edit"} />
                                </MDBox>
                            }
                            {
                                destinationTable === "cloudConsumption" &&
                                <Alert severity="info" sx={{ marginTop: 3, marginBottom: 5, fontSize: "14px", textAlign: "left" }}>
                                    {"Mapping fields for cloud consumption cannot be added/changed manually."}
                                </Alert>
                            }
                        </MDBox>
                    </MDBox>
                    <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting || checkDisable()} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};

export default DataStoreMappingForm;