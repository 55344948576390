import _ from "lodash";
import { Chip, Icon, Tooltip } from "@mui/material";
// import { Alert, Chip, CircularProgress, Icon, IconButton, InputAdornment, MenuItem, TextField, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import moment from "moment";
import { useState } from "react";
import { parseJsonString } from "utils";
import RuleBuilder from "./RuleBuilder";
// import { Controller } from "react-hook-form";
// import MDTypography from "components/MDTypography";

const filterTypeLabels = {
    eq: "is equal to",
    equals: "is equal to",
    ne: "is not equal to",
    notEquals: "is not equal to",
    contains: "contains",
    notContains: "does not contain",
    startsWith: "starts with",
    endsWith: "ends with",
    set: "is set",
    notSet: "is not set",
    gt: "is greater than",
    gte: "is greater than or equal to",
    lt: "is less than",
    lte: "is less than or equal to",
    between: "is in between",
    notBetween: "is not in between",
    before: "is before",
    after: "is after",
};

const getFilterDescription = (field, dataType = "string", operator, values,tableCols, tableName) => {
    let valueDescription = "";
    let fld = _.find(tableCols, { "schemaName": field})
    if (Array.isArray(values) && values?.length > 1) {
        if (["between", "notBetween"].includes(operator)) {
            if (dataType === "date")
                valueDescription = `${moment(values[0]).format(
                    "MMM DD YYYY"
                )} and ${moment(values[1]).format("MMM DD YYYY")}`;
            else
                valueDescription = `${values[0]} and ${values[1]}`;
        }
        else
            valueDescription = values.slice(0, values.length - 1).join(", ") + " or " + values[values.length - 1];
    }
    else if (Array.isArray(values) && values?.length === 1) {
        if (dataType === "date")
            valueDescription = moment(values).format("MMM DD YYYY");
        else valueDescription = values[0];
    }
    else if (["set", "notSet"].includes(operator)) { valueDescription = ""; }
    else { valueDescription = values; }
    return `${_.startCase(tableName === "subCostPool" ? 'Sub Cost Pool' : tableName === "costPool" ? 'Cost Pool' : fld?.displayName)} ${filterTypeLabels[operator]} ${valueDescription}`;
};

const Rule = (props) => {
    const { fieldDef: { required, dataSource }, mappingType, condition, onConditionChange, onClear, errorMessage, setRows, setReload, tablesCols, setTablesCols } = props;
    const [showCondition, setShowCondition] = useState(false);
    const conditionsArr = parseJsonString(condition) || [];
    const conditions = conditionsArr?.map((c) => <Tooltip key={conditionsArr.indexOf(c)} title={getFilterDescription(c.field, c.type, c.operator, c.value, tablesCols[c.tableName], c.tableName)} ><Chip size="small" key={c.field} sx={{ margin: 0.3 }} label={getFilterDescription(c.field, c.type, c.operator, c.value, tablesCols[c.tableName], c.tableName)} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" /></Tooltip>);
    
    
    const handleCondition = () => {
        setShowCondition(true);
    }

    const handleConditionClose = () => {
        setShowCondition(false)
    }

    const hanldeConditionSave = (_condition) => {
        setRows([])
        onConditionChange(_condition)
        setShowCondition(false)
        setReload(Math.random())
    }

    const renderListConfig = () => {
        return (
            <>
                <MDBox display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
                    <MDBox display="flex" flexDirection="column"
                        mt={mappingType && mappingType !== "" ? 2 : 1}
                        textAlign={mappingType && mappingType !== "" ? "left" : "center"}
                    >
                        {(!conditions || conditions.length === 0) &&
                            <MDButton ml={2} disabled={!mappingType} startIcon={<Icon>add</Icon>} variant="text" color="info" onClick={(e) => handleCondition(e)}>Add Rule</MDButton>
                        }
                    </MDBox>
                </MDBox>
                {
                    mappingType && mappingType !== "" && (
                        <>
                            {conditions && conditions.length > 0 && (
                                <MDBox sx={{
                                    py: 1,
                                    px: 2,
                                    m: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    borderRadius: 2,
                                    border: "2px dashed #ddd",
                                    width: "500px",
                                }}
                                >
                                    {
                                        conditions
                                    }
                                </MDBox>
                            )}
                            {conditions && conditions.length > 0 &&
                                <MDBox>
                                    <MDButton ml={2} mb={2} startIcon={<Icon>edit</Icon>} variant="text" color="text" onClick={(e) => handleCondition(e)}>Edit Rule</MDButton>
                                    <MDButton ml={2} mb={2} startIcon={<Icon>cancel</Icon>} variant="text" color="text" onClick={() => onClear()}>Clear</MDButton>
                                </MDBox>
                            }
                        </>
                    )
                }
            </>
        );
    };

    return (
        <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{border: required && errorMessage ? '1px solid red' : '', pb: '7px' }}>
        {showCondition && 
            <RuleBuilder 
                {...props}
                onChange={onConditionChange} 
                value={condition} 
                conditionType={"Rule"} 
                conditionJson={condition} 
                mappingType={mappingType} 
                setTablesCols={setTablesCols} 
                tablesCols={tablesCols}
                tables={dataSource.list} 
                onConditionClose={handleConditionClose} 
                onConditionSave={hanldeConditionSave}
            />}
        { renderListConfig()}
        </MDBox>
    );
}

export default Rule;