import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";

const { white } = colors;
const { md } = boxShadows;

const tableContainer = {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
      boxShadow: md,
    },
  },
};

export default tableContainer;
