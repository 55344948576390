import { CircularProgress, LinearProgress } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const thinkingBoxStyles = () => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    px: 2,
    pt: 1,
    pb: 1.25,
    mx: .5,
    position: "relative",
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    "& .progressBar": {
        position: "absolute",
        right: 0,
        left: 0,
        bottom: 0,
        height: 5,
        backgroundColor: "#ddd",
    },
    "& .progressBar .MuiLinearProgress-bar": {
        backgroundColor: "#facd35",
    }
});

const ThinkingBox = () => {

    return (
        <MDBox sx={() => thinkingBoxStyles()}>
            <MDBox sx={{
                position: 'relative',
                backgroundColor: '#facd35',
                height: 20,
                width: 20,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            >
                <MDAvatar
                    name={"Y ä"}
                    size="xs"
                    sx={{
                        background: "#facd35",
                        color: "#000000",
                        fontWeight: "500",
                        position: "absolute",
                        zIndex: 2,
                    }}
                />
                <CircularProgress
                    size={28}
                    thickness={2}
                    sx={() => ({
                        color: "#facd35",
                        backgroundColor: "transparent",
                        position: 'absolute',
                        zIndex: 1,
                    })}
                />
            </MDBox>
            <MDTypography component="span" ml={2} py={.5} variant="button" fontWeight="light" lineHeight={1.5} whiteSpace="pre-wrap">
                Working on it! Just a moment, please ...
            </MDTypography>
            <LinearProgress className="progressBar" variant="indeterminate" />
        </MDBox>
    );
};

export default ThinkingBox;