import { createRef, useState } from 'react';
// import { useEffect, useContext } from 'react';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';
import DashboardItem from 'components/DashboardItem';
// import TableRenderer from '../ToggleTableRenderer';
import colors from "assets/theme/base/colors";
// import { CubeContext } from '@cubejs-client/react';
import _ from 'lodash';
// import { useImmer } from 'use-immer';
// import { convertRStoTimelineData } from 'utils';
import TimeLine from "highcharts/modules/timeline.js";
TimeLine(Highcharts)

// const TimelineChartRenderer = ({ title, subtitle, vizState, vizOptions, chartHelpContextKey }) => {
const TimelineChartRenderer = ({ title, subtitle, vizOptions, chartHelpContextKey }) => {
        const chartRef = createRef();
    const [toggleType, setToggleType] = useState('chart');
    const { width, height, ref: rref } = useResizeDetector();
    // const { query } = vizState;
    // const [cubeQuery, setCubeQuery] = useImmer(query);
    // const [loading, setLoading] = useState(true);
    // const { cubeApi } = useContext(CubeContext);
    // const [resultSet, setResultSet] = useState(null);
    // const [tableVizOptions, setTableVizOptions] = useState(null);

    // useEffect(() => {
    //     setCubeQuery(query);
    // }, [query]);

    // useEffect(() => {
    //     async function getData() {
    //         if (query) {
    //             cubeApi.load(query).then((resultSet) => {
    //                 setResultSet(resultSet);
    //                 setLoading(false);
    //             });
    //         } else {
    //             setResultSet([]);
    //             setLoading(false);
    //         }
    //     }
    //     getData();
    // }, [cubeQuery]);

    // useEffect(() => {
    //     let eventNameCol = query?.dimensions.map(dimensions => {
    //         let col = {
    //             "name": dimensions,
    //             "displayName": String(dimensions).match(/[^|]*$/g)[0],
    //             "type": "string"
    //         };
    //         return col;
    //     });

    //     let eventDescriptionCol = query?.measures.map(measures => {
    //         let col = {
    //             "name": measures,
    //             "displayName": String(measures).match(/[^|]*$/g)[0],
    //             "type": "string" 
    //         };
    //         return col;
    //     });

    //     var tableVizOptions = Object.assign({}, vizOptions);
        
    //     tableVizOptions["columns"] = [...eventNameCol, ...eventDescriptionCol];
    //     tableVizOptions["params"] = [tableVizOptions['category']];
    //     tableVizOptions["disableServerSidePaging"] = true;
    //     tableVizOptions["hideColumnOptions"] = true;
    //     tableVizOptions["heightUnits"] = 5.8;

    //     setTableVizOptions(tableVizOptions);
    // }, [vizOptions, vizState, toggleType === 'table']);

    // if (loading || !resultSet) {
    //     return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>;
    // }
    // if (!vizState || !vizOptions) {
    //     return null;
    // }


    var count = vizOptions.series.length;
    // const timelineData = convertRStoTimelineData(resultSet, count === 1 ? colors.singleDataColors : colors.graphColors, vizOptions);

    const timelineData = 
    [
        {
            "name": "ENIAC",
            "label": "1945: First general-purpose electronic computer",
            "description": "ENIAC, the first general-purpose electronic computer, is completed in 1945."
        },
        {
            "name": "Transistor",
            "label": "1947: Invention of the transistor",
            "description": "In 1947, the invention of the transistor revolutionized electronics and computing."
        },
        {
            "name": "UNIVAC I",
            "label": "1951: First commercial computer",
            "description": "UNIVAC I, the first commercial computer, is delivered to the U.S. Census Bureau in 1951."
        },
        {
            "name": "FORTRAN",
            "label": "1957: First high-level programming language",
            "description": "FORTRAN, the first widely used high-level programming language, is introduced in 1957."
        },
        {
            "name": "Integrated Circuit",
            "label": "1958: First integrated circuit",
            "description": "In 1958, the first working integrated circuit is invented by Jack Kilby."
        },
        {
            "name": "Mouse",
            "label": "1964: Invention of the computer mouse",
            "description": "The first computer mouse is invented by Douglas Engelbart in 1964."
        },
        {
            "name": "ARPANET",
            "label": "1969: Birth of the Internet",
            "description": "ARPANET, the precursor to the Internet, sends its first message between two computers in 1969."
        },
        {
            "name": "Intel 4004",
            "label": "1971: First microprocessor",
            "description": "The Intel 4004, the first commercially available microprocessor, is introduced in 1971."
        },
        {
            "name": "Apple II",
            "label": "1977: First successful personal computer",
            "description": "The Apple II, one of the first highly successful personal computers, is released in 1977."
        },
        {
            "name": "IBM PC",
            "label": "1981: IBM Personal Computer",
            "description": "The IBM Personal Computer (IBM PC) is introduced in 1981, setting the standard for future PCs."
        },
        {
            "name": "World Wide Web",
            "label": "1989: Invention of the World Wide Web",
            "description": "Tim Berners-Lee invents the World Wide Web in 1989, revolutionizing global communication and access to information."
        },
        {
            "name": "Google",
            "label": "1998: Founding of Google",
            "description": "Google is founded in 1998, soon becoming the dominant search engine and shaping the Internet as we know it."
        }
    ]
    
    const opts = {
        chart: {
            type: 'timeline',
            width: width,
            height: height || 400, 
            backgroundColor: colors.chartBackground
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false
        },
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        tooltip: {
            outside: false,
            followPointer: true,
            formatter: function () {
                return `<b>${this.point.label}</b><br/>${this.point.description}`;
            }
        },
        series: [{
            data: timelineData
        }]
    };

    const nodata = !count>0;

    return (
        // toggleType === 'table' && tableVizOptions ? (
        //     <TableRenderer
        //         title={title}
        //         subtitle={subtitle}
        //         vizState={vizState}
        //         vizOptions={tableVizOptions}
        //         toggleType={toggleType}
        //         setToggleType={setToggleType}
        //         resultSet={resultSet}
        //         chartHelpContextKey={chartHelpContextKey}
        //     />
        // ) : (
            <DashboardItem hideToggle={true} nodata={nodata} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} toggleType={toggleType} setToggleType={setToggleType} >
                <div ref={rref} style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} key={_.uniqueId()} />
                </div>
            </DashboardItem>
        // )
    );
};

export default TimelineChartRenderer;
