import _ from "lodash";
import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
import Dropdown from "./components/Dropdown"
import DynamicDropdown from "./components/DynamicDropdown";
import Textbox from "components/YAForm/components/Textbox";
import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import MDTypography from "components/MDTypography";
import { useYADialog } from "components/YADialog";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import Float from "components/YAForm/components/Float";
import { getFiscalMonthsArray } from "utils/budget";
import { useIntl } from "react-intl";
import { toFloat } from "utils";

const BusinessCaseNonCloudItemForm = (props) => {
    const { businessCaseId, headerDetails, pkId, mode, onClose } = props;
    const { showSnackbar } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [flag,setFlag] = useState(false)
    const domain = getDomain();
    let isDecimal = true

    const { watch, control, setValue, getValues, setError, clearErrors, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/businessCases/businessCaseItem/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);
        const model = response?.data;

        setValue("businessCaseHeaderId", businessCaseId);
        setValue("yearNameId", response.data["yearNameId"]);
        setValue("expenseTypeId", response.data["expenseTypeId"]);
        setValue("accountId", response.data["accountId"]);
        setValue("costCentreId", response.data["costCentreId"]);
        setValue("vendorId", response.data["vendorId"]);
        setValue("costPoolId", response.data["costPoolId"]);
        setValue("subCostPoolId", response.data["subCostPoolId"]);
        setValue("towerId", response.data["towerId"]);
        setValue("subTowerId", response.data["subTowerId"]);
        setValue("description", response.data["description"]);
        setValue("jan", toFloat(model?.jan));
        setValue("feb", toFloat(model?.feb));
        setValue("mar", toFloat(model?.mar));
        setValue("apr", toFloat(model?.apr));
        setValue("may", toFloat(model?.may));
        setValue("jun", toFloat(model?.jun));
        setValue("jul", toFloat(model?.jul));
        setValue("aug", toFloat(model?.aug));
        setValue("sep", toFloat(model?.sep));
        setValue("oct", toFloat(model?.oct));
        setValue("nov", toFloat(model?.nov));
        setValue("dec", toFloat(model?.dec));
        setValue("janCal", model?.jan == null || model?.jan == 0 ? model?.jan : parseFloat(model?.jan).toFixed(2))
        setValue("febCal", model?.feb == null || model?.feb == 0 ? model?.feb : parseFloat(model?.feb).toFixed(2))
        setValue("marCal", model?.mar == null || model?.mar == 0 ? model?.mar : parseFloat(model?.mar).toFixed(2));
        setValue("aprCal", model?.apr == null || model?.apr == 0 ? model?.apr : parseFloat(model?.apr).toFixed(2));
        setValue("mayCal", model?.may == null || model?.may == 0 ? model?.may : parseFloat(model?.may).toFixed(2));
        setValue("junCal", model?.jun == null || model?.jun == 0 ? model?.jun : parseFloat(model?.jun).toFixed(2));
        setValue("julCal", model?.jul == null || model?.jul == 0 ? model?.jul : parseFloat(model?.jul).toFixed(2));
        setValue("augCal", model?.aug == null || model?.aug == 0 ? model?.aug : parseFloat(model?.aug).toFixed(2));
        setValue("sepCal", model?.sep == null || model?.sep == 0 ? model?.sep : parseFloat(model?.sep).toFixed(2));
        setValue("octCal", model?.oct == null || model?.oct == 0 ? model?.oct : parseFloat(model?.oct).toFixed(2));
        setValue("novCal", model?.nov == null || model?.nov == 0 ? model?.nov : parseFloat(model?.nov).toFixed(2))
        setValue("decCal", model?.dec == null || model?.dec == 0 ? model?.dec : parseFloat(model?.dec).toFixed(2));
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            setValue("businessCaseHeaderId", businessCaseId);
            setValue("yearNameId", null);
            setValue("expenseTypeId", null);
            setValue("accountId", null);
            setValue("costCentreId", null);
            setValue("vendorId", null);
            setValue("costPoolId", null);
            setValue("subCostPoolId", null);
            setValue("towerId", null);
            setValue("subTowerId", null);
            // setValue("solutionOfferingId", headerDetails['solutionOfferingId']);
            // setValue("businessUnitId", null);
            setValue("description", null);
            setValue("jan", null);
            setValue("feb", null);
            setValue("mar", null);
            setValue("apr", null);
            setValue("may", null);
            setValue("jun", null);
            setValue("jul", null);
            setValue("aug", null);
            setValue("sep", null);
            setValue("oct", null);
            setValue("nov", null);
            setValue("dec", null);
            setValue("janCal", null);
            setValue("febCal", null);
            setValue("marCal", null);
            setValue("aprCal", null);
            setValue("mayCal", null);
            setValue("junCal", null);
            setValue("julCal", null);
            setValue("augCal", null);
            setValue("sepCal", null);
            setValue("octCal", null);
            setValue("novCal", null);
            setValue("decCal", null);
        }
    }, [mode]);

    const onSubmit = async data => {
        let url = mode === "edit" ? `${domain}/api/businessCases/businessCaseItem/${pkId}` : `${domain}/api/businessCases/${businessCaseId}/businessCaseItem/new`;

        const response = await Axios.post(url, data);
        if (response.data && response.data.result === false) {
            if (Array.isArray(response.data.errors) && response.data.errors.length > 0) {
                response.data.errors.forEach((e) => {
                    setError(e.field, { type: "manual", message: e.message });
                });
            }
        }
        else {
            handleClose();
            onDialogClose();
            showSnackbar(response.data.message, "success");
        }
    };

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    const state = getValues();
    
    const totalBusinessCase1 = () => {
       
        let  tb1 =
                toFloat(state?.jan)+
                toFloat(state?.feb)+
                toFloat(state?.mar)+
                toFloat(state?.apr)+
                toFloat(state?.may)+
                toFloat(state?.jun)+
                toFloat(state?.jul)+
                toFloat(state?.aug)+
                toFloat(state?.sep)+
                toFloat(state?.oct)+
                toFloat(state?.nov)+
                toFloat(state?.dec)

        return tb1;
    }
    
    const handleOnMonthlyAmountBlur = useCallback((name, value) => {
        setValue(name.replace("Cal", ""), value);
        clearErrors(name.replace("Cal", ""));
    },[headerDetails]);

    if(flag == true){
        totalBusinessCase1();
        setFlag(false)
    }

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={1000}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }

        return (
            <MDBox pt={1} px={3} pb={2} maxWidth={1000}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "A year associated with the business case.", impact: "", severity: "",  name: "yearNameId", displayName: "Year", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/year" } }} errorMessage={errors["yearNameId"] && errors["yearNameId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "Defines the type of expenditure (OpEx or CapEx).", impact: "", severity: "", name: "expenseTypeId", displayName: "Expense Type", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/expenseType" } }} errorMessage={errors["expenseTypeId"] && errors["expenseTypeId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The account associated with the business case.", impact: "", severity: "Low", name: "accountId", displayName: "Account", "required": false, dataSource: { type: "custom", url: "/api/businessCases/lookup/account" } }} errorMessage={errors["accountId"] && errors["accountId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The specific cost center responsible for the business case.", impact: "", severity: "Low", name: "costCentreId", displayName: intl.formatMessage({ id: "budgetItemForm.costCentre" }), "required": false, dataSource: { type: "custom", url: "/api/businessCases/lookup/costCentre" } }} errorMessage={errors["costCentreId"] && errors["costCentreId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "Specifies the vendor involved in the business case.", impact: "", severity: "Low", name: "vendorId", displayName: "Vendor", "required": false, dataSource: { type: "custom", url: "/api/businessCases/lookup/vendor" } }} errorMessage={errors["vendorId"] && errors["vendorId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The cost pool linked with the business case.", impact: "", severity: "", name: "costPoolId", displayName: "Cost Pool", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/costPool", dependentFields: ["subCostPoolId"] } }} errorMessage={errors["costPoolId"] && errors["costPoolId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The sub cost pool linked with the business case.", impact: "", severity: "Low", name: "subCostPoolId", displayName: "Sub Cost Pool", "required": false, dataSource: { type: "custom", url: "/api/businessCases/lookup/subCostPool/:costPoolId", parentFields: ["costPoolId"] } }} errorMessage={errors["subCostPoolId"] && errors["subCostPoolId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "Represents TBM Tower related to the business case.", impact: "", severity: "Low", name: "towerId", displayName: "Tower", "required": false, dataSource: { type: "custom", url: "/api/budgets/lookup/tower", dependentFields: ["subTowerId"] } }} errorMessage={errors["towerId"] && errors["towerId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The second-level of the IT tower used in the business case.", impact: "", severity: "Low", name: "subTowerId", displayName: "Sub Tower", "required": false, dataSource: { type: "custom", url: "/api/budgets/lookup/subTower/:towerId", parentFields: ["towerId"] } }} errorMessage={errors["subTowerId"] && errors["subTowerId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Textbox watch={watch} setValue={setValue} control={control} textarea={true} fieldDef={{ unique: "",toolTip: "A detailed explanation about the business case by providing additional context.", impact: "", severity: "Low", name: "description", displayName: "Description", "required": false }} errorMessage={errors["description"] && errors["description"].message} />
                            </MDBox>
                        </MDBox>
                        <MDBox>
                            <MDBox mt={2} mb={1}>
                                <MDTypography variant="button" fontWeight="medium">Month-wise Expenditure</MDTypography>
                            </MDBox>
                            <MDBox display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                                {
                                    getFiscalMonthsArray(headerDetails?.financialYearStartMonth).map((mth) => {
                                        return (
                                            <MDBox key={mth + 'box'} m={.7}>
                                                <Float key={mth} watch={watch} setValue={setValue} control={control} fieldDef={{ name: mth.toLowerCase() + "Cal", displayName: mth, variant: "outlined", dataSource:{parentFields: ['yearNameId']}, width: 100 }}  errorMessage={errors[mth.toLowerCase()] && errors[mth.toLowerCase()].message} onBlur={handleOnMonthlyAmountBlur} isDecimal={isDecimal} />
                                            </MDBox>
                                        )
                                    })
                                }
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox mt={3} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};

const BusinessCaseCloudItemForm = (props) => {
    const { businessCaseId, headerDetails, pkId, mode, onClose, userInfo } = props;
    const { showSnackbar } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [cpId, setCpId] = useState(null)
    const [scpId, setScpId] = useState(null)
    const [vId, setvId] = useState(null)
    const [serviceName, setServiceName] = useState(null)
    const [providerId, setProviderId] = useState(null)
    const [formDatadded, setFormDataadd] = useState(false);
    const [tId, setTId] = useState(null)
    const [stId, setStId] = useState(null)
    const [sc, setSc] = useState(null)
    const [flag,setFlag] = useState(false)
    const domain = getDomain();
    let isDecimal = true

    const { watch, control, setValue, getValues, setError, clearErrors, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/businessCases/businessCaseCloudItem/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);
        const model = response?.data;
        setValue("businessCaseHeaderId", businessCaseId);
        setValue("providerId", response.data["providerId"]);
        setProviderId(response.data["providerId"])
        setValue("yearNameId", response.data["yearNameId"]);
        setValue("serviceName", response.data["serviceName"]);
        setValue("serviceCategory", response.data["serviceCategory"]);
        setValue("accountId", response.data["accountId"]);
        setValue("costCentreId", response.data["costCentreId"]);
        setValue("vendorId", response.data["vendorId"]);
        setValue("costPoolId", response.data["costPoolId"]);
        setValue("subCostPoolId", response.data["subCostPoolId"]);
        setValue("towerId", response.data["towerId"]);
        setValue("subTowerId", response.data["subTowerId"]);
        setValue("description", response.data["description"]);
        setValue("jan", toFloat(model?.jan));
        setValue("feb", toFloat(model?.feb));
        setValue("mar", toFloat(model?.mar));
        setValue("apr", toFloat(model?.apr));
        setValue("may", toFloat(model?.may));
        setValue("jun", toFloat(model?.jun));
        setValue("jul", toFloat(model?.jul));
        setValue("aug", toFloat(model?.aug));
        setValue("sep", toFloat(model?.sep));
        setValue("oct", toFloat(model?.oct));
        setValue("nov", toFloat(model?.nov));
        setValue("dec", toFloat(model?.dec));
        setValue("janCal", model?.jan == null || model?.jan == 0 ? model?.jan : parseFloat(model?.jan).toFixed(2))
        setValue("febCal", model?.feb == null || model?.feb == 0 ? model?.feb : parseFloat(model?.feb).toFixed(2))
        setValue("marCal", model?.mar == null || model?.mar == 0 ? model?.mar : parseFloat(model?.mar).toFixed(2));
        setValue("aprCal", model?.apr == null || model?.apr == 0 ? model?.apr : parseFloat(model?.apr).toFixed(2));
        setValue("mayCal", model?.may == null || model?.may == 0 ? model?.may : parseFloat(model?.may).toFixed(2));
        setValue("junCal", model?.jun == null || model?.jun == 0 ? model?.jun : parseFloat(model?.jun).toFixed(2));
        setValue("julCal", model?.jul == null || model?.jul == 0 ? model?.jul : parseFloat(model?.jul).toFixed(2));
        setValue("augCal", model?.aug == null || model?.aug == 0 ? model?.aug : parseFloat(model?.aug).toFixed(2));
        setValue("sepCal", model?.sep == null || model?.sep == 0 ? model?.sep : parseFloat(model?.sep).toFixed(2));
        setValue("octCal", model?.oct == null || model?.oct == 0 ? model?.oct : parseFloat(model?.oct).toFixed(2));
        setValue("novCal", model?.nov == null || model?.nov == 0 ? model?.nov : parseFloat(model?.nov).toFixed(2))
        setValue("decCal", model?.dec == null || model?.dec == 0 ? model?.dec : parseFloat(model?.dec).toFixed(2));
        setFormDataadd(true)
        setLoading(false);
    }

    useEffect(async () => {
        if (providerId && vId) {
            setValue("vendorId", vId);
        }
        if (providerId && serviceName) {
            setScpId(null)
            setSc(null)
            setTId(null)
            setStId(null)
            await Axios.get(`${domain}/api/businessCases/lookup/costPool`).then(async (cp) => {
                setCpId(_.find(cp?.data, function(o) {return o.label === "Outside Services"})?.value)
                await Axios.get(`${domain}/api/businessCases/lookup/subCostPool/${_.find(cp?.data, function(o) {return o.label === "Outside Services"})?.value}`).then((scp) => {
                    setScpId(_.find(scp?.data, function(o) {return o.label === "Cloud Service Provider"})?.value)
                })
            })
            await Axios.get(`${domain}/api/businessCases/serviceCategory/${providerId}/${serviceName}`).then(async (sc) =>{
                if (sc?.data?.length > 0) {
                    setSc(sc?.data[0]['ServiceCategory'])
                } else {
                    setSc('Others')
                }
                await Axios.get(`${domain}/api/businessCases/lookup/tower`).then(async (to) => {
                    if (sc?.data?.length > 0) {
                        const towerId = _.find(to?.data, function(o) {return o.label === sc?.data[0]['Tower']})?.value
                        setTId(towerId)
                        await Axios.get(`${domain}/api/businessCases/lookup/subTower/${towerId}`).then((st) => {
                            setStId(_.find(st?.data, function(o) {return o.label === "Cloud"})?.value)
                        })
                    } else {
                        const towerId = _.find(to?.data, function(o) {return o.label === "Application"})?.value
                        setTId(towerId)
                        await Axios.get(`${domain}/api/businessCases/lookup/subTower/${towerId}`).then((st) => {
                            setStId(_.find(st?.data, function(o) {return o.label === "Cloud"})?.value)
                        })
                    }
                })
            })
        }
    },[mode, serviceName, providerId, vId])

    useEffect(() => {
        if (mode === "edit" && !formDatadded) {
            getFormData();
        }
            setValue("businessCaseHeaderId", businessCaseId);
            setValue("serviceCategory", sc);
            setValue("costPoolId", cpId);
            setValue("subCostPoolId", scpId);
            setValue("towerId", tId);
            setValue("subTowerId", stId);
    }, [mode, cpId, scpId, sc, tId, stId]);

    const onSubmit = async data => {
        let url = mode === "edit" ? `${domain}/api/businessCases/businessCaseCloudItem/${pkId}` : `${domain}/api/businessCases/${businessCaseId}/businessCaseCloudItem/new`;

        const response = await Axios.post(url, data);
        if (response.data && response.data.result === false) {
            if (Array.isArray(response.data.errors) && response.data.errors.length > 0) {
                response.data.errors.forEach((e) => {
                    setError(e.field, { type: "manual", message: e.message });
                });
            }
        }
        else {
            handleClose();
            onDialogClose();
            showSnackbar(response.data.message, "success");
        }
    };

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    const state = getValues();
    
    const totalBusinessCase1 = () => {
       
        let  tb1 =
                toFloat(state?.jan)+
                toFloat(state?.feb)+
                toFloat(state?.mar)+
                toFloat(state?.apr)+
                toFloat(state?.may)+
                toFloat(state?.jun)+
                toFloat(state?.jul)+
                toFloat(state?.aug)+
                toFloat(state?.sep)+
                toFloat(state?.oct)+
                toFloat(state?.nov)+
                toFloat(state?.dec)

        return tb1;
    }
    
    const handleOnMonthlyAmountBlur = useCallback((name, value) => {
        setValue(name.replace("Cal", ""), value);
        clearErrors(name.replace("Cal", ""));
    },[headerDetails]);

    if(flag == true){
        totalBusinessCase1();
        setFlag(false)
    }

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={1000}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }

        return (
            <MDBox pt={1} px={3} pb={2} maxWidth={1000}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "Specifies the cloud service provider responsible for delivering the cloud services for the business case.", impact: "", severity: "",  name: "providerId", displayName: "Provider", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/provider", dependentFields: ["serviceName", "serviceCategory"] } }} setProviderId={setProviderId} errorMessage={errors["providerId"] && errors["providerId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "A year associated with the business case.", impact: "", severity: "", name: "yearNameId", displayName: "Year", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/year" } }} errorMessage={errors["yearNameId"] && errors["yearNameId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <DynamicDropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "Represents the specific cloud service of the provider utilized within the business case. The Service Name defines the Service Category, Cost Pool, Sub Cost Pool, Tower and Sub Tower.", impact: "", severity: "", name: "serviceName", displayName: "Service Name", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/serviceName/:providerId", parentFields: ['providerId'] } }} setServiceName={setServiceName}errorMessage={errors["serviceName"] && errors["serviceName"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <DynamicDropdown watch={watch} setValue={setValue} control={control} disabled={true} fieldDef={{ unique: "", toolTip: "The broader category of the cloud service, such as compute, storage, networking, or database. This field is auto-selected based on the Service Name.", impact: "", severity: "", name: "serviceCategory", displayName: "Service Category", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/serviceCategory/:providerId/:serviceName", parentFields: ["providerId", 'serviceName'] } }} errorMessage={errors["serviceCategory"] && errors["serviceCategory"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The account associated with the business case.", impact: "", severity: "Low", name: "accountId", displayName: "Account", "required": false, dataSource: { type: "custom", url: "/api/businessCases/lookup/account" } }} errorMessage={errors["accountId"] && errors["accountId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The specific cost center responsible for the business case.", impact: "", severity: "Low", name: "costCentreId", displayName: intl.formatMessage({ id: "budgetItemForm.costCentre" }), "required": false, dataSource: { type: "custom", url: "/api/businessCases/lookup/costCentre" } }} errorMessage={errors["costCentreId"] && errors["costCentreId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} userInfo={userInfo} cloud={true} control={control} fieldDef={{ unique: "", toolTip: "Specifies the vendor involved in the business case.", impact: "", severity: "", name: "vendorId", displayName: "Vendor", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/vendor" } }} setvId={setvId} errorMessage={errors["vendorId"] && errors["vendorId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} disabled={true} fieldDef={{ unique: "", toolTip: "The cost pool linked with the business case.", impact: "", severity: "", name: "costPoolId", displayName: "Cost Pool", "required": true, dataSource: { type: "custom", url: "/api/businessCases/lookup/costPool", dependentFields: ["subCostPoolId"] } }} errorMessage={errors["costPoolId"] && errors["costPoolId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} disabled={true} fieldDef={{ unique: "", toolTip: "The sub cost pool linked with the business case.", impact: "", severity: "", name: "subCostPoolId", displayName: "Sub Cost Pool", "required": true, dataSource: { type: "custom", url: `/api/businessCases/lookup/subCostPool/:costPoolId`, parentFields: ['costPoolId'] } }} errorMessage={errors["subCostPoolId"] && errors["subCostPoolId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} disabled={true} fieldDef={{ unique: "", toolTip: "Represents TBM Tower related to the business case.", impact: "", severity: "Low", name: "towerId", displayName: "Tower", "required": false, dataSource: { type: "custom", url: "/api/businessCases/lookup/tower", dependentFields: ["subTowerId"], parentFields: ["serviceCategory"]} }} errorMessage={errors["towerId"] && errors["towerId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} disabled={true} fieldDef={{ unique: "", toolTip: "The second-level of the IT tower used in the business case.", impact: "", severity: "Low", name: "subTowerId", displayName: "Sub Tower", "required": false, dataSource: { type: "custom", url: "/api/businessCases/lookup/subTower/:towerId", parentFields: ["towerId"] } }} errorMessage={errors["subTowerId"] && errors["subTowerId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Textbox watch={watch} setValue={setValue} control={control} textarea={true} fieldDef={{ unique: "", toolTip: "A detailed explanation about the business case by providing additional context.", impact: "", severity: "Low", name: "description", displayName: "Description", "required": false }} errorMessage={errors["description"] && errors["description"].message} />
                            </MDBox>
                        </MDBox>
                        <MDBox>
                            <MDBox mt={2} mb={1}>
                                <MDTypography variant="button" fontWeight="medium">Month-wise Expenditure</MDTypography>
                            </MDBox>
                            <MDBox display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                                {
                                    getFiscalMonthsArray(headerDetails?.financialYearStartMonth).map((mth) => {
                                        return (
                                            <MDBox key={mth + 'box'} m={.7}>
                                                <Float key={mth} watch={watch} setValue={setValue} control={control} fieldDef={{ name: mth.toLowerCase() + "Cal", displayName: mth, variant: "outlined", dataSource:{parentFields: ['yearNameId']}, width: 100 }}  errorMessage={errors[mth.toLowerCase()] && errors[mth.toLowerCase()].message} onBlur={handleOnMonthlyAmountBlur} isDecimal={isDecimal} />
                                            </MDBox>
                                        )
                                    })
                                }
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox mt={3} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};
export {
    BusinessCaseNonCloudItemForm,
    BusinessCaseCloudItemForm
}