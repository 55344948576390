import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, } from "react";
import { useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import { useYADialog } from "components/YADialog";
import fetchRequest from "utils/fetchRequest";
import YASkeleton from "components/YASkeleton";
import Dropdown from "components/YAForm/components/Dropdown";


const S3bucketForm = (props) => {
    const { onClose, tableData, fileName, loading } = props;
    const { showSnackbar, showAlert } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);

    const { watch, control, setValue, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    const onSubmit = async formdata => {
        const fdata = { bucketId: formdata.s3BucketId, tableData: tableData, fileName: fileName }
        const [error, data] = await fetchRequest.post(`api/interface/export/s3`, fdata);
        if (!error) {
            handleClose();
            onDialogClose();
            if (data.result) {
                showSnackbar(data.message, "success");
            }
            else {
                showSnackbar(data.message, "error");
            }
        }
        else {
            showAlert("Export", error?.data?.message || "Something went wrong. Contact your administrator.");
            console.error(error);
        }

    };
    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    if (loading) {
        return (
            <MDBox pt={1} px={3} pb={2} minWidth={400}>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
            </MDBox>
        )
    }

    return (
        <MDBox pt={1} px={3} pb={2} minWidth={400}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <MDBox mb={2}>
                    <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "s3BucketId", displayName: "S3 Bucket", required: true, dataSource: { type: "custom", url: "/api/interface/lookup/s3bucket" } }} errorMessage={errors["s3BucketId"] && errors["s3BucketId"].message} />
                </MDBox>
                {/* <MDBox mb={2}>
                    <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ name: "folder", displayName: "Folder" }} errorMessage={errors["folder"] && errors["folder"].message} />
                </MDBox> */}
                <MDBox mt={4} mb={1} textAlign="right">
                    <MDButton type="submit" disabled={isSubmitting} variant="gradient" color="info" startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                        Save
                    </MDButton>
                </MDBox>
            </form>
        </MDBox>
    );
};

export default S3bucketForm;