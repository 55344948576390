import ChartRenderer from "components/ChartRenderer";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import fetchRequest from "utils/fetchRequest";
import FilterContainer from "components/FilterContainer";
import { useImmer } from "use-immer";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import MDBox from "components/MDBox";
import YASkeleton from "components/YASkeleton";
import useHandleError from "hooks/useHandleError";
import colors from "assets/theme/base/colors";
import { getFiscalMonthsArray } from "utils/budget";
import { ResponsiveDashboardContainer1 } from "components/ResponsiveDashboardContainer";
import { DashboardContextProvider, initDashboardContext, setDashboardContextFilters, useDashboardContext } from "components/DashboardContext";
import { current } from "immer";
import { applyDefaultFilters, setDrilldownFilters, setSelectedFilter, deleteSelectedFilter } from "utils/dashboard";

const DashboardPage = () => {
  const handleError = useHandleError();
  const { dashboardId } = useParams();
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const [dashboardDef, setDashboardDef] = useImmer(null);
  const [filtersCleared, setFiltersCleared] = useState(false)
  const [, dispatch] = useDashboardContext();
  filtersCleared ? window.history.replaceState({}, document.title) : '';

  useEffect(() => {
    async function getDashboardDef() {
      var [err, data] = await fetchRequest.get(`/api/dashboard/${dashboardId}`)
      if (err) {
        handleError(err);
      }
      else {
        let newDashboardDef = state && !filtersCleared ? setDrilldownFilters(applyDefaultFilters(data), state, true) : applyDefaultFilters(data);
        setDashboardDef(newDashboardDef);
        initDashboardContext(dispatch, newDashboardDef.filters, newDashboardDef.variables);
        setLoading(false);
        // setFiltersCleared(false)
      }
    }
    getDashboardDef();
  }, [dashboardId, filtersCleared]);

  const selectFilter = (selectedFilter) => {
    setDashboardDef((draft) => {
      let filter = draft.filters?.find(f => f.name === selectedFilter.name);
      if (filter)
        filter.selected = true;
    });
  }
  const deleteFilter = (deletedFilter) => {
    setDashboardDef((draft) => {
      deleteSelectedFilter(draft, deletedFilter)
      setDashboardContextFilters(dispatch, current(draft.filters));
    });
  }
  const setFilter = (selectedFilter) => {
    const firstMonth = dashboardDef.variables["firstMonth"] || 'Jan';
    const currentMonth = dashboardDef.variables["currentMonth"] || 'Dec';
    const mthArray = getFiscalMonthsArray(firstMonth);
    let fil = selectedFilter
    if (fil.name === "Month" && fil.session) {
      if (selectedFilter.values)
        fil.values = mthArray.slice(0, mthArray.indexOf(selectedFilter.values[0]) + 1)
      else
        fil.values = mthArray.slice(0, mthArray.indexOf(currentMonth) + 1)
    }

    if (selectedFilter.values?.length === 0) {
      setDashboardDef((draft) => {
        deleteSelectedFilter(draft, selectedFilter)
        let filter = draft.filters?.find(f => f.name === selectedFilter.name);
        if (filter)
          filter.selected = true;
        setDashboardContextFilters(dispatch, current(draft.filters));
      });
    }
    else {
      setDashboardDef((draft) => {
        setSelectedFilter(draft, fil.values, fil.name, fil.dimension, fil.operator)
        setDashboardContextFilters(dispatch, current(draft.filters));
      });
    }
  }

  if (loading) {
    return <YASkeleton variant="dashboard-loading" />;
  }

  const dashboardItem = (item) => {
    return (
      <div key={item.id}>
        <ChartRenderer title={item.name} subtitle={item.desc} chartHelpContextKey={item.helpContextKey} vizState={item.vizState} vizOptions={item.vizOptions} cubeOptions={item.cubeOptions} />
      </div>
    )
  };

  const { displayName, desc, layouts, widgets, helpContextKey, headerShadow } = dashboardDef;
  return (
    <MDBox bgColor={colors.dashboardBackground} minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0, md: 6, sm: 6, xs: 6 }}>
      <PageHeader title={displayName} subtitle={desc} pageHelpContextKey={helpContextKey} dashboardDef={dashboardDef} selectFilter={selectFilter} setFilter={setFilter} deleteFilter={deleteFilter} clearFilters={() => setFiltersCleared(true)} sessionFilters={true} headerShadow={ widgets ? true : headerShadow ? headerShadow :false}/>
      <FilterContainer dashboardDef={dashboardDef} selectFilter={selectFilter} setFilter={setFilter} deleteFilter={deleteFilter} clearFilters={() => setFiltersCleared((prv)=> !prv)} sessionFilters={false} headerShadow={widgets ? true : headerShadow ? headerShadow :false}/>
      <MDBox px={1} pb={2}>
        <ResponsiveDashboardContainer1 dashboardLayouts={layouts}>
          {widgets.map(dashboardItem)}
        </ResponsiveDashboardContainer1>
      </MDBox>
    </MDBox>
  );
};

const DashboardPageWithContext = () => <DashboardContextProvider><DashboardPage /></DashboardContextProvider>

export default AnimatedRoute(DashboardPageWithContext);
