import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import BarChartRenderer from 'components/ChartRenderer/components/BarChartRenderer';
import ColumnChartRenderer from 'components/ChartRenderer/components/ColumnChartRenderer';
import TreeMapChartRenderer from 'components/ChartRenderer/components/TreeMapChartRenderer';
import GaugeChartRenderer from 'components/ChartRenderer/components/GaugeChartRenderer';
import BulletChartRenderer from 'components/ChartRenderer/components/BulletChartRenderer';
import TableRenderer from 'components/ChartRenderer/components/TableRenderer';
import SankeyRenderer from './components/SankeyRenderer';
import ColumnYearlyChartRenderer from './components/ColumnYearlyChartRenderer';
import ColumnYearlyLineChartRenderer from './components/ColumnYearlyLineChartRenderer';
import ColumnYearlyLinePredictedChartRenderer from './components/ColumnYearlyLinePredictedChartRenderer';
import ColumnYearlyLinePredictedChartRenderer1 from './components/ColumnYearlyLinePredictedChartRenderer1';
import TitleChartRenderer from './components/TitleChartRenderer';
import BannerCompactRenderer from './components/BannerCompactRenderer';
import MetricRenderer from './components/MetricRenderer';
import MetricYearlyRenderer from './components/MatricYearlyRenderer';
import BannerCompactCategoryRenderer from './components/BannerCompactCategoryRenderer';
import BannerPrimaryRenderer from './components/BannerPrimaryRenderer';
import BannerStackRenderer from './components/BannerStackRenderer';
import BannerListRenderer from './components/BannerListRenderer';
import LineChartRenderer from './components/LineChartRenderer';
import LineChartRenderer_CF from './components/LineChartRenderer_CF';
import ErrorBoundary from 'components/ErrorBoundary';
import StackedColumnChartRenderer from './components/StackedColumnChartRenderer';
import StackedBarChartRenderer from './components/StackedBarChartRenderer';
import SpendLineRenderer from './components/SpendLineRenderer';
import SubDashboardChartRenderer from './components/SubDashboardChartRenderer';
import StackedColumnYearlyLineChartRenderer from './components/StackedColumnYearlyLineChartRenderer';
import PieChartRenderer from './components/PieChartRenderer';
import PiesChartRenderer from './components/PiesChartRenderer';
import StackedAreaChartRenderer from './components/StackedAreaChartRenderer';
// import SpendLinesRenderer from './components/SpendLinesRenderer';
import ErrorBox from 'components/ErrorBox';
import DashboardItem from 'components/DashboardItem';
import YASkeleton from 'components/YASkeleton';
import PivotTableRenderer from './components/PivotTableRenderer';
import {convertYearFilterAll} from 'utils/charts'
import HeaderListRenderer from './components/HeaderListRenderer';
import PivotTableRenderer_CF from './components/PivotTableRenderer_CF';
import TreeGraphRenderer_CF from './components/TreeGraphRenderer_CF';
import BarChartRenderer_CF from 'components/ChartRenderer/components/BarChartRenderer_CF';
import ColumnChartRenderer_CF from 'components/ChartRenderer/components/ColumnChartRenderer_CF';
import CommentsListRenderer_CF from './components/CommentsListRenderer_CF';
import BannerCompactRenderer_CF from './components/BannerCompactRenderer_CF';
import ColumnYearlyLinePredictedChartRenderer_CF from './components/ColumnYearlyLinePredictedChartRenderer_CF';
import GaugeChartRenderer_CF from 'components/ChartRenderer/components/GaugeChartRenderer_CF';
import TableRenderer_CF from 'components/ChartRenderer/components/TableRenderer_CF';
import StackedGroupedColumnRenderer from 'components/ChartRenderer/components/StackedGroupedCloumnRenderer'
import VennChartRenderer from './components/VennChartRenderer';
import FixedplacementBarChart from './components/FixedplacementBarChartRenderer';
import TimelineChartRenderer from './components/TimelineChartRenderer';

const TypeToChartComponent = {
  line: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <LineChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  line_CF: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <LineChartRenderer_CF
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  bar: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <BarChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },

  bar_CF: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <BarChartRenderer_CF
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  column: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <ColumnChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  column_CF: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <ColumnChartRenderer_CF
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  treeGraph_CF: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <TreeGraphRenderer_CF
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  fixedPlacementBar: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <FixedplacementBarChart
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  columnYearly: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <ColumnYearlyChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  columnYearlyLine: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <ColumnYearlyLineChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  columnYearlyLinePredicted1: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <ColumnYearlyLinePredictedChartRenderer1
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  stackedColumn: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <StackedColumnChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  commentsChart_CF: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <CommentsListRenderer_CF
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  stackedBar: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <StackedBarChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },  
  stackedArea: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <StackedAreaChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },  
  pie: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <PieChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },  
  venn: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <VennChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },  
  pivotTable: ({ loading, title, subtitle, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <PivotTableRenderer
        loading={loading}
        pivotTable={true}
        title={title}
        subtitle={subtitle}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  pivotTable_CF: ({ loading, title, subtitle, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <PivotTableRenderer_CF
        loading={loading}
        pivotTable={true}
        title={title}
        subtitle={subtitle}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  subDashboard: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig,currentMonth,previousMonth, onDrilldownRequested, vizState, cubeOptions}) => {
    return (
      <SubDashboardChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        currentMonth={currentMonth}
        previousMonth={previousMonth}
      />
    );
  },
  stackedColumnYearlyLine: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <StackedColumnYearlyLineChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },

  stackedGroupedColumn: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <StackedGroupedColumnRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  treemap: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState, cubeOptions }) => {
    return (
      <TreeMapChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        cubeOptions={cubeOptions}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  solidgauge: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <>
      <GaugeChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      /><br/>
      </>
    );
  },

  solidgauge_CF: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <>
      <GaugeChartRenderer_CF
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      /><br/>
      </>
    );
  },
  bullet: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <>
      <BulletChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      /><br/>
      </>
    );
  },
  // table: ({ loading, title, subtitle, vizOptions, resultSet, pivotConfig, onDrilldownRequested }) => {
  //   return (
  //     <TableRenderer
  //       loading={loading}
  //       title={title}
  //       subtitle={subtitle}
  //       vizOptions={vizOptions}
  //       resultSet={resultSet}
  //       pivotConfig={pivotConfig}
  //       onDrilldownRequested={onDrilldownRequested}
  //     />
  //   );
  // },
  title: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <TitleChartRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  bannerCompact: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <BannerCompactRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  bannerCompact_CF: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <BannerCompactRenderer_CF
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  metric: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <MetricRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  metricYearly: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <MetricYearlyRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  bannerPrimary: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <BannerPrimaryRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  bannerStack: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <BannerStackRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  bannerList: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <BannerListRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  headerList: ({ loading, title, subtitle, chartHelpContextKey, vizOptions, resultSet, pivotConfig, onDrilldownRequested, vizState }) => {
    return (
      <HeaderListRenderer
        loading={loading}
        title={title}
        subtitle={subtitle}
        chartHelpContextKey={chartHelpContextKey}
        vizOptions={vizOptions}
        vizState={vizState}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderSkeleton = (title, subtitle, chartType) => {
  if (["bannerStack", "bannerPrimary", "bannerCompact", "metric"].includes(chartType)) {
    return <YASkeleton variant="dashboard-item" />
  }
  return <DashboardItem loading={true} title={title} subtitle={subtitle}></DashboardItem>
};

const renderChart = (title, subtitle, chartType, chartHelpContextKey,currentMonth,previousMonth, vizState, vizOptions, cubeOptions, Component) =>
  ({ resultSet, error, pivotConfig }) => {
    if (error) {
      return <ErrorBox error={error} />
    }
    if (!resultSet) {
      return renderSkeleton(title, subtitle, chartType);
    }
    return <ErrorBoundary>
      <Component loading={!resultSet} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} currentMonth={currentMonth} previousMonth={previousMonth} vizState={vizState} vizOptions={vizOptions} cubeOptions={cubeOptions} resultSet={resultSet} pivotConfig={pivotConfig} />
    </ErrorBoundary>
  };

const ChartRenderer = ({ title, subtitle, chartHelpContextKey, vizState, vizOptions, cubeOptions, currentMonth,previousMonth, teamForMember, role }) => {
  const { chartType } = vizState;
  let vizStateNew = convertYearFilterAll(vizState, vizOptions)
  if (chartType === "table") {
    return (
      <ErrorBoundary>
        <TableRenderer
          title={title}
          subtitle={subtitle}
          chartHelpContextKey={chartHelpContextKey}
          vizState={vizStateNew ? vizStateNew : vizState}
          vizOptions={vizOptions}
          cubeOptions={cubeOptions}
        />
      </ErrorBoundary>
    );
  }
  else if (chartType === "table_CF") {
    return (
      <ErrorBoundary>
        <TableRenderer_CF
          title={title}
          subtitle={subtitle}
          chartHelpContextKey={chartHelpContextKey}
          vizState={vizState}
          vizOptions={vizOptions}
          cubeOptions={cubeOptions}
          teamForMember={teamForMember}
          role={role}
        />
      </ErrorBoundary>
    );
  } else if(chartType === "sankey"){
    return (
      <ErrorBoundary>
        <SankeyRenderer
          title={title}
          subtitle={subtitle}
          chartHelpContextKey={chartHelpContextKey}
          vizState={vizState}
          vizOptions={vizOptions}
          cubeOptions={cubeOptions}
        />
      </ErrorBoundary>
    );
  } else if(chartType === "pies"){
    return (
      <ErrorBoundary>
        <PiesChartRenderer
          title={title}
          subtitle={subtitle}
          chartHelpContextKey={chartHelpContextKey}
          vizState={vizState}
          vizOptions={vizOptions}
          cubeOptions={cubeOptions}
        />
      </ErrorBoundary>
    );
  } else if(chartType === "spendLine"){
    return (
      <ErrorBoundary>
        <SpendLineRenderer
          title={title}
          subtitle={subtitle}
          chartHelpContextKey={chartHelpContextKey}
          vizState={vizState}
          vizOptions={vizOptions}
          cubeOptions={cubeOptions}
        />
      </ErrorBoundary>
    );
  }
  else if(chartType === "timeline"){
    return (
      <ErrorBoundary>
        <TimelineChartRenderer
          title={title}
          subtitle={subtitle}
          chartHelpContextKey={chartHelpContextKey}
          vizState={vizState}
          vizOptions={vizOptions}
        />
      </ErrorBoundary>
    );
  }
  else if(chartType === "bannerCompactCategory"){
    return (
      <ErrorBoundary>
        <BannerCompactCategoryRenderer
          vizState={vizState}
          vizOptions={vizOptions}
          cubeOptions={cubeOptions}
        />
      </ErrorBoundary>
    );
  }
  else if(chartType === "ColumnYearlyLinePredicted"){
    return (
      <ErrorBoundary>
        <ColumnYearlyLinePredictedChartRenderer
          title={title}
          subtitle={subtitle}
          chartHelpContextKey={chartHelpContextKey}
          vizState={vizState}
          vizOptions={vizOptions}
          cubeOptions={cubeOptions}
        />
      </ErrorBoundary>
    );
  }
  else if(chartType === "ColumnYearlyLinePredicted_CF"){
    return (
      <ErrorBoundary>
        <ColumnYearlyLinePredictedChartRenderer_CF
          title={title}
          subtitle={subtitle}
          chartHelpContextKey={chartHelpContextKey}
          vizState={vizState}
          vizOptions={vizOptions}
          cubeOptions={cubeOptions}
        />
      </ErrorBoundary>
    );
  }
  else
  {
    return (
      <OtherChartRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={vizOptions} cubeOptions={cubeOptions} currentMonth={currentMonth} previousMonth={previousMonth}/>
    )
  }
};

const OtherChartRenderer = ({ title, subtitle, chartHelpContextKey, vizState, vizOptions, cubeOptions, currentMonth, previousMonth }) => {
  let vizStateNew = convertYearFilterAll(vizState, vizOptions)
  const { query, chartType, pivotConfig } = vizStateNew ? vizStateNew : vizState;
  const component = TypeToMemoChartComponent[chartType];
  let updatedQuery = query

    if (vizOptions.queryType && vizOptions.queryType === "CompareWithYears") {
      const updatedFilters = updatedQuery.filters.filter((filter) => filter.member !== "Years.year");
      updatedQuery = { ...updatedQuery, filters: updatedFilters };
    }

    if(vizOptions?.transformFilters) {
      const  updatedFilters = updatedQuery?.filters?.map((filter)=> { 
        return vizOptions.transformFilters.map(fil => {  
          if (fil.from === filter.member)
            return {
                member : fil.to,
                operator : filter.operator,
                values: filter.values
            }       
          else
            return filter
        })[0]
      })
      updatedQuery = { ...updatedQuery, filters: updatedFilters }
    }

    if(vizOptions?.duck) {
      const  updatedFilters = updatedQuery?.filters?.map((filter)=> { 
        if (filter.member === "Years.year") 
          return {
            member : "YearsDuck.year",
            operator : filter.operator,
            values: filter.values
          }
        else if (filter.member === "Months.month") 
          return {
            member : "MonthsDuck.month",
            operator : filter.operator,
            values: filter.values
          }
        else if (filter.member === "CloudTeams.name") 
          return {
            member : "CloudTeamsDuck.name",
            operator : filter.operator,
            values: filter.values
          }
        else
          return filter
      }).filter((filter) => filter.member !== "ExpenseTypes.name");
      updatedQuery = { ...updatedQuery, filters: updatedFilters }
    }
  
    if (vizOptions?.ignoreFilters) {
      const findStar = vizOptions?.ignoreFilters.filter((filter) => filter.includes('*')).map(item => item.split('.')[0]) 
      const updatedFilters = updatedQuery?.filters?.filter((filter)=> !vizOptions?.ignoreFilters.includes(filter.member) && !findStar.some(prefix => filter.member.startsWith(prefix)))
      updatedQuery = { ...updatedQuery, filters: updatedFilters }
    }

  const renderProps = updatedQuery ? useCubeQuery(updatedQuery) : useCubeQuery(query);
  return component && renderChart(title, subtitle, chartType, chartHelpContextKey, currentMonth, previousMonth, vizState, vizOptions, cubeOptions, component)({ ...renderProps, pivotConfig });
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  vizOptions: PropTypes.object,
  cubeOptions: PropTypes.object,
  cubeApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  vizOptions: {},
  cubeOptions: null,
  cubeApi: null,
};
export default ChartRenderer;