import { Card, Modal, Tab, Tabs, Icon, IconButton } from "@mui/material";
import DataTable from "components/DataTable";
// import useFetchRequest from "hooks/useFetchRequest";
import { useCallback, useMemo, useState, useEffect } from "react";
import numeral from "numeral";
import fetchRequest from "utils/fetchRequest";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import SingleTowerRule from "./components/SingleTowerRule";
import MultiTowerRule from "./components/MultiTowerRule";
import moment from 'moment';
import _ from "lodash";
import { useAppController } from "context";
import { useImmer } from "use-immer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import StrategySelectionSidebar from "pages/Dataflow/components/StrategySelectionSidebar";
import * as XLSX from "xlsx";
import { normalizeCurrency } from "utils/table";
import { convertPlural } from "utils";
import Rule from "./components/Rule";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ height: "100%", width: "100%" }}
    >
      {value === index && (
        <MDBox p={3} height="100%" width="100%" {...other}>
          {children}
        </MDBox>
      )}
    </div>
  );
}
const AddTowerRule = (props) => {
  const { typeFilter, yearFilter, monthFilter, setRefresh, chargeBackMonthly } = props;
  const [mappingType, setMappingType] = useState(typeFilter === "Spend" ? undefined : 2)
  const [ condition, setCondition ] = useState(null)
  const chargeBack = _.find(chargeBackMonthly, { monthNameId: monthFilter, yearNameId: yearFilter }) ? true : false

  let mappingTypes = []

  if (typeFilter === "Spend") {
    mappingTypes = [
      { value: 1, displayName: "Cost Center, Expense Type & Cost Pool" },
      { value: 2, displayName: "Cost Center, Expense Type, Cost Pool, Account & Vendor" },
      { value: 3, displayName: "Any Attribute" },
    ]
  }
  else {
    mappingTypes = [
      { value: 2, displayName: "Cost Center, Expense Type, Cost Pool, Account & Vendor" },
    ]
  }

  const selectedMappingTypeOption = mappingTypes.find(o => o.value === mappingType);

  const handleChangeMappingType = (value) => {
    setMappingType(value);
  }

  const handleClear = () => {
    setCondition(null)
  }

  const strategyItemStyles = ({ palette: { white, info } }) => ({
    display: "flex",
    flexDirection: "column",
    px: 2,
    py: 1.5,
    m: 0.8,
    zIndex: 2,
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #ddd",
    "& .title": {
      marginBottom: 1
    },
    "&:hover": {
      backgroundColor: info.main
    },
    "&:hover .title, &:hover .subtitle": {
      color: white.main,
    }
  });

  if (typeFilter === "Spend")
    return (
      <>
        {
          !mappingType && (
            <MDBox height="100%" px={3} pt={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <MDTypography variant="subtitle1" fontWeight="light" color="text" component="span" mb={3} mt={props.mt}>
                Choose a mapping strategy
              </MDTypography>
              <MDBox display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" px={3}>
                {
                  mappingTypes?.map((option) => {
                    return <MDBox key={`l_${option.value}`}
                      sx={(theme) => strategyItemStyles(theme)}
                      onClick={() => {
                        setMappingType(option.value)
                      }}
                    >
                      <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
                      <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{option.displayName}</MDTypography>
                    </MDBox>
                  })
                }
              </MDBox>


            </MDBox>
          )
        }
        {
          (mappingType && mappingType === 3) && 
            <MappingCondition 
              {...props} 
              mappingTypes={mappingTypes} 
              chargeBack={chargeBack} 
              mappingType={mappingType} 
              onChangeMappingType={handleChangeMappingType} 
              selectedMappingTypeOption={selectedMappingTypeOption} 
              setRefresh={setRefresh} 
              condition={condition} 
              setCondition={setCondition} 
              handleClear={handleClear} 
            />
        }
        {
          (mappingType && mappingType !== 3) && 
            <FetchData 
              {...props} 
              mappingTypes={mappingTypes} 
              chargeBack={chargeBack} 
              mappingType={mappingType} 
              onChangeMappingType={handleChangeMappingType} 
              selectedMappingTypeOption={selectedMappingTypeOption} 
              typeFilter={typeFilter} 
              setRefresh={setRefresh} 
            />
        }
      </>
    )
  else
    return (
      <>
        {
          mappingType !== undefined && <FetchData {...props} chargeBack={chargeBack} yearFilter={yearFilter} monthFilter={monthFilter} mappingTypes={mappingTypes} mappingType={2} typeFilter={typeFilter} setRefresh={setRefresh} />
        }
      </>
    )
}

const MappingCondition = (props) => {
  const { condition, setCondition, handleClear, handleChangeMappingType, selectedMappingTypeOption, typeFilter, setRefresh, yearFilter, monthFilter, mappingTypes, mappingType, onChangeMappingType } = props
  const [showOptions, setShowOptions] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ loading, setLoading ] = useState(false)
  const [data, setData] = useState(null)
  const [ rows, setRows ] = useState([])
  const [ reload, setReload ] = useState(false)
  const [tablesCols, setTablesCols] = useImmer({});
  const { showSnackbar } = useYADialog();
  const [controller,] = useAppController();
  const { appDef: { settings } } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

  useEffect(async () => {
    if (condition) {
      setLoading(true)
      let [error, data] = await fetchRequest.post(`/api/dataflow/towerRules3/${yearFilter}/${monthFilter}`, condition)
      
      if (error) {
        if (error.data?.message) {
          showSnackbar(error.data?.message, "error")
        } else {
          showSnackbar("An error occured while processing your request.", "error");
        }
        setLoading(false)
      }
      else if (data) {
        let newData
        newData = data.costElements.map(item => {
          let obj = {}
          Object.keys(item).map( key => {
            let modKey = key.replace(/\./g, "__")
            obj[modKey] = item[key]
          })
          return obj
        });
        setRows(newData)
        setData(data)
        setLoading(false)
      } 
    }
  },[reload])

  const filteredMappingTypes = mappingTypes.filter(m => m.value !== mappingType);
  const amountAllocated = rows.reduce((total, idx) => total + idx.amount, 0)??0

  const handleOnOptionsClick = () => {
    setShowOptions(true);
  }

  const handleOnOptionsClose = () => {
    setShowOptions(false);
  }

  let columns = []
  if (condition && tablesCols && rows.length > 0) {
    Object.keys(rows[0]).map( key => {
      let splitKey = key.split("__")
      if (splitKey.length > 1) {
        let tableName = splitKey.length > 2 ? splitKey[1] :splitKey[0]
        let field = splitKey.length > 2 ? splitKey[2] : splitKey[1]
        if (field !== 'id') {
          let displayName = tablesCols[tableName].find(o => o.schemaName === field).displayName??field
          let cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }
          let column = {
            Header: displayName,
            accessor: key,
            Cell: cell
          }
          columns.push(column) 
        }
      } else if (key !== 'amount') {
        let foundDef = tablesCols['expenditure'].find(o => o.schemaName === key)
        let displayName = foundDef.displayName??key
        let type = foundDef.type
        let cell = null
        if (type === "date") {
          cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> }
        } else {
          cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }
        }
        let column = {
          Header: displayName,
          accessor: key,
          Cell: cell
        }
        columns.push(column)
      }
    })
    columns.push({
      Header: "Amount",
      accessor: 'amount',
      Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> }
    })
  }

  const strategyItemStyles = () => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pl: 2,
    pr: 1,
    py: 1.5,
    zIndex: 2,
    marginBottom: "0px",
    marginRight: "-10px",
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #ddd",
    "& .selectionBox": {
      display: "flex",
      flexDirection: "column",
    },
    "& .title": {
      marginBottom: 1
    }
  });

  const handleOnOptionSelection = (value) => {
    if (onChangeMappingType)
      setRows([])
      setCondition(null)
      onChangeMappingType(value);
  }

  const handleConditionClear = () => {
    setRows([])
    handleClear()
  }

  return(
    <MDBox display="flex">
    <MDBox width={condition ? "50%" : "100%"} borderRight="1px solid rgba(0, 0, 0, 0.05)">
      <MDBox pl={3} pr={4} pt={1} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="subtitle1" fontWeight="medium" color="dark" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">Create a mapping rule.</MDTypography>
        { !condition &&
          <MDBox
            sx={(theme) => strategyItemStyles(theme)}
            onClick={handleOnOptionsClick}
          >
            <MDBox className="selectionBox">
              <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
              <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{selectedMappingTypeOption?.displayName}</MDTypography>
            </MDBox>
            <Icon sx={{ ml: 1.5, mt: .5, fontSize: "32px!important", color: "rgba(0, 0, 0, 0.54)" }}>keyboard_arrow_down</Icon>
          </MDBox>
        }
      </MDBox>
      <MDBox>
        <Rule
            multipleRules={true}
            fieldDef={{
                name: "rule",
                displayName: "Rule",
                disableFilters: true,
                required: true,
                unique: true,
                dataSource: {
                    type: "custom",
                    list: [
                      {value: "expenditure", displayName: "Expenditure"},
                      {value: "expenseType", displayName: "Expense Type"},
                      {value: "account", displayName: "Account"},
                      {value: "costCentre", displayName: "Cost Center"},
                      {value: "vendor", displayName: "Vendor"},
                      {value: "costPool", displayName: "Cost Pool"},
                      {value: "subCostPool", displayName: "Sub Cost Pool"}
                    ]
                }
            }}
            mappingType={"Tower"}
            condition={condition}
            onConditionChange={setCondition}
            setReload={setReload}
            yearFilter={yearFilter}
            monthFilter={monthFilter}
            setRows={setRows}
            onClear={handleConditionClear}
            setTablesCols={setTablesCols}
            tablesCols={tablesCols}
        />
      </MDBox>
      {
        condition &&
        <DataTable
          variant="tile"
          table={{ columns, rows: rows }}
          containerMaxHeight={"calc(100vh - 550px)"}
          showTotalEntries={true}
          isSorted={true}
          newStyle1={true}
          noEndBorder
          entriesPerPage={true}
          canSearch={true}
          loading={loading}
        />
      }
    </MDBox>
    {
      condition && !loading &&
      <TowerRuleSelection 
        {...props} 
        isSubmitting={isSubmitting} 
        setIsSubmitting={setIsSubmitting} 
        errors={errors} 
        setErrors={setErrors} 
        onChangeMappingType={handleChangeMappingType} 
        selectedMappingTypeOption={selectedMappingTypeOption} 
        typeFilter={typeFilter} 
        setRefresh={setRefresh} 
        condition={condition}
        setReload={setReload}
        data={data}
        setCondition={setCondition}
        amountAllocated={amountAllocated}
      />
    }
    {showOptions && (
      <StrategySelectionSidebar
        options={filteredMappingTypes}
        onOptionSelection={handleOnOptionSelection}
        onOptionsClose={handleOnOptionsClose}
      />
    )}
    </MDBox>
  )
}

const getPresignedUrl = async (fId) => {
  return await fetchRequest.get(`/api/blob/presignedPost/${fId}`);
}

const TowerRuleSelection = (props) => {
  const { yearFilter, monthFilter, condition, setIsSubmitting, isSubmitting, setReload, data, setCondition, amountAllocated, mappingType } = props
  const { showSnackbar } = useYADialog();
  const [tabValue, setTabValue] = useState(0);
  const [controller,] = useAppController();
  const { appDef: { featureSet } } = controller;
  const enableAssetDistribution = featureSet && featureSet.dataManagement?.assetDistribution === true;

  const handleChange = (e, newValue) => {
    setTabValue(newValue);
  }
  const submitRules = async (ceSelected, fileName, fileData, mappedFields) => {
    setIsSubmitting(true);
    if (fileName && fileName !== "") {
      var uf = {}
      uf.originalFileName = fileName
      uf.mappingFields = JSON.stringify(mappedFields)
      uf.yearNameId = yearFilter
      uf.monthNameId = monthFilter
      uf.totalRecords = fileData.length
      uf.loadedRecords = fileData.length
      uf.destinationTable = "towerConsumption"
      uf.fileStatus = "Loaded"
      uf.source = 'GL'

      const [resError, response] = await fetchRequest.post(`/api/dataflow/createupload`, uf);
      if (resError) {
        console.error("An error occured while creating upload");
        console.error(resError);
        if (resError.data?.message) {
          showSnackbar(resError.data?.message, "error")
          setReload(Math.random());
        } else {
          showSnackbar("An error occured while processing your request.", "error");
        }
        return false;
      }

      const wb = XLSX.utils.book_new();
      const ws1 = XLSX.utils.json_to_sheet(fileData);
      XLSX.utils.book_append_sheet(wb, ws1);

      var data = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      var newFile = new File([data], fileName);

      const [presignedUrlError, presignedUrlResponse] = await getPresignedUrl(response.id);
      if (presignedUrlError) {
        console.error("An error occured while getting presigned url");
        console.error(presignedUrlError);
        if (presignedUrlError.data?.message) {
          showSnackbar(presignedUrlError.data?.message, "error")
          setReload(Math.random());
        } else {
          showSnackbar("An error occured while processing your request.", "error");
        }
        return false;
      }

      const options = new URL(presignedUrlResponse).host.indexOf("blob.core.windows.net") !== -1 ? {
        headers: {
          'X-Ms-Blob-Type': 'BlockBlob',
          'X-Ms-Version': '2023-11-03'
        }
      } : null;
      const [putError,] = await fetchRequest.put(presignedUrlResponse, newFile, options);
      if (putError) {
        console.error("An error occured while uploading to blob store");
        console.error(putError);
        showSnackbar("An error occured while processing your request.", "error");
        return false;
      }
      ceSelected.map((a) => a["allocationFileId"] = response.id)
    }
    ceSelected.map((a) => a.destinationTable = convertPlural(a.destinationTable))
    ceSelected = ceSelected.reduce((acc, current) => {
      const x = acc.find(item => item.towerId === current.towerId && item.subTowerId === current.subTowerId && item.tier === current.tier);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

  
    let twRule = ceSelected.map((a) => {
      return {
        "id": null,
        "filterCondition": condition,
        "towerId": a.towerId,
        "subTowerId": a.subTowerId,
        "portion": a.portion,
        "destinationTable": a.destinationTable,
        "allocationFileId": a.allocationFileId,
        "condition": a.condition,
        "ruleName": a.ruleName,
        "yearNameId": yearFilter,
        "monthNameId": monthFilter,
        "srlNo": a.srlNo,
        "source": 'GL',
        "tier": a.tier

      }
    })


    let [err, data1] = await fetchRequest.post(`/api/dataflow/towerRules/${yearFilter}/${monthFilter}`, JSON.stringify(twRule))
    if (err) {
      console.error(err);
      if (err.data?.message) {
        showSnackbar(err.data?.message, "error")
      } else {
        showSnackbar("An error occured while processing your request.", "error");
      }
    }
    else if (data1) {
      showSnackbar(data1, "success");
      if (props.setRefresh) {

        props.setRefresh(Math.random());
      }
      setCondition(null)
      setReload(Math.random());
    }
    setIsSubmitting(false);
  }

  return(
    <MDBox width="40%" px={3} pt={4} pb={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      { data &&
      <>
        <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <MDBox flex={1} textAlign="center" display="flex">
            <MDBox display="flex" flexDirection="column" flex={1} mt="auto">
              <MDTypography variant="button" component="span" fontWeight="medium" color="text">Allocating</MDTypography>
              <MDTypography variant="h3" component="span" fontWeight="medium" color="dark">{numeral(amountAllocated.toFixed(2)).format('$0,0')}</MDTypography>
            </MDBox>
          </MDBox>
          <MDBox>
            <Icon sx={{ mt: 1, mb: 1, color: "#7b809a", fontSize: "28px!important" }}>south</Icon>
          </MDBox>
        </MDBox>
        <MDBox
          height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
          <Tabs width="100%" value={tabValue} onChange={handleChange} textColor="inherit">
            <Tab disableRipple label="Tower" />
            <Tab disableRipple label="Multiple Towers" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <SingleTowerRule mappingType={mappingType} enableAssetDistribution={enableAssetDistribution} yearFilter={yearFilter} monthFilter={monthFilter} costElementsRemaining={data?.costElements} selectedRows={data?.costElements} isSubmitting={isSubmitting} submitRules={submitRules} subTowers={data?.subTowers} />
          </TabPanel>
          <TabPanel value={tabValue} index={1} display="flex" flexDirection="column" alignItems="center" px={0} py={0}>
            <MultiTowerRule mappingType={mappingType} enableAssetDistribution={enableAssetDistribution} yearFilter={yearFilter} monthFilter={monthFilter} costElementsRemaining={data?.costElements} selectedRows={data?.costElements} isSubmitting={isSubmitting} submitRules={submitRules} subTowers={data?.subTowers} />
          </TabPanel>
        </MDBox>
        </>
      }
    </MDBox>
  )
}

const FetchData = (props) => {
  const { yearFilter, monthFilter, mappingType, typeFilter, setRefresh, chargeBack } = props;
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
  const [ loading, setLoading ] = useState(false)
  const [data, setData] = useState(null)
  const [ rows, setRows ] = useState([])
  const [ reload, setReload ] = useState(false)
  const [ _err, setErrors ] = useState(false)
  const [tablesCols, setTablesCols] = useImmer({});
  const [ condition, setCondition ] = useState(null)
  const [ tableVals, setTableVals ] = useState(null)

  useEffect(async () => {
    let [error, response] = await fetchRequest.get(`/api/dataflow/towerStratergy/${mappingType}`)
    if (error) {
      if (error.data?.message) {
        setErrors(error.data?.message)
      } else {
        setErrors("An error occured while processing your request.");
      }
      setLoading(false)
    }
    else if (response) {
      const tables = new Set(response?.map( c => c.tableName))
      setTableVals(tables)
      setCondition(response)
      setLoading(true)
      let [error, data] = typeFilter === "Spend" ? await fetchRequest.post(`/api/dataflow/towerRules3/${yearFilter}/${monthFilter}`, response) : await fetchRequest.get(`api/dataflow/towerRulesBudget/${yearFilter}/${monthFilter}?type=${typeFilter.toLowerCase()}`)
      if (error) {
        if (error.data?.message) {
          setErrors(error.data?.message)
        } else {
          setErrors("An error occured while processing your request.");
        }
        setLoading(false)
      }
      else if (data) {
        let newData
        newData = data.costElements.map(item => {
          let obj = {}
          Object.keys(item).map( key => {
            let modKey = key.replace(/\./g, "__")
            obj[modKey] = item[key]
          })
          return obj
        });
        setRows(newData)
        setData(data)
        setLoading(false)
      } 
    }
  },[reload, mappingType])

  useEffect(() => {
    if (tableVals && typeFilter === 'Spend') {
      tableVals.forEach(async tableVal => {
        if (tableVal && tableVal !== "" && !tablesCols[tableVal] ) {
          let url = `/api/dataflow/resource/${tableVal}`
          let [error, response] = await fetchRequest.get(url)
          if (error) {
            console.error(error)
          } else {
            setTablesCols( draft => {
              if (!draft[tableVal]) {
                draft[tableVal] = response.fields
              }
            })
          }
        }
      }) 
    }
  },[tableVals])

  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }

  if (loading === false && data === null) {
    return (
      <div>
        no data
      </div>
    );
  }
  if (_err)
    console.error(_err)
  return (
    <MDBox>
      {
        loading && <YASkeleton variant="loading" />
      }
      {
        !loading && <ShowData {...props} tablesCols={tablesCols} condition={condition} data={data} rows={rows} setReload={setReload} chargeBack={chargeBack} filtersState={filtersState} handleOnFiltersStateUpdate={handleOnFiltersStateUpdate} setRefresh={setRefresh} />
      }
    </MDBox>
  )
}

const ShowData = (props) => {
  const { data, rows, condition, tablesCols, yearFilter, monthFilter, setReload, mappingType, onChangeMappingType, mappingTypes, selectedMappingTypeOption, filtersState, handleOnFiltersStateUpdate, containerHeight, typeFilter, chargeBack } = props;
  const [showOptions, setShowOptions] = useState(false);
  const { showSnackbar } = useYADialog();
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [glRows, setGLRows] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const filterCondition = condition.filter( c => !c['optional'])
  const [clearSelection, setClearSelection] = useState(false);

  const [controller,] = useAppController();
  const { appDef: { featureSet, settings } } = controller;
  const enableAssetDistribution = featureSet && featureSet.dataManagement?.assetDistribution === true;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

  const handleOnOptionsClick = () => {
    setShowOptions(true);
  }

  const handleOnOptionsClose = () => {
    setShowOptions(false);
  }

  const handleOnOptionSelection = (value) => {
    if (onChangeMappingType)
      onChangeMappingType(value);
  }

  const handleOnUpdate = useCallback(({ selected }) => {
    setSelectedRows(selected)
    setClearSelection(false);
  }, [yearFilter, monthFilter])

  const handleOnSelectionClearClick = () => {
    setSelectedRows([])
    setClearSelection(true);
    setRefresh(Math.random())
  }

  const handleChange = (e, newValue) => {
    setTabValue(newValue);
  }

  const handleDialogOpen = () => {
    setDialogOpen(false)
    setReload(Math.random())
  }

  const getDetails = async (e, row) => {
    if (rows.length > 0 && e.target.innerHTML !== "") {
      const obj = row.original;
      row.original.mappingType = mappingType;
      row.original.year = yearFilter;
      row.original.month = monthFilter;
      row.original.mapping = 'tower';
      row.original.condition = filterCondition.map( (con, i) => {
        con['operator'] = "eq"
        con['k'] = i+1
        if (['costPool', 'subCostPool'].includes(con['tableName'])) {
          if (con["value"]) {
            if(!con["value"].includes(obj[`costPoolMappings__${con['tableName']}__${con['field']}`]))
              con["value"].push(obj[`costPoolMappings__${con['tableName']}__${con['field']}`]) 
          } else {
            con["value"] = [obj[`costPoolMappings__${con['tableName']}__${con['field']}`]]
          }
        } else {
          if (con["value"]) {
            if(!con["value"].includes(obj[`${con['tableName']}__${con['field']}`]))
              con["value"].push(obj[`${con['tableName']}__${con['field']}`]) 
          } else {
            con["value"] = [obj[`${con['tableName']}__${con['field']}`]]
          }
        }
        return con
      })

      let [err, data] = await fetchRequest.post(`/api/dataflow/mapping/getGLData/`, JSON.stringify(row.original));
      if (err) {
        console.error('err', err)
        // handleError(err);
      }
      else {
        let newData = data.map(item => {
          return {
            "accountCode": item["account.code"],
            "accountDescription": item["account.description"],
            "expenseType": item["expenseType.name"],
            "costCentreCode": item["costCentre.code"],
            "costCentreDescription": item["costCentre.description"],
            "vendorCode": item["vendor.code"],
            "vendorName": item["vendor.name"],
            "amount": item["amount"],
            "applicationID": item["applicationID"],
            "invoice": item["invoice"],
            "journalID": item["journalID"],
            "journalLine": item["journalLine"],
            "projectID": item["projectID"],
            "transactionDate": item["transactionDate"]
          }
        });
        setGLRows(newData)
        setDialogOpen(true)
      }
    }
  };
  const getPresignedUrl = async (fId) => {
    return await fetchRequest.get(`/api/blob/presignedPost/${fId}`);
  }
  const submitRules = async (ceSelected, fileName, fileData, mappedFields) => {
    setIsSubmitting(true);
    if (fileName && fileName !== "") {
      var uf = {}
      uf.originalFileName = fileName
      uf.mappingFields = JSON.stringify(mappedFields)
      uf.yearNameId = yearFilter
      uf.monthNameId = monthFilter
      uf.totalRecords = fileData.length
      uf.loadedRecords = fileData.length
      uf.destinationTable = "towerConsumption"
      uf.fileStatus = "Loaded"
      uf.source = 'GL'

      const [resError, response] = await fetchRequest.post(`/api/dataflow/createupload`, uf);
      if (resError) {
        console.error("An error occured while creating upload");
        console.error(resError);
        if (resError.data?.message) {
          showSnackbar(resError.data?.message, "error")
          setRefresh(Math.random());
          setReload(Math.random())
        } else {
          showSnackbar("An error occured while processing your request.", "error");
        }
        return false;
      }

      const wb = XLSX.utils.book_new();
      const ws1 = XLSX.utils.json_to_sheet(fileData);
      XLSX.utils.book_append_sheet(wb, ws1);

      var data = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      var newFile = new File([data], fileName);

      const [presignedUrlError, presignedUrlResponse] = await getPresignedUrl(response.id);
      if (presignedUrlError) {
        console.error("An error occured while getting presigned url");
        console.error(presignedUrlError);
        if (presignedUrlError.data?.message) {
          showSnackbar(presignedUrlError.data?.message, "error")
          setRefresh(Math.random());
          setReload(Math.random())
        } else {
          showSnackbar("An error occured while processing your request.", "error");
        }
        return false;
      }

      const options = new URL(presignedUrlResponse).host.indexOf("blob.core.windows.net") !== -1 ? {
        headers: {
          'X-Ms-Blob-Type': 'BlockBlob',
          'X-Ms-Version': '2023-11-03'
        }
      } : null;
      const [putError,] = await fetchRequest.put(presignedUrlResponse, newFile, options);
      if (putError) {
        console.error("An error occured while uploading to blob store");
        console.error(putError);
        showSnackbar("An error occured while processing your request.", "error");
        return false;
      }
      ceSelected.map((a) => a["allocationFileId"] = response.id)
    }
    ceSelected.map((a) => a.destinationTable = convertPlural(a.destinationTable))

    selectedRows.map((i) => {
      const obj = rows[i];
      Object.keys(obj)
      filterCondition.forEach( (con, i) => {
        con['operator'] = "eq"
        con['k'] = i+1
        if (['costPool', 'subCostPool'].includes(con['tableName'])) {
          if (con["value"]) {
            if(!con["value"].includes(obj[`costPoolMappings__${con['tableName']}__${con['field']}`]))
              con["value"].push(obj[`costPoolMappings__${con['tableName']}__${con['field']}`]) 
          } else {
            con["value"] = [obj[`costPoolMappings__${con['tableName']}__${con['field']}`]]
          }
        } else {
          if (con["value"]) {
            if(!con["value"].includes(obj[`${con['tableName']}__${con['field']}`]))
              con["value"].push(obj[`${con['tableName']}__${con['field']}`]) 
          } else {
            con["value"] = [obj[`${con['tableName']}__${con['field']}`]]
          }
        }
      })
    })
    
    ceSelected = ceSelected.reduce((acc, current) => {
      const x = acc.find(item => item.towerId === current.towerId && item.subTowerId === current.subTowerId && item.tier === current.tier);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

  
    let twRule = ceSelected.map((a) => {
      return {
        "id": null,
        "filterCondition": JSON.stringify(filterCondition),
        "towerId": a.towerId,
        "subTowerId": a.subTowerId,
        "portion": a.portion,
        "destinationTable": a.destinationTable,
        "allocationFileId": a.allocationFileId,
        "condition": a.condition,
        "ruleName": a.ruleName,
        "yearNameId": yearFilter,
        "monthNameId": monthFilter,
        "srlNo": a.srlNo,
        "source": 'GL',
        "tier": a.tier

      }
    })


    let [err, data1] = await fetchRequest.post(`/api/dataflow/towerRules/${yearFilter}/${monthFilter}`, JSON.stringify(twRule))
    if (err) {
      console.error(err);
      if (err.data?.message) {
        showSnackbar(err.data?.message, "error")
        setRefresh(Math.random());
        setReload(Math.random())
      } else {
        showSnackbar("An error occured while processing your request.", "error");
      }
    }
    else if (data1) {
      showSnackbar(data1, "success");
      if (props.setRefresh) {

        props.setRefresh(Math.random());
      }
      setReload(Math.random())
    }
    setIsSubmitting(false);
  }

  const amountAllocated = selectedRows?.reduce((total, idx) => total + rows[idx]?.amount, 0);

  let columns = []
  if (typeFilter === 'Spend') {
    if (condition && tablesCols && rows.length > 0) {
      Object.keys(rows[0]).map( key => {
        let splitKey = key.split("__")
        if (splitKey.length > 1) {
          let tableName = splitKey.length > 2 ? splitKey[1] :splitKey[0]
          let field = splitKey.length > 2 ? splitKey[2] : splitKey[1]
          if (field !== 'id') {
            let displayName = tablesCols[tableName]?.find(o => o.schemaName === field).displayName??field
            let cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }
            let column = {
              Header: displayName,
              accessor: key,
              Cell: cell
            }
            columns.push(column) 
          }
        } else if (key !== 'amount') {
          let foundDef = tablesCols['expenditure']?.find(o => o.schemaName === key)
          let displayName = foundDef?.displayName??key
          let type = foundDef?.type
          let cell = null
          if (type === "date") {
            cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> }
          } else {
            cell = ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }
          }
          let column = {
            Header: displayName,
            accessor: key,
            Cell: cell
          }
          columns.push(column)
        }
      })
      columns.push({
        Header: "Amount",
        accessor: 'amount',
        Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> }
      })
    } 
  } else {
    if (mappingType === 2) {
      columns = [
        { Header: "Expense Type", accessor: "expenseType__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account Code", accessor: "account__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account Description", accessor: "account__description", dataType: "string", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Code", accessor: "costCentre__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Name", accessor: "costCentre__description", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor Code", accessor: "vendor__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor Name", accessor: "vendor__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Pool Name", accessor: "costPool__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Sub Cost Pool Name", accessor: "subCostPool__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: typeFilter, accessor: "amount", align: "right", dataType: "currency", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value.toFixed(2)).format("$0,0")}</MDTypography> } },
      ]
    }
  }

  const columnsMemo = useMemo(() => columns, [yearFilter, monthFilter]);
  const rowsMemo = useMemo(() => rows, [yearFilter, monthFilter, refresh]);

  const filteredMappingTypes = mappingTypes.filter(m => m.value !== mappingType);

  const strategyItemStyles = () => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pl: 2,
    pr: 1,
    py: 1.5,
    zIndex: 2,
    marginBottom: "0px",
    marginRight: "-10px",
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #ddd",
    "& .selectionBox": {
      display: "flex",
      flexDirection: "column",
    },
    "& .title": {
      marginBottom: 1
    }
  });

  let glColumns = [
    { Header: "Account Code", accessor: "accountCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Account Description", accessor: "accountDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Expense Type", accessor: "expenseType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Center Code", accessor: "costCentreCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Center Name", accessor: "costCentreDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Vendor Code", accessor: "vendorCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Vendor Name", accessor: "vendorName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "RGT Model", accessor: "rgtModel", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Type", accessor: "costType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> } },
    { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Journal ID", accessor: "journalID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Journal Line", accessor: "journalLine", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Transaction Date", accessor: "transactionDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> } },
    { Header: "Invoice Number", accessor: "invoice", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
  ]

  if (rows.length > 0) {
    return (
      <>
        <Modal open={dialogOpen} onClose={handleDialogOpen}>
          <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
            <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
              <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDBox>
                  <MDTypography variant="h6" component="span" color="text">
                    General Ledger Transactions
                  </MDTypography>
                </MDBox>
                <MDBox display="flex">
                  <IconButton onClick={handleDialogOpen} title="Close">
                    <Icon>close</Icon>
                  </IconButton>
                </MDBox>
              </MDBox>
              <DataTable
                variant="tile"
                table={{ columns: glColumns, rows: glRows }}
                containerMaxHeight={424}
                showTotalEntries={true}
                isSorted={true}
                newStyle1={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
              >
              </DataTable>
            </Card>
          </MDBox>
        </Modal>
        <MDBox display="flex" flexDirection="row">
          <MDBox width={selectedRows?.length > 0 ? "50%" : "100%"} borderRight="1px solid rgba(0, 0, 0, 0.05)">
            {typeFilter !== "Budget" && typeFilter !== "Forecast" &&
              <MDBox pl={3} pr={4} pt={1} display="flex" alignItems="center" justifyContent="space-between">
                <MDTypography variant="subtitle1" fontWeight="medium" color="dark" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">Select one or more cost centers to create a rule.</MDTypography>
                {
                  selectedRows?.length === 0 &&
                  <MDBox
                    sx={(theme) => strategyItemStyles(theme)}
                    onClick={handleOnOptionsClick}
                  >
                    <MDBox className="selectionBox">
                      <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
                      <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{selectedMappingTypeOption?.displayName}</MDTypography>
                    </MDBox>
                    <Icon sx={{ ml: 1.5, mt: .5, fontSize: "32px!important", color: "rgba(0, 0, 0, 0.54)" }}>keyboard_arrow_down</Icon>
                  </MDBox>
                }
              </MDBox>
            }
            <DataTable
              variant="tile"
              table={{ columns: columnsMemo, rows: rowsMemo }}
              showTotalEntries={true}
              containerMaxHeight={containerHeight}
              isSorted={true}
              newStyle1={true}
              noEndBorder
              entriesPerPage={true}
              canSearch={true}
              isSelectable={typeFilter === "Spend" && !chargeBack ? true : false}
              onUpdate={handleOnUpdate}
              onRowClick={(typeFilter !== "Budget" || typeFilter !== "Forecast") && getDetails}
              onSelectionClearClick={handleOnSelectionClearClick}
              filtersState={filtersState}
              onFiltersStateUpdate={handleOnFiltersStateUpdate}
              canFilter={true}
              clearSelection={clearSelection}
            >
            </DataTable>
          </MDBox>
          {
            selectedRows?.length > 0 && (
              <MDBox width="50%" pb={2} display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
                <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <MDBox flex={1} textAlign="center" display="flex">
                    <MDBox display="flex" flexDirection="column" flex={1} mt="auto">
                      <MDTypography variant="button" component="span" fontWeight="medium" color="text">Allocating</MDTypography>
                      <MDTypography variant="h3" component="span" fontWeight="medium" color="dark">{numeral(amountAllocated.toFixed(2)).format('$0,0')}</MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <Icon sx={{ mt: 1, mb: 1, color: "#7b809a", fontSize: "28px!important" }}>south</Icon>
                  </MDBox>
                </MDBox>
                <MDBox
                  height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
                  <Tabs width="100%" value={tabValue} onChange={handleChange} textColor="inherit">
                    <Tab disableRipple label="Tower" />
                    <Tab disableRipple label="Multiple Towers" />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <SingleTowerRule enableAssetDistribution={enableAssetDistribution} yearFilter={yearFilter} monthFilter={monthFilter} costElementsRemaining={rows} selectedRows={selectedRows} isSubmitting={isSubmitting} submitRules={submitRules} subTowers={data.subTowers} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1} display="flex" flexDirection="column" alignItems="center" px={0} py={0}>
                    <MultiTowerRule enableAssetDistribution={enableAssetDistribution} yearFilter={yearFilter} monthFilter={monthFilter} costElementsRemaining={rows} selectedRows={selectedRows} isSubmitting={isSubmitting} submitRules={submitRules} subTowers={data.subTowers} />
                  </TabPanel>
                </MDBox>
              </MDBox>
            )
          }
          {showOptions && (
            <StrategySelectionSidebar
              options={filteredMappingTypes}
              onOptionSelection={handleOnOptionSelection}
              onOptionsClose={handleOnOptionsClose}
            />
          )}
        </MDBox>
      </>
    )
  } else if (rows.length === 0 && data.noOfItems > 0) {
    return (<MDBox height="100%" display="flex" alignItems="center" justifyContent="center">
      <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br /><br />All cost centers have been mapped successfully.</MDTypography>
    </MDBox>
    );
  } else if (data.noOfItems === 0) {
    return (<MDBox height="100%" display="flex" alignItems="center" justifyContent="center">
      <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br /><br />No Cost Pools to Map.</MDTypography>
    </MDBox>
    );
  }
}

export default AddTowerRule;