import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import PageHeader from "components/PageHeader1";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useParams } from "react-router-dom";
import AnimatedRoute from "components/AnimatedRoute";
import AlertHeader from "./components/AlertHeader";
import AlertNotificationDetails from "./components/AlertNotificationDetails";
import { parseJsonString } from "utils";
import { applyVariablesToObject } from "utils";
import { monthsArray } from "utils/budget";
import { financialYearStartEndMonthRanges } from "utils/dashboard";
import { yearFinancialNameFormats } from "utils/dashboard";
import { formatAlertMetricValue } from "utils";
import { CubeContext, useCubeQuery } from "@cubejs-client/react";
import { applyTemplateVariables } from "utils";
import { evaluate } from "mathjs";
import MDTypography from "components/MDTypography";
import AlertRunHistory from "./components/AlertRunHistory";
import { useYADialog } from "components/YADialog";
import MDInput from "components/MDInput";
import { Autocomplete, Icon, Stack } from "@mui/material";
import { useImmer } from "use-immer";
import numeral from "numeral";
import ErrorBox from "components/ErrorBox";
import ErrorBoundary from "components/ErrorBoundary";
import ChartRenderer from "components/VisualizationRenderer/components/ChartRenderer";
import DataTable from "components/DataTable";

const getCurrentFinancialYear = (val, startMonth = "Jan") => {
    const startEndMonthRange = financialYearStartEndMonthRanges[startMonth];
    return new Date().getMonth() >= Number(startEndMonthRange[0]) ? Number(val) : Number(val) - 1;
}

const getCurrentFinancialYearName = (val, startMonth, financialYearNameFormat = "fullYear") => {
    if (!val)
        return '';
    return yearFinancialNameFormats[financialYearNameFormat](getCurrentFinancialYear(val, startMonth));
}

const getPrevFinancialYearName = (val, startMonth, financialYearNameFormat = "fullYear") => {
    if (!val)
        return '';
    return yearFinancialNameFormats[financialYearNameFormat](getCurrentFinancialYear(val, startMonth) - 1);
}

const getDefaultVariables = (headerDetails) => {
    return ({
        currentYear: getCurrentFinancialYearName(new Date().getFullYear(), headerDetails.financialYearStartMonth || 'Jan', headerDetails.financialYearNameFormat),
        previousYear: getPrevFinancialYearName(new Date().getFullYear(), headerDetails.financialYearStartMonth || 'Jan', headerDetails.financialYearNameFormat),
        currentMonth: monthsArray[new Date().getMonth()],
        previousMonth: monthsArray[new Date().getMonth() - 1]
    });
};

// const applyVariablesToLabel = ({ labelTemplate, metricType, triggerConditionType, thresholdValue, thresholdValueSet }) => {
//     console.log('applyVariablesToLabel thresholdValueSet', thresholdValueSet)
//     if (labelTemplate)
//         return applyTemplateVariables(labelTemplate, { metricType, triggerConditionType, thresholdValue, thresholdValueSet })

//     return `Threshold: ${formatAlertMetricValue(thresholdValue, metricType)}`
// };

const getReferenceLines = ({ metricType, triggerConditionType, thresholdPosition, thresholdValue, thresholdValue2, thresholdValueSet, thresholdValue2Set }) => {

    if (triggerConditionType === "THRESHOLD") {
        if (["WITHIN_THRESHOLD", "OUTOF_THRESHOLD"].includes(thresholdPosition)) {
            if (!isNaN(thresholdValue) && !isNaN(thresholdValue2))
                return [
                    {
                        refLineValue: Number(thresholdValue2),
                        refLineLabel: `Upper Threshold: ${formatAlertMetricValue(thresholdValue2Set, metricType)}`,
                        // refLineLabel: applyVariablesToLabel({ metricType, triggerConditionType, thresholdValue: thresholdValue2, thresholdValueSet: thresholdValue2Set || "", labelTemplate: thresholdValue2LabelTemplate }),
                    },
                    {
                        refLineValue: Number(thresholdValue),
                        refLineLabel: `Lower Threshold: ${formatAlertMetricValue(thresholdValueSet, metricType)}`,
                        // refLineLabel: applyVariablesToLabel({ metricType, triggerConditionType, thresholdValue, thresholdValueSet: thresholdValueSet || "", labelTemplate: thresholdValueLabelTemplate }),
                    }
                ];
        }
        else {
            if (!isNaN(thresholdValue))
                return [
                    {
                        refLineValue: Number(thresholdValue),
                        refLineLabel: `Threshold: ${formatAlertMetricValue(thresholdValueSet, metricType)}`,
                        // refLineLabel: applyVariablesToLabel({ metricType, triggerConditionType, thresholdValue, thresholdValueSet: thresholdValueSet || "", labelTemplate: thresholdValueLabelTemplate }),
                    }
                ];
        }
    }

    return [];
};

const parseViewConfig = (headerDetails) => {
    let parsedViewConfig = parseJsonString(headerDetails["metric.config"]);
    let parsedAlertFilterConfig = parseJsonString(headerDetails["filter"]);
    let defaultVariables = getDefaultVariables(headerDetails);
    if (parsedViewConfig) {
        parsedViewConfig.display = applyVariablesToObject(parsedViewConfig.display, defaultVariables);
        if (!parsedViewConfig.threshold) {
            if (parsedViewConfig?.display?.vizOptions) {
                parsedViewConfig.display.vizOptions["config"] = {
                    ...(parsedViewConfig.display.vizOptions.config || {}),
                    refLinesConfig: getReferenceLines({
                        dynamic: false,
                        metricType: headerDetails["metric.type"],
                        triggerConditionType: headerDetails.triggerConditionType,
                        thresholdPosition: headerDetails.thresholdPosition,
                        thresholdValue: headerDetails.thresholdValue,
                        thresholdValue2: headerDetails.thresholdValue2,
                        thresholdValueSet: headerDetails.thresholdValue,
                        thresholdValue2Set: headerDetails.thresholdValue2,
                    }),
                    refLine_enable: parsedViewConfig.display.vizOptions.config?.refLine_enable || true
                }
            }
        }

        parsedViewConfig.defaultVariables = defaultVariables;
    }

    if ((parsedAlertFilterConfig || []).length > 0) {
        const availableFiltersNames = parsedViewConfig.availableFilters?.map(f => f.columnName) || [];
        const filtersToApply = parsedAlertFilterConfig
            .filter(sf => availableFiltersNames.includes(sf.column))
            .map(sf => {
                const isSelected = parsedViewConfig.availableFilters?.find(f => sf.column === f.columnName);
                return ({ member: isSelected.queryName, operator: "equals", values: sf.values });
            });

        if (parsedViewConfig?.display?.vizState?.query)
            parsedViewConfig.display.vizState.query.filters = (parsedViewConfig.display.vizState.query.filters || []).concat(filtersToApply || []);
        if (parsedViewConfig?.display?.table?.vizState?.query)
            parsedViewConfig.display.table.vizState.query.filters = (parsedViewConfig.display.table.vizState.query.filters || []).concat(filtersToApply || []);
    }

    return parsedViewConfig;
};

const normalizeObjKeys = (obj) => {
    let rObj = {};
    Object.keys(obj).forEach((k) => {
        rObj[k.replace(/\./g, '__')] = obj[k];
    });
    return rObj;
}

const getThresholdReturnValue = (thresholdConfig, thresholdResultset) => {
    const { vizOptions } = thresholdConfig
    let thresholdObj = thresholdResultset[0];
    thresholdObj = normalizeObjKeys(thresholdObj);
    const returnValueExpressionStr = applyTemplateVariables(vizOptions.returnValueExpression, thresholdObj);
    return evaluate(returnValueExpressionStr)
};

const setCustomThreshold = (parsedViewConfig, headerDetails, thresholdResultset) => {
    if (thresholdResultset?.length > 0) {
        const thresholdConfig = parsedViewConfig.threshold;
        const thresholdValueObj = getThresholdReturnValue(thresholdConfig, thresholdResultset);
        if (parsedViewConfig?.display?.vizOptions) {
            parsedViewConfig.display.vizOptions["config"] = {
                ...(parsedViewConfig.display.vizOptions.config || {}),
                refLinesConfig: getReferenceLines({
                    dynamic: true,
                    metricType: headerDetails["metric.type"],
                    triggerConditionType: headerDetails.triggerConditionType,
                    thresholdPosition: headerDetails.thresholdPosition,
                    thresholdValueSet: headerDetails.thresholdValue,
                    thresholdValue2Set: headerDetails.thresholdValue2,
                    thresholdValue: thresholdValueObj?.thresholdValue,
                    thresholdValue2: thresholdValueObj?.thresholdValue2,
                    thresholdValueLabelTemplate: parsedViewConfig.display.vizOptions.config?.thresholdValueLabelTemplate,
                    thresholdValue2LabelTemplate: parsedViewConfig.display.vizOptions.config?.thresholdValue2LabelTemplate,
                }),
                refLine_enable: parsedViewConfig.display.vizOptions.config?.refLine_enable || true
            }
        }
    }
    return parsedViewConfig;
};

const loadThresholdQuery = async (cubeApi, query, variables) => {
    const newQuery = applyVariablesToObject(query, variables || {});
    try {
        const response = await cubeApi.load(newQuery);
        return {
            resultSet: response,
            error: null
        };
    } catch (error) {
        return {
            resultSet: null,
            error
        };
    }
}

const timeframeOptions = [
    { value: "from 7 months ago to now", label: "Last 6 months" },
    { value: "from 10 months ago to now", label: "Last 9 months" },
    { value: "from 13 months ago to now", label: "Last 12 months" },
    { value: "from 25 months ago to now", label: "Last 24 months" },
    // { value: "from 49 months ago to now", label: "Last 48 months" },
    { value: "this year", label: "Current Year" },
    { value: "last year", label: "Last Year" },
];

const buildRows = (data) => {
    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, "__")] = r[k]
            });
            rows.push(row);
        });
    }
    return rows;
}

const tableStyles = () => ({
    mt: 2,
    border: "1px solid #ddd",
    borderRadius: "4px",
    overflow: "hidden",
    "& .MuiTableBody-root": {
        borderTop: "1px solid rgba(0, 0, 0, 0.05)!important"
    },
    "& .MuiTableCell-body:first-of-type": {
        borderLeft: "1px solid rgba(0, 0, 0, 0.05)!important"
    },
    "& th, & td": {
        padding: "6px",
    },
    "& th .MuiTypography-root, & td .MuiTypography-root": {
        fontSize: 12,
    },
});

const MetricTrendDetails = ({ vizState, vizOptions }) => {
    const { query, chartType } = vizState;
    const { isLoading, resultSet, error } = useCubeQuery(query);
    if (error) {
        return <ErrorBox error={error} />
    }
    if (isLoading)
        return <MDBox pt={20} display="flex" alignItems="center" justifyContent="center">
            <YASkeleton variant="chart" />
        </MDBox>

    if (resultSet) {
        const columns = vizOptions?.columns?.map((c, ci) => {
            const currenyColumn = ["number", "currency"].includes(c.dataType);
            return ({
                Header: c.title,
                accessor: c.name?.replace(/\./g, "__"),
                align: currenyColumn ? "right" : "left",
                disableSorting: true,
                Cell: ({ cell: { value } }) => {
                    if (["currency"].includes(c.dataType))
                        return <MDTypography key={`${c.name}_${ci}`} variant="button" color="dark">{numeral(value).format('$0,0')}</MDTypography>
                    return <MDTypography key={`${c.name}_${ci}`} variant="button" color="dark">{value || "(empty)"}</MDTypography>
                },
            })
        }) || [];
        const rows = buildRows(resultSet.tablePivot());

        return <>
            <MDBox height={450} mt={1}>
                <ChartRenderer
                    chartType={chartType}
                    vizOptions={vizOptions}
                    resultSet={resultSet}
                />
            </MDBox>
            {
                rows?.length > 0 && <MDBox sx={theme => tableStyles(theme)}>
                    <DataTable
                        variant="tile"
                        table={{ columns, rows }}
                        pageSizeVal={10}
                        entriesPerPage={false}
                    />
                </MDBox>
            }
        </>
    }
    return null;
};

const AlertDetails = () => {

    const { alertId } = useParams();
    const handleError = useHandleError();
    const [timeframeValue, setTimeframeValue] = useState("from 7 months ago to now");
    const [headerDetails, setHeaderDetails] = useState(null);
    const [step, setStep] = useState("LOADING");
    const [viewConfig, setViewConfig] = useImmer(null);
    const [refresh,] = useState(null);
    const { cubeApi } = useContext(CubeContext);
    const { showCustomForm } = useYADialog();

    useEffect(() => {
        async function getDetails() {
            setStep("LOADING");
            var [err, data] = await fetchRequest.get(`/api/alert/${alertId}`);
            if (err) {
                handleError(err);
            }
            else {
                if (data) {
                    let parsedConfig = parseViewConfig(data);
                    if (parsedConfig?.threshold?.query) {
                        const { resultSet, err } = await loadThresholdQuery(cubeApi, parsedConfig.threshold.query, parsedConfig.defaultVariables);
                        if (err) {
                            console.error(err);
                        }
                        else {
                            parsedConfig = setCustomThreshold(parsedConfig, data, resultSet?.tablePivot());
                        }
                    }
                    setViewConfig(parsedConfig);
                    setHeaderDetails(data);
                }
            }
            setStep("LOADED");
        }
        getDetails();
    }, [refresh])

    useEffect(() => {
        if (viewConfig)
            setViewConfig(draft => {
                let displayConfig = draft.display;
                if (displayConfig?.vizState?.query?.timeDimensions) {
                    displayConfig.vizState.query.timeDimensions[0]["dateRange"] = timeframeValue;
                }
            })
    }, [timeframeValue]);

    const handleOnOpenAlertRunHistoryClick = useCallback(
        () => {
            showCustomForm("Alert Run History", () => <AlertRunHistory alertId={alertId} />);
        },
        [alertId]
    );


    if (step === "LOADING") {
        return <YASkeleton variant="dashboard-loading" />;
    }

    const displayConfig = viewConfig?.display;

    const appliedFilters = parseJsonString(headerDetails.filter);
    let appliedFiltersWithConfig = [];

    if ((appliedFilters || []).length > 0) {
        const availableFiltersNames = viewConfig.availableFilters?.map(f => f.columnName) || [];
        appliedFiltersWithConfig = appliedFilters
            .filter(sf => availableFiltersNames.includes(sf.column))
            .map(sf => {
                const selected = viewConfig.availableFilters?.find(f => sf.column === f.columnName);
                let valueDescription = "";
                if (sf.values?.length > 1) {
                    valueDescription = sf.values.slice(0, sf.values.length - 1).join(", ") + " or " + sf.values[sf.values.length - 1]
                }
                else if (sf.values?.length === 1) {
                    valueDescription = sf.values[0];
                }
                return ({ name: selected.name, desc: valueDescription });
            });
    }

    const getSecondaryActions = () => {
        return [
            { label: "Show run history", onClick: handleOnOpenAlertRunHistoryClick },
        ];
    }

    return (<>
        <PageHeader title={headerDetails?.name} subtitle={headerDetails?.desc} secondaryActions={getSecondaryActions} />
        <MDBox px={3} pb={6} pt={1} display="flex" gap={4}>
            <MDBox pt={1} flex={1}>
                <AlertHeader headerDetails={headerDetails} />
                {
                    displayConfig && (
                        <MDBox mt={2}>
                            <MDBox mt={4} mb={2} px={1} display="flex" justifyContent="space-between" alignItems="center">
                                <MDTypography fontWeight="medium" variant="button">{`${headerDetails["metric.name"]} Trend`}</MDTypography>
                                <Stack direction={"row"} spacing={4} alignItems={"center"}>
                                    <Autocomplete
                                        disableClearable={true}
                                        value={timeframeValue}
                                        options={timeframeOptions}
                                        onChange={(event, newValue) => {
                                            setTimeframeValue(newValue.value);
                                        }}
                                        color="text"
                                        fontWeight="medium"
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                minWidth: 150,
                                                padding: "2px 39px 2px 10px"
                                            },
                                            "& .MuiOutlinedInput-input": {
                                                fontSize: 12
                                            },
                                            "& .MuiOutlinedInput-input.MuiAutocomplete-input": {
                                                padding: .5
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.value === value
                                        }}
                                        getOptionLabel={option => {
                                            if (typeof option === "string")
                                                return timeframeOptions.find(op => op.value === option)?.label;
                                            return option.label
                                        }}
                                        renderInput={(params) => <MDInput {...params} />}
                                    />
                                </Stack>
                            </MDBox>
                            {
                                appliedFiltersWithConfig?.length > 0 && <MDBox px={1} mb={2} width="100%" display="flex" flexWrap="wrap" gap={1}>
                                    <Icon>filter_alt</Icon>
                                    {
                                        appliedFiltersWithConfig?.map(f => {
                                            return <MDTypography key={f.name} component="div" py={.5} px={1} borderRadius="8px" backgroundColor="#efefef" display="inline" variant="caption">{`${f.name}`} <b>eq</b> {`${f.desc}`}</MDTypography>
                                        })
                                    }
                                </MDBox>
                            }
                            {
                                displayConfig.vizState && displayConfig.vizOptions && <ErrorBoundary>
                                    <MetricTrendDetails vizState={displayConfig.vizState} vizOptions={displayConfig.vizOptions} />
                                </ErrorBoundary>
                            }
                        </MDBox>
                    )
                }
            </MDBox>
            <MDBox p={3} flexBasis="400px" height="100%" border="1px solid #ddd" borderRadius="8px">
                <AlertNotificationDetails headerDetails={headerDetails} />
            </MDBox>
        </MDBox>
    </>
    );
};

export default AnimatedRoute(AlertDetails);