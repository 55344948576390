import Fade from '@mui/material/Fade';
// import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';
import pxToRem from 'assets/theme/functions/pxToRem';

// const { text, white } = colors;
const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;

const tooltip = {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(400),
      // backgroundColor: white.main,
      // color: text.main,
      fontSize: size.sm,
      fontWeight: fontWeightRegular,
      // textAlign: "center",
      borderRadius: borderRadius.md,
      opacity: 0.7,
      padding: `${pxToRem(8)} ${pxToRem(16)}`,
      // border: '1px solid #dadde9',
    },

    arrow: {
      // color: white.main,
      // border: '1px solid #dadde9',
    },
  },
};

export default tooltip;
