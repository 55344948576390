import MDBox from 'components/MDBox';
import _ from 'lodash';
import DataTable from 'components/DataTable';
import MDTypography from 'components/MDTypography';
import { Icon, Card, Menu, MenuItem, Tooltip, CircularProgress, Modal, IconButton, Alert } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import fetchRequest from 'utils/fetchRequest';
import MDButton from 'components/MDButton';
import { useYADialog } from 'components/YADialog';
import PageHeader from 'components/PageHeader';
import AnimatedRoute from 'components/AnimatedRoute';
import YASkeleton from 'components/YASkeleton';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';
import numeral from 'numeral';
import { useAppController } from 'context';
import { useImmer } from 'use-immer';
import FilterChip from 'components/FilterChip';
import * as XLSX from 'xlsx';
import { formatNum,showFullDecimal } from 'utils';
import { normalizeCurrency } from 'utils/table';
import CapsuleText from 'components/CapsuleText';

const FilterDropdown = (props) => {
  const { formId, filter, onFilterChange } = props;
  const { name, displayName, dataSource, values } = filter;
  const [options, setOptions] = useState(dataSource?.type === 'static' ? dataSource.data : []);
  const [loading, setLoading] = useState(dataSource?.type !== 'static');

  useEffect(() => {
    async function getOptions() {
      setLoading(true);
      const [error, data] = await fetchRequest.get(`/api/assetRules/${formId}/${name}`);
      if (error)
        console.error(error)
      setOptions(data || []);
      setLoading(false);
    }
    if (dataSource?.type !== 'static') getOptions();
  }, [name]);

  const handleOnFilterChange = (selectedFilter) => {
    onFilterChange({
      name: selectedFilter.name,
      type: selectedFilter.type,
      operator: selectedFilter.operator,
      values: selectedFilter.values.map(v => options.find(o => o.label === v)?.value)
    });
  }

  const sOptions = useMemo(() => options?.map(o => o.label), [options]);
  const filterValue = { name, operator: "eq", values: values?.map(v => options?.find(o => o.value === v)?.label) };
  return (
    <FilterChip loading={loading} dismissible={false} openOnMount={false} key={name} dataType={"select"} name={name} field={displayName} filterValue={filterValue} options={sOptions} onFilterSave={handleOnFilterChange} />
  );
};
let linkFieldAccessor
let linkFieldColumns = []

const buildColumns = (masterDef, defaultDateFormat) => {
  linkFieldAccessor = []
  const columns = [];
  if (Array.isArray(masterDef.fields) && masterDef.fields.length > 0) {
    masterDef.fields?.filter(f => !f.hidden)?.forEach((f) => {
      let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
      let accessor = f.schemaName;
      if (f.type === 'dropdown' && !f.dataSource.consumptionAsset) {
        if (f.dataSource.type === "static") {
          accessor = `${f.schemaName}__displayName`
        } else {
          accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
        }
      }
      if (f.type === "rule") {
        accessor = "ruleStr"
      }
      if (f.type === "toAssetrule") {
        accessor = "toAssetRuleStr"
      }
      col['Header'] = f.displayName;
      col['accessor'] = accessor;
      col['Cell'] = ({ cell: { value } }) => {
        if (f.type === "currency")
          return <Tooltip title={showFullDecimal(value)}><MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}><strong>{formatNum(2).format(value)}</strong></MDTypography></Tooltip>
        else if (f.type === "datepicker")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
        else if (f.type === 'rule')
          return (value || "") !== "" ? <Tooltip placement="top" title={value}><Icon fontSize="medium" color="text">info</Icon></Tooltip> : null
        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? value : "Unknown"}</MDTypography>
      };
      col['dataType'] = f.filterType || f.type
      col['disableFilters'] = f.disableFilters || false
      f.dataSource && f.dataSource.linkField != undefined ? linkFieldColumns.push(col) : columns.push(col);
      if (f.dataSource) {
        let columcreat = (f) => {
          let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
          let accessor = f.schemaName;
          if (f.type === 'dropdown') {
            accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
          }
          col['Header'] = f.displayName;
          col['accessor'] = accessor;
          col['Cell'] = ({ cell: { value } }) => {
            if (f.type === "currency")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
            else if (f.type === "datepicker")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
            return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? value : "Unknown"}</MDTypography>
          };
          col['dataType'] = f.filterType || f.type
          col['disableFilters'] = f.disableFilters || false
          linkFieldColumns.push(col);
          linkFieldAccessor.push(accessor)
          if (f.dataSource) {
            linkFieldCheck(f)
          }
        }
        let linkFieldCheck = (f) => {
          if (f.dataSource.linkField) {
            columcreat(f.dataSource.linkField)
          }
        }
        f.dataSource.linkField ? linkFieldAccessor.push(accessor) : linkFieldAccessor
        linkFieldCheck(f)
        if (linkFieldColumns.length > 0) {
          linkFieldColumns = linkFieldColumns.reverse()
          linkFieldColumns.forEach(item => { columns.push(item) })
          linkFieldColumns = []
        }
      }
    });
  }
  if (!masterDef.readonly)
    columns.push({
      Header: '',
      accessor: 'actions',
      align: 'left',
      disableSorting: true,
      disableFilters: true,
    });
  return columns;
};

const buildRows = (pkColumn, data) => {
  const rows = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach((r) => {
      let row = {};
      Object.keys(r).forEach((k) => {
        row[k.replace(/\./g, '__')] = r[k];
      });
      if (linkFieldAccessor.length > 0) {
        let nameSet = row[linkFieldAccessor[0]].split(" | ").reverse()
        for (let i = 0; i < linkFieldAccessor.length; i++) {
          row[linkFieldAccessor[i]] = nameSet[i]
        }
      }
      rows.push(row);
    });
  }
  return rows;
};

const filtersInitiaized = (filters) => {
  let initiaized = false;
  filters?.forEach(f => {
    if (f.values && Array.isArray(f.values) && f.values?.length > 0) {
      initiaized = true;
      return;
    }
  });
  return initiaized;
}

const LoadSpendPopup = (props) => {
  const { handlePopupClose, handlePublishButtonClick, selectedField, selectedFieldName, yearFilter, monthFilter, isPublishing } = props
  const [fetchingCount, setFetchingCount] = useState(false);
  const [fetchingCountError, setFetchingCountError] = useState(false);
  const [listItemsCount, setListItemsCount] = useState(null);

  const fetchAssetCount = async (fieldName) => {
    setFetchingCount(true);
    setFetchingCountError(false);
    let [_err1, data1] = await fetchRequest.post(`/api/cloudSpend/listItemsCount/${fieldName}/${yearFilter}/${monthFilter}`);
    setListItemsCount(data1);
    if (_err1) {
      console.error(_err1);
      setFetchingCountError(true);
    }
    setFetchingCount(false);
  }
  useEffect(() => {
    fetchAssetCount(selectedField)
  },[]);
  return (
    <Modal open={true} onClose={handlePopupClose}>
      <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <Card sx={{ overflow: 'hidden', width: "calc(100vw - 1000px)", minHeight: "calc(100vh - 750px)" }}>
          <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
              <MDTypography variant="h6" component="span" color="text">
                Cloud Summary
              </MDTypography>
            </MDBox>
            {!isPublishing && 
            <MDBox display="flex">
              <IconButton onClick={handlePopupClose} title="Close">
                <Icon>close</Icon>
              </IconButton>
            </MDBox>}
          </MDBox>
          {fetchingCount && (
            <CircularProgress size={30} sx={{ marginTop: 1 }} color="info" alignItems="center" justifyContent="center" />
          )}
          {(!fetchingCount && selectedField && selectedFieldName) &&
            <MDBox display="flex" alignItems="center" justifyContent="center">
              {
                fetchingCountError && (
                  <Alert severity="error"
                    sx={{ marginTop: 1, marginBottom: 1, fontSize: "14px", textAlign: "left" }}
                  >{'Error occured while fetching asset count'}</Alert>
                )
              }
              {
                !fetchingCountError && (
                  <Alert severity={(listItemsCount || 0) === 0 ? "warning" : "info"}
                    sx={{ marginTop: 1, marginBottom: 1, fontSize: "14px", textAlign: "left" }}
                  >
                    <strong>{`${listItemsCount && listItemsCount?.["assetCount"] || 0}`}</strong> {`Asset(s) will be created with spend`} <strong>{formatNum(2).format(listItemsCount?.["spendByFieldName"])}</strong><br />
                    <strong>{`${listItemsCount && listItemsCount?.["nullCount"] || 0}`}</strong> {listItemsCount?.["nullCount"] > 0 ? `null(s) found in ${selectedFieldName} with spend` : `null(s) found in ${selectedFieldName}`} <strong>{listItemsCount?.["nullCount"] > 0 ? formatNum(2).format(listItemsCount?.["totalSpend"] - listItemsCount?.["spendByFieldName"]) : ''}</strong>
                  </Alert>
                )
              }
              <IconButton size="small" sx={{ marginTop: 1 }} onClick={() => fetchAssetCount(selectedField)} title={`Click to refetch ${selectedFieldName} count`}><Icon color="text">refresh</Icon></IconButton>
            </MDBox>
          }
          <MDBox px={2.5} pb={2} pt={1} display="flex" position="flex-end" justifyContent="center" alignItems="center">
            <MDButton
              size="medium"
              color="info"
              disabled={!selectedField || fetchingCountError || listItemsCount?.assetCount === 0 || isPublishing}
              onClick={handlePublishButtonClick}
              startIcon={isPublishing ? <CircularProgress color="white" size={15} /> : undefined}
            >
              Publish Spend
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

const CloudSpendDetails = (props) => {
  const [step, setStep] = useState('LOADING');
  const handleError = useHandleError();
  const { yearFilter, monthFilter, yearFilterName, monthFilterName } = props
  const [masterDef, setMasterDef] = useImmer(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([])
  const [published, setPublished] = useState(false)
  const [ queryTotal, setQueryTotal ] = useState(0)
  const [isPublishing, setIsPublishing] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);
  const [selectedField, setSelectedField] = useState("cloudAssetName");
  const [selectedFieldName, setSelectedFieldName] = useState("Cloud Asset Name");
  const [options, setOptions] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [controller,] = useAppController();
  const { appDef: { settings } } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
  const { showAlert, showSnackbar } = useYADialog();
  let filters = []
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: filters });

  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }

  const handleClose = () => {
    setRefresh(Math.random());
  };

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  };

  const getAppliedFilters = () => {
    if (!masterDef.filters || masterDef.filters.length === 0)
      return null;

    return {
      "filters": JSON.stringify(
        masterDef.filters?.map(f => ({
          name: f.name,
          operator: f.operator,
          value: f.values
        })) || []
      )
    };
  };

  useEffect(() => {
    async function getMasterDef() {
      let [err, data] = await fetchRequest.get(`/api/cloudSpend/cloudDef`);
      let [_err, option] = await fetchRequest.get(`/api/dataflow/resource/cloudConsumption`)
      if (err) {
        handleError(err);
      } else {
        data.yearNameId = yearFilter;
        data.monthNameId = monthFilter;
        setMasterDef(data);
        setColumns(buildColumns(data, defaultDateFormat));
      }
      if (_err) {
        console.error(_err)
      } else {
        setOptions(option.fields.filter(f => f.type === "string"))
      }
    }
    getMasterDef();
  }, []);

  useEffect(() => {
    async function getList() {
      setLoading(true);
      const appliedFilters = getAppliedFilters();
      if (yearFilter && monthFilter) {
        let [err, data] = await fetchRequest.post(`/api/cloudSpend/list/${yearFilter}/${monthFilter}`, appliedFilters);
        let [err1, data1] = await fetchRequest.get(`/api/cloudSpend/isPublished/${yearFilter}/${monthFilter}`);
        if (err || err1) {
          handleError(err);
        } else {
          if (data && Array.isArray(data) && data.length > 0) {
            setPublished(data1[0] && !_.includes([0, null, false], data1[0].publish) ? true : false)
            setList(data)
            setRows(buildRows(masterDef.pkColumn || 'id', data));
            setStep('LOADED');
          } else {
            setRows([]);
            setStep('EMPTY');
          }
        }
        setLoading(false);
      }
    }
    if (masterDef) {
      getList();
    }
  }, [masterDef, yearFilter, monthFilter, refresh]);

  if (step === 'LOADING') {
    return <YASkeleton variant="dashboard-loading" />;
  }

  const { displayName, desc, message, canFilter } = masterDef;

  const handlePublishButtonClick = async () => {
    setIsPublishing(true)
    let [err, response] = await fetchRequest.post(`/api/cloudSpend/publishSpend/${yearFilter}/${monthFilter}?fieldName=${selectedField}`);
    if (err) {
      if (err.data?.errorType && err.data?.message && err.data?.errorType === "masterCheckFail") {
        showAlert("Publish Spend", err.data?.message);
      }
      else
        showAlert("Publish Spend", "Something went wrong. Contact your administrator.");
      setIsPublishing(false)
    } else if (response) {
      setIsPublishing(false)
      handleClose();
      showSnackbar(response.message, "success");
      setRefresh(Math.random())
      handlePopupClose()
    }
  };

  const handleUnublishButtonClick = async () => {
    setIsUnpublishing(true)
    let [err, response] = await fetchRequest.post(`/api/cloudSpend/unPublishSpend/${yearFilter}/${monthFilter}`);
    if (err) {
      showAlert("Unpublish Spend", "Something went wrong. Contact your administrator.");
      setIsUnpublishing(false)
    } else if (response) {
      setIsUnpublishing(false)
      handleClose();
      showSnackbar(response.message, "success");
      setRefresh(Math.random())
    }
  };

  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };

  const handleOnFilterChange = (selectedFilter) => {
    setMasterDef((draft) => {
      let filter = draft.filters?.find(f => f.name === selectedFilter.name);
      filter.operator = selectedFilter.operator;
      filter.values = selectedFilter.values;
    });
  };
  const handleDownload = async () => {
    let newData = list.map(item => {
      return {
        "Provider": item["provider.providerCode"],
        "Account": item["account.description"],
        "Cost Center": item["costCentre.description"],
        "Vendor": item["vendor.name"],
        "Cloud Asset": item["cloudAssetName"],
        "Team": item["cloudTeamId"] ? item["cloudTeamId"] : "Unknown",
        "Spend": item['amount']
      }
    })
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(newData)
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, `CloudSpend_${yearFilterName}-${monthFilterName}.xlsx`)
    await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Cloud Spend rules from ${yearFilterName} - ${monthFilterName}}` })
    handleCloseMenuItem();
  };

  const handlePopupClose = () => {
    setOpenPopup(false)
    setSelectedField("cloudAssetName")
    setRefresh(Math.random());
  }

  const renderPrimaryActions = () => {
    return (
      <>
        <MDBox pt={0} mt={0} display="flex" flexDirection="row">
          <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
              <MenuItem key={'download'} onClick={handleDownload}>{"Download"}</MenuItem>
          </Menu>
          { queryTotal && <CapsuleText leftSideText={"Total Cloud Spend "} rightSideText={numeral(normalizeCurrency(queryTotal)).format('$0,0')}/>}
          {!published &&
            <>
              <MDButton data-testid={"publish"} disabled={ isPublishing ? true : false} sx={{ mr: 1 }} variant="gradient" color="info" startIcon={isPublishing ? <CircularProgress color="white" size={15} /> : undefined} onClick={() => { setOpenPopup(true) }}> Publish </MDButton>
            </>
          }
          {published && <MDButton data-testid={"unpublish"} disabled={isUnpublishing ? true : false} sx={{ mr: 1 }} variant="gradient" color="info" startIcon={isUnpublishing ? <CircularProgress color="white" size={15} /> : undefined} onClick={handleUnublishButtonClick}> Unpublish </MDButton>}
          &nbsp;
          <MDBox mt={0} mr={1} pt={0}>
            <MDButton
              // size="medium"
              disableRipple
              color="dark"
              variant="text"
              onClick={handleOpenMenu}
              sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
              iconOnly
            >
              <Icon px={0} py={0}>more_horiz</Icon>
            </MDButton>
          </MDBox>

        </MDBox>

      </>
    );
  };

  const renderFilters = () => {
    return (
      <>
        {masterDef.filters?.map((f) => (
          <FilterDropdown key={f.name} filter={f} onFilterChange={handleOnFilterChange} />
        ))}
      </>
    )
  }

  const defaultFilteresInitiaized = filtersInitiaized(masterDef?.filters);

  return (
    <>
      <PageHeader
        title={displayName}
        subtitle={desc}
        message={message}
        hideBreadcrumbs={true}
        anchor={displayName}
        settingsHeaderFormat={true}
      />
      {<MDBox p={3} pt={1}>
        {step === 'EMPTY' && (masterDef.filters?.length === 0 || !defaultFilteresInitiaized) && (
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100vh - 300px)"
          >
            <EmptyState
              size="large"
              image={new_item_img}
              title={`No ${displayName} Yet`}
            />
          </MDBox>
        )}
        {(step === 'LOADED' || (step === 'EMPTY' && masterDef.filters?.length > 0) && defaultFilteresInitiaized) &&
          <>
            <Card sx={{ height: '100%' }} px={0}>
              <DataTable
                containerMaxHeight={390}
                table={{ columns, rows }}
                showTotalEntries={true}
                isSorted={true}
                newStyle1={true}
                setQueryTotal={setQueryTotal}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                primaryRender={renderPrimaryActions()}
                filtersComponent={renderFilters()}
                canFilter={canFilter}
                loading={loading}
                canTotal={'amount'}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
              />
            </Card>
          </>
        }
        {openPopup &&
          <LoadSpendPopup
            handlePopupClose={handlePopupClose}
            handlePublishButtonClick={handlePublishButtonClick}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            setSelectedFieldName={setSelectedFieldName}
            selectedFieldName={selectedFieldName}
            options={options}
            yearFilter={yearFilter}
            monthFilter={monthFilter}
            isPublishing={isPublishing}
          />
        }
      </MDBox>}
    </>
  );
};

export default AnimatedRoute(CloudSpendDetails);
