import { Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import _ from "lodash";

// import Breadcrumbs from "components/Breadcrumbs"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography"
import YASkeleton from "components/YASkeleton";
import { openContextHelp } from "context";
import { useAppController } from "context";
import { useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { getPageName } from 'utils';
import { Link } from "react-router-dom";
import colors from "assets/theme/base/colors";

import FilterContainer from "components/FilterContainer";
//, usePageTitleForBreadcrumb, hideBreadcrumbs
const PageHeader = ({ title, subtitle, message, noTopPadding, headerComponent, primaryActionComponent, secondaryActions, loading, dashboardDef, selectFilter, setFilter, deleteFilter, clearFilters, sessionFilters, anchor, headerShadow, keyMatch, routeMatch, previoustitle, rptFilterClear, settingsHeaderFormat }) => {
  const [controller, dispatch] = useAppController();
  const { helpCenterUrl, helpCenterInternal, showinapphelp, helpCenterToken } = controller;
  const { state } = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const secondaryActionsArr = (typeof secondaryActions === "function" ? secondaryActions() : secondaryActions) || [];
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
    // showCustomDrawer('', () => <UserInfoDrawer />);
  };
  let dashboardDrilldownMatches = useMatch({ path: "/dashboard/:dashboardId/report/:reportId" });
  const route = useLocation().pathname.split("/").slice(1);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a.onClick)
      a.onClick();
  };
  let parentDisplayName = "";
  if (dashboardDef?.parentDisplayName)
  parentDisplayName = dashboardDef?.parentDisplayName
  else if (route.length > 0) {
    for (let i = 0; i < route.length; i++) {
      let temp = ""
      i++
      let tempText = route[i] ? route[i].split("-") : ''
      for (let j = 0; j < tempText.length; j++) {
        temp = temp + " " + tempText[j][0].toUpperCase() + tempText[j].slice(1)
      }
      if (i == 1)
        parentDisplayName = temp
      else
       parentDisplayName = parentDisplayName + "\t" + '\t'
    }
  }

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      {
        secondaryActionsArr?.map(a => (
          <MenuItem key={a.label} onClick={() => handleCloseMenuItem(a)} disabled={a.disabled}>{a.label}</MenuItem>
        ))
      }
    </Menu>
  );
  
  // const convPageName2HTML = (page) => {
  //   let pg = []
  //   if (page) {
  //     let pge = page.split('-')
  //     let idx = ""
  //     if ( dashboardDef?.parent) {
  //       if (pge[0] === 'ct') {
  //         idx = '(Cost-Transparency)'
  //       }
  //       if (pge[0] === 'finance') {
  //         idx = '(Finance-Overview)'
  //       }
  //       if (pge[0] === 'vendor') {
  //         idx = '-'
  //       }  
  //       if (pge[0] === 'contract') {
  //         idx = '(Contracted-Spend)'
  //       }       
  //     }
  //     if (idx !== "") {
  //       pge.shift()
  //       idx !== "-" ? pge.push(idx) : ""
  //     }
  //     pge.map(p => { pg.push(p.length <= 3 ? p.toUpperCase() : p.charAt(0).toUpperCase() + p.slice(1)) })
  //     pg = (pg + "").replaceAll(",","-")
  //   }
  //   else
  //     pg = "Home"
  //   return pg + '.html'
  // }

  let pageName = ""
  if (helpCenterInternal) {
    anchor = ''
    pageName = ('Home.html') + (showinapphelp ? showinapphelp === 'true' ? (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : '') : '?t=' + helpCenterToken + (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : '') : '?t=' + helpCenterToken + (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : ''));
    // pageName = (convPageName2HTML(getPageName()) || 'Home.html') + (showinapphelp ? showinapphelp === 'true' ? (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : '') : '?t=' + helpCenterToken + (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : '') : '?t=' + helpCenterToken + (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : ''));
  }
  else
    pageName = (getPageName() || 'home') + (showinapphelp ? showinapphelp === 'true' ? (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : '') : '?t=' + helpCenterToken + (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : '') : '?t=' + helpCenterToken + (anchor ? '#' + anchor.replaceAll(' ', '-').toLowerCase() : ''));
  // console.log(pageName, helpCenterInternal)
  const stateUpdate = () => {
    const dashboardFilters = dashboardDef?.filters.map( filter => filter.queryName)
    let currentState = state?.filter( filter => {
      if (_.includes(dashboardFilters, filter.name))
        return filter
    })
    if(Array.isArray(rptFilterClear) && rptFilterClear.length > 0) {
      currentState = currentState.filter((curFilter)=> !rptFilterClear.some((rptFilter)=> rptFilter['queryName'] === curFilter['name']))
    }
    currentState = currentState && currentState.length > 1 ? currentState?.slice(0, length-1) : [];
    const updatedState = currentState?.filter(item => (item?.operator != 'set'));
    return updatedState
  }
  return (
    <MDBox pt={noTopPadding ? 0 : 2} sx={{backgroundColor: headerShadow ? colors.chartBackground : '', boxShadow: headerShadow ? "0px 2px 8px rgba(48, 53, 109, 0.1)" : ""}} pb={1} px={3}>
      {/* {
        !hideBreadcrumbs && false
        (
          <MDBox color="inherit" pb={.5} >
            <Breadcrumbs loading={loading} icon="home" pageTitle={usePageTitleForBreadcrumb ? title : undefined} title={route[route.length - 1]} parentDispName= {route[route.length - 2]} route={route} light={false} />
          </MDBox>
        )
      } */}
      <MDBox display="flex" justifyContent="space-between">
        {
          !headerComponent && (
            <MDBox display="flex"
              flexDirection={{ lg: "row", md: "column", sm: "column", xs: "column" }}
              // flexDirection="row" 
              flex={1}
              justifyContent="space-between"
            >
              <MDBox display="flex" flexDirection="column" flex={1}>
                {/* <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{loading ? <YASkeleton variant="title" /> : title}</MDTypography> */}
                <MDTypography
                  display="flex"
                  alignItems="center"
                  component="span"
                  fontWeight={"medium"}
                  lineHeight={1}
                // sx={{ "&:hover": { "& .helpIcon" :{ visibility: 'visible' }} }}
                >
                  {loading && <YASkeleton variant="title" />}
                  {!loading &&
                    <>
                      {/* <MDBox display="flex" px={0} mb={1.5} pt={0} flexDirection="row" alignIytems="middle" justifyContent="flex-start" height="27px"> */}
                      {dashboardDrilldownMatches && (
                        <>
                          <Link to={`/dashboard/${dashboardDrilldownMatches.params.dashboardId}`} key={dashboardDrilldownMatches.params.dashboardId} state={stateUpdate()}>
                            <MDTypography
                              display={{ lg: "inherit", md: "none", sm: "none", xs: "none" }}
                              component="span"
                              variant="h3"
                              fontWeight="medium"
                              sx={() => ({
                                "&:hover": {
                                  color: colors.linkColour
                                }
                              })}
                            >
                              {loading ? undefined : parentDisplayName}
                            </MDTypography>
                            <MDTypography variant="h3" sx={{ paddingTop: 2.5, marginRight: 1 }} display={{ lg: "none", md: "inherit", sm: "inherit", xs: "inherit" }}>
                              {loading ? undefined : <ArrowCircleLeftIcon fontSize="large" />}
                            </MDTypography>

                          </Link>
                          <MDTypography variant="h3" display={{ lg: "inherit", md: "none", sm: "none", xs: "none" }}>
                            <ChevronRightIcon variant="caption" sx={{ paddingTop: 0.5 }} fontSize="large" />
                          </MDTypography>
                        </>
                      )}
                      { keyMatch && (
                        <>
                          <Link to={routeMatch} key={keyMatch} state={stateUpdate()}>
                            <MDTypography
                              display={{ lg: "inherit", md: "none", sm: "none", xs: "none" }}
                              component="span"
                              variant="h3"
                              fontWeight="medium"
                              sx={() => ({
                                "&:hover": {
                                  color: colors.linkColour
                                }
                              })}
                            >
                              {loading ? undefined : previoustitle}
                            </MDTypography>
                            <MDTypography variant="h3" sx={{ paddingTop: 2.5, marginRight: 1 }} display={{ lg: "none", md: "inherit", sm: "inherit", xs: "inherit" }}>
                              {loading ? undefined : <ArrowCircleLeftIcon fontSize="large" />}
                            </MDTypography>

                          </Link>
                          <MDTypography variant="h3" display={{ lg: "inherit", md: "none", sm: "none", xs: "none" }}>
                            <ChevronRightIcon variant="caption" sx={{ paddingTop: 0.5 }} fontSize="large" />
                          </MDTypography>
                        </>
                      )}
                      {<><Tooltip
                        display={{ lg: "none", md: "inherit", sm: "inherit", xs: "inherit" }}
                        placement="bottom-end"
                        title={subtitle}>
                        <MDTypography
                          component="span"
                          variant="h3"
                          fontWeight="medium"
                          // sx={{ lineHeight: 1.25,}}
                          sx={{ lineHeight: 1.30, paddingTop: 0.6, "&:hover": { "& .helpIcon": { visibility: 'visible' } } }}
                        >
                          {title}
                          {
                            // (helpCenterUrl || "") !== "" && (pageHelpContextKey || "") !== "" &&
                            (helpCenterUrl || "") !== "" && (pageName || "") !== "" &&
                            <Tooltip
                              placement="right"
                              title="Click to learn more"
                            >
                              <IconButton className="helpIcon"
                                sx={({ palette: { text } }) => ({
                                  marginLeft: .15,
                                  marginBottom: .1,
                                  color: "#979191",
                                  visibility: 'hidden',
                                  "&:hover": {
                                    color: text.main
                                  }
                                })}
                                size="small"
                                onClick={() => {
                                  // showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, pageName) :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp') :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                                  window.open(helpCenterUrl + '/' + pageName, 'yarkenhelp');
                                }}
                              >
                                <Icon>help</Icon>
                              </IconButton>
                            </Tooltip>
                          }
                        </MDTypography>
                      </Tooltip>

                        <MDTypography
                          data-testid = {typeof title === "string" ? title?.toLowerCase().replaceAll(' ', ''): ""}
                          component="span"
                          fontWeight="medium"
                          variant="h3"
                          display={{ lg: "flex", md: "none", sm: "none", xs: "none" }}
                          // sx={{ lineHeight: 1.25,}}
                          sx={{ lineHeight: 1.30, paddingTop: 0.2, "&:hover": { "& .helpIcon": { visibility: 'visible' } } }}
                        >
                        {
                          settingsHeaderFormat ? <>
                            <MDBox flex={1} display="flex" flexDirection="column">
                              <MDTypography data-testid={title?.toLowerCase().replaceAll(' ', '')} variant="button" color="dark" fontWeight="medium">{title}</MDTypography>
                              <MDTypography data-testid={subtitle?.toLowerCase().replaceAll(' ', '')} mb={1} variant="button" color="text">{subtitle}</MDTypography>
                            </MDBox>
                          </> : title
                        }
                          {
                            // (helpCenterUrl || "") !== "" && (pageHelpContextKey || "") !== "" &&
                            (helpCenterUrl || "") !== "" && (pageName || "") !== "" && !settingsHeaderFormat &&
                            <Tooltip
                              placement="right"
                              title="Click to learn more"
                            >
                              <IconButton className="helpIcon"
                                sx={({ palette: { text } }) => ({
                                  marginLeft: .15,
                                  marginBottom: .1,
                                  color: "#979191",
                                  visibility: 'hidden',
                                  "&:hover": {
                                    color: text.main
                                  }
                                })}
                                size="small"
                                onClick={() => {
                                  showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, pageName) : window.open(helpCenterUrl + '/' + pageName, 'yarkenhelp') : window.open(helpCenterUrl + '/' + pageName, 'yarkenhelp');
                                }}
                              >
                                <Icon>help</Icon>
                              </IconButton>
                            </Tooltip>
                          }
                        </MDTypography>
                      </>
                      }
                      {/* </MDBox> */}
                    </>
                  }
                </MDTypography>
                {subtitle && !settingsHeaderFormat && <MDTypography data-testid={subtitle.toLowerCase().replaceAll(' ', '')} display={{ lg: "inherit", md: "none", sm: "none", xs: "none" }} component="span" variant="subtitle1">{subtitle}</MDTypography>}
                {message && <MDTypography display={{ lg: "inherit", md: "none", sm: "none", xs: "none" }} component="span" color='error' variant="button">{message}</MDTypography>}
                {/* {message && <MDButton color='info'>{message}</MDButton>} */}
              </MDBox>
              <MDBox display="flex" flexDirection="row">
                {sessionFilters ?
                  <><MDTypography sx={() => ({
                    marginTop: 1.5,
                    marginRight: -1.5,
                    color: "#454545",
                    fontSize: '14px',
                    // })}>Show data for</MDTypography><FilterContainer dashboardDef={dashboardDef} selectFilter={selectFilter} setFilter={setFilter} deleteFilter={deleteFilter} clearFilters={clearFilters} sessionFilters={true}/> </>
                  })}></MDTypography><FilterContainer dashboardDef={dashboardDef} selectFilter={selectFilter} setFilter={setFilter} deleteFilter={deleteFilter} clearFilters={clearFilters} sessionFilters={true} headerShadow={headerShadow}/> </>
                  : ''}
              </MDBox>
            </MDBox>
          )
        }
        {
          headerComponent && (
            <MDBox sx={{ flex: 1 }}>{headerComponent()}</MDBox>
          )
        }
        {
          primaryActionComponent && (
            <MDBox pt={1} ml={2}>{primaryActionComponent()}</MDBox>
          )
        }
        {
          secondaryActionsArr && secondaryActionsArr.length > 0 && (
            <MDBox pt={1} ml={2}>
              <MDButton
                // size="medium"
                disableRipple
                color="info"
                variant="outlined"
                onClick={handleOpenMenu}
                sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                iconOnly
              >
                <Icon>more_horiz</Icon>
              </MDButton>
            </MDBox>
          )
        }
        {renderMenu()}
      </MDBox>
    </MDBox >
  )
}

PageHeader.defaultProps = {
  loading: false,
  hideBreadcrumbs: false,
  usePageTitleForBreadcrumb: false
};

export default PageHeader
