import { Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//▲▼

const statStyles = ({ horizontalLayout, flexDirection, align, verticalAlign, textColor }) => {
    return ({
        px: horizontalLayout ? 0 : 2,
        py: horizontalLayout ? 0 : 1.5,
        display: "flex",
        flexDirection,
        flex: 1,
        gap: horizontalLayout ? 1 : 'inherit',
        justifyContent: verticalAlign,
        alignItems: align,
        "& .MuiTypography-root:not(.varianceColor)": {
            color: textColor
        }
    });
}
function StatisticsCard({ title, value, valueLabel, flexDirection, align, verticalAlign, valueFontsize, textColor, showDeltaIndicator, percentage }) {
    const horizontalLayout = ["row", "row-reverse"].includes(flexDirection);
    return (
        <MDBox sx={() => statStyles({ horizontalLayout, flexDirection, align, verticalAlign, textColor })}>
            {title &&
                <MDBox>
                    <MDTypography variant="button" component="span" color="text" display="flex" alignItems="center" fontWeight="medium">
                        {title}
                    </MDTypography>
                </MDBox>
            }
            <MDBox>
                <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                    <MDTypography variant={valueFontsize} component="span" lineHeight="110%">{value}</MDTypography>
                    {
                        showDeltaIndicator && percentage.amount &&
                        <MDTypography className="varianceColor" color={percentage.color} lineHeight="inherit" variant="h5" component="span">
                            {percentage.deltaDirectionUp ? <>▲</> : <>▼</>}
                        </MDTypography>
                    }
                    {
                        valueLabel &&
                        <MDTypography component="p" variant="button" color="text" display="flex" whiteSpace="nowrap">{valueLabel}</MDTypography>
                    }
                </Stack>
            </MDBox>
            <MDBox>
                <MDTypography component="p" variant="button" color="text" display="flex" whiteSpace="nowrap">
                    {
                        percentage.amount &&
                        <MDTypography
                            className="varianceColor"
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color={percentage.color}
                        >
                            {percentage.amount}
                        </MDTypography>
                    }
                    {
                        percentage.label &&
                        <>&nbsp;{percentage.label}</>
                    }
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

StatisticsCard.defaultProps = {
    valueFontsize: "h4",
    textColor: "#333333",
    flexDirection: "column",
    align: "flex-start",
    verticalAlign: "space-between",
    percentage: {
        color: "text",
        text: "",
        label: "",
    },
};

export default StatisticsCard;
