import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useLocation, useNavigate } from "react-router-dom";
import { useResizeDetector } from 'react-resize-detector';
import { formatAmount, getDrilldownPath_CF, removeSessionFilter_CF } from 'utils';
import colors from "assets/theme/base/cloudForecasts/colors";
import { useYADialog } from "components/YADialog";
import DashboardItem from 'components/DashboardItem_CF';
import _ from 'lodash';
import { useDashboardContext } from 'components/DashboardContext';
import { parseTableResultset_CF, convertRStoGraph_CF, creatToggleTableCubeOptions } from 'utils/charts';
import { isTimeDimensionQuery } from 'utils/dashboard';



const BarChartRenderer_CF = ({ loading, title, hideTitle, subtitle, chartHelpContextKey, resultSet, vizOptions, noOptions, vizState, cubeOptions }) => {
    const [state,] = useDashboardContext();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const [tableCubeOptions, setTableCubeOptions] = useState(null)
    const chartRef = createRef();
    const { width, height, ref: rref } = useResizeDetector();

    const {showReport} = useYADialog();
    let navigate = useNavigate()
    let location = useLocation()
 
    if(loading)
       return <DashboardItem loading={loading} hideTitle={hideTitle} title={title} subtitle={subtitle} noOptions={(vizOptions.compactView || vizOptions.noOptions) ? true : noOptions}></DashboardItem>
    
    let scrollablePlotAreaMinHeight = 100;
    let currentFilters;

    let parsedResultset = [];
    if (isTimeDimensionQuery(resultSet.loadResponses[0].query)) {
        parsedResultset = parseTableResultset_CF(resultSet, state, vizOptions)
        currentFilters = removeSessionFilter_CF(resultSet.loadResponses[0].query.filters, vizOptions)
    }
    else {
        parsedResultset = resultSet.tablePivot();
        currentFilters = removeSessionFilter_CF(resultSet.loadResponse.pivotQuery.filters, vizOptions)
    }

    var count = vizOptions.series.length
    var graphData = convertRStoGraph_CF(parsedResultset, vizOptions.wigetType === "leftrightbar" ? colors.varianceColors : count === 1 ? colors.singleDataColors : colors.graphColors, "bar", vizOptions)

    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        let isInArray = (name, array) => {
            return array.some(item => item.name === name);
        }
        let parsedColumns = []
        parsedResultset.length ?
            parsedResultset.forEach(item => {
                Object.keys(item).map(key => {
                    let col = {
                        "name": key,
                        "displayName": String(key).match(/[^|]*$/g)[0],
                        "type": Number(item[key]) ? vizOptions.units ? 'number' : "currency" : 'string'
                    }
                    if (!isInArray(col.name, parsedColumns))
                        parsedColumns.push(col)
                })
            })
            : parsedColumns = null
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = parsedResultset.length && parsedColumns.length ? [...parsedColumns] : [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = vizOptions?.tableHeight ? vizOptions?.tableHeight : 5.8
        if (cubeOptions) {
            let tableCubeOptions = creatToggleTableCubeOptions(cubeOptions)
            setTableCubeOptions(tableCubeOptions)
        }
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])

    //calculate scrollablePlotAreaMinHeight
    scrollablePlotAreaMinHeight = 40 * graphData.categories.size * Object.keys(graphData.range).length;
    var opts = {
        chart: {  type: 'bar',
        // height: location.pathname.indexOf("report") > 0 ? 450 : 250,
            width: width, 
            height: vizOptions.compactView ? 130 : height,
            scrollablePlotArea: {
                 minHeight: vizOptions.compactView ? 0 : scrollablePlotAreaMinHeight,
                // opacity: 1,
                // marginRight: 30
            },
            style: { fontFamily: 'inherit', fontSize: '14px', },
            spacingBottom: 0,
            // spacingTop: 0,
            spacingRight: 0,
            spacingLeft: 0,
            backgroundColor:colors.chartBackground
        },
        title: { text: '' },
        legend:{ enabled: vizOptions.compactView ? false : true,
        
         },
        exporting: { enabled: false },
        lang: { thousandsSep: ',' },        
        credits: { enabled: false },
        tooltip: {
            outside: false,
            followPointer:true,
            formatter: function () {
                return `<b>${this.point.category ? this.point.category : ''}</b><br/>${this.point.series.name}: <b>$${Highcharts.numberFormat(this.point.y,0,".",",")}</b>`;
            }
        },
        xAxis: {
            labels: {
                enabled: vizOptions.compactView ? false : true // Set x-axis labels to not enabled
            },
            visible: vizOptions.category ? true : false,
            categories: Array.from(graphData.categories) ,
        },
        yAxis: [{
            softMax: 1,
            gridLineWidth: vizOptions.compactView ? 0 : 1,
            // visible: false,
            // gridLineColor: 'transparent',
            reversed: false,
            title: {
                text: ''
            },
            labels: {
                enabled: vizOptions.compactView ? false : true, // Set x-axis labels to not enabled
                padding: 15,
                formatter: function () {
                    return formatAmount(Math.abs(this.value)).replace(/ /g,'').replace('.0','');
                }
            },
        },
        ],
        plotOptions: {
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                dataLabels: [{
                align: 'left',
                inside: true,
                style: { fontFamily: 'inherit', fontSize: '14px', },
                format: vizOptions.compactView ? '<b>{series.name}</b>': ""
                }, 
                {
                align: 'left',
                style: { fontFamily: 'inherit', fontSize: '14px', },
                formatter: vizOptions.compactView ? function () {
                    return `<b>${formatAmount(this.y,2)}</b>`;
                }: function () {
                    return '';
                } 
                }],
                cursor: vizOptions.compactView ? "normal" : "pointer",
                groupPadding: 0.1,
                pointPadding: 0.1,
                // pointWidth: 20,
                borderWidth: 0,
                borderRadius: 4,       
                point: {
                    events: {
                        click: function (event) {
                            var obj = Object.assign([], [...currentFilters]);
                            if (vizOptions.category)
                                // if (!obj.find((({name}) => name === vizOptions.category))) 
                                if (obj.find((({name}) => name === vizOptions.category))) 
                                {
                                   _.remove(obj, {name: vizOptions.category})
                                   obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                                else
                                {
                                   obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                            if (obj.find((({name}) => name === "Years.year")) && (vizOptions.series[0].name.indexOf(".previousYear") !== -1) && vizOptions["popupTo"] && (vizOptions["popupTo"] !== "")) {
                                _.remove(obj, {name: "Years.year"})
                                obj.push({name: "Years.year", "values": [event.point.series.name.replace(" YTD","")]})
                            }
                            if (vizOptions.queryType && vizOptions.queryType === "CompareWithPrevYearTrend" && vizOptions["popupTo"] && vizOptions["popupTo"] !== "") {
                                if (obj.find((({ name }) => name === "Years.year")))
                                    _.remove(obj, { name: "Years.year" })
                                obj.unshift({name: "Years.year", "values": [event.point.series.name.replace(" YTD","")]})
                            }
                            if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                                vizOptions.excludeFilters.map((fil) => {
                                    if (obj.find((({name}) => name === fil)))
                                        _.remove(obj, {name: fil})                                
                                })
                            }
                            // vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : location.pathname + vizOptions.drillTo, { state: obj})
                            vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath_CF(location.pathname, vizOptions.drillTo), { state: obj})
                            vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && showReport(vizOptions["popupTo"], obj, null);
                        }
                    }
                },
            },
            bar: {
                grouping: vizOptions.wigetType === "leftrightbar" ? false : true,
                dataLabels: {
                   enabled: true,
                    //  inside: true,
                     style: {
                        fontSize: '12px',
                        fontFamily: 'inherit',
                        fontWeight: 400,
                    },
                    formatter: function () {
                        if (vizOptions.wigetType === "leftrightbar" && this.y === 0)
                            return '';
                        return `{series.name} + '(' + formatAmount(Math.abs(this.y)).replace(/ /g, '').replace('.0', '') + ')'`;
                    }
                },
                pointWidth: vizOptions.compactView ? 40 : 20
            }
        },


        series: Object.values(graphData.range)
    }

    const nodata = graphData.categories?.size === 0;
    const parsedResultsetData = parsedResultset?.length > 0 ?  parsedResultset : resultSet?.tablePivot();

    return (toggleType === 'table' && tableVizOptions ?
    <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet} parsedResultset={parsedResultset} cubeOptions={tableCubeOptions}/> :
        <DashboardItem nodata={nodata} title={title} hideTitle ={hideTitle} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} noOptions={(vizOptions.compactView || vizOptions.noOptions) ? true : noOptions} compact={vizOptions.compactView ? true : false} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={parsedResultsetData} cubeOptions={cubeOptions}>
            <div ref={rref} style={{position: 'relative', height: vizOptions.compactView ? '100%' : '100%'}}>
                {/* <div>
                    vizOptions["popupTo"]
                </div> */}
                <div style={{position: 'absolute', left: 0, top: vizOptions.compactView ? -20 : 0, bottom: vizOptions.compactView ? -20 : 0, right: 0}}>
                    <HighchartsReact key={_.uniqueId()} ref={chartRef} highcharts={Highcharts} options={opts} />
                </div>
            </div>
        </DashboardItem>
    )
}

export default BarChartRenderer_CF;