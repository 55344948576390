import { Alert, Card, Icon, IconButton, InputAdornment, MenuItem, Modal, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import { useState } from "react";
import ListRule from "../ListRule";
import OfferingRule from "../OfferingRule";

const TowerRuleModal = (props) => {
    const { enableAssetDistribution, yearFilter, monthFilter, selectedTowers, subTowers, onAddRuleSave, onAddRuleClose, item, rowId, ruleName } = props;
    const [tierAllocation, setTierAllocation] = useState(item.tierAllocation ? item.tierAllocation : [0, 0, 0, 0, 0]);
    const [towerSingle, setTowerSingle] = useState(item.name ? item.name : "");
    const [destinationTable, setDestinationTable] = useState(item.destinationTable ? (item.destinationTable === "multiple" ? "" : item.destinationTable) : "");
    const [portion, setPortion] = useState(item.portion ? parseFloat(item.portion) : "");

    const [splitStrategy, setSplitStrategy] = useState(item.splitStrategy ? item.splitStrategy : null);
    const [weightColumn, setWeightColumn] = useState(item.weight ? item.weight : "");
    const [condition, setCondition] = useState(item.condition ? item.condition : "");
    const [errors, setErrors] = useState({});
    const [distributionType, setDestributionType] = useState("")
    const [showListField, setShowListField] = useState(false);

    const handleClearAssetSelection = () => {
        setErrors({});
        setDestinationTable("");
        setSplitStrategy(null);
        setWeightColumn("");
        setCondition("");
        setTierAllocation([0, 0, 0, 0, 0]);
    }

    const handleOnSplitStrategyChange = (strategyValue) => {
        setTierAllocation([0, 0, 0, 0, 0]);
        setWeightColumn("");
        setSplitStrategy(strategyValue)
    }

    const handleAddRuleClose = () => {
        if (onAddRuleClose)
            onAddRuleClose()
    }

    const validateData = () => {
        let err = false;
        let e = {};

        if (towerSingle === "") {
            e.towerSingle = true;
            err = true;
        }
        // let validatePortionText = /^([0-9]{1,2}\.[0-9]{1,2})$|^([0-9]{1,2})$/
        let validatePortionText = /^(?:100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?)$/

        if ((isNaN(parseFloat(portion).toFixed(2)) || portion === "" || (parseFloat(portion).toFixed(2) <= 0 || parseFloat(portion).toFixed(2) > 100))) {
            e.portion = true
            err = true;
        }
        if (!(validatePortionText.test(parseFloat(portion).toFixed(2)))) {
            e.portion = true
            err = true;
            if (parseFloat(portion).toFixed(2) === 100) {
                e.portion = false;
                err = false;
            }
        }
        if (splitStrategy === "byWeight" && weightColumn === "") {
            e.weightColumn = true;
            err = true;
        }
        if (splitStrategy === "byTier" && _.sum(tierAllocation) !== 100) {
            e.tierAllocation = true;
            err = true;
        }
        if (distributionType && distributionType !== ""  && destinationTable === ""){
            e.destinationTable = true
            err = true;
        }
        if (distributionType , distributionType !== "" , destinationTable !== "" , splitStrategy === "byConsumption"){
            e.splitStrategy = true
            err = true;
        }
        return [err, e];
    }

    const saveRule = async () => {
        const [err, e] = validateData();

        if (!err) {
            let sub = _.find(subTowers, { 'towerId': parseInt(towerSingle.split(" | ")[0]), 'id': parseInt(towerSingle.split(" | ")[1]) })
            const allocation = { name: towerSingle, desc: sub["tower.name"] + " | " + sub.name, portion: parseFloat(portion), destinationTable: destinationTable, condition: condition, splitStrategy: splitStrategy, weight: weightColumn, tierAllocation: tierAllocation };
            if (onAddRuleSave) {
                onAddRuleSave(allocation, rowId);
                handleAddRuleClose();
            }
        } else {
            setErrors(e);
        }
    }
    const newSelectedTowers = towerSingle !== "" ? selectedTowers.filter(item => item !== towerSingle) : selectedTowers
    const readOnly = (ruleName && ruleName !== "")
    return (
        <Modal open={true} onClose={handleAddRuleClose}>
            <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Card sx={{ minHeight: "600px", width: "600px", overflow: 'hidden' }}>
                    <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox>
                            <MDTypography variant="h6" component="span" color="text">Add Tower</MDTypography>
                        </MDBox>
                        <MDBox display="flex">
                            <IconButton onClick={handleAddRuleClose} title="Close">
                                <Icon>close</Icon>
                            </IconButton>
                        </MDBox>
                    </MDBox>
                    <MDBox height="100%" textAlign="center" pb={2} >
                        <MDBox pt={2} height="478px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ overflowY: "auto" }}>
                            <MDTypography variant="subtitle2" fontWeight="medium" color={errors?.towerSingle ? "error" : "dark"}>Choose a tower and assign a portion percentage to it.</MDTypography>
                            <MDBox pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                                <TextField focused label="Tower" sx={{ minWidth: 300 }} name="tower" select margin="dense" variant="outlined"
                                    onChange={(e) => { setErrors({}); setTowerSingle(e.target.value) }}
                                    value={towerSingle}
                                    error={errors?.towerSingle}
                                    disabled={readOnly}
                                >
                                    {subTowers?.filter((item) => !newSelectedTowers?.includes(`${item.towerId} | ${item.id}`)).map((item) => <MenuItem key={`${item.towerId} | ${item.id}`} value={`${item.towerId} | ${item.id}`}>{item["tower.name"]} | {item["name"]}</MenuItem>)}
                                </TextField>
                                { readOnly ?
                                    <TextField focused
                                        InputProps={{
                                            endAdornment: <InputAdornment disableTypography sx={{ fontSize: 13 }} position="end">%</InputAdornment>,
                                        }}
                                        label="Portion" sx={{ width: 100, marginLeft: 0.5, textAlign: "center", paddingRight: 0 }} id="trPortion" name="trPortion" value={parseFloat(portion).toFixed(2)}
                                        disabled={readOnly}
                                        onChange={(e) => { setErrors({}); setPortion(e.target.value) }} margin="dense" variant="outlined" >
                                    </TextField> : 
                                    <TextField focused
                                        InputProps={{
                                            endAdornment: <InputAdornment disableTypography sx={{ fontSize: 13 }} position="end">%</InputAdornment>,
                                        }}
                                        label="Portion" sx={{ width: 100, marginLeft: 0.5, textAlign: "center", paddingRight: 0 }} id="trPortion" name="trPortion" value={portion}
                                        onChange={(e) => { setErrors({}); setPortion(e.target.value) }} margin="dense" variant="outlined" >
                                    </TextField>
                                    }
                            </MDBox>
                            {errors && errors.portion && <MDTypography mt={1} variant="caption" color="error">Allocation Portion percentage should be between 0 and 100</MDTypography>}
                            {
                                enableAssetDistribution && towerSingle && towerSingle !== "" && distributionType === "" && (
                                    <MDBox display="flex" flexDirection="row">
                                        <MDButton
                                            size="small"
                                            sx={{ mt: 1, ml: 1, textTransform: "none" }}
                                            variant="outlined"
                                            color="info"
                                            onClick={() => { if (destinationTable === 'solutionOffering') { setSplitStrategy(""); setDestinationTable(""); setCondition("") } setDestributionType("asset"); setShowListField(true) }}>Distribute to Asset?</MDButton>
                                        <MDButton
                                            size="small"
                                            sx={{ mt: 1, ml: 1, textTransform: "none" }}
                                            variant="outlined"
                                            color="info"
                                            onClick={() => { if (destinationTable !== 'solutionOffering') { setCondition("") } setDestributionType("offering"); setShowListField(true); setDestinationTable("solutionOffering") }}>Distribute to Offering?</MDButton>
                                    </MDBox>
                                )
                            }
                            {distributionType === "asset" && (
                                <ListRule
                                    yearFilter={yearFilter}
                                    monthFilter={monthFilter}
                                    destinationTable={destinationTable}
                                    splitStrategy={splitStrategy}
                                    weightColumn={weightColumn}
                                    tierAllocation={tierAllocation}
                                    condition={condition}
                                    onDestinationTableChange={setDestinationTable}
                                    onSplitStrategyChange={handleOnSplitStrategyChange}
                                    onWeightColumnChange={setWeightColumn}
                                    onTierAllocationChange={setTierAllocation}
                                    onConditionChange={setCondition}
                                    onClearAssetSelection={handleClearAssetSelection}
                                    errors={errors}
                                    showListField={showListField}
                                    setErrors={setErrors}
                                />
                            )}
                            {distributionType === "offering" && (
                                <OfferingRule
                                    yearFilter={yearFilter}
                                    monthFilter={monthFilter}
                                    destinationTable={destinationTable}
                                    splitStrategy={splitStrategy}
                                    weightColumn={weightColumn}
                                    tierAllocation={tierAllocation}
                                    condition={condition}
                                    onDestinationTableChange={setDestinationTable}
                                    onSplitStrategyChange={handleOnSplitStrategyChange}
                                    onWeightColumnChange={setWeightColumn}
                                    onTierAllocationChange={setTierAllocation}
                                    onConditionChange={setCondition}
                                    onClearAssetSelection={handleClearAssetSelection}
                                    errors={errors}
                                    showListField={showListField}
                                    setErrors={setErrors}
                                />
                            )}
                            {distributionType === "consumption" && (
                                <Alert severity={"info"}
                                    sx={{ marginTop: 1, fontSize: "14px", textAlign: "left" }}
                                >
                                    Distributing by consumption to multiple assets
                                </Alert>
                            )}
                        </MDBox>
                        <MDBox pt={1} pr={2} sx={{ backgroundColor: "white", textAlign: "right" }}>
                            <MDButton name="saveRules" variant="gradient" color="info" onClick={saveRule}>Save Rule</MDButton>
                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
        </Modal>
    );
};

export default TowerRuleModal;