import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import Textbox from "components/YAForm/components/Textbox";
import { useContext } from "react";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
// import { parseJsonString } from "utils";

const DataStoreMappingeName = (props) => {
    const { resourceData, showInterfaceForm } = props
    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();
    const { onDialogClose } = useContext(YADialogCustomFormContext);

    const onSubmit = async formdata => {
        let isNameCheck = resourceData.find((obj => obj.name === formdata.name))
        if (isNameCheck) {
            setError("name", { type: "manual", message: 'Name must be unique.' });
        } else {
            let name = formdata.name.trim()
            if (name) {
                showInterfaceForm(name)
                onDialogClose()
            } else {
                setError("name", { type: "manual", message: 'Name is required.' });
            }
        }
    };


    return (
        <MDBox pt={1} px={3} pb={2} minWidth={400}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <MDBox mb={2}>
                    <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ name: "name", displayName: "Data Mapping Template name", required: true }} errorMessage={errors["name"] && errors["name"].message} />
                </MDBox>
                <MDBox mt={4} mb={1} textAlign="right">
                    <MDButton type="submit" disabled={isSubmitting} variant="gradient" color="info" startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                        Save
                    </MDButton>
                </MDBox>
            </form>
        </MDBox>
    );
};

export default DataStoreMappingeName;