import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Icon, MenuItem, Radio, RadioGroup, Select, Stack, Switch, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { colorThemes } from "components/VisualizationRenderer/components/ChartRenderer/constants";
import { chartwiseDefaultColorThemes, settingOptionSets } from "components/VisualizationRenderer/components/ChartRenderer/constants";
import { memo, useEffect, useRef, useState } from "react";
import { toInt } from "utils";
import debounce from 'lodash/debounce';
import produce from "immer";
import RichTextbox from "components/RichTextbox";
import Alert from '@mui/material/Alert';

const colorPickerStyles = () => {
    return {
        display: 'flex',
        alignItems: 'center',
        "& .colorSwatch": {
            mx: .5,
            height: 28,
            width: 28,
            borderRadius: '8px',
            border: '3px solid #ddd',
        },
        "& .MuiIcon-root": {
            cursor: 'pointer',
        },
        "& .MuiTypography-root": {
            textTransform: 'uppercase',
        },
        "& input": {
            width: '100%',
            height: '100%',
        }
    };
};

const ColorPicker = ({ color, defaultColor, onColorChange, onColorReset }) => {
    const [colorVal, setColorVal] = useState(color || '#ddd');

    useEffect(() => {
        if (color !== colorVal)
            setColorVal(color);
    }, [color])

    const onChangeComplete = debounce((colorHex) => {
        onColorChange(colorHex);
    }, 100);

    const handleOnColorChange = ({ target: { value } }) => {
        setColorVal(value);
        onChangeComplete(value);
    };
    return (
        <MDBox sx={theme => colorPickerStyles(theme)}>
            {defaultColor !== colorVal &&
                <Icon title="Click to reset" onClick={onColorReset}>refresh</Icon>
            }
            <MDTypography variant="caption" mx={.5} mb={.25}>{colorVal}</MDTypography>
            <MDBox className="colorSwatch" style={{ backgroundColor: colorVal }}>
                <input type="color" value={colorVal} onChange={handleOnColorChange} />
            </MDBox>
        </MDBox>
    )
}

const colorSwatchStyles = () => {
    return {
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        padding: .5,
        gap: .75,
        marginBottom: 1,
        "& .colorSwatch-color": {
            height: 18,
            width: 18,
            borderRadius: .75
        },
        "& .colorSwatch-color-custom": {
            cursor: "pointer",
            height: 18,
            width: 18,
            borderRadius: .75
        },
        "& .addColorBtn": {
            cursor: "pointer",
        },
        "& .addColorBtn:hover": {
            backgroundColor: "#ddd"
        },
        "& .clearColorsBtn": {
            cursor: "pointer",
            color: "#ddd"
        },
        "& .clearColorsBtn:hover": {
            color: "inherit",
            backgroundColor: "#ddd"
        },
    };
};

const ColorSwatchColor = ({ colorIndex, color, enablePicker, showPickerOnMount, onColorChange }) => {
    const [colorVal, setColorVal] = useState(color || '#ddd');
    const colorInputRef = useRef();

    useEffect(() => {
        if (enablePicker && showPickerOnMount && colorInputRef.current)
            colorInputRef.current.click();
    }, [colorIndex, enablePicker, showPickerOnMount])

    const onChangeComplete = debounce((colorHex) => {
        onColorChange(colorIndex, colorHex);
    }, 100);

    const handleOnColorChange = ({ target: { value } }) => {
        setColorVal(value);
        onChangeComplete(value);
    };

    return (
        <MDBox key={`ci_${colorIndex}`} className={enablePicker ? "colorSwatch-color-custom" : "colorSwatch-color"} style={{ backgroundColor: enablePicker ? colorVal : color }}>
            {
                enablePicker && <input ref={colorInputRef} id={`ci_${colorIndex}`} type="color" value={colorVal} onChange={handleOnColorChange} />
            }
        </MDBox>
    )
}

const ColorSwatch = ({ chartType, config, onSettingChange }) => {
    const themeColorsVal = config["theme_colors"] || "default";
    const reverseThemeColorsVal = Boolean(config["theme_colors_reverse"]);
    const customColorSwatch = Array.isArray(config["theme_colors_custom"]) ? config["theme_colors_custom"] : [];
    const customColors = themeColorsVal === "custom";
    const [newColorAdded, setNewColorAdded] = useState(false);

    let colorSwatch = [];

    if (customColors)
        colorSwatch = [...customColorSwatch];
    else {
        // use chart specific default theme first
        if (themeColorsVal === "default")
            colorSwatch = chartwiseDefaultColorThemes[chartType] || colorThemes["default"] || [];
        else
            colorSwatch = colorThemes[themeColorsVal] || colorThemes["default"] || [];
    }

    if (!customColors && reverseThemeColorsVal)
        colorSwatch = [].concat(colorSwatch)?.reverse();

    const handleAddCustomColor = () => {
        setNewColorAdded(true);
        onSettingChange(
            "theme_colors_custom",
            produce(customColorSwatch, draft => {
                draft.push("#2F70D9");
            })
        );
    };

    const handleClearColors = () => {
        onSettingChange("theme_colors_custom", []);
    };

    const handleOnColorChange = (colorIndex, colorVal) => {
        setNewColorAdded(false);
        onSettingChange(
            "theme_colors_custom",
            produce(customColorSwatch, draft => {
                draft[colorIndex] = colorVal;
            })
        );
    };

    return (
        <MDBox sx={theme => colorSwatchStyles(theme)}>
            {
                colorSwatch.map((color, i) =>
                    <ColorSwatchColor
                        key={`c_${i}`}
                        enablePicker={customColors}
                        colorIndex={i}
                        color={color}
                        showPickerOnMount={newColorAdded && colorSwatch.length === i + 1}
                        onColorChange={handleOnColorChange}
                    />
                )
            }
            {customColors &&
                <Stack direction={"row"}>
                    <Icon title="Click to add new color" className="addColorBtn" onClick={handleAddCustomColor}>add</Icon>
                    {
                        colorSwatch?.length > 0 &&
                        <Icon title="Click to clear all colors" className="clearColorsBtn" onClick={handleClearColors}>close</Icon>
                    }
                </Stack>
            }
        </MDBox>
    );
};

const getOptions = (optionSetName) => {
    const optionSet = settingOptionSets[optionSetName] || [];
    return optionSet.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)
}

const SettingItem = ({ chartType, item, config, onSettingChange }) => {
    let isChecked = config[item.name] === undefined ? item.defaultValue : config[item.name];
    let value = config[item.name] || item.defaultValue;
    const [settingValue, setSettingValue] = useState(value);

    useEffect(() => {
        if (["textbox", "textarea", "integer"].includes(item.type) && JSON.stringify(settingValue) !== JSON.stringify(value))
            onSettingChange(item.name, settingValue);
    }, [settingValue]);

    return <>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            {item.type !== "textarea" && <MDTypography variant="caption" mx={.5}>{item.label}</MDTypography>}
            {
                item.type === "dropdown" && <Select
                    name={item.name}
                    value={value}
                    onChange={(event) => {
                        onSettingChange(item.name, event.target.value);
                    }}
                    size="small"
                    variant="outlined"
                    sx={{
                        maxWidth: 120,
                        minWidth: 120,
                        "& .MuiSelect-select": {
                            px: 1,
                            py: .5
                        }
                    }}
                >
                    {getOptions(item.optionSetName)}
                </Select>
            }
            {
                item.type === "textbox" && <TextField
                    key={item.name}
                    name={item.name}
                    value={settingValue || ""}
                    onChange={(event) => {
                        setSettingValue(event.target.value);
                    }}
                    size="small"
                    variant="outlined"
                    sx={{
                        maxWidth: "50%",
                        minWidth: "50%",
                        "& .MuiOutlinedInput-input": {
                            px: .75,
                            py: .75
                        }
                    }}
                />
            }
            {
                item.type === "textarea" && <MDBox width="100%">
                    <MDTypography variant="caption" mx={.5} mb={1} display="block">{item.label}</MDTypography>
                    <RichTextbox
                        key={item.name}
                        value={value}
                        onChange={(content) => {
                            onSettingChange(item.name, content)
                        }}
                    />
                </MDBox>
            }
            {
                item.type === "integer" && <TextField
                    key={item.name}
                    name={item.name}
                    type="number"
                    value={settingValue || item.defaultValue || ""}
                    onChange={(event) => {
                        let val = toInt(event.target.value);
                        if (item.maxValue)
                            val = val > toInt(item.maxValue) ? toInt(item.maxValue) : val;
                        if (item.minValue)
                            val = val < toInt(item.minValue) ? toInt(item.minValue) : val;
                        setSettingValue(val);
                    }}
                    size="small"
                    variant="outlined"
                    sx={{
                        maxWidth: "50%",
                        minWidth: "50%",
                        "& .MuiOutlinedInput-input": {
                            px: .75,
                            py: .75
                        }
                    }}
                />
            }
            {
                item.type === "switch" && <Switch checked={isChecked}
                    name={item.name}
                    variant="standard"
                    size="small"
                    color="success"
                    sx={{
                        // mt: -.5,
                        "& .MuiSwitch-switchBase": {
                            marginTop: "2px"
                        }
                    }}
                    onChange={
                        (_, checked) => {
                            onSettingChange(item.name, checked)
                        }
                    }
                />
            }
            {
                item.type === "colorpicker" && <ColorPicker
                    key={item.name}
                    color={value}
                    defaultColor={item.defaultValue}
                    onColorReset={() => {
                        onSettingChange(item.name, item.defaultValue);
                    }}
                    onColorChange={(colorVal) => {
                        onSettingChange(item.name, colorVal);
                    }}
                />
            }
        </MDBox>
        {
            item.name === "theme_colors" && <ColorSwatch chartType={chartType} config={config} onSettingChange={onSettingChange} />
        }
    </>
}

const fieldsListStyles = (theme) => {
    const { palette, functions, borders } = theme;
    const { black } = palette;
    const { pxToRem, rgba } = functions;
    const { borderWidth } = borders;
    return {
        boxShadow: "none",
        "&::before": {
            height: 0
        },
        "& .MuiListItem-root": {
            cursor: 'grab',
            userSelect: 'none',
            border: `${borderWidth[1]} solid transparent`,
        },
        "& .MuiListItem-root:hover": {
            borderRadius: pxToRem(4),
            backgroundColor: '#facd35',
            // backgroundColor: info.main,
            // color: white.main,
            border: `${borderWidth[1]} solid ${rgba(black.main, 0.09)}`,

        },
        "& .MuiListItem-root .dragIcon": {
            visibility: 'hidden'
        },
        "& .MuiListItem-root:hover .dragIcon": {
            visibility: 'visible'
        },
        "& .MuiListItem-root:hover .MuiTypography-root, & .MuiListItem-root:hover .MuiIcon-root": {
            color: black.main
        },
        "& .MuiListItem-root:hover .MuiCheckbox-root .MuiSvgIcon-root": {
            border: `${borderWidth[1]} solid ${black.main}`,
        },
        "& .MuiListItemIcon-root": {
            minWidth: pxToRem(20),
            margin: `${pxToRem(4)} ${pxToRem(8)}`
        },
        "& .MuiAccordionDetails-root": {
            padding: `0 ${pxToRem(8)} 0 ${pxToRem(8)}`
        },
        "& .MuiAccordionSummary-content": {
            margin: `${pxToRem(8)} 0`
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: pxToRem(6)
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: pxToRem(32)
        },
        "& + .Mui-expanded": {
            margin: `0 0 ${pxToRem(8)} 0`
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0
        },
        "& .MuiAccordionSummary-root": {
            marginTop: pxToRem(4),
            backgroundColor: "#efefef",
            padding: `0 0 0 ${pxToRem(8)}`,
            minHeight: pxToRem(32),
            flexDirection: "row-reverse"
        },
        "& .MuiRadio-root": {
            padding: .75,
            marginRight: .75,
        },
        "& .MuiRadio-root .MuiSvgIcon-root": {
            height: `${pxToRem(16)}!important`,
            width: `${pxToRem(16)}!important`,
        },
        "& .Mui-checked::after": {
            height: `${pxToRem(8)}!important`,
            width: `${pxToRem(8)}!important`,
        },
    };
};

const ConfigSection = memo(({ hideEditorSettings, type, chartType, title, name, config, settings, onSettingChange }) => {
    return (
        <Accordion key={name} defaultExpanded={true} sx={(theme) => fieldsListStyles(theme)}>
            <AccordionSummary expandIcon={<Icon>keyboard_arrow_down</Icon>}>
                <MDTypography variant="caption" fontWeight="medium">{title}</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
                <>
                    {
                        type === "singleSelection" &&
                        <MDBox
                            mt={1}
                            // mx={1.5}
                            px={.25}
                            display="flex"
                            alignItems="center"
                        >

                            <RadioGroup
                                key={title}
                                defaultValue={settings?.defaultValue}
                                value={config?.config?.grandTotalType || config?.grandTotalType || settings?.defaultValue}
                                name={title}
                                onChange={(event) => {
                                    onSettingChange(name, event.target.value);
                                }}
                            >
                                {
                                    settings?.options?.map(
                                        op => <FormControlLabel
                                            key={op.value}
                                            value={op.value}
                                            control={<Radio />}
                                            label={<MDTypography variant="caption" my={.25}>{op.label}</MDTypography>}
                                        />
                                    )
                                }
                            </RadioGroup>
                        </MDBox>
                    }
                    {
                        type === "group" &&
                        <MDBox
                            mt={1}
                            // mx={1.5}
                            display="flex"
                            flexDirection="column"
                        >
                            {
                                settings?.group?.filter(item => hideEditorSettings ? (item.category || "") !== "editor" : true)?.map((item) => <SettingItem key={item.name} chartType={chartType} item={item} config={config} onSettingChange={onSettingChange} />)
                            }
                            {settings.group.map(i => {
                                if (i.label=="Show Top"){
                                    return (<Alert key={i.name} severity="info" width="10%" sx={{ fontSize: 12 }}>{"Increasing the value can result in performance issues."}</Alert>)
                                }
                            })}
                            {/* <Alert severity="info" width="10%" sx={{ fontSize: 12 }}>{"Increasing the value can result in performance issues."}</Alert> */}

                        </MDBox>
                    }
                </>
            </AccordionDetails>
        </Accordion>
    );
});

export default ConfigSection;