import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import { getDomain } from "utils";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon, IconButton, Tooltip, Autocomplete, TextField, Checkbox, Chip, createFilterOptions } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import FieldDetails from "../FieldDetails";

const DynamicMultiSelect = (props) => {
    const { watch, control, setValue, formId, fieldDef: { name, displayName, required, variant, width, placeholder, dataSource, toolTip, unique, impact, type, severity }, errorMessage } = props;
    const [options, setOptions] = useState(dataSource.type === "static" ? dataSource.data : []);
    const [modOptions, setModOptions] = useState(dataSource.type === "static" ? dataSource.data : []);
    const [loading, setLoading] = useState(dataSource.type !== "static");
    const dataType = dataSource.type === "static" || dataSource.type === "custom" ? "select" : ''
    const watchAllFields = watch(dataSource.parentFields || []);
    const [open, setOpen] = useState(false);

    const parentFields = useMemo(() => {
        let parentFieldsObj = {};
        if (dataSource.parentFields) {
            dataSource.parentFields.forEach((f, i) => { parentFieldsObj[f] = watchAllFields[i] });
        }
        return parentFieldsObj;
    }, watchAllFields)
    const filter = createFilterOptions();

    useEffect(() => {
        async function getOptions() {
            setLoading(true);
            const domain = getDomain();
            let get_url = dataSource.type === "custom" ? `${domain}${dataSource.url}` : `${domain}/api/master/${formId}/${name}`
            if (dataSource.parentFields) {
                let hasEmpty = false;
                dataSource.parentFields.forEach((f) => {
                    if (!parentFields[f] && !hasEmpty) {
                        hasEmpty = true;
                    }
                    get_url = get_url.replace(new RegExp(`:${f}`, 'g'), parentFields[f] || 0)
                });

                if (hasEmpty) {
                    setOptions([]);
                } else {
                    let q = ("nc=" + Math.random()).replace(".", "");
                    get_url += get_url.indexOf("?") > 0 ? `&${q}` : `?${q}`
                    const response = await Axios.get(get_url);
                    const modRes = response.data.map((a) => {
                        if(!a.value) {
                            a.value = a.displayName
                            a.label = a.displayName
                        }
                        return a
                    })
                    setOptions(modRes);
                    setModOptions(modRes)
                }
            }
            else {
                let q = ("nc=" + Math.random()).replace(".", "");
                get_url += get_url.indexOf("?") > 0 ? `&${q}` : `?${q}`
                const response = await Axios.get(get_url);
                const modRes = response.data.map((a) => {
                    if(!a.value) {
                        a.value = a.displayName
                        a.label = a.displayName
                    }
                    return a
                })
                setOptions(modRes);
                setModOptions(response.data)
            }
            setLoading(false);
        }
        if (dataSource.type !== "static")
            getOptions();
    }, [parentFields]);

    if (loading) return <YASkeleton variant="dropdown" />

    const renderTags = (value, getTagProps) => {
        return value.map((val, index) => {
            const option = options.find(opt => opt.value === val);
            const { key, tabIndex, onDelete, disabled } = getTagProps({ index });
            return (
                <Chip
                key={key}
                label={option?.label??val}
                size="medium"
                sx={{ m: 0.3 }}
                tabIndex={tabIndex}
                onDelete={onDelete}
                disabled={disabled}
                />
            );
        })
    }
    const getOptions = (values) => {
        if (values && values.trim() !== '') {
            values = (typeof values === 'string') ? values.split(',') : values
            const ifNotExist = values.filter( val => {
                if(!modOptions.find(({value}) => value === val))
                    return val
            })
            if (ifNotExist && ifNotExist.length > 0) {
                let newOpts = ifNotExist.map( val => {
                    let newoption = { "value": val, "label": val };
                    return newoption
                })
                const modOpt = [...modOptions, ...newOpts]
                setModOptions(modOpt)
                return modOpt
            } else {
                return modOptions
            }
        } else {
            return options
        }
    }
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return <Autocomplete
                multiple
                disableClearable={required}
                disableCloseOnSelect
                disabled={loading}
                options={getOptions(value)}
                noOptionsText=""
                value={ value && (typeof value === 'string' && value.trim() !== '') ? value.split(','): value && value.trim() !== '' ? value : []}
                renderTags={(value, getTagProps) => renderTags(value, getTagProps)}
                getOptionLabel={(option) => option?.label}
                onChange={(event, item) => {
                    event.preventDefault()
                    const parseVal = String(item.map( a => a.value || a))
                    onChange(parseVal)
                    if (dataSource.dependentFields) {
                        dataSource.dependentFields.forEach((f) => {
                            setValue(f, null)
                        });
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.map(a => a?.label)[0] ? options.map(a => a?.label).some((option) => inputValue === option?.label) : false;
                    if (inputValue !== '' && !isExisting) {
                      filtered.push(inputValue);
                    }
                    return filtered;
                }}
                isOptionEqualToValue={(option, value) => {
                    if (typeof value === 'string') {
                        return option.value === value??[]
                    } else {
                        return option.value === value.value??[]
                    }
                }}
                renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    const isExisting = modOptions.map(a => a?.label).some((o) => o === option?.label);
                    return (
                        <li key={key} {...optionProps}>
                            <Checkbox
                                sx={{ p: 0, mr: 1, "& .MuiSvgIcon-root": { height: 16, width: 1, border: "1px solid #c5c9cc", borderRadius: dataType === "select" ? "4px" : "8px" } }}
                                checked={selected}
                            />
                            <MDBox sx={theme => ({ flexGrow: 1, '& span': { color: theme.palette.mode === 'light' ? '#8b949e' : '#586069' } })}>
                                <MDTypography color="text" variant="subtitle2" fontWeight="small">  {(isExisting || selected) ? option?.label : option.trim() !== '' ? `Add "${option}"`: ""}</MDTypography>
                            </MDBox>
                        </li>
                    );
                }}
                renderInput={params =>
                    <>
                        <MDBox sx={{ "&:hover": { "& .helpIcon": { visibility: 'visible' } } }} display='flex' flexDirection='row'>
                            <TextField {...params}
                                name={name}
                                required={required}
                                value={value}
                                error={errorMessage && true}
                                helperText={errorMessage}
                                label={displayName}
                                placeholder={placeholder}
                                variant={variant || "standard"}
                                sx={width ? { width: width } : undefined}
                                fullWidth={width ? false : true}
                            />
                            {toolTip?.length >= 0 &&
                                <IconButton className="helpIcon"
                                    sx={({ palette: { text } }) => ({
                                        // marginLeft: .15,
                                        // marginBottom: 1,
                                        marginRight: -2,
                                        color: "#979191",
                                        visibility: 'hidden',
                                        "&:hover": {
                                            color: text.main
                                        }
                                    })}
                                    size="small"
                                    onClick={()=> {setOpen(true)}}
                                >
                                    <Tooltip placement="left" title={toolTip ? toolTip : displayName}>
                                        <Icon>help</Icon>
                                    </Tooltip>
                                </IconButton>
                            }
                            {
                              open && (<FieldDetails impact = {impact} unique = {unique} type = {type} setOpen= {setOpen} toolTip= {toolTip} displayName= {displayName} required= {required} severity= {severity}/>)
                            }
                        </MDBox>
                    </>
                }
            />
            }}
        />
    )
}
export default DynamicMultiSelect;