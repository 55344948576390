import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import numeral from "numeral";
import DataTable from "components/DataTable";
import { AnimatedComponentInline } from "components/AnimatedComponent";

// const getColumnHeader = (columnName) => {
//     return (_.words(columnName || "") || []).map(c => _.capitalize(c)).join(" ")
// }

const tableAnswerStyles = () => ({
    minWidth: "330px",
    maxWidth: "550px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    overflow: "hidden",
    "& .MuiTableBody-root": {
        borderTop: "1px solid rgba(0, 0, 0, 0.05)!important"
    },
    "& .MuiTableCell-body:first-of-type": {
        borderLeft: "1px solid rgba(0, 0, 0, 0.05)!important"
    },
    "& th, & td": {
        padding: "4px",
    },
    "& th .MuiTypography-root, & td .MuiTypography-root": {
        fontSize: 12,
    },
});

const isCurrenyColumn = (columnName) => {

    if (["cost centre", "cost center", "costcentre", "costcenter", "cost pool", "costpool"].some(s => columnName.toLowerCase().indexOf(s) !== -1))
        return false;

    return ["spend", "spent", "expense", "expenditure", "variance", "budget", "amount", "cost"].some(s => columnName.toLowerCase().indexOf(s) !== -1)
};

const TableAnswer = ({ data, vizOptions }) => {
    if (data && Array.isArray(data)) {
        const columns = vizOptions?.columns?.map((c, ci) => {
            const currenyColumn = ["number", "currency"].includes(c.columnType) || isCurrenyColumn(c.columnLabel);
            return ({
                Header: c.columnLabel,
                accessor: c.columnName,
                align: currenyColumn ? "right" : "left",
                disableSorting: true,
                Cell: ({ cell: { value } }) => {
                    if (["currency"].includes(c.columnType) || isCurrenyColumn(c.columnLabel))
                        return <MDTypography key={`${c.columnName}_${ci}`} variant="button" color="dark">{numeral(value).format('$0,0')}</MDTypography>
                    return <MDTypography key={`${c.columnName}_${ci}`} variant="button" color="dark">{value || "(empty)"}</MDTypography>
                },
            })
        })

        return <MDBox sx={theme => tableAnswerStyles(theme)}>
            <DataTable
                variant="tile"
                table={{ columns, rows: data }}
                pageSizeVal={10}
                entriesPerPage={false}
                hideFooterForMinRecords={true}
            />
        </MDBox>
    }

    return (
        <span></span>
    );
}

export default AnimatedComponentInline(TableAnswer);