import { CircularProgress, Divider, Icon, IconButton, Stack } from "@mui/material";
import AnimatedRoute from "components/AnimatedRoute";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useCallback, useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { useAppController } from "context";
import ErrorBoundary from "components/ErrorBoundary";
import moment from "moment";
import { useSocketContext } from "context/SocketContext";
import { generateUUID } from "utils";
import fetchRequest from "utils/fetchRequest";
import { parseJsonString } from "utils";
import WelcomeMessage from "./components/WelcomeMessage";
import QuestionBox from "./components/QuestionBox";
import ConversationHistory from "./components/ConversationHistory";
import Conversation from "./components/Conversation";

const dialogStyles = () => ({
    width: "100%",
    maxWidth: "600px",
    height: "100vh",
    // borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    // justifyContent="center"
    flexDirection: "column",
    margin: "0 0 0 auto",
    overflow: "hidden",
    "@keyframes cursorBlinkAnim": {
        '0%': {
            borderRightColor: "initial"
        },
        '100%': {
            borderRightColor: "transparent"
        }
    },
    "@keyframes answerFadeInAnim": {
        '0%': {
            opacity: 0
        },
        '100%': {
            opacity: 1
        }
    },
    "& .cursorBox": {
        whiteSpace: "pre-wrap",
    },
    "& .cursorBox::after": {
        content: '""',
        paddingRight: .5,
        borderRight: "12px solid #ddd",
        animation: "cursorBlinkAnim .6s infinite alternate",
    },
    "& .answerFadeIn": {
        animation: "answerFadeInAnim .5s ease-in-out forwards",
        opacity: 0
    }
});


const AskMeDialogContainer = ({ newSessionUid, onClose }) => {

    const [controller,] = useAppController();
    const { userInfo } = controller;

    const [generatedSessionUid, setGeneratedSessionUid] = useState(newSessionUid);
    const [sessionUid, setSessionUid] = useState();
    const [currentConversation, setCurrentConversation] = useState({ loading: false, error: null });
    const [submitting, setSubmitting] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    // const [cachedQuestionsList, setCachedQuestionsList] = useLocalStorage("askYarkenQuestionsList", []);
    const [qaList, setQaList] = useImmer([]);
    const [followupQuestions, setFollowupQuestions] = useState([]);
    const scrollbarRef = useRef();

    const { socket, socketConnected } = useSocketContext();

    useEffect(async () => {

        if (sessionUid) {
            setCurrentConversation(prev => ({ ...prev, loading: true }));
            const [error, conversations] = await fetchRequest.get(`/api/askme/sessions/${sessionUid}`)
            if (error) {
                setCurrentConversation(prev => ({ ...prev, loading: false, error }));
                setQaList([]);
            }
            else {
                let conversationsArr = conversations?.map(c => {
                    let conv = (
                        {
                            question: c.question,
                            answer: {
                                ...(parseJsonString(c.data) || {}),
                                summary: c.summary
                            }
                        });
                    if (conv.answer.data === "ERROR") {
                        conv.answer = { data: "I apologize for any inconvenience. If you could kindly rephrase your question and provide specific details, I'll assist you promptly. Thank you!.", renderType: "string" };
                    }
                    return conv;
                });

                conversationsArr = (conversationsArr || []).reverse();
                setCurrentConversation(prev => ({ ...prev, loading: false }));
                setQaList(conversationsArr || []);
                setShowHistory(false);
            }
        }

    }, [sessionUid]);


    useEffect(() => {

        socket?.on('disconnect', () => {
            setQaList(draft => {
                if (draft?.length > 0) {
                    let latestQuestion = draft[draft.length - 1];
                    if (latestQuestion.thinking) {
                        latestQuestion.thinking = false;
                        latestQuestion.progress = null;
                        latestQuestion.answer = { data: "Something went wrong.", renderType: "string" };
                        latestQuestion.error = true;
                    }
                }
            });
            setSubmitting(false);
        });

        socket?.on("askYarkenResponse", (message) => {
            setQaList(draft => {
                if (draft?.length > 0) {
                    let latestQuestion = draft[draft.length - 1];
                    if (latestQuestion.thinking) {
                        latestQuestion.answer = { ...latestQuestion.answer, ...message };
                        if (message.end) {
                            latestQuestion.thinking = false;
                            latestQuestion.progress = null;
                            setSubmitting(false);
                        }
                        latestQuestion.progress = { stage: "SummarizingResponse" };
                        latestQuestion.error = false;
                        if (message?.data === "ERROR") {
                            latestQuestion.answer = { data: "I apologize for any inconvenience. If you could kindly rephrase your question and provide specific details, I'll assist you promptly. Thank you!.", renderType: "string" };
                            // latestQuestion.error = true;
                        }
                        // else
                        //     setCachedQuestionsList(current(draft));
                    }
                }
            });
        });

        socket?.on("askYarkenResponseSummary", (message) => {
            setQaList(draft => {
                if (draft?.length > 0) {
                    let latestQuestion = draft[draft.length - 1];
                    if (latestQuestion.thinking) {
                        latestQuestion.answer["summary"] = message.summary;
                        latestQuestion.thinking = false;
                        latestQuestion.error = false;
                        latestQuestion.progress = null;
                        if (message?.data === "ERROR") {
                            latestQuestion.answer = { data: "I apologize for any inconvenience. If you could kindly rephrase your question and provide specific details, I'll assist you promptly. Thank you!.", renderType: "string" };
                            // latestQuestion.error = true;
                        }

                        // else
                        //     setCachedQuestionsList(current(draft));
                    }
                }
            });
            setSubmitting(false);
        });

        socket?.on("askYarkenResponseFollowupQuestions", (message) => {
            setFollowupQuestions(message.followupQuestions);
        });

        // socket?.on("askYarkenResponseProgress", (message) => {
        //     setQaList(draft => {
        //         if (draft?.length > 0) {
        //             let latestQuestion = draft[draft.length - 1];
        //             latestQuestion.progress = message;
        //         }
        //     });
        // });

        () => {
            socket?.off("askYarkenResponse");
            socket?.off("askYarkenResponseSummary");
            // socket?.off("askYarkenResponseProgress");
            socket?.off("askYarkenResponseFollowupQuestions");
            socket?.off("askYarkenSqlQueryResponse");
            socket?.off("disconnect");
        }

        //
    }, [socket]);

    useEffect(() => {
        setTimeout(() => {
            if (scrollbarRef?.current) {
                scrollbarRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
    }, [qaList, followupQuestions]);

    const handleOnHistoryBtnClick = useCallback(() => {
        setShowHistory(true);
        setSessionUid(null);
        setQaList([]);
        setFollowupQuestions([]);
    }, []);

    const handleOnNewChatBtnClick = useCallback(() => {
        setShowHistory(false);
        setSessionUid(null);
        setGeneratedSessionUid(generateUUID());
        setQaList([]);
        setFollowupQuestions([]);
    }, []);

    const handleOnChatSelectionClick = useCallback((sessionUidVal) => {
        setSessionUid(sessionUidVal);
        setShowHistory(false);
    }, []);

    const handleOnAskBtnClick = useCallback(async (questionText, retry = false) => {

        if (socketConnected) {
            setFollowupQuestions([]);
            setSubmitting(true);
            if (retry)
                setQaList(draft => {
                    let latestQuestion = draft[draft.length - 1];
                    latestQuestion.thinking = true;
                    latestQuestion.progress = null;
                });
            else
                setQaList(draft => {
                    draft.push({ question: questionText, answer: {}, thinking: true, progress: null, error: false, timestamp: moment().format("YYYY-DD-DD HH:mm:ss") });
                });

            socket.emit("askYarken", { sessionUid: sessionUid || generatedSessionUid, questionText });

            setTimeout(() => {
                setQaList(draft => {
                    if (draft?.length > 0) {
                        let latestQuestion = draft[draft.length - 1];
                        if (!latestQuestion.progress)
                            latestQuestion.progress = { stage: "GeneratingQuery" };
                    }
                });
            }, 2000);
        }
    }, [socket, socketConnected, sessionUid, generatedSessionUid]);

    if (currentConversation.loading)
        return <MDBox display="flex" alignItems="center" justifyContent="center" height="100%">
            <CircularProgress size={40} />
        </MDBox>


    if (showHistory)
        return <ConversationHistory onClose={onClose} onChatSelection={handleOnChatSelectionClick} onNewChat={handleOnNewChatBtnClick} />

    return (
        <>
            <MDBox width="100%">
                <MDBox
                    pl={3}
                    pr={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    height={48}
                >
                    <MDTypography variant="subtitle2" fontWeight={"medium"}>Ask YäRKEN</MDTypography>
                    <Stack direction={"row"} spacing={1}>
                        {
                            qaList?.length > 0 && (
                                <IconButton onClick={handleOnNewChatBtnClick} title="New Chat">
                                    <Icon>add</Icon>
                                </IconButton>
                            )
                        }
                        <IconButton onClick={onClose} title="Close">
                            <Icon>close</Icon>
                        </IconButton>
                    </Stack>
                </MDBox>
                <MDBox px={3} pb={1}>
                    <MDTypography
                        component="span"
                        variant="caption"
                        fontWeight="medium"
                        color="info"
                        sx={{ lineHeight: 0, display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={handleOnHistoryBtnClick}
                    >
                        <Icon>west</Icon>&nbsp;&nbsp;History
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox height="100%" width="100%" overflow="auto" display="flex" justifyContent="center">
                {
                    !qaList || qaList?.length === 0 && (
                        <WelcomeMessage onQuestionClick={handleOnAskBtnClick} />
                    )
                }
                {
                    qaList && qaList?.length > 0 && (
                        <MDBox width="100%" pt={3} pb={4}>
                            {
                                qaList.map((qa, qai) => {
                                    return (
                                        <>
                                            <Conversation key={`qa${qai}`} username={userInfo?.displayName} lastAnswer={qai === qaList.length - 1} question={qa.question} answer={qa.answer} thinking={qa.thinking} progress={qa.progress} error={qa.error} onRetry={handleOnAskBtnClick} />
                                            {
                                                qai < qaList.length - 1 && <Divider sx={{ my: 2, background: "#ccc" }} />
                                            }
                                        </>
                                    )
                                })
                            }
                            {
                                followupQuestions?.length > 0 && (
                                    <MDBox p={2} width="100%" display="flex" flexWrap="wrap" gap={1}>
                                        {
                                            followupQuestions?.filter(q => String(q).trim() !== "").map(q => {
                                                const fq = (q || "").replaceAll('"', '');
                                                return <MDTypography key={q} component="div" p={.5} borderRadius="8px" backgroundColor="#efefef" display="inline" variant="caption" sx={{ cursor: "pointer" }} onClick={() => handleOnAskBtnClick(fq)}>{fq}</MDTypography>
                                            })
                                        }
                                    </MDBox>
                                )
                            }
                            <MDBox ref={scrollbarRef} pt={2} />
                        </MDBox>
                    )
                }
            </MDBox>
            <QuestionBox submitting={submitting} connected={socketConnected} onAskBtnClick={handleOnAskBtnClick} />
        </>
    );
};


const AskMeDialog = ({ onClose }) => {
    const converasationSessionID = generateUUID();
    return <MDBox bgColor="white" sx={theme => dialogStyles(theme)}>
        <ErrorBoundary>
            <AskMeDialogContainer newSessionUid={converasationSessionID} onClose={onClose} />
        </ErrorBoundary>
    </MDBox>
};

export default AnimatedRoute(AskMeDialog);