import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth } = borders;
const { tableBorder } = colors;

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(4)} ${pxToRem(4)}`,
      border: `${borderWidth[1]} solid ${tableBorder}`,
      fontSize: typography.size.xs
    },
  },
};

export default tableCell;
