import { Alert, Card, Icon, IconButton, Modal, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from 'react';
import UploadStep from "components/DataUpload/components/UploadStep";
import PreviewStep from "components/DataUpload/components/PreviewStep";
import MappingStep from "components/DataUpload/components/MappingStep";
import _ from 'lodash'
import fetchRequest from "utils/fetchRequest";
import { convertPlural } from "utils";

const LoadBURuleModal = (props) => {
  const { yearFilter, monthFilter, uploadType, onClose, onLoadRuleSave, businessUnits, reloadData } = props;
  const [activeStep, setActiveStep] = useState(uploadType ? "upload" : "select-type");

  const handleUploadDialogClose = () => {
    if (reloadData) {
      reloadData();
    }
    if (onClose) onClose();
  }

  const [uploadSubType] = useState(uploadType);
  const [fileName, setFileName] = useState(null);
  const [ws, setWs] = useState(null);
  const [mappedWs, setMappedWs] = useState(null);
  const [sourceFields, setSourceFields] = useState([]);
  const [mappingFields, setMappingFields] = useState([]);
  const [ruleName, setRuleName] = useState(props.ruleName || "");
  const [ruleNameError, setRuleNameError] = useState(false)

  const ruleReadOnly = props.ruleName ? true : false

  const handleOnFileUpload = (_, fileName1, ws1, sourceFields1) => {
    setFileName(fileName1);
    setWs(ws1);
    setSourceFields(sourceFields1);
    setActiveStep("preview");
  }

  const handleOnMappingBack = () => {
    setActiveStep("preview");
  }

  const handleOnMappingNext = (mappingFields1) => {
    setMappingFields(mappingFields1);
    let mappedTable = ws.map((t) => {
      let r = {}
      mappingFields1.map((a) => {
        r[a.destinationColumn] = t[a.sourceColumn]
        return r
      })
      return r
    })
    _.sortBy(mappedTable, Object.keys(mappedTable[0]).slice(0, 1))
    let sortedTable = mappedTable.map((a) => {
      if (a["Business Unit"])
        return { "Business Unit": a["Business Unit"].toString().toLowerCase().trim(), "Usage": a["Usage"], "Asset Type": convertPlural(a["Asset Type"]) }
    })
    // let tot = sortedTable.reduce((tot, currentValue) => tot + currentValue["Usage"], 0)
    const costarr = _(sortedTable)
      .groupBy('Business Unit')
      .map((c, id) => {
        let bu = _.find(businessUnits, function(o) { return (o['code'] || '')?.toLowerCase().trim() === id.toLowerCase().trim()} )
        let cnt = c.filter((obj) => {
          if (obj)
            return obj["Asset Type"] !== undefined && obj["Asset Type"] !== null
        })
        return {
          ID: bu ? bu["code"]+ " | " + bu["id"] : "",
          BusinessUnit: id,
          Usage: _.sumBy(c, 'Usage') ? _.sumBy(c, 'Usage') : 0,
          Status: bu ? "OK" : "Business Unit not found",
          __Split: cnt.length
        }
      }).value();
    setMappedWs(costarr)
    setActiveStep("review");
  }

  const handleNext = async () => {
    switch (activeStep) {
      case "select-type": {
        setActiveStep("upload");
        break;
      }
      case "upload": {
        break;
      }
      case "preview": {
        setActiveStep("mapping");
        break;
      }
      case "mapping": {
        setActiveStep("review");
        break;
      }
      case "review": {
        if (ruleName && ruleName.trim() !== "") {
          if (!ruleReadOnly) {
            const [err, data] = await fetchRequest.get(`/api/dataflow/checkRuleName/${yearFilter}/${monthFilter}/${ruleName}/?businessUnit=${true}`);
            if (err) {
              setRuleNameError(true)
            } else {
              if (data && data.nameCount === 0) {
                onLoadRuleSave(mappedWs.map((a) => {
                  return { "desc": a.BusinessUnit, "usage": a.Usage, name: a.ID, "splitStrategy": (a.__Split > 0 ? "byConsumption" : ""), "destinationTable": (a.__Split > 0 ? "multiple" : "") }
                }), fileName, mappingFields, ws, ruleName)
                handleUploadDialogClose()
              } else {
                setRuleNameError(true)
              }
            }
          } else {
            onLoadRuleSave(mappedWs.map((a) => {
              return { "desc": a.BusinessUnit, "usage": a.Usage, name: a.ID, "splitStrategy": (a.__Split > 0 ? "byConsumption" : ""), "destinationTable": (a.__Split > 0 ? "multiple" : "") }
            }), fileName, mappingFields, ws, ruleName)
            handleUploadDialogClose()
          }
        } else {
          setRuleNameError(true)
        }
        break;
      }
      case "finish": {
        break;
      }

      default:
        break;
    }
  };

  const handleBack = () => {
    switch (activeStep) {
      case "select-type": {
        break;
      }
      case "upload": {
        !uploadType && setActiveStep("select-type");
        break;
      }
      case "preview": {
        setActiveStep("upload");
        break;
      }
      case "mapping": {
        setActiveStep("preview");
        break;
      }
      case "review": {
        setActiveStep("mapping");
        break;
      }
      case "finish": {
        break;
      }

      default:
        break;
    }
  };

  let enableBackBtn = false;
  let enableNextBtn = false;

  switch (activeStep) {
    case "select-type": {
      enableBackBtn = false;
      if (uploadSubType)
        enableNextBtn = true;
      break;
    }
    case "upload": {
      enableBackBtn = uploadType ? false : true;
      enableNextBtn = false;
      break;
    }
    case "preview": {
      enableBackBtn = true;
      if (ws?.length > 0)
        enableNextBtn = true;
      break;
    }
    case "mapping": {
      enableBackBtn = true;
      if (mappingFields?.length > 0)
        enableNextBtn = true;
      break;
    }
    case "review": {
      enableBackBtn = true;
      if (mappedWs?.length === _.filter(mappedWs, { Status: "OK" }).length)
        enableNextBtn = true;
      break;
    }
    case "finish": {

      enableBackBtn = false;
      enableNextBtn = false;
      break;
    }

    default:
      break;
  }
  // ${monthFilterName}, FY ${yearFilterName}

  return (
    <Modal open={true} onClose={handleUploadDialogClose}>
      <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <Card
          sx={{
            overflow: 'hidden',
            width: (activeStep === "preview" || activeStep === "review") ? "calc(100vw - 32px)" : undefined,
            height: (activeStep === "preview" || activeStep === "review") ? "calc(100vh - 32px)" : undefined
          }}
        >
          <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
              <MDTypography variant="h6" component="span" color="text">
                {`Upload consumption file`}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <IconButton onClick={handleUploadDialogClose} title="Close">
                <Icon>close</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
          {
            activeStep === "upload" && (
              <>
              <Alert sx={{fontSize:13}} severity="warning">All existing rules will be deleted.</Alert>
              <UploadStep uploadSubType={uploadSubType} onFileUpload={handleOnFileUpload} />
              </>
            )
          }
          {
            activeStep === "preview" && (
              <PreviewStep uploadSubType={uploadSubType} ws={ws} />
            )
          }
          {
            activeStep === "mapping" && (
              <MappingStep uploadSubType={uploadSubType} sourceFields={sourceFields} onMappingBack={handleOnMappingBack} onMappingNext={handleOnMappingNext} hasMore={true} />
            )
          }
          {
            activeStep === "review" && (
              <>
                <MDBox display="grid" justifyContent="center" alignItems="center" >

                  <TextField focused
                    label="Rule Name" sx={{ width: 300, textAlign: "center" }} id="trPortion" name="trPortion" value={ruleName} disabled={ruleReadOnly}
                    onChange={(e) => { setRuleNameError(false); setRuleName(e.target.value) }} margin="dense" variant="outlined" >
                  </TextField>
                  {ruleNameError && <MDTypography variant="caption" color="error">Please give an unique name to the rule</MDTypography>}
                </MDBox>
                <PreviewStep uploadSubType={uploadSubType} ws={mappedWs} />
              </>
            )
          }

          {
            activeStep !== "mapping" && (
              <MDBox px={2.5} pb={2} pt={1} display="flex" justifyContent="space-between" alignItems="center">
                <MDBox>
                  {
                    enableBackBtn && (
                      <MDButton
                        size="medium"
                        color="info"
                        startIcon={<Icon>arrow_back_ios</Icon>}
                        onClick={handleBack}
                      >
                        Prev
                      </MDButton>
                    )
                  }
                </MDBox>
                {
                  activeStep === "preview" && <MDTypography color="text" variant="subtitle2" fontWeight="medium">Data Preview</MDTypography>
                }
                {
                  activeStep === "review" && <MDTypography color="text" variant="subtitle2" fontWeight="medium">Rules Preview</MDTypography>
                }
                <MDBox>
                  { activeStep !== "review" && enableNextBtn && (
                      <MDButton
                        size="medium"
                        color="info"
                        endIcon={<Icon>arrow_forward_ios</Icon>}
                        onClick={handleNext}
                      >
                        {"Next"}
                      </MDButton>
                    )
                  }
                   { activeStep === "review" && (
                      <MDButton
                        size="medium"
                        color="info"
                        endIcon={<Icon>arrow_forward_ios</Icon>}
                        onClick={handleNext}
                        disabled = {!enableNextBtn}
                      >
                        {"Finish"}
                      </MDButton>
                    )
                  }
                </MDBox>
              </MDBox>
            )
          }
        </Card>
      </MDBox>
    </Modal>
  )
};

export default LoadBURuleModal;