import AnimatedRoute from "components/AnimatedRoute";
import PageHeader from "components/PageHeader";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDInput from 'components/MDInput';
// import MapRule from "./components/index";
import useHandleError from "hooks/useHandleError";
import colors from "assets/theme/base/colors";
import useFetchRequest from "hooks/useFetchRequest";
import { Autocomplete, Icon } from '@mui/material';
// import { useAppController } from 'context';
import YASkeleton from "components/YASkeleton";
import Spend from "pages/Designer/components/Spend";


const Benchmark = () => {

  const [refresh, setRefresh] = useState(null);
  const [years, setYears] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);
  const [yearFilterName, setYearFilterName] = useState(null);
  const handleError = useHandleError();

  const { response: levelsRes, error: levelsErr, loading: levelsLoading } = useFetchRequest(`/api/dataflow/categories`);
//   const [controller] = useAppController();
//   const { userInfo } = controller;
  
  useEffect(() => {
    if (!levelsLoading) {
        if (levelsErr !== null) {
            handleError(levelsErr);
        }
        else if (levelsRes !== null) {
            let currentYearIndex = levelsRes.years?.length - 1;
            const currentFinancialYear = levelsRes.currentFinancialYear;
            if (currentFinancialYear) {
                const index = levelsRes.years?.map(y => y.name).indexOf(currentFinancialYear.value);
                if (index > -1)
                    currentYearIndex = index;
            }
            setYears(levelsRes.years)
            setYearFilter(levelsRes.years[currentYearIndex]?.id)
            setYearFilterName(levelsRes.years[currentYearIndex]?.name);
            // setApprover(["chargebackApprover","admin"].some(role => userInfo.role.includes(role)))
        }
    }
    }, [levelsLoading, levelsRes, refresh]);

  const chipStyles = ({ palette: { white } }) => ({
    cursor: 'pointer',
    border:  'none',
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'row',
    minWidth: 0 ,
    pb: 1,
    pl:  0.5,
    margin: '.75 0', 
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    fontSize: '15px',
    backgroundColor: white,
    height: 32,
    position: 'relative', 
    "& .MuiOutlinedInput-notchedOutline" :  {border: 'none'},
    
  
    "& .MuiTypography-root, & .MuiIcon-root":  { color: '#435cc8!important' } ,
  })

  const tabStyles = (_theme, { selected }) => ({
    color: selected ? "#435EC3" : "#adadad",
    textTransform: "none",
    backgroundColor: "#F7F8FD",
    "& .MuiButtonBase-root": {
        fontSize: "18px!important",
        transform: "none",
        backgroundColor: "#435EC3",
        
    },
    "&::after": selected ? {
        content: '""',
        position: "absolute",
        bottom: 0,
        height: 4,
        width: "60%",
        borderRadius: "0px",
        backgroundColor: "#435EC3"
    } : {}
  });

  const renderYearFilter = () => (
    <MDBox data-testid = {"yearfilter"} display="flex" color='#435cc8!important' alignItems="center" pb={2}>
        <Icon fontSize="small">calendar_today_two_tone</Icon>
        <Autocomplete
            disableClearable={true}
            value={yearFilter}
            options={years}
            onChange={(event, newValue) => {
                setYearFilter(newValue.id)
                setYearFilterName(newValue.name)
            }}
            color="text"
            fontWeight="medium"
            sx={{
                mb: .7,
                // border:  '1px solid #ffffff',
                "& .MuiOutlinedInput-root": {
                    height: 40,
                    minWidth: 135,
                    // boxShadow: "0 8px 16px #1a488e1f"

                },
                "& .MuiAutocomplete-endAdornment": {
                    top: '12px'
                },
                "& .MuiOutlinedInput-input": {
                    fontSize: 14,
                    fontWeight: 600
                },
                "& .MuiOutlinedInput-input, .MuiAutocomplete-input": {
                    // padding: .5, 
                }
            }}
            isOptionEqualToValue={(option, value) => {
                return option.id === value
            }}
            getOptionLabel={option => {
                if (typeof option === "number")
                    return years.find(op => op.id === option)?.name;
                return option.name
            }}
            
            renderInput={(params) => <MDInput  sx={(theme) => chipStyles(theme)} label="" {...params} />}
        />
    </MDBox>
  )

  if (levelsLoading || !yearFilter) return <YASkeleton variant="loading" />

  return (
    <MDBox bgColor={colors.dashboardBackground} minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0, md: 6, sm: 6, xs: 6 }}>
      <PageHeader headerShadow={true} title={"Benchmark"} subtitle={`Create Benchmark`} primaryActionComponent={renderYearFilter}/>
        <Spend typeFilter={"Benchmark"} yearFilter={yearFilter} uploadType="benchmark" yearFilterName={yearFilterName} tabStyles={tabStyles} refresh={refresh} setRefresh={setRefresh} chargeBackMonthly={""}/>
    </MDBox>
  )
}

export default AnimatedRoute(Benchmark)