import { useCallback } from "react";
import { ClickAwayListener, Icon } from "@mui/material";
import { useNotificationsContext } from "context/NotificationsContext";
import MDTypography from "components/MDTypography";
import EmptyState from "components/EmptyState";
import YAScrollbar from "components/YAScrollbar";

import MDBox from "components/MDBox";
import { NotificationMessageCard } from "components/NotificationMessage";

const NotificationsDrawer = props => {
    const { notifications, removeNotification } = useNotificationsContext();

    const handleClose = useCallback(() => {
        if (props.onClose)
            props.onClose();
    }, []);

    const handleRemoveNotification = useCallback((id) => {
            removeNotification(id);
    }, []);

    return <ClickAwayListener onClickAway={handleClose}>
        <MDBox height="90vh">
            <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={4}
                pb={0.5}
                pl={3}
                pr={2}
            >
                <MDBox>
                    <MDTypography variant="h5">Notifications</MDTypography>
                </MDBox>
                <Icon
                    sx={({ typography: { size }, palette: { dark } }) => ({
                        fontSize: `${size.lg} !important`,
                        color: dark.main,
                        stroke: "currentColor",
                        strokeWidth: "2px",
                        cursor: "pointer",
                        transform: "translateY(-15px)",
                    })}
                    onClick={handleClose}
                >
                    close
                </Icon>
            </MDBox>
            {
                ((notifications || []).length === 0) && (
                    <MDBox flex={1} display="flex" alignItems="center" justifyContent="center" mt={8}>
                        <EmptyState
                            size="medium"
                            iconName="notifications"
                            description={"No Notifications Found"}
                            variant="text"
                        />
                    </MDBox>
                )
            }
            {
                ((notifications || []).length > 0) && (
                    <YAScrollbar>
                        <MDBox p={2}>
                            {
                                notifications?.map((notification) => {
                                    return <NotificationMessageCard key={notification.id} {...notification} onDismiss={handleRemoveNotification} onDrawerClose={handleClose} />
                                })
                            }
                        </MDBox>
                    </YAScrollbar>
                )
            }
        </MDBox>
    </ClickAwayListener>
}

export default NotificationsDrawer;