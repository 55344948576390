// import MDBox from "components/MDBox";
import StatisticsCard from "components/StatisticsCard";
import numeral from "numeral";
import { formatMetricAmount, formatMetricDeltaAmount } from "utils/charts";

const StatisticsRenderer = ({ resultSet, vizOptions }) => {
    const { values, axis, legend, config } = vizOptions;
    const data = resultSet?.tablePivot();

    let configValue = config || {};

    const textColorVal = configValue["text_color"] || "#333333";
    const headerTitleVal = configValue["header_title"];
    const valueFontsizeVal = configValue["metric_fontsize"];
    const footerLabelVal = configValue["footer_label"];
    const flexDirectionVal = configValue["flex_direction"];
    const horizAlignVal = configValue["horiz_align"];
    const vertAlignVal = configValue["vert_align"];
    const valueLabelVal = configValue["value_label"];
    const showDecimalPoints = Boolean(configValue["show_decimalpoints"]);
    const hideHeaderTitleVal = Boolean(configValue["hide_header_title"]);
    const showAbsValueVal = Boolean(configValue["show_abs_value"]);
    const showDeltaIndicatorVal = Boolean(configValue["show_delta_indicator"]);
    const negateColorLogicVal = Boolean(config["negate_color_logic"]);

    let title = null;
    let value = "";
    let deltaValueVal = null;
    let deltaValueDirectionUpVal = false;
    let deltaPercentVal = null;

    if (values?.length > 0 && data?.length > 0) {
        value = data[0][values[0].name] || 0;
        if (values[0].dataType === "percent")
            value = showAbsValueVal ? numeral(value).format("0.00%") : numeral(value).format("0%");
        else if (["number", "decimal"].includes(values[0].dataType)) {
            const numberFormat = values[0].decimalPoints > 0 ? `0,0.${Array(values[0].decimalPoints).fill('0').join('')}` : '0,0';
            value = isNaN(value) ? '0' : numeral(value).format(showDecimalPoints ? numberFormat : "0,0");
        }
        else {
            const currencyFormat = values[0].decimalPoints > 0 ? `$0,0.${Array(values[0].decimalPoints).fill('0').join('')}` : '$0,0';
            value = showAbsValueVal ? numeral(value).format(showDecimalPoints ? currencyFormat : "$0,0") : formatMetricAmount(value, showDecimalPoints);
        }
        title = values[0].title;
    }
    if (axis?.length > 0 && data?.length > 0) {
        deltaValueVal = data[0][axis[0].name] || 0;
        deltaValueDirectionUpVal = deltaValueVal > 0;
        if (axis[0].dataType === "percent")
            deltaValueVal = numeral(deltaValueVal).format("0.00%");
        else if (["number", "decimal"].includes(axis[0].dataType)) {
            deltaValueVal = isNaN(deltaValueVal) ? '0' : String(deltaValueVal || '0');
        }
        else
            deltaValueVal = formatMetricDeltaAmount(deltaValueVal);
    }
    if (legend?.length > 0 && data?.length > 0) {
        deltaPercentVal = data[0][legend[0].name] || 0;
        deltaValueDirectionUpVal = deltaPercentVal > 0;
        deltaPercentVal = numeral(deltaPercentVal).format("0.0%");
    }

    const color = !negateColorLogicVal ?
        (deltaValueDirectionUpVal ? "success" : "error")
        :
        (deltaValueDirectionUpVal ? "error" : "success");

    return (
        <StatisticsCard
            title={!hideHeaderTitleVal ? (headerTitleVal || title) : null}
            value={value}
            valueLabel={valueLabelVal}
            valueFontsize={valueFontsizeVal}
            textColor={textColorVal}
            flexDirection={flexDirectionVal}
            align={horizAlignVal}
            verticalAlign={vertAlignVal}
            deltaValue={deltaValueVal}
            deltaPercentValue={deltaPercentVal}
            showDeltaIndicator={showDeltaIndicatorVal}
            percentage={{
                color: color,
                amount: deltaValueVal || deltaPercentVal ? `${(deltaValueVal || "")}${deltaPercentVal ? ` (${deltaPercentVal})` : ""}` : null,
                deltaDirectionUp: deltaValueDirectionUpVal,
                label: footerLabelVal,
            }}
        />
    );
}

export default StatisticsRenderer