const formHelpText = {
  styleOverrides: {
    root: {
      marginLeft: 0, 
      marginRight: 0
    },
  },
};

export default formHelpText;
