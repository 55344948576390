import { Icon, Menu, MenuItem } from "@mui/material";
import Breadcrumbs from "components/Breadcrumbs"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography"
import YASkeleton from "components/YASkeleton";
// import { openContextHelp } from "context";
// import { useAppController } from "context";
import { useState } from "react";
// import { getPageName } from 'utils';

const PageHeader1 = ({ title, subtitle, message, hideBreadcrumbs, headerComponent, primaryActionComponent, secondaryActions, loading }) => {

  const [openMenu, setOpenMenu] = useState(false);
  const secondaryActionsArr = (typeof secondaryActions === "function" ? secondaryActions() : secondaryActions) || [];

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
    // showCustomDrawer('', () => <UserInfoDrawer />);
  };
  const handleCloseMenu = () => setOpenMenu(false);

  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a.onClick)
      a.onClick();
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      {
        secondaryActionsArr?.map(a => (
          <MenuItem key={a.label} onClick={() => handleCloseMenuItem(a)}>{a.label}</MenuItem>
        ))
      }
    </Menu>
  );

  // const pageName = getPageName();

  return (
    <MDBox pt={3} pb={0} px={3}>
      {
        !hideBreadcrumbs && (
          <MDBox color="inherit" pb={.75} >
            <Breadcrumbs />
          </MDBox>
        )
      }
      <MDBox display="flex" justifyContent="space-between">
        {
          !headerComponent && (
            <MDBox display="flex" flexDirection="column" flex={1}>
              {/* <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{loading ? <YASkeleton variant="title" /> : title}</MDTypography> */}
              <MDTypography
                display="flex"
                alignItems="center"
                component="span"
                fontWeight={"medium"}
                lineHeight={1.2}
                sx={{ "&:hover": { "& .helpIcon": { visibility: 'visible' } } }}
              >
                {loading && <YASkeleton variant="title" />}
                {!loading &&
                  <>
                    {title}
                    {/* {
                      // (helpCenterUrl || "") !== "" && (pageHelpContextKey || "") !== "" &&
                      (helpCenterUrl || "") !== "" && (pageName || "") !== "" &&
                      <Tooltip
                        placement="right"
                        title="Click to learn more"
                      >
                        <IconButton className="helpIcon"
                          sx={({ palette: { text } }) => ({
                            marginLeft: .15,
                            color: "#979191",
                            visibility: 'hidden',
                            "&:hover": {
                              color: text.main
                            }
                          })}
                          size="small"
                          onClick={() => {
                            openContextHelp(dispatch, pageName);
                          }}
                        >
                          <Icon>help</Icon>
                        </IconButton>

                      </Tooltip>
                    } */}
                  </>
                }
              </MDTypography>
              {subtitle && <MDTypography component="span" variant="subtitle2">{subtitle}</MDTypography>}
              {message && <MDTypography component="span" color='error' variant="button">{message}</MDTypography>}
              {/* {message && <MDButton color='info'>{message}</MDButton>} */}
            </MDBox>
          )
        }
        {
          headerComponent && (
            <MDBox sx={{ flex: 1 }}>{headerComponent()}</MDBox>
          )
        }
        {
          primaryActionComponent && (
            <MDBox mt={-1.5} mr={1.5}>{primaryActionComponent()}</MDBox>
          )
        }
        {
          secondaryActionsArr && secondaryActionsArr.length > 0 && (
            <MDBox mt={-1.5}>
              <MDButton
                // size="medium"
                // disableRipple
                // color="light"
                // variant="outlined"
                onClick={handleOpenMenu}
                sx={{ "& .MuiIcon-root": { fontSize: "22px!important" } }}
                iconOnly
              >
                <Icon>more_horiz</Icon>
              </MDButton>
            </MDBox>
          )
        }
        {renderMenu()}
      </MDBox>
    </MDBox>
  )
}

PageHeader1.defaultProps = {
  loading: false,
  hideBreadcrumbs: false,
  usePageTitleForBreadcrumb: false
};

export default PageHeader1