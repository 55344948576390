import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import Textbox from "components/YAForm/components/Textbox";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import { useYADialog } from "components/YADialog";
import fetchRequest from "utils/fetchRequest";
// import Switch from "components/YAForm/components/Switch";
import YASkeleton from "components/YASkeleton";
import MDTypography from "components/MDTypography";
import Dropdown from "components/YAForm/components/Dropdown";
// import { parseJsonString } from "utils";
// import { parseCronExpression } from "utils";

const InterfaceForm = (props) => {
    const { interfaceId, mode, onClose, dataStoreMapping, handleMappingNext } = props;
    const [loading, setLoading] = useState(false);
    const { showSnackbar, showAlert } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);

    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    // const watchedFields = watch(["cloudSourceId"]);
    // const watchedFieldsObj = useMemo(() => {
    //     return {
    //         cloudSourceId: watchedFields[0]
    //     };
    // }, watchedFields)

    let cronExpList = [
        // {"id": "* * * ? * *", "value": "* * * ? * *", "label" : "Every Second"},
        // {"id": "0 * * ? * *", "value": "0 * * ? * *", "label" : "Every Minute"},
        // {"value": 2, "exp": "0 0/1 * ? * *"},
        {"id": "0 0 * ? * *", "value": "0 0 * ? * *", "label" : "Every Hour"},
        {"id": "0 0 0 * * ?", "value": "0 0 0 * * ?", "label" : "Every Day At 12 AM"},
        {"id": "0 0 0 12 * ?", "value": "0 0 0 12 * ?", "label" : "Every Day At 12 PM"},
        {"id": "0 0 0 ? * 0", "value": "0 0 0 ? * 0", "label" : "On Sunday At 12 AM"},
        {"id": "0 0 12 ? * 0", "value": "0 0 12 ? * 0", "label" : "On Sunday At 12 PM"},
        {"id": "0 0 1 * *", "value": "0 0 1 * *", "label" : "First Day of Month At 12 AM"},
        {"id": "0 12 1 * *", "value": "0 12 1 * *", "label" : "First Day of Month At 12 PM"},
        {"id": "0 0 0 1 1 * *", "value": "0 0 0 1 1 * *", "label" : "First Day of Jan At 12 AM"},
        {"id": "0 0 0 1 1 * *", "value": "0 0 12 1 1 * *", "label" : "First Day of Jan At 12 PM"},
    ]
 
    // cronExpList.map((item) => {
            // item["label"] = parseCronExpression(item.exp)
    // })

    async function getFormData() {
        setLoading(true);
        const [error, data] = await fetchRequest.get(`/api/interface/${interfaceId}`);
        if (error)
            console.error(error);
        setValue("name", data["name"]);
        setValue("dataStoreMappingId", data["dataStoreMappingId"]);
        setValue("triggerCronExpression", data["triggerCronExpression"]);
        setValue("s3BucketId", data["s3BucketId"]);
        setValue("folder", data["folder"]);
        setValue("active", data["active"] || false);
        setLoading(false);
    }

    async function getdataStoreMapping() {
        setLoading(true);
        const [error, data] = await fetchRequest.get(`/api/interface/lookup/source`);
        if (error)
            console.error(error);
        setValue("dataStoreMappingId", data.find(i => i.label == `${ dataStoreMapping?.Name } (${ dataStoreMapping?.DestinationTable })`)?.value);
        setValue("name", dataStoreMapping?.Name)
        setValue("triggerCronExpression", cronExpList[2].value)
        setValue("folder", dataStoreMapping?.Name?.replaceAll(" ", "").toLowerCase())
        setValue("active", true);
        setLoading(false);
    }
    // async function getDefaultValue() {
    //     const [error, data] = await fetchRequest.get(`/api/interface/lookup/s3bucket`);
    //     if (error)
    //         console.error(error);
    //     setValue("s3BucketId",data[0].value)
    // }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            getdataStoreMapping()
        }
        // getDefaultValue()
    }, [mode]);

    const handleClose = useCallback((data) => {
        if (onClose) onClose(data);
    }, []);

    const onSubmit = async formdata => {
        Object.keys(formdata).forEach((key)=> formdata[key] = typeof formdata[key] === 'string' ? formdata[key].trim() : formdata[key])
        const url = mode === "edit" ?
            `/api/interface/${interfaceId}`
            : `/api/interface/new`;

        const [error, data] = await fetchRequest.post(url, JSON.stringify(formdata));
        if (!error) {
            if (data && data.result === false) {
                if (Array.isArray(data.errors) && data.errors.length > 0) {
                    data.errors.forEach((e) => {
                        setError(e.field, { type: "manual", message: e.message });
                    });
                }
            }
            else {
                handleClose({ notificationChannelId: data.notificationChannelId });
                onDialogClose();
                showSnackbar(data.message, "success");
                if (handleMappingNext)
                    handleMappingNext()
            }
        }
        else {
            showAlert("Create Integrations", error?.data?.message || "Something went wrong. Contact your administrator.");
            console.error(error);
        }
    };

    if (loading) {
        return (
            <MDBox pt={1} px={3} pb={2} minWidth={400}>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
                <MDBox>
                    <YASkeleton variant="filter-item" />
                </MDBox>
            </MDBox>
        )
    }

    return (
        <MDBox pt={1} px={3} pb={2} minWidth={400}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <MDBox mb={2}>
                    <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The name of the Integration.", impact: "", severity: "", name: "name", displayName: "Integration Name", required: true }} errorMessage={errors["name"] && errors["name"].message} />
                </MDBox>
                <MDBox mb={2}>
                    <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "This template used to auto-map fields. Choose the mapping template based on the file upload type, such as master, consumptions or assets files.", impact: "", severity: "", name: "dataStoreMappingId", displayName: "Data Mapping Template", required: true, dataSource: { type: "custom", url: "/api/interface/lookup/source" } }} errorMessage={errors["dataStoreMappingId"] && errors["dataStoreMappingId"].message} />
                </MDBox>
                <MDBox mb={2}>
                    <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "Used to specify the time interval for executing a file upload from the cloud object storage.", impact: "", severity: "", name: "triggerCronExpression", displayName: "Run Schedule", required: true, dataSource: { type: "static", data: cronExpList } }} errorMessage={errors["triggerCronExpression"] && errors["triggerCronExpression"].message} />
                </MDBox>
                <MDBox mt={6} mb={2}>
                    <MDTypography variant="button" fontWeight="medium">Configure location</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                    <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The Container or Bucket name within Azure Blob or AWS S3 cloud storage.", impact: "", severity: "", name: "s3BucketId", displayName: "Storage Connector", required: true, dataSource: { type: "custom", url: "/api/interface/lookup/s3bucket" } }} errorMessage={errors["s3BucketId"] && errors["s3BucketId"].message} />
                </MDBox>
                <MDBox mb={2}>
                    <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ unique: "", toolTip: "The folder name in cloud object storage from which you want to retrieve files.", impact: "", severity: "Low", name: "folder", required: true , displayName: "Folder" }} errorMessage={errors["folder"] && errors["folder"].message} />
                </MDBox>
                <MDBox mt={4} mb={1} textAlign="right">
                    <MDButton type="submit" disabled={isSubmitting} variant="gradient" color="info" startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                        Save
                    </MDButton>
                </MDBox>
            </form>
        </MDBox>
    );
};

export default InterfaceForm;