import ChartRenderer from "components/ChartRenderer";
import { useEffect, useState } from "react";
import { getDomain, setSelectedFilter2 } from 'utils';
// import { applyDefaultFilter1 } from 'utils';
// import { deleteSelectedFilter1, setSelectedFilter1 } from 'utils';
import Axios from "axios";
// import FilterContainer from "components/FilterContainer";
import { useImmer } from "use-immer";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import MDBox from "components/MDBox";
import YASkeleton from "components/YASkeleton";
import { useAppController } from "context";
import fetchRequest from "utils/fetchRequest";
import colors from "assets/theme/base/colors";
import { ResponsiveDashboardContainer1 } from "components/ResponsiveDashboardContainer";
import AppSettings from "pages/AppSettings";
import { Navigate } from "react-router-dom";
import useHandleError from "hooks/useHandleError";

const Home = () => {
  const [ controller ] = useAppController();
  if (controller.userInfo.role?.[0] === "userAdmin")
    return <AppSettings/>
  else {
      const isHomeDisabled = controller.appDef?.menu?.find(m => m.title === "Home")?.disabled ? true : false;
      if (isHomeDisabled)
        return <Navigate to={"/upgrade-license"} />
      else
        return <HomePage />
    }

}
const HomePage = () => {
  const dashboardId = "home";
  const [loading, setLoading] = useState(true);
  const [dashboardDef, setDashboardDef] = useImmer(null);
  // const [filtersCleared, setFiltersCleared] = useState(false)
  const [ controller ] = useAppController();
  const [currentYear, setCurrentYear] = useState([''])
  const [previousMonth, setPreviousMonth] = useState([''])  
  const [currentMonth, setCurrentMonth] = useState([''])
  const handleError = useHandleError();

  useEffect(() => {
    async function getDashboardDef() {
      const domain = getDomain();

      var [err, data] = await fetchRequest.get(`/api/home/currentdetails/`);
      if (err) {
        handleError(err)
      }
      else {
        setCurrentYear([data.currentYear]);
        setCurrentMonth([data.currentMonth]);
        setPreviousMonth([data.previousMonth])
      }    

      const response = await Axios.get(`${domain}/api/dashboard/home?${("nc=" + Math.random()).replace(".", "")}`);
      // setDashboardDef(applyDefaultFilter1(response.data));
      setDashboardDef(response.data);

      setDashboardDef((draft) => {
        setSelectedFilter2(draft,  [data.currentYear], "Year", "Years.year", "equals", false)
      });       
      setDashboardDef((draft) => {
        setSelectedFilter2(draft,  [data.currentMonth], "Month", "Months.month", "equals", false)
      });   
      setDashboardDef((draft) => {
        setSelectedFilter2(draft,  [data.previousMonth], "Month", "Months.month", "equals", true)
      });   

      setLoading(false);
      // setFiltersCleared(false)  
    }

    getDashboardDef();
  }, [dashboardId]);

  // const selectFilter = (selectedFilter) => {
  //   setDashboardDef((draft) => {
  //     let filter = draft.filters?.find(f => f.name === selectedFilter.name);
  //     if (filter)
  //       filter.selected = true;
  //   });
  // }
  // const deleteFilter = (deletedFilter) => {
  //   setDashboardDef((draft) => {
  //     deleteSelectedFilter1(draft, deletedFilter)
  //   });
  // }
  // const setFilter = (selectedFilter) => {
  //   if (selectedFilter.values?.length === 0) {
  //     setDashboardDef((draft) => {
  //       deleteSelectedFilter1(draft, selectedFilter)
  //       let filter = draft.filters?.find(f => f.name === selectedFilter.name);
  //       if (filter)
  //         filter.selected = true;
  //     });
  //   }
  //   else
  //     setDashboardDef((draft) => {
  //       setSelectedFilter1(draft, selectedFilter.values, selectedFilter.name, selectedFilter.dimension, selectedFilter.operator)
  //     });
  // }

  if (loading) {
    return <YASkeleton variant="dashboard-loading" />;
  }

  if (loading === false && dashboardDef === null) {
    return (
      <div>
        no data
      </div>
    );
  }
let addCubeOptionsFilters = (item)=>{
  let cubeOptions
  if (item.cubeOptions) {
    cubeOptions = { ...item.cubeOptions };
    let cubeFilters = [];
    let yearFilterObj = {
      "type": "DIMENSION",
      "name": "Years.year",
      "title": "Year",
      "dataType": "string",
      "operator": "equals",
      "values": currentYear
    };
    cubeFilters.push(yearFilterObj);
    if (item.vizOptions.currentMonthFilter) {
      let monthFilterObj = {
        "type": "DIMENSION",
        "name": 'Months.month',
        "title": "Month",
        "dataType": "string",
        "operator": "equals",
        "values": currentMonth
      };
      cubeFilters.push(monthFilterObj);
    }
    cubeOptions.vizData = { ...cubeOptions.vizData, filters: cubeFilters };
  }
  return cubeOptions
}
  const dashboardItem = (item) => {
    let cubeOptions = addCubeOptionsFilters(item)
    return (
      <div key={item.id}>
        <ChartRenderer title={item.name} subtitle={item.desc} vizState={item.vizState} vizOptions={item.vizOptions} previousMonth={previousMonth} currentMonth={currentMonth} cubeOptions={cubeOptions} />
      </div>
    )
  };

  const { desc, layouts, widgets, headerShadow } = dashboardDef;
  return (
    <MDBox bgColor={colors.dashboardBackground} minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0,  md: 6, sm:6, xs: 6 }}>
      <PageHeader title={controller.userInfo?.tenantName  + ' ' + currentMonth + ' ' + currentYear} subtitle={desc} hideBreadcrumbs headerShadow={ widgets ? true : headerShadow ? headerShadow :false} />
      {/* <FilterContainer dashboardDef={dashboardDef} selectFilter={selectFilter} setFilter={setFilter} deleteFilter={deleteFilter} clearFilters={() => setFiltersCleared(true)} /> */}
      <MDBox px={1} pb={2}>
        <ResponsiveDashboardContainer1 dashboardLayouts={layouts}>
          {widgets.map(dashboardItem)}
        </ResponsiveDashboardContainer1>
      </MDBox>
    </MDBox>
  );
};

export default AnimatedRoute(Home);