
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// import YASkeleton from "components/YASkeleton";
import useFetchRequest from "hooks/useFetchRequest";
import { Autocomplete, Icon } from "@mui/material";
// import useLocalStorage from "hooks/useLocalStorage";
import MDButton from "components/MDButton";

const SelectVendorStep = (props) => {

    const { vendor, onVendorChange, handleNext, handleBack } = props;
    const { response: vendors, error: dataErr } = useFetchRequest(`/api/businessCases/lookup/vendor`);

    if (dataErr)
        console.error(dataErr)

    let vendorOptions = [];
    if (vendors) {
        vendors.map((vendor) => {
            vendorOptions.push({ name: vendor.value, displayName: vendor.label })
        })
    }

    const vendorSelectStyles = () => ({
        width: 300,
        mt: 3,
        "& .MuiOutlinedInput-root": {
            boxShadow: "0 8px 16px #1a488e1f"
        },
        "& .MuiInputBase-input": {
            textAlign: "center"
        }
    });



    return (
        <>
            {
                <MDBox height="400px" width="600px" display="flex" alignItems="center" justifyContent="center" >
                    <MDBox display="flex" flexDirection="column" alignItems="center" mt={-4}>
                        <MDTypography variant="subtitle1" fontWeight="light" color="text" component="span" >
                            {`Choose a vendor`}
                        </MDTypography>

                        <Autocomplete
                            disableClearable={true}
                            value={vendor}
                            options={vendorOptions}
                            groupBy={(option) => option.category}
                            isOptionEqualToValue={(option, value) => {
                                return option.name === value
                            }}
                            getOptionLabel={option => {
                                if (typeof option !== "object")
                                    return vendorOptions.find(op => op.name === option)?.displayName;
                                return option.displayName
                            }}
                            onChange={(_event, newValue) => {
                                if (onVendorChange)
                                    onVendorChange(newValue.name);
                            }}
                            sx={() => vendorSelectStyles()}
                            renderInput={(params) => <MDInput placeholder="Choose Vendor" {...params} sx={{ textAlign: "center" }} />}
                        />
                    </MDBox>
                </MDBox>

            }
            <MDBox px={2.5} pb={0} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDBox>
                    <MDButton
                        size="medium"
                        color="info"
                        startIcon={<Icon>arrow_back_ios</Icon>}
                        onClick={handleBack}
                    >
                        Prev
                    </MDButton>
                </MDBox>
                {vendor &&
                    <MDBox>
                        <MDButton
                            size="medium"
                            color="info"
                            endIcon={<Icon>arrow_forward_ios</Icon>}
                            onClick={handleNext}
                        >
                            Finish
                        </MDButton>
                    </MDBox>

                }
            </MDBox>
        </>
    )
};

export default SelectVendorStep;