import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import { getDomain } from "utils";
import MDBox from "components/MDBox";
import { Icon, IconButton, Tooltip, Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import FieldDetails from "components/YAForm/components/FieldDetails"

const DynamicDropdown = (props) => {
    const { watch, setValue, control, disabled, formId, fieldDef: { name, displayName, required, variant, width, placeholder, dataSource, toolTip, impact, type, unique, severity }, errorMessage, mode } = props;
    const [options, setOptions] = useState(dataSource.type === "static" ? dataSource.data : []);
    const [loading, setLoading] = useState(dataSource.type !== "static");
    const [open, setOpen] = useState(false);

    const watchAllFields = watch(dataSource.parentFields || []);
    const parentFields = useMemo(() => {
        let parentFieldsObj = {};
      if (dataSource.parentFields) {
            dataSource.parentFields.forEach((f, i) => { parentFieldsObj[f] = watchAllFields[i] });
        }
        return parentFieldsObj;
        }, watchAllFields)
    const filter = createFilterOptions();

    useEffect(() => {
        async function getOptions() {
            setLoading(true);
            const domain = getDomain();
            let get_url = dataSource.type === "custom" ? `${domain}${dataSource.url}` : `${domain}/api/master/${formId}/${name}`
            if (dataSource.parentFields) {
                let hasEmpty = false;
                dataSource.parentFields.forEach((f) => {
                    if (!parentFields[f] && !hasEmpty) {
                        hasEmpty = true;
                    }
                    get_url = get_url.replace(new RegExp(`:${f}`, 'g'), parentFields[f] || 0)
                });

                if (hasEmpty) {
                    setOptions([]);
                } else {
                    let q = ("nc=" + Math.random()).replace(".", "");
                    get_url += get_url.indexOf("?") > 0 ? `&${q}` : `?${q}`
                    const response = await Axios.get(get_url);
                    if(dataSource.assetCode) {
                        const modRes = response.data.values.map((a) => {
                            let aObj = {}
                            aObj.value = a.value
                            aObj.label = a.label
                            return aObj
                        })
                        setOptions(modRes);
                    }else {
                        setOptions(response.data);
                    }
                }
            }
            else {
                let q = ("nc=" + Math.random()).replace(".", "");
                get_url += get_url.indexOf("?") > 0 ? `&${q}` : `?${q}`
                const response = await Axios.get(get_url);
                const modRes = response.data.map((a) => {
                    if(!a.value) {
                        a.value = a.displayName
                        a.label = a.displayName
                    }
                    return a
                })
                setOptions(modRes);
            }
            setLoading(false);
        }
        if (dataSource.type !== "static")
            getOptions();
    }, [parentFields]);
   
    if (loading) return <YASkeleton variant="dropdown" />
    
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return <Autocomplete
                    disableClearable={required}
                    onChange={(event, item) => {
                         onChange(item?.value || item || null);
                        if (dataSource.dependentFields)
                            dataSource.dependentFields.forEach((f) => {
                                setValue(f, null)
                              });
                    }}
                    disabled={loading}
                    options={options}
                    value={options.filter(a => a.value === value).length > 0 ? options.filter(a => a.value === value)[0].label : mode === "edit" ? value : value }
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.map(a => a?.label)[0] ? options.map(a => a?.label).some((option) => inputValue === option?.label) : false;
                        if (inputValue !== '' && !isExisting) {
                          filtered.push(inputValue);
                        }
                        return filtered;
                    }}
                    renderOption={(props, option, { selected }) => {
                        const isExisting = options.map(a => a?.label).some((o) => o === option?.label);
                        return (
                        <li {...props}>
                          {(isExisting || selected) ? option?.label : `Add "${option}"`}
                        </li>
                        )
                      }}
                    renderInput={params => 
                        <>
                        <MDBox sx={ {"&:hover": { "& .helpIcon" : { visibility: 'visible' } }} } display='flex' flexDirection='row'>
                        <TextField {...params}
                            name={name}
                            required={required}
                            disabled={disabled}
                            error={errorMessage && true}
                            helperText={errorMessage}
                            label={displayName}
                            placeholder={placeholder}
                            variant={variant || "standard"}
                            sx={width ? { width: width } : undefined}
                            fullWidth={width ? false : true}
                        />
                        {toolTip?.length >= 0 && 
                            <IconButton className="helpIcon"
                                sx={({ palette: { text } }) => ({
                                // marginLeft: .15,
                                // marginBottom: 1,
                                marginRight: -2,
                                color: "#979191",
                                visibility: 'hidden',
                                "&:hover": {
                                    color: text.main
                                }
                                })}
                                size="small"
                                onClick={()=> {setOpen(true)}}
                            //   onClick={() => {
                            //     // showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, pageName) :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp') :  window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                            //     window.open(helpCenterUrl+'/'+pageName,'yarkenhelp');
                            //   }}
                            >
                                <Tooltip placement="left" title={toolTip ? toolTip : displayName}>
                                    <Icon>help</Icon>
                                </Tooltip>
                            </IconButton>
                        }
                        {
                         open && (<FieldDetails impact = {impact} unique = {unique} type = {type} setOpen= {setOpen} toolTip= {toolTip} displayName= {displayName} required= {required} severity= {severity}/>)
                        }
                        </MDBox>
                        </>
                    }
                />
            }
            }
        />
    )
}

export default DynamicDropdown;