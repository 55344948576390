import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";

const { text } = colors;
const { size } = typography;

const formControlLabel = {
  styleOverrides: {
    root: {
      // display: "block",
      // minHeight: pxToRem(24),
      // marginBottom: pxToRem(2),
      marginLeft: 0,
    },

    label: {
      // display: "inline-block",
      fontSize: size.sm,
      // fontWeight: fontWeightBold,
      color: text.main,
      lineHeight: 1,
      // transform: `translateY(${pxToRem(1)})`,
      marginLeft: 0,
      marginRight: pxToRem(8),

      "&.Mui-disabled": {
        color: text.main,
      },
    },
  },
};

export default formControlLabel;
