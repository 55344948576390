import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import moment from "moment";
import YASkeleton from "components/YASkeleton";
import MDTypography from "components/MDTypography";
import Tooltip from '@mui/material/Tooltip';
import numeral from "numeral";
import { normalizeCurrency } from "utils/table";

const HeaderListRenderer = ({ loading, vizOptions, resultSet }) => {

    if (loading)
        return <YASkeleton variant="dashboard-item" />
    var data = resultSet?.tablePivot()[0]

    let resultData = []
    data ? vizOptions.banner?.map((item, id) => {
        if (item?.type === 'date')
            var date = moment(data[item?.value]).format("MMM DD YYYY")
        let obj = {
            "value": date ? date : (vizOptions.format === "currency" ? numeral(normalizeCurrency(data[item?.value])).format('$0,0') : (vizOptions.format === "number" ?  numeral(normalizeCurrency(data[item?.value])).format('0,0') : data[item?.value])), "label": item.renderLabel ?  data[item?.label] || 'unknown' : item?.label, "id": id
        }
        resultData.push(obj)
    }) : 
    vizOptions.banner?.map((item, id) => {
        let obj = {
            "value": "no data", "label": item.renderLabel ? (data ? data[item?.label] : 'no data') :  item?.label, "id": id
        }
        resultData.push(obj)
    }) 
  
    return (
        <>
            <Card sx={{ width: "100%", border: "none", boxShadow: "0px 2px 8px rgba(48, 53, 109, 0.1);", borderRadius: "12px", paddingLeft: "10px", paddingRight: "13px" }}>
                <MDBox display="flex" justifyContent="space-around">
                    {resultData?.map((item, index) => (
                        <MDBox key={item.id} display="flex" flexDirection="column" marginRight='8px' marginLeft='8px' paddingTop='7px' paddingRight = '7px' sx={{
                            borderRight: index < resultData.length - 1 ? '0.1px solid #ddd' : 'none', width: `${100 / resultData.length}%`, textAlign: 'left'
                        }}>
                            <MDTypography component="span" variant="subtitle1" padding='1px' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} data-testid = {(item.renderLabel ?  data[item?.label] || 'unknown' : item?.label || 'unknown').toLowerCase().replaceAll(' ', '')} >
                                {item.label}
                            </MDTypography>
                            { item.value ?<Tooltip title={item.value}>
                                <MDTypography component="span" fontWeight="medium" lineHeight={1.2} paddingBottom='13px' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                    {item.value}
                                </MDTypography>
                            </Tooltip> : <MDBox height={37} ></MDBox>}
                        </MDBox>
                    ))}
                </MDBox>
            </Card>

        </>
    );
}

export default HeaderListRenderer
