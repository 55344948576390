import pxToRem from "assets/theme/functions/pxToRem";
const tableHead = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
      color:'#000000',
      
    },
  },
};

export default tableHead;
