// import _ from "lodash";
import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
import Dropdown from "../Dropdown";
// import DynamicDropdown from "../DynamicDropdown";
import Rule from "../Rule";
// import Integer from "components/YAForm/components/Integer";
import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import fetchRequest from "utils/fetchRequest";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import ToSolutionOfferings from "../ToSolutionOfferings";


const SolutionOfferingRuleForm = (props) => {
    const { pkId, mode, onClose, yearNameId, monthNameId, getTablecol, filterTypeLabels, getFilterDescription  } = props;
    const { showSnackbar, showAlert } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const [fromAsset, setFromAsset] = useState(null)
    const [, setErrors] = useState({});
    const [toAsset, setToAsset] = useState(null)
    const [fromCondition, setFromCondition] = useState(null);
    const [toCondition, setToCondition] = useState(null);

    const [loading, setLoading] = useState(false);
    const [flag,setFlag] = useState(false)
    const domain = getDomain();

    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    async function getNewRule() {
        setLoading(true);
        setValue("fromAsset", null);
        setValue("rule", null);
        setValue("toSolutionOfferingRule", null);
        setLoading(false); 
    }

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/solutionOfferingRules/solutionOfferingRuleItem/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);
        setFromCondition(response.data["rule"])
        // setValue("sequence", response.data["sequence"]);
        setValue("fromAsset", response.data["fromAsset"]);
        setFromAsset(response.data["fromAsset"].toLowerCase().replaceAll(" ",''));
        setValue("rule", response.data["rule"]);
        setValue("toSolutionOfferingRule", response.data["toSolutionOfferingRule"]);
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            getNewRule();
        }
    }, [mode]);

    const onSubmit = async data => {
        let url = mode === "edit" ? `/api/solutionOfferingRules/${pkId}` : `/api/solutionOfferingRules/new/${yearNameId}/${monthNameId}`;
        let formData = { fromAsset: data.fromAsset, rule: fromCondition, toSolutionOfferingRule: toCondition }
        let [err, response] = await fetchRequest.post(url, formData)
        if (err) {
            if (err.data?.message) {
                handleClose();
                onDialogClose();
                showSnackbar(err.data?.message, "error")
            } else {
                handleClose();
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
        }
        else if (!err && response && response.result === false) {
            if (Array.isArray(response.errors) && response.errors.length > 0) {
                response.errors.forEach((e) => {
                    setError(e.field, { type: "manual", message: e.message });
                });
            }
        }
        else {
            handleClose();
            onDialogClose();
            showSnackbar(response.message, "success");
        }
    };

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    if(flag == true){
        setFlag(false)
    }

    const handleClearFromAssetSelection = () => {
        setFromCondition(null);
        setValue("rule", null)
    }

    const handleClearToSolutionOfferingSelection = () => {
        setToCondition(null);
        setValue("toSolutionOfferingRule", null)
    }

    const onFromAssetChange = (value) => {
        setFromAsset(value)
        setFromCondition(null)
    }

    const handleToCondition = (condition) => {
        setToCondition(condition)
    }

    let handleAlert = (e, reverseSet) => {
        if (reverseSet && !e) {
            showAlert("Alert", "This action will result in the revocation of user access to the application");
        }
    }

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={1000}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }

        return (
            <MDBox pt={1} px={2} minWidth={600} overflow="auto">
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox>
                            <MDBox mb={3}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    fromAsset={fromAsset} 
                                    setFromAsset={onFromAssetChange} 
                                    toAsset={toAsset} 
                                    setToAsset={setToAsset} 
                                    control={control} 
                                    fieldDef={{ name: "fromAsset", displayName: "From Asset", "required": true, 
                                        dataSource: { 
                                            type: "static",
                                            data: [
                                                { value: "applications", label: "Applications", name: 'applications' },
                                                { value: "products", label: "Products", name: 'products' },
                                                { value: "services", label: "Services", name: 'services' },
                                            ],
                                            dependentFields: ["rule"],
                                        }
                                    }} 
                                    errorMessage={errors["fromAsset"] && errors["fromAsset"].message} 
                                />
                            </MDBox>
                            <MDBox mb={3}>
                                <Rule
                                    watch={watch}
                                    setValue={setValue}
                                    control={control}
                                    fromAsset={fromAsset}
                                    multipleRules={true}
                                    fieldDef={{
                                        name: "rule",
                                        displayName: "Rule",
                                        disableFilters: true,
                                        required: true,
                                        unique: true,
                                        dataSource: {
                                            type: "custom",
                                            parentFields: ["fromAsset"],
                                            url: "/api/dataflow/resource/:asset"
                                        }
                                    }}
                                    errorMessage={errors["rule"] && errors["rule"].message}
                                    handleAlert={handleAlert}
                                    condition={fromCondition}
                                    onConditionChange={setFromCondition}
                                    onClearAssetSelection={handleClearFromAssetSelection}
                                />
                            </MDBox>
                            <ToSolutionOfferings 
                                watch={watch}
                                setValue={setValue}
                                control={control}
                                fromAsset={fromAsset}
                                handleAlert={handleAlert}
                                getTablecol={getTablecol}
                                filterTypeLabels ={filterTypeLabels}
                                getFilterDescription={getFilterDescription}
                                fieldDef={{
                                    name: "toSolutionOfferingRule",
                                    displayName: "To Solution Offering Rule",
                                    disableFilters: true,
                                    required: true,
                                    unique: true,
                                    dataSource: {
                                        type: "static",
                                        data: [
                                            { value: "solutionOfferings", label: "Solution Offerings", name: 'solutionOfferings' },
                                        ]
                                    }
                                }}
                                errorMessage={errors["toSolutionOfferingRule"] && errors["toSolutionOfferingRule"].message}
                                condition={toCondition}
                                onConditionChange={handleToCondition}
                                onClearSolutionOfferingSelection={handleClearToSolutionOfferingSelection}
                                errors={errors}
                                setErrors={setErrors}
                            />
                            {/* <MDBox mb={3}>
                                <Dropdown 
                                    watch={watch} 
                                    setValue={setValue} 
                                    fromAsset={fromAsset} 
                                    setFromAsset={onFromAssetChange} 
                                    toAsset={toAsset} 
                                    setToAsset={setToAsset} 
                                    control={control} 
                                    fieldDef={{ name: "toAsset", displayName: "To Asset", 
                                        dataSource: { 
                                            type: "custom", 
                                            parentFields: ["fromAsset"], 
                                            dependentFields: ["toCode"], 
                                            url: "/api/dataflow/assets/list" 
                                        } 
                                    }}
                                    // fieldDef={{ name: "toAsset", displayName: "To Asset", "required": true,
                                    //     dataSource: { 
                                    //         type: "static",
                                    //         data: [
                                    //             { value: "applications", label: "Applications", name: 'applications' },
                                    //             { value: "products", label: "Products", name: 'products' },
                                    //             { value: "services", label: "Services", name: 'services' },
                                    //         ],
                                    //         parentFields: ["fromAsset"],
                                    //         dependentFields: ["toCode"],
                                    //         url: "/api/dataflow/assets/list" 
                                    //     }
                                    // }}
                                    errorMessage={errors["toAsset"] && errors["toAsset"].message} 
                                />
                            </MDBox>
                            <MDBox mb={3}>
                                <DynamicDropdown
                                    watch={watch} 
                                    setValue={setValue} 
                                    setFromAsset={onFromAssetChange} 
                                    control={control} 
                                    fieldDef={{ name: "toCode", displayName: "To Code", required: true, unique: false,
                                        dataSource: { 
                                            parentFields: ["toAsset"], 
                                            type: "custom", 
                                            url: "/api/dataflow/listItemValues/:toAsset/code?toAsset=true", 
                                            consumptionAsset: true 
                                        }
                                    }} 
                                    toAsset={toAsset} 
                                    setToAsset={setToAsset} 
                                    errorMessage={errors["toCode"] && errors["toCode"].message} 
                                />
                            </MDBox> */}
                            {/* <MDBox mb={3}>
                                <Integer disabled watch={watch} setValue={setValue} control={control} fieldDef={{ name: "sequence", displayName: "Sequence", "required": true }} errorMessage={errors["sequence"] && errors["sequence"].message} />
                            </MDBox> */}
                        </MDBox>
                    </MDBox>
                    <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};

export default SolutionOfferingRuleForm;