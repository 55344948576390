import RuleMapping from "./RuleMapping";

const FilteredUploadRules = (props) => {
    const { containerHeight, tableName, categoryFilter, subCategoryFilter } = props;
    return (
        <RuleMapping
            containerHeight={containerHeight}
            tableName={tableName}
            categoryFilter={categoryFilter}
            subCategoryFilter={subCategoryFilter}
        />
    )
};

export default FilteredUploadRules;