import { useCubeQuery } from "@cubejs-client/react";
import { Autocomplete, Checkbox, Icon, IconButton, ListSubheader, MenuItem, Popper, Select, Stack, TextField, styled } from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppController } from "context";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DataFieldTypes } from "components/DataField";
import { toInt } from "utils";

const StyledPopper = styled(({ children, ...rest }) => (
    <Popper {...rest}>
        <MDBox pr={2} textAlign="right">
            <Icon fontSize="medium" sx={{ cursor: "pointer" }}>close</Icon>
        </MDBox>
        {children}
    </Popper>
))();
const timelinefilterTypes = {
    "MONTHS.QTR": ["currentQuarter", "previousQuarter"],
    "MONTHS.MONTH": ["currentMonth", "previousMonth"],
    "YEARS.YEAR": ["currentYear", "previousYear"],
    "MONTHSDUCK.QTR": ["currentQuarter", "previousQuarter"],
    "MONTHSDUCK.MONTH": ["currentMonth", "previousMonth"],
    "YEARSDUCK.YEAR": ["currentYear", "previousYear"],
}

const timelineFilters = ["MONTHS.QTR", "MONTHS.MONTH", "YEARS.YEAR", "MONTHSDUCK.QTR", "MONTHSDUCK.MONTH", "YEARSDUCK.YEAR"];

const valuelessOperators = ["set", "notSet", "currentQuarter", "previousQuarter", "currentMonth", "previousMonth", "currentYear", "previousYear"];

const filterTypes = {
    "string": ["equals", "notEquals", "contains", "notContains", "startsWith", "endsWith", "notSet", "set"],
    "number": ["equals", "notEquals", "gt", "gte", "lt", "lte", "notSet", "set"],
    "decimal": ["equals", "notEquals", "gt", "gte", "lt", "lte", "notSet", "set"],
    "percent": ["equals", "notEquals", "gt", "gte", "lt", "lte", "notSet", "set"],
    "currency": ["equals", "notEquals", "gt", "gte", "lt", "lte", "notSet", "set"],
    "time": ["equals", "notEquals", "inDateRange", "notInDateRange", "beforeDate", "afterDate", "isRelative"],
};

const relativeFilterTypes = ["nLastMonths", "nLastMonthsIncCurrent", "nLastYears", "nLastYearsIncCurrent", "ytd"];

const filterTypeLabels = {
    "currentQuarter": "is current quarter",
    "previousQuarter": "is last quarter",
    "currentMonth": "is current month",
    "previousMonth": "is last month",
    "currentYear": "is current year",
    "previousYear": "is last year",
    "equals": "is equal to",
    "notEquals": "is not equal to",
    "contains": "contains",
    "notContains": "does not contain",
    "startsWith": "starts with",
    "endsWith": "ends with",
    "set": "is not null",
    "notSet": "is null",
    "gt": "is greater than",
    "gte": "is greater than or equal to",
    "lt": "is less than",
    "lte": "is less than or equal to",
    "inDateRange": "is in between",
    "notInDateRange": "is not in between",
    "beforeDate": "is before",
    "afterDate": "is after",
    "isRelative": "is relative time",
    "nLastMonths": "last n months",
    "nLastMonthsIncCurrent": "last n months (include current)",
    "nLastYears": "last n years",
    "nLastYearsIncCurrent": "last n years (include current)",
    "ytd": "is YTD",
}

const getResultSet = (filterDef) => {
    if (filterDef.name === 'Months.month')
        return useCubeQuery({ dimensions: [filterDef.name, "Months.srl"], order: [["Months.srl", "asc"]] })
    return useCubeQuery({ dimensions: [filterDef.name] })
}

const DimensionFilterItem = ({ filterDef, values, setValues, error: inputError }) => {
    const { resultSet, isLoading, error } = getResultSet(filterDef);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (resultSet)
            setOptions(resultSet.tablePivot().filter(item => item[filterDef.name]).map(item => item[filterDef.name]))
    }, [isLoading, resultSet]);

    if (error) {
        return <div>{error.toString()}</div>;
    }

    return <Autocomplete
        name={filterDef.name}
        multiple
        limitTags={3}
        options={options}
        disableCloseOnSelect
        loading={isLoading}
        value={values || []}
        onChange={(event, item) => {
            const valArr = item && item.length > 0 ? item.map(v => v.toString()) : item;
            setValues(valArr);
        }}
        PopperComponent={StyledPopper}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
                <Checkbox
                    sx={{
                        p: 0,
                        mr: 1,
                        "& .MuiSvgIcon-root": {
                            height: 16,
                            width: 16,
                            border: "1px solid #c5c9cc",
                            borderRadius: "4px"
                        }
                    }}
                    checked={selected}
                />
                {option}
            </li>
        )}
        renderInput={(params) => (
            <TextField {...params} error={inputError} fullWidth size="small" />
        )}
    />
};

const TextboxFilterItem = ({ stringFilter, filterDef, values, setValues, error }) => {
    const [valuesVal, setValuesVal] = useState(values?.length > 0 ? values[0] : null);

    useEffect(() => {
        setValuesVal(values?.length > 0 ? values[0] : null)
    }, [values]);

    return <TextField
        name={filterDef.name}
        value={valuesVal || ""}
        error={error}
        fullWidth
        size="small"
        variant="outlined"
        onChange={({ target: { value } }) => {
            setValuesVal(value ? value.trim() : null)
        }}
        onBlur={({ target: { value } }) => {
            if (!stringFilter) {
                setValuesVal(isNaN(parseFloat(value)) ? null : parseFloat(value))
                setValues(isNaN(parseFloat(value)) ? null : [parseFloat(value).toString()])
            } else {
                setValuesVal(value)
                setValues([value])
            }
        }}
    />
};

const TimeFilterItem = ({ rangeType, filterDef, values, setValues, error, error2 }) => {
    const val = values?.length > 0 ? moment(values[0]) : null;
    const val2 = values?.length > 1 ? moment(values[1]) : null;
    const [valuesVal, setValuesVal] = useState(val);
    const [valuesVal2, setValuesVal2] = useState(val2);

    const [controller,] = useAppController();
    const { appDef: { settings } } = controller;
    const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

    useEffect(() => {
        setValuesVal(values?.length > 0 ? moment(values[0]) : null)
        setValuesVal2(values?.length > 1 ? moment(values[1]) : null)
    }, [values]);

    return <>
        <DesktopDatePicker
            name={"from"}
            inputFormat={filterDef?.format || defaultDateFormat}
            value={valuesVal}
            onChange={(value) => {
                setValuesVal(value);
                if (rangeType)
                    setValues([moment(value).format("YYYY-MM-DD"), moment(valuesVal2).format("YYYY-MM-DD")]);
                else
                    setValues([moment(value).format("YYYY-MM-DD")]);
            }}
            renderInput={(params) => <TextField size="small" fullWidth {...params} error={error} />}
        />
        {
            rangeType &&
            <>
                <MDTypography component="p" my={1} variant="caption" textAlign="center" fontWeight="medium" color="text">and</MDTypography>
                <DesktopDatePicker
                    name={"to"}
                    inputFormat={filterDef?.format || defaultDateFormat}
                    value={valuesVal2}
                    onChange={(value) => {
                        setValuesVal2(value);
                        if (rangeType)
                            setValues([moment(valuesVal).format("YYYY-MM-DD"), moment(value).format("YYYY-MM-DD")]);
                    }}
                    renderInput={(params) => <TextField size="small" fullWidth {...params} error={error2} />}
                />
            </>
        }
    </>
};

const parseRelativeTimeFilterValues = (values) => {
    let valuesJson = { rangeType: null, rangeCount: null, includeCurrent: false };

    if (values?.length > 0)
        valuesJson.rangeType = values[0]
    if (values?.length > 1)
        valuesJson.rangeCount = values[1]

    return valuesJson;
};

const RelativeTimeFilterItem = ({ filterDef, values, setValues, error }) => {
    const [valuesVal, setValuesVal] = useState(parseRelativeTimeFilterValues(values));

    useEffect(() => {
        setValuesVal(parseRelativeTimeFilterValues(values))
    }, [values]);

    const options = relativeFilterTypes?.map(
        f => <MenuItem key={f} value={f}>{filterTypeLabels[f]}</MenuItem>
    );

    return <>
        <Select
            fullWidth
            variant="outlined"
            sx={{ mb: 1.25 }}
            defaultValue={"nLastMonths"}
            value={valuesVal.rangeType || ""}
            onChange={({ target: { value } }) => {
                setValuesVal({ rangeType: value, rangeCount: null })
                setValues([value, null])
            }}
        >
            {options}
        </Select>
        {
            valuesVal.rangeType !== "ytd" &&
            <TextField
                name={filterDef.name}
                value={valuesVal.rangeCount || ""}
                error={error}
                fullWidth
                size="small"
                variant="outlined"
                onChange={({ target: { value } }) => {
                    let val = toInt(value);
                    val = val > 100 ? 100 : val;
                    val = val < 0 ? 0 : val;
                    setValuesVal(prev => ({ rangeType: prev.rangeType, rangeCount: val }))
                }}
                onBlur={({ target: { value } }) => {
                    let val = toInt(value);
                    val = val > 100 ? 100 : val;
                    val = val < 0 ? 0 : val;
                    setValuesVal(prev => ({ rangeType: prev.rangeType, rangeCount: val }))
                    setValues([valuesVal.rangeType, val])
                }}
            />
        }
    </>
};

const optionGroupStyles = ({ palette: { dark, grey }, typography: { size }, functions: { pxToRem } }, { noTopMargin, separator }) => ({
    color: separator ? grey[400] : dark.main,
    fontSize: size.xs,
    lineHeight: separator ? pxToRem(10) : pxToRem(36),
    mt: noTopMargin ? 0 : 2,
    mb: separator ? 2 : 0
});

const OptionGroup = ({ name, noTopMargin, separator }) => {
    return <ListSubheader sx={theme => optionGroupStyles(theme, { noTopMargin, separator })}>{name}</ListSubheader>
}

const getOptions = (name, dataType) => {
    let standardOptions = [];
    if (timelineFilters.includes(name?.toUpperCase())) {
        // standardOptions.push(<OptionGroup key="default" name="Default" noTopMargin />)
        timelinefilterTypes[name?.toUpperCase()]?.forEach(
            f => standardOptions.push(<MenuItem key={f} value={f}>{filterTypeLabels[f]}</MenuItem>)
        )
        // standardOptions.push(<OptionGroup key="custom" name="Custom" />)
        standardOptions.push(<OptionGroup key="sep" name="______________________________" noTopMargin separator />)
    }

    const customOptions = filterTypes[dataType]?.map(
        f => <MenuItem key={f} value={f}>{filterTypeLabels[f]}</MenuItem>
    );
    return [...standardOptions, ...customOptions];
}

const getFilterDescription = (dataType, operator, values) => {
    console.log(dataType, operator, values)
    let valueDescription = "";
    if (values?.length > 1) {
        if (dataType === "time") {
            if (["inDateRange", "notInDateRange"].includes(operator))
                valueDescription = `${moment(values[0]).format("MMM DD YYYY")} and ${moment(values[1]).format("MMM DD YYYY")}`;
            else {
                if ("isRelative" === operator) {
                    if ("nLastMonths" === values[0])
                        return `is last ${values[1]} months`;
                    if ("nLastMonthsIncCurrent" === values[0])
                        return `is last ${values[1]} months (including current)`;
                    if ("nLastYears" === values[0])
                        return `is last ${values[1]} years`;
                    if ("nLastYearsIncCurrent" === values[0])
                        return `is last ${values[1]} years (including current)`;
                    if ("ytd" === values[0])
                        return `is YTD`;
                }
            }
        }
        else
            valueDescription = values.slice(0, values.length - 1).join(", ") + " or " + values[values.length - 1]
    }
    else if (values?.length === 1) {
        if (dataType === "time")
            valueDescription = moment(values[0]).format("MMM DD YYYY");
        else
            valueDescription = values[0];
    }
    return `${filterTypeLabels[operator]} ${valueDescription}`;
}

const filterStyles = () => ({
    listStyle: "none",
    // mb: 1,
    border: "1px solid #ddd",
    borderRadius: 1.5,
    "& .dropdownIcon": {
        cursor: "pointer",
        height: 20,
        width: 22,
        fontSize: "22px!important",
        color: "rgba(0, 0, 0, 0.54)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    },
    "& .deleteIcon": {
        cursor: "pointer",
        pt: "3px",
        height: 20,
        width: 22,
        fontSize: "16px!important",
        // color: "rgba(0, 0, 0, 0.54)",
        mx: .25,
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    },
    "& .dropdownIcon.expanded": {
        transform: "rotate(-180deg)"
    },
    "& .summmary": {
        pt: .85,
        pb: 1,
        pl: .75,
        pr: .75,
    },
    "& .fieldTitle": {
        cursor: "pointer",
        ml: .5,
        mt: .5,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        mr: "auto"
    },
    "& .details": {
        px: 1,
        pb: 1
    },
    "& .MuiSelect-select": {
        px: 1,
        py: .5
    }
});

const CubeFilter = memo(({ dismissible, filterDef, onFilterDelete, onFilterChange }) => {
    const { name, title, dataType, operator, values } = filterDef;
    const [expanded, setExpanded] = useState(false);
    const options = useMemo(() => getOptions(name, dataType), [dataType]);
    const [error, setError] = useState(false);
    const [error2, setError2] = useState(false);
    const [valuesVal, setValuesVal] = useState(values || null);
    const [operatorVal, setOperatorVal] = useState(operator || "equals");

    const showFilterConfig = useCallback(() => {
        setExpanded(true)
    }, []);

    const hideFilterConfig = useCallback(() => {
        setError(false)
        setError2(false)
        setExpanded(false)
    }, []);

    const saveFilter = () => {
        let hasErrors = false;
        setError(false);
        setError2(false);
        if (!valuelessOperators.includes(operatorVal) && (!valuesVal || valuesVal.length === 0 || String(valuesVal[0])?.trim() === "")) {
            setError(true);
            hasErrors = true;
        }
        else if (filterDef?.dataType === "time") {
            if (["isRelative"].includes(operatorVal)) {
                if (!valuesVal || valuesVal.length < 2 || !valuesVal[0] || (valuesVal[0] !== "ytd" && !valuesVal[1])) {
                    setError(true);
                    hasErrors = true;
                }
            }
            else {
                if (!valuesVal || valuesVal.length === 0 || !moment(valuesVal[0]).isValid()) {
                    setError(true);
                    hasErrors = true;
                }
                if (["inDateRange", "notInDateRange"].includes(operatorVal) && (valuesVal?.length < 2 || !moment(valuesVal[1]).isValid())) {
                    setError2(true);
                    hasErrors = true;
                }
            }
        }

        if (!hasErrors) {
            onFilterChange(filterDef.name, {
                member: filterDef.name,
                operator: operatorVal,
                values: valuesVal || []
            });
            hideFilterConfig();
        }
    }

    const resetFilter = () => {
        setError(false);
        setError2(false);
        setValuesVal([]);
        setOperatorVal("equals");
        onFilterChange(filterDef.name, {
            member: filterDef.name,
            operator: "equals",
            values: []
        });
        hideFilterConfig();
    }

    const filterDescription = getFilterDescription(filterDef.dataType, operator, values);

    const renderFilter = () => {
        if (!valuelessOperators.includes(operatorVal)) {
            if (filterDef.type === DataFieldTypes.MEASURE) {
                return <TextboxFilterItem filterDef={filterDef} error={error} values={valuesVal} setValues={setValuesVal} />
            }
            else if (filterDef.type === DataFieldTypes.DIMENSION) {
                if (filterDef.dataType === "time") {
                    if (["isRelative"].includes(operatorVal))
                        return <RelativeTimeFilterItem filterDef={filterDef} error={error} error2={error2} values={valuesVal} setValues={setValuesVal} />
                    return <TimeFilterItem rangeType={["inDateRange", "notInDateRange"].includes(operatorVal)} filterDef={filterDef} error={error} error2={error2} values={valuesVal} setValues={setValuesVal} />
                }
                else if (["contains", "notContains", "startsWith", "endsWith"].includes(operatorVal)) {
                    return <TextboxFilterItem stringFilter filterDef={filterDef} error={error} values={valuesVal} setValues={setValuesVal} />
                }
                else {
                    return <DimensionFilterItem filterDef={filterDef} error={error} values={valuesVal} setValues={setValuesVal} />
                }
            }
        }
        return null;
    };

    return (
        <MDBox component="li" sx={theme => filterStyles(theme)}>
            <MDBox display="flex" flexDirection="column" className="summmary">
                <MDBox display="flex" flexDirection="row" alignItems="center">
                    <MDTypography data-testid={title?.toLowerCase().replaceAll(' ', '')} variant="caption" fontWeight="medium" color="text" className="fieldTitle" onClick={showFilterConfig}>{title}</MDTypography>
                    {
                        !expanded && (
                            <Icon className={`dropdownIcon${expanded ? " expanded" : ""}`} onClick={showFilterConfig}>keyboard_arrow_down</Icon>
                        )
                    }
                    {
                        dismissible &&
                        <Icon title="Click to delete" className="deleteIcon" color="error" onClick={() => onFilterDelete(name)}>delete</Icon>
                    }
                </MDBox>
                {
                    !expanded &&
                    <MDTypography mt={.5} ml={.5} variant="caption" color="text">{valuelessOperators.includes(operatorVal) || (values && values.length > 0) ? filterDescription : "is (All)"}</MDTypography>
                }
            </MDBox>
            {
                expanded && (
                    <MDBox className="details">
                        <Select
                            fullWidth
                            variant="outlined"
                            sx={{ mb: 1 }}

                            defaultValue={"equals"}
                            value={operatorVal}
                            onChange={({ target: { value } }) => { setOperatorVal(value); setValuesVal([]); }}>
                            {options}
                        </Select>
                        {renderFilter()}
                        <Stack direction="row-reverse" spacing={1} sx={{ mt: 1 }}>
                            <IconButton
                                title="Click to apply filter"
                                size="small"
                                sx={{
                                    backgroundColor: "green!important",
                                    color: "white!important",
                                    height: 20,
                                    width: 20,
                                }}
                                onClick={saveFilter}
                            >
                                <Icon>done</Icon>
                            </IconButton>
                            <IconButton
                                title="Click to cancel"
                                size="small"
                                sx={{
                                    backgroundColor: "red!important",
                                    color: "white!important",
                                    height: 20,
                                    width: 20,
                                }}
                                onClick={hideFilterConfig}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <IconButton
                                title="Click to reset filter"
                                size="small"
                                sx={{
                                    // backgroundColor: "grey!important",
                                    // color: "white!important",
                                    height: 20,
                                    width: 20,
                                    ml: "4px!important",
                                    mr: "auto!important"
                                }}
                                onClick={resetFilter}
                            >
                                <Icon>refresh</Icon>
                            </IconButton>
                        </Stack>
                    </MDBox>
                )
            }
        </MDBox >
    );
});

CubeFilter.defaultProps = {
    dismissible: true,
};

export default CubeFilter;