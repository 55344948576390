import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate, useLocation } from "react-router-dom";
import { formatAmount, getDrilldownPath } from 'utils';
import colors from "assets/theme/base/colors";
import { useResizeDetector } from 'react-resize-detector';
import { useYADialog } from "components/YADialog";
import { convertRStoGraph ,convertRStoMultiGraph, convertRStoGraphYearlyStackedAreaSpline, removeSessionFilter, ConvertRstoGraphdailyStacked } from "utils"
import { parseTableResultset, multipleStackConvert, stackedConverter, creatToggleTableCubeOptions } from "utils/charts";
import { useDashboardContext } from 'components/DashboardContext';
import DashboardItem from 'components/DashboardItem';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import _, { isArray } from 'lodash';
import numeral from 'numeral';
import { isTimeDimensionQuery } from 'utils/dashboard';

const StackedColumnChartRenderer = ({ loading, title, subtitle, chartHelpContextKey, resultSet, vizOptions, vizState, cubeOptions }) => {
    
    const [state,] = useDashboardContext();
    const chartRef = createRef();
    const { width, height, ref: rref } = useResizeDetector();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const [tableCubeOptions, setTableCubeOptions] = useState(null)
    const {showReport} = useYADialog();
    let navigate = useNavigate()
    let location = useLocation()
    var graphData

    if(loading)
       return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

    let currentFilters
    let parsedResultset = [];
    if (isTimeDimensionQuery(resultSet.loadResponses[0].query)) {
        parsedResultset = parseTableResultset(resultSet, state, vizOptions)
        currentFilters = removeSessionFilter(resultSet.loadResponses[0].query.filters, vizOptions)
    }
    else {
        parsedResultset = resultSet.tablePivot();
        currentFilters = removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions)
    }

    var count
    if (vizOptions.homePage) {
        count = vizOptions.series.length;
        graphData = convertRStoGraphYearlyStackedAreaSpline(resultSet, colors.graphColors, "", vizOptions)
    }
    else if (vizOptions.multiStack === true) { graphData = convertRStoMultiGraph(resultSet, count === 1 ? colors.singleDataColors : colors.stackedgraphcolors, "column", vizOptions) }
    else if (vizOptions.plotType && vizOptions.plotType === 'stackedMultiple') {
        if (vizOptions.queryType && vizOptions.queryType === "CompareWithDate") {
            graphData = ConvertRstoGraphdailyStacked(resultSet, colors, vizOptions)
        } else if(vizOptions.queryType === 'currentYear'){
           graphData = stackedConverter(vizOptions, parsedResultset, colors)
        }
        else
            graphData = multipleStackConvert(vizOptions, parsedResultset, colors)
    }
    else {
        count = vizOptions.series.length;
        graphData = convertRStoGraph(resultSet, count === 1 ? colors.singleDataColors : colors.stackedgraphcolors, "column", vizOptions)
    }

    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        let isInArray = (name, array) => {
            return array.some(item => item.name === name);
        }
        let parsedColumns = []
        parsedResultset.length ?
            parsedResultset.forEach(item => {
                Object.keys(item).map(key => {
                    let col = {
                        "name": key,
                        "displayName": String(key).match(/[^|]*$/g)[0],
                        "type": Number(item[key]) ? vizOptions.units ? 'number' : "currency" : 'string'
                    }
                    if (!isInArray(col.name, parsedColumns))
                        parsedColumns.push(col)
                })
            })
            : parsedColumns = null
        if (vizOptions.plotType === "stackedMultiple") {
            parsedColumns = parsedColumns?.filter(item => item.name !== 'Months.month' || item.name !== 'MonthsDuck.month')
        }
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = parsedResultset.length && parsedColumns.length ? [...parsedColumns] : [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = vizOptions.ignoreCategory ?[tableVizOptions['subcategory']] : [tableVizOptions['category']]
        tableVizOptions[""] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = vizOptions?.tableHeight ? vizOptions?.tableHeight : 5.8;
        if (cubeOptions) {
            let tableCubeOptions = creatToggleTableCubeOptions(cubeOptions)
            setTableCubeOptions(tableCubeOptions)
        }
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])
    if (isArray(graphData?.range))
        graphData.range = graphData.range?.filter(fil => fil.name !== null && fil.name !== 'null')
    // console.log(graphData.range.filter(fil => fil.name !== null && fil.name !== 'null'))
    var opts = {
        chart: { 
            // height: location.pathname.indexOf("report") > 0 ? 450 : 250, 
            width: width, 
            height: vizOptions.homePage ? height-16:height,
            type: 'column', style: { fontFamily: 'inherit', fontSize: '14px', } ,
            spacingBottom: vizOptions.homePage ? null:0,
            // spacingTop: 0,
            spacingRight: vizOptions.homePage ? null:0,
            spacingLeft: vizOptions.homePage ? null:0,
        },
        title: { text: '' },
        exporting: { enabled: false },
        lang: { thousandsSep: ',' },        
        credits: { enabled: false },
        tooltip: {
            outside: false,
            formatter: function () {
                    return `<b>${this.point.category}</b><br/>${this.point.series.name}: 
                    <b>$${Highcharts.numberFormat(this.point.y,0,".",",")}</b> 
                    ${vizOptions.multiStack === true ? `<b>(${Highcharts.numberFormat(this.point.percentage,0,".",",")}%)</b>` : ''}`;
            }
        },
        xAxis: {
            visible:!vizOptions.hideLegend,
            categories: Array.from(graphData.categories)
        },
        legend: vizOptions.plotType && vizOptions.plotType === 'stackedMultiple'  && !vizOptions.providerview ? {
            labelFormatter: function () {
                {
                    return this.options.stack;
                }
            }
        } : {
            enabled: !vizOptions.homePage
        }, 
        yAxis: [{
            visible:!vizOptions.hideLegend,
            reversed: false,
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return vizOptions.stacking ? numeral((this.value || 0) / 100).format('0%') : formatAmount(Math.abs(this.value)).replace(/ /g,'').replace('.0','');
                }
            },
        }
    ],
    plotOptions: {
            series: {
                cursor: "pointer",
                groupPadding: 0.1,
                stacking: vizOptions.stacking ? vizOptions.stacking : "normal",
              // pointPadding: 0,
                borderWidth: 0,
                borderRadius: vizOptions.homePage ? 0:4,
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                point: {
                    events: {
                        click: function (event) {
                            let duckCube = ""
                            if (vizOptions.duck) duckCube = "Duck"
                            var obj = Object.assign([], [...currentFilters]);
                            if (!vizOptions.queryType || vizOptions.queryType !== "CompareWithDate") {
                                if (obj.find((({name}) => name === `Months${duckCube}.month`)))
                                    _.remove(obj, {name: `Months${duckCube}.month`})    
                            }                        
                            if (vizOptions.category)
                                // if (!obj.find((({name}) => name === vizOptions.category))) 
                                if (obj.find((({name}) => name === vizOptions.category))) 
                                {
                                   _.remove(obj, {name: vizOptions.category})
                                   obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                                else
                                {
                                    {
                                        if (vizOptions.queryType && vizOptions.queryType === "CompareWithDate") {
                                            let date = graphData.dateValuSet.find(item => item.label === event.point.category )
                                            if(date)
                                            obj.push({ "name": vizOptions.category, "values": [date.value] })
                                        }else
                                       obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                    }

                                }
                            if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                                vizOptions.excludeFilters.map((fil) => {
                                    if (obj.find((({name}) => name === fil)))
                                        _.remove(obj, {name: fil})                                
                                })
                            }
                            if (vizOptions.duck) {
                                if (obj.find((({name}) => name === "Years.year")))
                                    _.remove(obj, {name: "Years.year"})    
                                if (obj.find((({name}) => name === "Months.month")))
                                    _.remove(obj, {name: "Months.month"})    
                                if (obj.find((({name}) => name === "YearsDuck.year")))
                                    _.remove(obj, {name: "YearsDuck.year"})   
                                // console.log(event.point) 
                                // obj.push({ name: `Years${duckCube}.year`, "values": [event.point.series.name.replace(" YTD", "")] })
                            }
                            var popupTo = vizOptions["popupTo"] ? vizOptions["popupTo"] : ''
                            if (vizOptions["popupToKey"]) {
                                popupTo =  vizOptions["popupTo"] ? vizOptions["popupTo"].replace("changeme",event.point.series.name.toLowerCase()).replace(' ','') : ''
                            }
                            if(vizOptions.subcategory) {
                                var  newFil = {"name": vizOptions.subcategory, "values": [event.point.series.name]}
                                if(vizOptions?.transformFilters) {
                                    newFil = 
                                        vizOptions.transformFilters.map(fil => {  
                                            if (fil.from === vizOptions.subcategory)
                                                return {
                                                    "name" : fil.to,
                                                    "values" : [event.point.series.name]
                                                }       
                                            })[0]
                                        // console.log(newFil)

                                }
                                else
                                    newFil = {"name": vizOptions.subcategory, "values": [event.point.series.name]}
                                newFil !== undefined ? obj.push(newFil) : obj.push({"name": vizOptions.subcategory, "values": [event.point.series.name]})
                            }
                            vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath(location.pathname, vizOptions.drillTo), { state: obj})
                            vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && showReport(popupTo, obj, null);
                        }
                    }
                }
            },
            column: {
                dataLabels: {
                    // enabled: true,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'inherit',
                        fontWeight: 100,
                    },                       
                  formatter: function () {
                    return formatAmount(Math.abs(this.y)).replace(/ /g,'').replace('.0','');
                  }
                }
              }
        },
        series: Object.values(graphData.range)

    }
    let navigateToPage = (linkTo) => {
        linkTo && linkTo !== "" && navigate(location.pathname === "/" ? linkTo : getDrilldownPath(location.pathname, linkTo), {state: {}})
    }
    const nodata = graphData.categories?.size === 0 || graphData.range?.length === 0;
    return ( toggleType === 'table' && tableVizOptions ?
    <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet} parsedResultset={parsedResultset} cubeOptions={tableCubeOptions}/> :
        <DashboardItem  nodata={nodata} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={parsedResultset} cubeOptions={cubeOptions}>
            <div ref={rref} style={{position: 'relative', height: '100%'}}>
                <div style={{position: 'absolute', left: 0, top: 0, bottom: 0, right: 0}}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
                    {vizOptions["linkTo"] && vizOptions["linkTo"] !== "" &&
                        <MDBox display="flex" color={colors.linkColour ? colors.linkColour : "dark"} flexDirection="row" justifyContent="flex-end">
                        <MDTypography style={{position: 'absolute', bottom: '-10px', right: '5px'}} variant="button" sx={{ "&:hover": { cursor: 'pointer' }}} fontWeight="medium" color={colors.linkColour ? colors.linkColour : "dark"} px={1} whiteSpace="nowrap" onClick={() => { navigateToPage(vizOptions["linkTo"])}}>
                            {vizOptions.linkText.toUpperCase()} 
                        </MDTypography>
                        <Icon>east</Icon>
                        </MDBox>
                    }
                </div>
            </div>
        </DashboardItem>
    )
}

export default StackedColumnChartRenderer;