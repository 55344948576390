import numeral from "numeral";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

const TextAnswer = ({ question, answer, lastAnswer, error, onRetry }) => {
    const answerText = (String(answer) || "");

    return (
        <MDTypography component="span" variant="button" fontWeight="light" lineHeight={1.5} whiteSpace="pre-wrap">
            {
                answer && (
                    <>
                        {
                            answerText
                        }
                        {
                            lastAnswer && error === true && (
                                <MDButton color="info" variant="outlined" size="small" sx={{ ml: 2 }} onClick={() => onRetry(question, true)}>Try again</MDButton>
                            )
                        }
                    </>
                )
            }
        </MDTypography>
    );
}

export const TextAnswerAnimated = ({ question, answer, lastAnswer, error, onRetry }) => {
    const answerWords = (String(answer) || "").split(" ");

    return (
        <MDTypography component="span" variant="button" fontWeight="light" lineHeight={1.5} whiteSpace="pre-wrap">
            {
                answer && (
                    <>
                        {
                            answerWords?.map((a, i) => (
                                <span key={a} className="answerFadeIn" style={{ animationDelay: `${numeral((i + 1) * 5 / 100).format('0.00')}s` }}>{`${a} `}</span>
                            ))
                        }
                        {
                            lastAnswer && answerWords?.length > 0 && (
                                <span className="answerFadeIn cursorBox" style={{ animationDelay: `${numeral((answerWords?.length) * 5 / 100).format('0.00')}s` }}></span>
                            )
                        }
                        {
                            lastAnswer && error === true && (
                                <MDButton color="info" variant="outlined" size="small" sx={{ ml: 2 }} onClick={() => onRetry(question, true)}>Try again</MDButton>
                            )
                        }
                    </>
                )
            }
        </MDTypography>
    );
}

export default TextAnswer;