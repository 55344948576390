
import MDBox from 'components/MDBox';
import DataTable from 'components/DataTable';
import MDTypography from 'components/MDTypography';
import { Icon, Card, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import fetchRequest from 'utils/fetchRequest';
import MDButton from 'components/MDButton';
import { useYADialog } from 'components/YADialog';
import AnimatedRoute from 'components/AnimatedRoute';
import YASkeleton from 'components/YASkeleton';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';
import numeral from 'numeral';
import { useImmer } from 'use-immer';
import * as XLSX from 'xlsx';
import RecommendationForm from '../RecommendationForm/index';
import DataloadDialog from "components/DataloadDialog";
import PageHeader from 'components/PageHeader';

const getDropdownValues = (columnDef, value) => {
  const selectedIds = value?.split(",") || [];
  const selectedValues = columnDef.dataSource?.data?.filter(d => selectedIds.includes(d.value))?.map(d => d.label) || [];
  return selectedValues.join(", ")
}

const buildColumns = (masterDef, defaultDateFormat) => {
  const columns = [];
  if (!masterDef.readonly)
  columns.push({
    Header: 'Actions',
    accessor: 'actions',
    align: 'left',
    disableSorting: true,
    disableFilters: true,
    minWidth: 170
  });
  if (Array.isArray(masterDef.fields) && masterDef.fields.length > 0) {
    masterDef.fields?.filter(f => !f.hidden)?.forEach((f) => {
      let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
      const isStaticDropdown = ["multiselect", "dropdown", "custom"].includes(f.type) && f.dataSource?.type === 'static';
      let accessor = f.schemaName === "assetCode" ? `${f.schemaName}__name` : f.schemaName ;
      col['Header'] = f.displayName;
      col['id'] = f.displayName;
      col['accessor'] = accessor;
      col['Cell'] = ({ cell: { value } }) => {
        if (isStaticDropdown) {
          const values = getDropdownValues(f, value);
          return <MDTypography title={values} key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{values}</MDTypography>
        }
        else if (f.type === "currency")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
        else if (f.type === "datepicker")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
        else if(f.toUpperCase)
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value[0].toUpperCase() + value.slice(1)}</MDTypography>

        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value}</MDTypography>
      };
      col['dataType'] = f.filterType || f.type
      col['disableFilters'] = f.disableFilters || false,
        columns.push(col);
    });
  }
  return columns;
};

const buildRows = (pkColumn, data, onEdit, onDelete, onDismiss, fields) => {
  const rows = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach((r) => {
      let row = {};
      Object.keys(r).forEach((k) => {
        row[k.replace(/\./g, '__')] = r[k];
      });
      row['actions'] =
        r?.taxonomy === true ? (
          <span></span>
        ) : (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
            <Tooltip title="Edit" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onEdit(r[pkColumn])}>
                <Icon fontSize='small'>edit</Icon>
              </IconButton>
            </Tooltip>
            {
               r["status"] === "dismissed" ?
               (
                <Tooltip title="Delete" placement="top">
                  <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onDelete(r[pkColumn])}>
                    <Icon fontSize='small' color="error">delete</Icon>
                  </IconButton>
                </Tooltip>
               ) : 
             (
              <Tooltip title="Dismiss" placement="top">
                <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onDismiss(r[pkColumn])}>
                  <Icon fontSize='small' color="error">highlight_off</Icon>
                </IconButton>
              </Tooltip>
             )
           }
          </MDBox>
        );
      let passwordFileds = fields.filter(item => item.password === true)
      if (passwordFileds.length > 0) {
        passwordFileds.forEach((item) => {
          row[item.name] = "*****"
        })
      }
      rows.push(row);
    });
  }
  return rows;
};

const RecommendationScreen = (props) => {
  const { refresh, setRefresh, settings, containerHeight } = props
  const [step, setStep] = useState('LOADING');
  const handleError = useHandleError();
  const [masterDef, setMasterDef] = useImmer(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [progress, setProgress] = useState(false);
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
  const [ act, setAct ] = useState(false);
  const uploadConfig = { monthFilter: null, yearFilter: null, yearFilterName: null, monthFilterName: null, uploadType: "recommendation", uploadCategory : "recommendation"}
  const { showCustomForm, showAlert, showPrompt, showSnackbar } = useYADialog();
  const handleClose = () => {
    setRefresh(Math.random());
  };
  const handleCloseMenuItem = (a) => {
    if (a)
      a();
  };

  const handleDownload =  async() => {
  if (columns && rows) {
  var data = [];
  rows.forEach(element => {  
    let obj = {}
    columns.forEach((e) => {
      if (e.Header === "Asset Code") {
        element[e.accessor] = element["assetCode"]
      }
      if(e.type === 'date' && element[e.accessor] !== null){
       element[e.accessor] = moment(element[e.accessor]).format(defaultDateFormat);
      }
      if(e.Header !== "Actions"){
       obj[e.Header] = element[e.accessor]
      }
     })
    data.push(obj)
  });
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(data)
  XLSX.utils.book_append_sheet(wb, ws, 'test')
  XLSX.writeFile(wb, `Recommendation ${moment(Date()).format("YYYYMMDDHHmmss")}.csv`)
  await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded [Recommendation] list` })
}

    handleCloseMenuItem();
  }

  const handleEdit = (pkId) => {
    showCustomForm(`Edit Recommendation`, () => <RecommendationForm onClose={handleClose} mode="edit" pkId={pkId} />, handleClose, "edit", pkId, "md");
  };

  const handleOnUpdate = useCallback(({ selected }) => {
    setSelectedRows(selected)
  }, [])

  const deleteMaster = async (pkId) => {
    const [err, data] = await fetchRequest.delete(`/api/recommendation/${pkId}`);
    if (err) {
      showAlert('Delete', 'Something went wrong. Contact your administrator.');
    }
    else
      if (data && data.result === true) {
        showSnackbar('Data deleted successfully', 'success');
        handleClose();
      }
      else if (data && data.result === false) {
        showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
      }
  };

  const dismissRecommendation = async (pkId) => {
    const [err, data] = await fetchRequest.post(`/api/recommendation/dismiss/${pkId}`);
    if (err) {
      showAlert('Dismiss', 'Something went wrong. Contact your administrator.');
    }
    else
      if (data && data.result === true) {
        showSnackbar('Data Dismissed successfully', 'success');
        handleClose();
      }
      else if (data && data.result === false) {
        showAlert('Dismiss', data.message || 'Something went wrong. Contact your administrator.');
      }
  };

  const handleDeleteSuccess = (pkId) => {
    deleteMaster(pkId);
  };

  const handleDismissSuccess = (pkId) => {
    dismissRecommendation(pkId);
  };

  const handleDelete = (pkId) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => handleDeleteSuccess(pkId));
  };

  const handleDismiss = (pkId) => {
    showPrompt('Dismiss', 'Are you sure you want to Dismiss?', () => handleDismissSuccess(pkId));
  };

  const deleteMasterMultipleRecords = async (selectedRows) => {
    setProgress(true);
    const [err, data] = await fetchRequest.post(`/api/recommendation/deleteMultiple`, selectedRows);

    if (err) {
      setProgress(false)
      showAlert('Delete', 'Something went wrong. Contact your administrator.');
    }
    else
      if (data && data.result === true) {
        showSnackbar('Data deleted successfully', 'success');
        handleClose();
      }
      else if (data && data.result === false) {
        showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
      }
  }

  const dismissRecommendationMultipleRecords = async (selectedRows) => {
    setProgress(true);
    const [err, data] = await fetchRequest.post(`/api/recommendation/dismissMultiple`, selectedRows);

    if (err) {
      setProgress(false)
      showAlert('Dismiss', 'Something went wrong. Contact your administrator.');
    }
    else
      if (data && data.result === true) {
        showSnackbar('Data Dismissed successfully', 'success');
        handleClose();
      }
      else if (data && data.result === false) {
        showAlert('Dismiss', data.message || 'Something went wrong. Contact your administrator.');
      }
  }

  const handleDeleteMultiple = (selectedRows) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => deleteMasterMultipleRecords(JSON.stringify(selectedRows)));
  };

  const handleDismissMultiple = (selectedRows) => {
    showPrompt('Dismiss', 'Are you sure you want to Dismiss?', () => dismissRecommendationMultipleRecords(JSON.stringify(selectedRows)));
  };
  
  useEffect(() => {
    async function getMasterDef() {
      var [err, data] = await fetchRequest.get(`/api/recommendation/recommendationDef`);
      if (err) {
        handleError(err);
      } else {
        setMasterDef(data);
        setColumns(buildColumns(data, defaultDateFormat));
      }
    }
    getMasterDef();
  }, []);

  useEffect(() => {
    async function getList() {
      setLoading(true);
      // if (yearFilter && monthFilter) {
        var [err, data] = await fetchRequest.post(`/api/recommendation/list`);
        if (err) {
          handleError(err);
        } else {
          if (data && Array.isArray(data) && data.length > 0) {
            setRows(buildRows(masterDef.pkColumn || 'id', data, handleEdit, handleDelete, handleDismiss, masterDef.fields));
            setStep('LOADED');
          } else {
            setRows([]);
            setStep('EMPTY');
          }
        }
        setLoading(false);
      // }   
    }
    if (masterDef) {
      getList();
    }
  }, [masterDef, refresh]);

  if (step === 'LOADING') {
    return <YASkeleton variant="dashboard-loading" />;
  }

  const { displayName, singularDisplayName, desc, canFilter } = masterDef;

  const handleAddButtonClick = () => {
    showCustomForm(`New ${singularDisplayName || displayName}`, () => <RecommendationForm onClose={handleClose} />, handleClose, null, null, 'md');
    handleCloseMenuItem();
  };

  const handleUploadDialogClose = (uploadSuccess) => {
    if (uploadSuccess)
        handleClose();
    setAct(false)
};

  const renderAddButton = () => !masterDef.readonly ? (
    <MDBox color="text" pt={0} mt={0} mr={1} display="flex" flexDirection="row">
    <MDButton
      variant="outlined"
      color="info"
      startIcon={<Icon>add</Icon>}
      onClick={handleAddButtonClick}
      sx={{ mr: 1 }}
    >
      Add New
    </MDButton>
    <MDButton data-testid = {"upload"} variant="gradient" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={()=> {setAct(true)}}>
          Upload
    </MDButton>
    </MDBox>
  ) : undefined;

  const getMenuActions = () => {
    let actions = [];
    (step !== 'EMPTY' && actions.push({ label: "Download", onClick: handleDownload}))
    return actions;
  }

return (
    <>
      <PageHeader title={displayName} subtitle={desc} hideBreadcrumbs={true} settingsHeaderFormat={true}/>
      <MDBox p={3} pt={1}>
        {act && <DataloadDialog title={`Recommendation Upload`} uploadConfig={uploadConfig} onClose={handleUploadDialogClose}/>}
        {step === 'EMPTY' && (
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100vh - 300px)"
          >
            <EmptyState
              size="large"
              image={new_item_img}
              title={`No ${displayName} Yet`}
              description={
                !masterDef.readonly
                  ? `Click on the '+ add new' button to add a new ${(
                    singularDisplayName || displayName
                  ).toLowerCase()}.`
                  : undefined
              }
              actions={renderAddButton()}
            />
          </MDBox>
        )}
        {step === 'LOADED' &&
          <>
            <Card sx={{ height: '100%' }} px={0}>
              <DataTable
                containerMaxHeight={containerHeight??500}
                table={{ columns, rows }}
                showTotalEntries={true}
                primaryRender={renderAddButton()}
                // secondaryActions={getSecondaryActions()}
                menuActionRender={getMenuActions()}
                isSorted={true}
                newStyle1={true}
                isSelectable={ masterDef.readonly || masterDef.taxonomy ? false : true }
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                onUpdate={handleOnUpdate}
                canFilter={canFilter}
                loading={loading}
                deleteMultiple={ masterDef.readonly || masterDef.taxonomy ? false : true }
                dismissMultiple={ masterDef.readonly || masterDef.taxonomy ? false : true }
                onDeleteMultiple={() => handleDeleteMultiple(selectedRows)}
                onDismissMultiple={ () => handleDismissMultiple(selectedRows)}
              />

              {progress && (
                <CircularProgress size={70} sx={() => ({ color: "#1A73E8", backgroundColor: "transparent", position: 'absolute', top: 350, left: 900, zIndex: 1, })} />
              )}
            </Card>
          </>
        }
        </MDBox>
    </>
  );
};

export default AnimatedRoute(RecommendationScreen);
