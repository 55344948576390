import { AnimatedComponentInline } from "components/AnimatedComponent";
import MDBox from "components/MDBox";
import ChartRenderer from "components/VisualizationRenderer/components/ChartRenderer";

const graphAnswerStyles = () => ({
    width: 550,
    height: 250,
    border: "1px solid #ddd",
    borderRadius: "4px",
    overflow: "hidden",
    pt: 2,
    px: 2
});

const GraphAnswer = ({ data, vizOptions }) => {
    if (data && Array.isArray(data)) {

        const resultSet = { result: data };
        resultSet["tablePivot"] = function () {
            return this.result
        }
        return <MDBox sx={theme => graphAnswerStyles(theme)}>
            <ChartRenderer
                chartType={vizOptions.chartType}
                vizOptions={vizOptions}
                resultSet={resultSet}
            />
        </MDBox>
    }

    return (
        <span></span>
    );
}

export default AnimatedComponentInline(GraphAnswer);