import React, { useContext, useEffect } from 'react'
import { useInitializeSocket } from 'hooks/useInitializeSocket';

export const SocketContext = React.createContext()

export function SocketProvider({ children }) {

    const { socket, socketConnected, initializeSocket, disconnectSocket } = useInitializeSocket();

    useEffect(() => disconnectSocket, [disconnectSocket]);

    const contextValue =
        // useMemo(
        //     () => 
        ({
            initializeSocket,
            disconnectSocket,
            socket,
            socketConnected
        })
    //     ,
    //     [initializeSocket, disconnectSocket, socket]
    // );


    return (
        <SocketContext.Provider value={contextValue}>
            {children}
        </SocketContext.Provider>
    )
}

export const useSocketContext = () => {
    return useContext(SocketContext);
};