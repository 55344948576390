import { useCallback, useEffect } from 'react';
import { useSocketContext } from 'context/SocketContext';
import { useNotificationsContext } from 'context/NotificationsContext';
import { useYADialog } from 'components/YADialog';
import { notificationEventEmitter } from 'utils/notificationEventEmitter';
import { useNavigate } from 'react-router-dom';

const useNotificationManager = () => {
    const navigate = useNavigate();
    const { socket } = useSocketContext();
    const { addNotification, notificationsCount } = useNotificationsContext();
    const { showNotification } = useYADialog();

    useEffect(() => {
        socket?.off("notification");
        socket?.on("notification", (notification) => {
            setTimeout(() => {
                const exisitngNotification = addNotification(notification);
                notificationEventEmitter.emit(`notify_${notification?.type}`, notification);
                if (!exisitngNotification)
                    showNotification(notification.message, notification);
            }, 2000);
        });

        socket?.off("broadcast");
        socket?.on("broadcast", (notification) => {
            if (notification.type === "LICENSE_EXPIRED") {
                navigate("/logout", { replace: true });
            }
            else {
                const exisitngNotification = addNotification(notification);
                if (!exisitngNotification)
                    showNotification(notification.message, notification);
            }
        });

    }, [socket]);

    return notificationsCount;
};

export const useNotificationSubscription = (subscriptionType, subscriptionCallback) => {

    const handleSubscriptionCallback = useCallback((details) => subscriptionCallback(details), [subscriptionCallback]);

    useEffect(() => {
        notificationEventEmitter.on(`notify_${subscriptionType}`, handleSubscriptionCallback);
        return () => {
            notificationEventEmitter.off(`notify_${subscriptionType}`, handleSubscriptionCallback);
        };
    }, []);

    return null;
};

export default useNotificationManager;
