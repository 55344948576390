import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import _ from "lodash";
import useFetchRequest from "hooks/useFetchRequest";
import { useState } from "react";


const ParamterMapping = (props) => {
    const { functionName, destinationColumnOptions, setFunctionParameters, funParamString } = props;
    const [mappingFields, setMappingFields] = useState([]);
    // const [mappingErrors, setMappingErrors] = useState([]);
    const [flag, setFlag] = useState(false);



    const { response: data } = useFetchRequest('/api/uploadRules/listFunctions');
    var params = [];
    if (data) {
        const paramRegex = /\((.*?)\)/;
        const functionNamewithParams = data?.find((func) => func.value === functionName)?.name;
        const match = functionNamewithParams.match(paramRegex);
        params = match[1].split(',').map(param => param.trim());

    }



    if (params?.length > 0 && funParamString && !flag) {
        const funParams = funParamString.split(',');
        let arr = [];
        funParams.forEach((param, i) => {
            arr.push({ index: i, parameter: params[i], selectedColumn: param })
        })
        setMappingFields(arr);
        // setParamList(params);
        setFlag(true);
    }

    // const validateMapping = () => {
    //     let requiredFieldsMapped = true;
    //     let mappingErrorsArr = [];

    //     console.log( "invalidFields");
    //     params.forEach((fld) => {
    //         const mappedField = _.find(mappingFields, { parameter: fld });
    //         if (!mappedField?.selectedColumn) {
    //             if (requiredFieldsMapped)
    //                 requiredFieldsMapped = false;
    //             mappingErrorsArr.push(fld);
    //         }
    //     });

    //     if (mappingErrorsArr?.length > 0)
    //         setMappingErrors(mappingErrorsArr);

    //     return requiredFieldsMapped;
    // }


   



    const chooseParameter = (param, value, index) => {
        let arr = [...mappingFields];
        if (param) {

            if (arr[index]) {
                arr[index].selectedColumn = value;
            } else {
                arr.push({ index: index, parameter: param, selectedColumn: value })
            }

        }
        setMappingFields(arr);
        setFunctionParameters(arr);
        // setParamList(params);

    }

    return (
        <>
            
                <MDBox height="100%" width="100%" overflow="scroll" p={3}>
                    <MDBox>

                        {
                            params?.map((param, i) => {
                                var selectedColumn;
                                if (mappingFields.length > 0) {
                                    selectedColumn = _.find(mappingFields, { parameter: param })?.selectedColumn;
                                }
                                // const hasError = mappingErrors?.includes(param);
                                return (
                                    <MDBox key={`key${i}`} display="flex" pb={1.5}>
                                        <MDBox sx={{ "&:hover": { "& .helpIcon": { visibility: 'visible' } } }} flex={1}>
                                            <MDTypography variant="caption" fontWeight="medium" color="text">{param}</MDTypography>

                                        </MDBox>
                                        <MDBox flex={1}>
                                            <Autocomplete
                                                // disableClearable
                                                value={selectedColumn || ""}
                                                options={destinationColumnOptions.map((option) => option.label)}
                                                onChange={(event, newValue) => {
                                                    chooseParameter(param, newValue, i)
                                                }}
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        height: 42
                                                    },
                                                    "& .MuiOutlinedInput-input": {
                                                        fontSize: 13
                                                    }
                                                }}
                                                renderInput={(params) => <MDInput error={""} helperText={""} placeholder="please choose" {...params} />}
                                            />
                                            {/* {errors[destField] && <MDTypography variant="caption" color="error">Please select a Source Field or 'Ignored'</MDTypography>} */}
                                        </MDBox>
                                    </MDBox>)
                            })
                        }
                    </MDBox>
                </MDBox>

            

        </>
    );
}

export default ParamterMapping;