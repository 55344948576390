const colors = {
  background: {
    default: "#ffffff",//"#f0f2f5",
  },

  login: {
    main: "#facd35",
    focus: "#e2bd42",
  },

  text: {
    main: "#333333",
    focus: "#6d728c",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#000000",
    focus: "#e91e63",
  },

  secondary: {
    main: "#7b809a",
    focus: "#8f93a9",
  },

  info: {
    main: "#1A73E8",
    focus: "#1662C4",
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "#333333",
    focus: "#2c3c58",
  },

  yellow: {
    main: "#facd35",
    focus: "#e2bd42",
  },

  filter: {
    main: "#eaeaea",
  },

  progress: {
    main: "#435EC3",
    focus: "#435EC3",
  },

  dprogress: {
    main: "#3696e0",
  },

  tableBorder: "#bababa",
  graphColors: [
    '#dedede',
    '#435cc8',
    '#fedb65',
    '#ffa500',
    '#19988b'
  ],
  graphColorsMultiple: [
    '#242F9B',
    '#4F60FD',
    '#646FD4',
    '#9BA3EB',
    '#DBDFFD',
  ],
  pieColors: [
    '#5A69ED',
    '#EE9672',
    '#EEC7B7',
    "#CBBEB9",
    '#EED3C8', 
    '#F2EAD0'
  ],
  areaColors1: [
    '#EED3C8',
    '#EEAF96',
    '#EE8A62',
  ],
  areaColors2: [
    '#CED1ED',
    '#ADB3ED',
  ],
  horizontalStackedGraphColors3: [
    '#EED3C8',
    '#EEAF96',
    '#EE8A62',
  ],
  horizontalStackedGraphColors1: [
    '#CED1ED',
    '#ADB3ED',
    '#7B87ED',
    '#4F60FD',
    '#3443BC',
  ],
  horizontalStackedGraphColors2: [
    '#F2EAD0',
    '#F2DF9D',
    '#F3CF56',
    '#EBB842',
    '#D1960A',
  ],
  horizontalStackedGraphColors4: [
    "#B4BDD0",
    "#9BA7C0",
    "#8291B0",
    "#5E7197",
    "#56688A"
  ],
  horizontalStackedGraphColors5: [
    "#B2EBF2",
    "#80DEEA",
    "#4DD0E1",
    "#00BCD4",
    "#00ACC1"
  ],
  horizontalStackedGraphColors6: [
    "#D7E363",
    "#CDDC39",
    "#C0CA33",
    "#AFB42B",
    "#9E9D24"
    
  ],
  horizontalStackedGraphColors7: [
    "#CBBEB9",
    "#BAA9A1",
    "#A8938A",
    "#8D7468",
    "#756057"
  ],
  multipleStackColors: [
    '#dedede',
    '#435cc8',
  ],
  multipleStackGroupedColors: [
    '#242F9B',
    '#4F60FD',
    '#646FD4',
    '#9BA3EB',
    '#DBDFFD',

  ],
  treemapColors: [
    '#171C4A',
    '#1D235C',
    '#222A6E',
    '#283180',
    '#2D3791',
    '#333EA3',
    '#3945B5',
    '#3E4CC7',
    '#4453D9',
    '#4A5AED',
    '#4F60FC',
    '#5A69ED',
    '#6B78ED',
    '#7B87ED',
    '#8C96ED',
    '#9CA4ED',
    '#ADB3ED',
    '#BEC2ED',
    '#CED1ED',
    '#DFE0ED',
  ],
  sankyColors: [
    "#dedede",
    '#D8726E',
    '#EB918D',
    '#FBBAB7',
    '#EE7D50',
    '#CC6745',
    '#EEA183',
    '#EEC7B7',
    '#D1960A',
    '#EBB842',
    '#F3CF56',
    '#F2DF9D',
    '#3443BC',
    '#4F60FD',
    '#7B87ED',
    '#ADB3ED',
    '#1857B6',
    '#5397FD',
    '#7EAAED',
    '#ADC7ED',
  ],
  providerColors: {
    'AWS': ['#f49c1c', '#F9C577'], 
    'GCP': ['#35ac55', "#73D48C"],
    'AZURE': ['#3cc5f1', "#D9F4FD"],
    'ORACLE': ['#ee2026', '#F68D91'],
    'IBM': ['#44e9d9', '#C8F9F4' ],
    'ALIBABA': ['#ff6a03', '#FFA05C'],
    'AZURE_MARKET_PLACE' : ['#2c347b', '#7881CE'],
    "0": ["#EE9672", "#EED3C8"],
    "1": ["#D4D2F9", "#eaeafb"],
    "2": ["#F7C8BA", "#fcfcfc"],
    "3": ["#999999", "#c2c2c2"],
    "4": ["#d2aeef", "#eae9f6"],
    "5": ["#F3CF56", "#F2EAD0"],
    "6": ["#ffc2d1", "#fff6f5"],
    "7": ["#84dcc6", "#b2e8dd"],
    "8": ["#eaf2d7", "#f9f9f4"],
    "9": ["#f6ac69", "#fce6d4"],
    "10": ["#bde0fe", "#dfeffe"],
    "11": ["#caffbf", "#eafbe9"],
    "12": ["#5caccc", "#99deeb"],
    "13": ["#8294C4", "#a9b5c8"],
    "14": ["#ACB1D6", "#d3d6e0"],
    "15": ["#BA94D1", "#ebd3e9"],
    "16": ["#80558C", "#af8da1"],
    "17": ["#6dc5ff", "#a2dfff"],
    "18": ["#D3D04F", "#e7e7b5"],
    "19": ["#61764B", "#8a9673"],
    "20": ["#D14D72", "#eb849e"],
    "21": ["#FF8FB1", "#ffeded"],
    "22": ["#5A69ED", "#CED1ED"],
    "23": ["#B07576", "#c6b5b7"],
    "24": ["#ffb300", "#ffd99a"],
    "25": ["#A555EC", "#ceb2ff"],
    "26": ["#19A7CE", "#8ddfff"],
    "27": ["#FF74B1", "#ffc3d1"],
    "28": ["#c6c0a9", "#deded8"],
    "29": ["#243763", "#4b5670"],
    "30": ["#EFA3C8", "#fbdde7"],
    "31": ["#790252", "#96106f"],
    "32": ["#8696FE", "#b3caff"],
    "33": ["#BFDB38", "#e0eabb"],
    "34": ["#301E67", "#4f3e95"],
    "35": ["#FF7000", "#ffbf66"]
  },
  chartBackground: "#FFFFFF",
  dashboardBackground: "#F7F9FD",
  linkColour: "#435cc8",
  linkBackColour: "#f0f2ff",

  singleDataColors: [
    '#435cc8',
    '#fedb65',
  ],
  varianceColors: [
    '#2e5d0d',
    '#cd080f'
  ],
  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },
  stackedgraphcolors: [
    '#B4BBFD',
    '#435cc8',
    '#fedb65',
    '#ffa500',
    '#D8726E',
    '#495057',
  ],

  gradients: {
    primary: {
      main: "#EC407A",
      state: "#D81B60",
    },

    secondary: {
      main: "#747b8a",
      state: "#495361",
    },

    info: {
      main: "#49a3f1",
      state: "#1A73E8",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#42424a",
      state: "#191919",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },

  },

  avatarColors: {
    0: ['#FFEDAF', '#000000'],
    1: ['#D4D2F9', '#000000'],
    2: ['#F7C8BA', '#000000'],
    3: ['#999999', '#000000'],
    4: ['#d2aeef', '#000000'],
    5: ['#ff8fab', '#000000'],
    6: ['#ffc2d1', '#000000'],
    7: ['#84dcc6', '#000000'],
    8: ['#eaf2d7', '#000000'],
    9: ['#f6ac69', '#000000'],
    10: ['#bde0fe', '#000000'],
    11: ['#caffbf', '#000000'],
    12: ['#5caccc', '#ffffff'],
    13: ['#8294C4', '#ffffff'],
    14: ['#ACB1D6', '#000000'],
    15: ['#BA94D1', '#000000'],
    16: ['#80558C', '#ffffff'],
    17: ['#6dc5ff', '#ffffff'],
    18: ['#D3D04F', '#000000'],
    19: ['#61764B', '#ffffff'],
    20: ['#D14D72', '#ffffff'],
    21: ['#FF8FB1', '#000000'],
    22: ['#FFE15D', '#000000'],
    23: ['#B07576', '#ffffff'],
    24: ['#ffb300', '#000000'],
    25: ['#A555EC', '#ffffff'],
    26: ['#19A7CE', '#000000'],
    27: ['#FF74B1', '#000000'],
    28: ['#c6c0a9', '#000000'],
    29: ['#243763', '#ffffff'],
    30: ['#EFA3C8', '#000000'],
    31: ['#790252', '#ffffff'],
    32: ['#8696FE', '#ffffff'],
    33: ['#BFDB38', '#000000'],
    34: ['#301E67', '#ffffff'],
    35: ['#FF7000', '#ffffff']
  },

  spmRagColors : {
      Green: ['#f0faed','#376e25'],
      Amber: ['#fff5eb','#ff8000'],
      Red  : ['#fff0f0','#fc3f3f'],
      Grey : ['#B5C0D0', '#B4B4B8']
  },

  yesNoColors : {
    Yes: ['#f0faed','#376e25'],
    No  : ['#fff0f0','#fc3f3f'],
    Grey : ['#B5C0D0', '#B4B4B8']
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
