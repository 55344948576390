import { Card, Icon, IconButton, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from 'components/DataTable';
import MDTypography from "components/MDTypography";
import fetchRequest from "utils/fetchRequest";
import { useState, useEffect } from "react";
import useHandleError from "hooks/useHandleError";
import { useYADialog } from "components/YADialog";
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';

const TeamMemberPopup = (props) => {
    const { handleTeamMemberClose, data, buildColumns, buildRows, defaultDateFormat } = props
    const [selectedCategoryDef, setSelectedCategoryDef] = useState(null)
    const [masterDef, setMasterDef] = useState(null)
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([])
    const [step, setStep] = useState('LOADING');
    const handleError = useHandleError();
    const [row, setRow] = useState(null)
    const { showForm, showAlert, showPrompt, showSnackbar } = useYADialog();
    const [refresh, setRefresh] = useState(null);
    const [displayName, setDisplayName] = useState("")
    const [singularDisplayName,setSingularDisplayName]= useState("")
    const [desc, setDesc] = useState("")

    const handleDelete = (pkId) => {
        showPrompt('Delete', 'Are you sure you want to delete?', () => handleDeleteSuccess(pkId));
    };

    const handleClose = () => {
        setRefresh(Math.random());
    };

    useEffect(() => {
        setSelectedCategoryDef(data.selectedCategoryDef)
        setRow(data.row)

    }, [])

    useEffect(() => {
        async function getMasterDef() {
            if (selectedCategoryDef && selectedCategoryDef.additonallink) {
                var [err, data1] = await fetchRequest.get(`/api/master/${selectedCategoryDef.additonallink}`);
                if (err) {
                    handleError(err);
                } else {
                    let field = []
                     data1.fields.forEach(element => {
                    if (element.dataSource.object == selectedCategoryDef.master) {
                        data1.slectedfield = element.name
        
                    } else {
                        selectedCategoryDef.fieldDisplayName ? element.displayName = selectedCategoryDef.fieldDisplayName : element.dataSource.displayName
                        field.push(element)
                    }
                    data1.fields = field
                    data1.slectedvalue = row.id
                    selectedCategoryDef.duplicateEntryMsg ? data1.duplicateEntryMsg = selectedCategoryDef.duplicateEntryMsg : ""
                });
                    setMasterDef(data1);
                    setDisplayName(data1.displayName)
                    setDesc(data1.desc)
                    setSingularDisplayName(data1.singularDisplayName)
                }
            }

        }
        getMasterDef();
    }, [selectedCategoryDef, refresh]);

    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/master/${selectedCategoryDef.additonallink}/list`);
            if (err) {
                handleError(err);
            } else {
                if (data && Array.isArray(data) && data.length > 0) {
                    let dataFilterd = selectedCategoryDef.SchemaName ? data.filter(item => item[selectedCategoryDef.SchemaName] === row.name) : data
                    if (dataFilterd.length > 0) {
                        setColumns(buildColumns(masterDef, defaultDateFormat));
                        setRows(buildRows(masterDef.pkColumn || 'id', dataFilterd, false, handleDelete));
                        setStep('LOADED');
                    } else {
                        setStep('EMPTY');
                    }
                    
                } else {
                    setStep('EMPTY');
                }
            }
        }
        if (masterDef) {
            getList();
        }
    }, [masterDef, refresh]);

    const deleteMaster = async (pkId) => {
        const [err, data] = await fetchRequest.delete(`/api/master/${selectedCategoryDef.additonallink}/${pkId}`);
        if (err) {
            showAlert('Delete', 'Something went wrong. Contact your administrator.');
        }
        else
            if (data && data.result === true) {
                showSnackbar('Data deleted successfully', 'success');
                handleClose();
            }
            else if (data && data.result === false) {
                showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
            }
    };

    const handleDeleteSuccess = (pkId) => {
        deleteMaster(pkId);
    };

    const handleAddButtonClick = () => {
        showForm(selectedCategoryDef.formTitle ? selectedCategoryDef.formTitle :'Add', masterDef, handleClose,);
    };

    const renderPrimaryActions = masterDef ? (
        <MDButton
            variant="outlined"
            color="info"
            startIcon={<Icon>add</Icon>}
            onClick={handleAddButtonClick}
        >
            Add New
        </MDButton>
    ) : undefined;

    const renderAddButton = () =>
    !masterDef.readonly ? (
      <MDButton
        variant="outlined"
        color="info"
        startIcon={<Icon>add</Icon>}
        onClick={handleAddButtonClick}
      >
        Add New
      </MDButton>
    ) : undefined;

    const handleAddRuleClose = () => {
        if (handleTeamMemberClose)
            handleTeamMemberClose()
    }
    return (
        <Modal open={true} onClose={handleAddRuleClose} >
            <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Card sx={{ minHeight: "600px", width: "800px", overflow: 'hidden' }}>
                    <MDBox pt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography px={3} variant="button" color="dark" fontWeight="medium">{displayName} <br />
                            <MDTypography mb={1} variant="button" color="text">{desc}</MDTypography></MDTypography>
                        <MDBox display="flex" px={2}>
                            <IconButton onClick={handleAddRuleClose} title="Close">
                                <Icon>close</Icon>
                            </IconButton>
                        </MDBox>
                    </MDBox>
                    <MDBox height="100%" pb={2} >
                        <MDBox px={1} pt={1} height="478px" display="flex" flexDirection="column" alignItems="flex Start" sx={{ overflowY: "auto" }}>
                            {step === 'LOADED' && (
                                <DataTable
                                    table={{ columns, rows }}
                                    showTotalEntries={true}
                                    isSorted={true}
                                    newStyle1={true}
                                    entriesPerPage={true}
                                    canSearch={true}
                                    canFilter={true}
                                    hideFooterForMinRecords={true}
                                    primaryActions={renderPrimaryActions}
                                />
                            )}
                            {step === 'EMPTY' && (
                                <EmptyState
                                    size="large"
                                    image={new_item_img}
                                    title={`No ${displayName} Yet`}
                                    description={
                                        !masterDef.readonly
                                            ? `Click on the '+ add new' button to add a new ${(
                                                singularDisplayName || displayName
                                            ).toLowerCase()}.`
                                            : undefined
                                    }
                                    actions={renderAddButton}
                                />
                            )}
                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
        </Modal>
    )
}
export default TeamMemberPopup