import { useCallback, useEffect, useMemo } from 'react';
import { Controller } from "react-hook-form";
import MDBox from "components/MDBox";
import FilterSelector from 'components/FilterSelector';
import FilterItem from '../FilterItem';
import { useImmer } from 'use-immer';
import MDTypography from 'components/MDTypography';

function FilterContainerComponent({ filters, preSelectedFilters, setValue }) {

  const [selectedFilters, setSelectedFilters] = useImmer(preSelectedFilters || []);

  const handleSelectFilter = useCallback((selectedFilterVal) => {
    // selectFilter(dispatch, selectedFilter);
    setSelectedFilters(draft => {
      let selectedFilter = filters?.find(f => f.name === selectedFilterVal.name);
      if (selectedFilter)
        draft.push(selectedFilter);
    });
  }, [filters]);

  const handleSetFilter = useCallback((selectedFilterVal) => {
    // setFilter(dispatch, selectedFilter);
    setSelectedFilters(draft => {
      let selectedFilter = draft?.find(f => f.name === selectedFilterVal.name);
      if (selectedFilter) {
        selectedFilter.values = selectedFilterVal.values;
      }
    });
  }, []);

  const handleDeleteFilter = useCallback((selectedFilterName) => {
    // deleteFilter(dispatch, selectedFilterName);
    setSelectedFilters(draft => draft?.filter(f => f.queryName !== selectedFilterName));
  }, []);

  // const handleClearFilters = useCallback(() => {
  //   // clearFilters(dispatch);
  //   setSelectedFilters([]);
  // }, []);

  useEffect(() => {
    const filter = selectedFilters?.map(f => ({ column: f.columnName, operator: "eq", values: f.values })) || [];
    setValue("filter", filter);
  }, [selectedFilters]);

  const availableFields = useMemo(() => {
    const selectedFiltersNames = selectedFilters.map(f => f.name) || [];
    const optinalFilters = (filters || []).filter(f => !f.required);
    return optinalFilters?.filter(f => !selectedFiltersNames.includes(f.name));
  }, [selectedFilters, filters]);

  if ((filters || [])?.length === 0)
    return <span></span>

  const mandatoryFilters = (filters || []).filter(f => f.required);

  return (
    <MDBox display="flex" flex={1} justifyContent="flex-start" alignItems="center" flexWrap="wrap">
      {
        mandatoryFilters?.map((item) => {
          return <FilterItem key={item.name} filter={item} setFilter={handleSetFilter} onDelete={() => handleDeleteFilter(item.queryName)} />
        })
      }
      {
        selectedFilters?.map((item) => {
          return <FilterItem key={item.name} filter={item} setFilter={handleSetFilter} onDelete={() => handleDeleteFilter(item.queryName)} />
        })
      }
      <MDBox>
        <FilterSelector fields={availableFields} onFilterSelect={handleSelectFilter} />
      </MDBox>
    </MDBox>
  )
}

function FilterContainer({ control, defaultFilters, setValue, errorMessage }) {

  return (
    <MDBox>
      <Controller
        name={"filter"}
        control={control}
        render={({ field: { value } }) => {
          const preSelectedFilters = (value || [])
            .map(sf => {
              const isSelected = defaultFilters?.find(f => sf.column === f.columnName);
              return ({ ...isSelected, defaultSelected: true, values: sf.values });
            });
          return <FilterContainerComponent filters={defaultFilters || []} preSelectedFilters={preSelectedFilters} setValue={setValue} />
        }}
      />
      {
        errorMessage && (
          <MDTypography variant="button" color="error">{errorMessage}</MDTypography>
        )
      }
    </MDBox>
  )
}

export default FilterContainer;