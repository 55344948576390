import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
import Textbox from "components/YAForm/components/Textbox";
import DatePicker from "components/YAForm/components/DatePicker";
import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import { useYADialog } from "components/YADialog";
import Dropdown from "components/YAForm/components/Dropdown";

const BusinessCaseForm = (props) => {
    const { pkId, mode, onClose } = props;
    const [loading, setLoading] = useState(false);
    const domain = getDomain();
    const { showSnackbar } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);


    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm({
        // defaultValues: defautValues
    });

    // const watchedFields = watch(["enableCpi", "enableLpi"]);
    // const watchedFieldsObj = useMemo(() => {
    //     return {
    //         enableCpi: watchedFields[0],
    //         enableLpi: watchedFields[1]
    //     };
    // }, watchedFields)
    let strategyList = [ 
        {"id": "1", "value": "Rehosting", "label" : "Rehosting"},
        {"id": "2", "value": "Replatforming", "label" : "Replatforming"},
        {"id": "3", "value": "Repurchasing", "label" : "Repurchasing"},
        {"id": "4", "value": "Retiring", "label" : "Retiring"},
        {"id": "5", "value": "Retaining", "label" : "Retaining"},
        {"id": "6", "value": "Re-Architecting", "label" : "Re-Architecting"}
    ];

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/businessCases/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);
        setValue("name", response.data["name"]);
        setValue("description", response.data["description"]);
        setValue("startDate", response.data["startDate"]);
        setValue("endDate", response.data["endDate"]);
        setValue("solutionOfferingId", response.data["solutionOfferingId"]);
        setValue("objective", response.data["objective"] || false);
        setValue("rStrategy", response.data["rStrategy"]);
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            setValue("name", null);
            setValue("description", null);
            setValue("startDate", null);
            setValue("endDate", null);
            setValue("solutionOfferingId", null);
            setValue("objective", null);
            setValue("rStrategy", null);
        }
    }, [mode]);

    const handleClose = useCallback((businessCaseId) => {
        if (onClose) onClose(businessCaseId);
    }, []);

    const onSubmit = async data => {
        const url = mode === "edit" ? `${domain}/api/businessCases/${pkId}` : `${domain}/api/businessCases/new`;
        const response = await Axios.post(url, data);
        if (response.data && response.data.result === false) {
            if (Array.isArray(response.data.errors) && response.data.errors.length > 0) {
                response.data.errors.forEach((e) => {
                    setError(e.field, { type: "manual", message: e.message });
                });
            }
        }
        else {
            handleClose(response.data?.businessCaseId);
            onDialogClose();
            showSnackbar(response.data.message, "success");
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={400}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }

        return (
            <MDBox pt={1} px={3} pb={2} width={475}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox mb={3}>
                            <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ name: "name", displayName: "Business Case Name", required: true, unique: true, toolTip: "The title or name of the business case.", impact: "", severity: "" }} errorMessage={errors["name"] && errors["name"].message} />
                        </MDBox>
                        <MDBox mb={5}>
                        <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ name: "description", displayName: "Description", required: true, unique: false, toolTip: "A brief description of the business case.", impact: "", severity: "" }} errorMessage={errors["description"] && errors["description"].message} />
                        </MDBox>
                        <MDBox mb={5}>
                        <DatePicker watch={watch} setValue={setValue} control={control} view={['year', 'month', 'day']} fieldDef={{ name: "startDate", displayName: "Start Date", required: true, dataSource: { dependentFields: ["endDate"] }, toolTip: "The actual start date of the business case.", impact: "", severity: "" }} errorMessage={errors["startDate"] && errors["startDate"].message} />
                        </MDBox>
                        <MDBox mb={5}>
                        <DatePicker watch={watch} setValue={setValue} control={control} view={['year', 'month', 'day']} fieldDef={{ name: "endDate", displayName: "End Date", required: true, dataSource: { parentFields: ["startDate"] }, minimumDate: true, toolTip: "The estimated completion date for the business case.", impact: "", severity: ""  }} errorMessage={errors["endDate"] && errors["endDate"].message} />
                        </MDBox>
                        <MDBox mb={3}>
                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "solutionOfferingId", displayName: "Solution Offering", required: false, unique: false, toolTip: "The solution offering associated with the business case.", impact: "", severity: "", dataSource: { type: "custom", url: "/api/businessCases/lookup/solutionOffering" }}} errorMessage={errors["solutionOfferingId"] && errors["solutionOfferingId"].message} />
                        </MDBox>
                        <MDBox mb={3}>
                            <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ name: "objective", displayName: "Objective", required: true, unique: false , toolTip: "The information about the primary goal that business case aims to achieve.", impact: "", severity: "" }} errorMessage={errors["objective"] && errors["objective"].message} />
                        </MDBox>
                        <MDBox mb={3}>
                            <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "rStrategy", displayName: "Migration Strategy", required: false, dataSource: { type: "static", data: strategyList }, toolTip: "The planned approach to migrate existing systems, data, or applications.", impact: "", severity: "" }} errorMessage={errors["rStrategy"] && errors["rStrategy"].message} />
                        </MDBox>
                    </MDBox>
                    <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};

export default BusinessCaseForm;