import { Outlet, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import PageNavbar from "layouts/PageLayout/components/PageNavbar";
import ErrorBoundary from "components/ErrorBoundary";
import { useRef } from "react";
import HelpSidebar from "./components/HelpSidebar";
import { Fab, Icon, Tooltip, Zoom, useTheme } from "@mui/material";
import { useYADialog } from "components/YADialog";
import { useAppController } from "context";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const AskMeButton = () => {
  const theme = useTheme();
  const { showGlobalSearch } = useYADialog();
  const [controller,] = useAppController();
  const { askYarkenEnabled, showHelpSideBar, showinapphelp } = controller;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  if (!askYarkenEnabled)
    return <span></span>

  return (
    <Zoom
      in
      timeout={transitionDuration}
      style={{
        transitionDelay: `${transitionDuration.exit}ms`,
        marginRight: showinapphelp && showHelpSideBar ? 360 : 0
      }}
      unmountOnExit
    >
      <Tooltip title="Ask YäRKEN" placement="left">
        <Fab disableRipple={true} disableTouchRipple sx={fabStyle} aria-label={"Ask YäRKEN"} color={"primary"} onClick={showGlobalSearch}>
          <Icon fontSize="medium">question_answer</Icon>
        </Fab>
      </Tooltip>
    </Zoom>
  );
}

const LicenseExpiredBanner = () => {
  const navigate = useNavigate();
  const [controller,] = useAppController();
  const { licenseExpired } = controller;

  if (!licenseExpired)
    return <></>

  return <MDBox bgColor="red" height={56} display="flex" alignItems="center" justifyContent="center" gap={2}>
    <MDTypography variant="button" fontWeight="medium" component="p" color="white">{"Unfortunately your license has expired. Renew your license."}</MDTypography>
      <MDButton variant="outlined" fontSize="small" onClick={() => navigate("/admin/settings")}>Renew Now</MDButton>
  </MDBox>
}

const PageLayout = () => {
  const contentBodyRef = useRef();
  return (
    <>
      <LicenseExpiredBanner />
      <PageNavbar />
      <MDBox
        sx={() => ({
          height: "calc(100vh - 48px)",
          position: "relative",
          overflow: 'auto'
        })}
      >
        <MDBox ref={contentBodyRef}>
          <ErrorBoundary page>
            <Outlet />
          </ErrorBoundary>
        </MDBox>
      </MDBox>
      <AskMeButton />
      <HelpSidebar contentBodyRef={contentBodyRef} />
    </>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node,//.isRequired,
  title: PropTypes.string,//.isRequired,
  subtitle: PropTypes.string,
  primaryActionComponent: PropTypes.func
};

export default PageLayout;
