import MDBox from "components/MDBox";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "components/DataTable";
import useFetchRequest from "hooks/useFetchRequest";
import YASkeleton from "components/YASkeleton";
import MDTypography from "components/MDTypography";
import moment from "moment";
import EmptyState from "components/EmptyState";
import MDBadge from "components/MDBadge";
import fetchRequest from "utils/fetchRequest";
import { parseJsonString } from "utils";
import { Card, CircularProgress, Icon } from "@mui/material";

const SubRowAsync = ({ alertId, row }) => {
    const alertLogId = row?.original?.id;
    const [step, setStep] = useState("LOADING");
    const [log, setLog] = useState(null);

    useEffect(() => {
        async function getAlertHistoryLog() {
            var [err, data] = await fetchRequest.get(`/api/alert/alertHistoryLog/${alertLogId}`);
            if (err) {
                console.error(err);
                setStep("ERROR");
            }
            else {
                if (data) {
                    if (data.status === "SUCCESS")
                        setLog(parseJsonString(data.response));
                    else
                        setLog(data.error || parseJsonString(data.response));
                    setStep("LOADED");
                }
                else {
                    setStep("EMPTY");
                }
            }
        }
        if (alertLogId)
            getAlertHistoryLog();
    }, [alertId, alertLogId])

    if (step === "EMPTY") {
        return (<MDBox mt={1} pt={4} minHeight={100} textAlign="center" size="20">
            <MDTypography variant="button" fontWeight="bold" color="text">No Data</MDTypography>
        </MDBox>);
    }

    return step === "LOADED" ? (
        <Card sx={{ minHeight: 100, height: "100%", mt: 1, p: 1, whiteSpace: "pre-wrap" }}>
            {
                !log && (
                    <MDBox mt={1} pt={3} textAlign="center" size="20">
                        <MDTypography variant="button" fontWeight="bold" color="text">No Data</MDTypography>
                    </MDBox>
                )
            }
            {log && JSON.stringify(log, null, 4)}
        </Card>
    ) : (<MDBox mt={1} textAlign="center" size="20">
        <CircularProgress color="info" />
    </MDBox>);
}

const AlertRunHistory = (props) => {
    const { alertId } = props;
    const { response: alertHistory, loading } = useFetchRequest(`/api/alert/alertHistory/${alertId}`);

    const columns = useMemo(() => ([
        {
            Header: () => null,
            id: 'expander',
            Cell: ({ row }) => (
                <span {...row.getToggleRowExpandedProps()}>
                    <Icon fontSize="medium">{row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
                </span>
            ),
            width: 50,
            disableSorting: true
        },
        {
            Header: "Last Run Status", accessor: "status", width: 250, Cell: ({ cell: { value } }) => {
                if ((value || "") === "")
                    return <MDTypography variant="caption"></MDTypography>
                return <MDBadge container badgeContent={value} color={value.toLowerCase()} variant="gradient" size="xs" />
            }
        },
        {
            Header: "Last Run", accessor: "createdAt", width: 250, Cell: ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY hh:mm A") : ""}</MDTypography>
            }
        },
    ]), [alertId]);

    const renderRowSubComponent = useCallback(({ row }) => (<SubRowAsync alertId={alertId} row={row} />), [alertId]);

    if (loading) {
        return <MDBox minWidth={700} minHeight={400} display="flex" alignItems="center" justifyContent="center">
            <YASkeleton variant="loading" />
        </MDBox>
    }

    if (!alertHistory || alertHistory?.length === 0) {
        return <MDBox minWidth={700} minHeight={400} display="flex" alignItems="center" justifyContent="center">
            <EmptyState
                size="medium"
                variant="secondary"
                iconName="description"
                description={"No Run History Found"}
            />
        </MDBox>
    }

    return (
        <MDBox minWidth={700}>
            <DataTable
                variant="tile"
                table={{ columns, rows: alertHistory || [] }}
                containerMaxHeight={400}
                showTotalEntries={true}
                isSorted={true}
                entriesPerPage={true}
                canSearch={false}
                renderRowSubComponent={renderRowSubComponent}
            >
            </DataTable>
        </MDBox>
    );
};

export default AlertRunHistory;