import { Icon, IconButton, Stack, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useYADialog } from "components/YADialog";
import { useEffect, useRef, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const micBoxStyles = () => ({
    "@keyframes myEffect": {
        from: {
            transform: "scale(1.0)"
        },
        to: {
            transform: "scale(1.1)"
        }
    },
    width: "23px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: "#ccc",
    cursor: "pointer",
    "& .mic_blinker": {
        position: "absolute",
        left: "-6px",
        height: 32,
        width: 32,
        borderRadius: "50%",
        backgroundColor: "#dad6d6",
        zIndex: 1,
        animation: "myEffect .5s infinite alternate",
    },
    "&:hover": {
        color: "#000"
    },
    "& .stop_icon": {
        position: "absolute",
        zIndex: 2,
        color: "#000"
    }
});

const QuestionBox = ({ connected, submitting, onAskBtnClick }) => {

    const [questionText, setQuestionText] = useState(null);
    const questionTxtRef = useRef();

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable
    } = useSpeechRecognition();

    const { showAlert } = useYADialog();

    useEffect(() => {
        setQuestionText(transcript);
    }, [transcript]);

    useEffect(() => {
        questionTxtRef.current?.focus();
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        SpeechRecognition.stopListening();
        if (onAskBtnClick) {
            onAskBtnClick(questionText);
            setQuestionText(null);
        }
    };

    const disableSendButton = !connected || submitting || String(questionText || "").trim().length < 3;

    return (
        <MDBox borderTop="1px solid #ddd" width="100%" pr={.5}>
            {!connected && <MDTypography
                component="div"
                textAlign="center"
                color="white"
                variant="button"
                fontWeight="light"
                lineHeight={1.5}
                backgroundColor="red"
                position="absolute"
                right={0}
                left={0}
                mt={"-20px"}
            >
                Connection interrupted.
            </MDTypography>}
            <MDBox display="flex">
                <MDBox pl={3.5} pr={2.5} flex={1}>
                    <form onSubmit={handleSubmit} noValidate={true}>
                        <Stack spacing={2} direction={"row"} alignItems={"center"}>
                            <MDBox sx={() => micBoxStyles()}>
                                {listening &&
                                    <>
                                        <MDBox className="mic_blinker">
                                        </MDBox>
                                        <Icon
                                            title="Stop"
                                            className="stop_icon"
                                            fontSize="small"
                                            sx={{ mt: .5 }}
                                            onClick={SpeechRecognition.stopListening}
                                        >mic</Icon>
                                    </>
                                }
                                {!listening &&
                                    <Icon
                                        title="Text to speech"
                                        fontSize="small"
                                        sx={{ mt: .5 }}
                                        onClick={() => {
                                            if (browserSupportsSpeechRecognition && isMicrophoneAvailable) {
                                                questionTxtRef.current?.focus();
                                                resetTranscript();
                                                SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
                                            }
                                            else {
                                                if (!browserSupportsSpeechRecognition)
                                                    showAlert("Error", "Your browser doesn't support microphone. Try again in a different browser.");
                                                else
                                                    showAlert("Enable Microphone", "Your microphone is disabled. Enable it and try again.");
                                            }
                                        }}
                                    >mic</Icon>
                                }
                            </MDBox>
                            <TextField
                                inputRef={questionTxtRef}
                                // multiline
                                value={questionText || ""}
                                fullWidth
                                size="medium"
                                variant="outlined"
                                placeholder="Type your question here..."
                                sx={{
                                    "& .MuiInputBase-input": {
                                        minHeight: "48px",
                                        fontSize: "14px"
                                    },
                                    "& .MuiOutlinedInput-notchedOutline, &:before": {
                                        border: "none"
                                    },
                                }}
                                onChange={({ target: { value } }) => setQuestionText(value)}
                            />
                            <IconButton
                                disabled={disableSendButton}
                                type="submit"
                                size="large"
                                title="Click to send your question"
                                sx={({ palette: { primary, secondary, white } }) => ({
                                    height: 48,
                                    width: 48,
                                    borderRadius: "50%",
                                    backgroundColor: disableSendButton ? `${secondary.main}!important` : `${primary.main}!important`,
                                    color: `${white.main}!important`,
                                    "& .MuiIcon-root": {
                                        transform: "rotate(-20deg)",
                                        ml: .5,
                                        mt: -.5
                                    }
                                })}
                            >
                                <Icon className="material-icons-round">send</Icon>
                            </IconButton>
                        </Stack>
                    </form>
                </MDBox>
            </MDBox>
        </MDBox>
    );
};

export default QuestionBox;