import MDBox from "components/MDBox";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Icon, IconButton, Modal, Card, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";

const FieldDetails = (props) => {
  const { impact, unique, type, setOpen, toolTip, displayName, required, severity } = props
  const fieldDetails = {
    Name: displayName,
    Mandatory: required ? 'Yes' : 'No',
    Unique: unique ? 'Yes' : 'No',
    Type: (type === 'currency') ? 'Amount' : (type === 'switch') ? 'Boolean' : (type === 'datepicker') ? 'Date' : (type === 'float') ? 'Float' : (type === 'number') ? 'Number' : 'Text',
    Description: toolTip,
    'Impact if field left blank': impact ? impact : 'N/A',
    'Severity of impact': severity ? severity : 'N/A'
  }

  return (<>
    <Modal open={open} onClose={() => { setOpen(false) }}>
      <MDBox pt={20} pl={50} pr={50} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <Card sx={{ minHeight: "40%", minWidth: "75%", overflow: 'hidden', position: 'relative' }}>
          <PerfectScrollbar>
            <MDBox>
              <MDBox px={0.8} pt={0.8} display="flex" justifyContent="space-between" alignItems="center" position="absolute" top={0} right={0}>
                <IconButton size="small" onClick={() => { setOpen(false) }} title="Close">
                  <Icon>close</Icon>
                </IconButton>
              </MDBox>
              <TableContainer sx={{ paddingTop: '35px', paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px', borderRadius: '15px' }} component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} align="center" style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                        Field Details
                      </TableCell>
                    </TableRow>
                    {Object.entries(fieldDetails).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell style={{ fontWeight: "bold" }}>{key}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>
          </PerfectScrollbar>

        </Card>

      </MDBox>
    </Modal>

  </>)
}

export default FieldDetails