import _ from "lodash";
import DataTable from "components/DataTable";
import useFetchRequest from "hooks/useFetchRequest";
import numeral from "numeral";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { Icon, IconButton, Tooltip, CircularProgress } from "@mui/material";
import { useImmer } from "use-immer";
import { useState, useEffect, useContext } from "react";
import MDBox from "components/MDBox";
import { useAppController } from "context";
import { normalizeCurrency } from "utils/table";
import { useNotificationSubscription } from "hooks/useNotificationManager";
import { NotificationType } from "constants";
import MDButton from 'components/MDButton';
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import DraggableDataTable from 'components/DraggableDataTable';

const GetSortPopup = (props) => {
    const { columns, rows, reloadData, yearFilter, monthFilter, destinationTable, maxWidth } = props
    const [data, setData] = useState([]);
    const [ isSubmitting, setIsSubmitting ] = useState(false)
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const { showAlert, showSnackbar } = useYADialog();
  
    const sortSequence = data.map( arr => {
      return {id: arr.id, sequence: arr.sequence}
    })

    const onApplyRules = async() => {
        setIsSubmitting(true)
        let [err, response] = await fetchRequest.post(`/api/dataflow/rules/${destinationTable}/${yearFilter}/${monthFilter}/applySort`, {data: sortSequence});
        if (err) {
            if (err.data?.message) {
                setIsSubmitting(false)
                onDialogClose();
                reloadData();
                showSnackbar(err.data?.message, "error")
            } else {
                setIsSubmitting(false)
            showAlert("Apply Sort", "Something went wrong. Contact your administrator.");
            }
        } else {
            setIsSubmitting(false)
            onDialogClose();
            reloadData();
            showSnackbar(response.message, "success");
        }
    }
  
    const applyBtnRenderer = () => {
        return (
          <MDButton 
              startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} 
              disabled={isSubmitting ? true : data.length ? false : true} 
              onClick={() => onApplyRules()} 
              color={ "info"} 
              sx={{mr:5}} 
              variant="contained"
          >
              Apply Sort
          </MDButton>
        );
    }

    // rows.map( row => row.id = `${row.id}`)
    return (
      <MDBox  pb={3}
      minWidth="800px"
      width="100%"
      >
        <DraggableDataTable
            primaryRender={applyBtnRenderer()}
            subTitle={"Sort the rules by drag and drop"}
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            canSearch={true}
            sortedData={setData}
            sortColumn={"sequence"}
            maxWidth={maxWidth}
      />
    </MDBox>
    )
}

const CostPoolMappingView = (props) => {
    const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
    const { yearFilter, yearFilterName, monthFilter, monthFilterName,id, containerHeight, typeFilter, setRefresh, chargeBackMonthly, setAction, download } = props;
    const [filtersState, setFiltersState] = useImmer({
        globalFilter: undefined,
        filters: [],
    });
    const [controller] = useAppController();
    const { appDef: { settings } } = controller;
    const chargeBack = _.find(chargeBackMonthly, { monthNameId: monthFilter, yearNameId: yearFilter }) ? true : false

    const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState((draft) => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    };
    const { response: uploadedFiles, error: _err, loading: loading, reloadData, } = typeFilter === 'Spend'
        ?
        (id ? useFetchRequest(`/api/dataflow/costPoolMapping/${yearFilter}/${monthFilter}?costPoolId=${id}`) : useFetchRequest(`/api/dataflow/costPoolMapping/${yearFilter}/${monthFilter}`))
        :
        (id ? useFetchRequest(`/api/dataflow/costPoolRulesBudget/${yearFilter}/${monthFilter}?costPoolId=${id}`) : useFetchRequest(`/api/dataflow/costPoolRulesBudget/${yearFilter}/${monthFilter}?allocated=${true}&&type=${typeFilter.toLowerCase()}`));


    const handleDelete = (item) => {
        showPrompt(
            "Delete",
            `Are you sure you want to delete [${item["costPool__name"]} (${item["subCostPool__name"]}) with Priority ${item["priority"]}]`,
            async () => {
                var [err, data] = await fetchRequest.delete(
                    `/api/dataflow/costPoolMapping/${item["id"]}`
                );
                if (err) {
                    if (err.data?.message) {
                        showSnackbar(err.data?.message, "error")
                        setRefresh(Math.random());
                        reloadData()
                    } else {
                        showAlert("Delete", "Something went wrong. Contact your administrator.");
                    }
                } else if (data) {
                    showSnackbar(data, "success");
                    if (setRefresh) {
                        setRefresh(Math.random());
                    }
                    reloadData();
                }
            }
        );
    };

    const handleDeleteMultiple = (selectedRows) => {
        const cpIdsFromCUR = uploadedFiles.map(i => i.source === 'cloud' ? i.id : null)
        _.pullAll(selectedRows, _.compact(cpIdsFromCUR))
        showPrompt("Delete", "Are you sure you want to delete?", () =>
            deleteCostPoolMappingMultipleRecords(JSON.stringify(selectedRows))
        );
    };

    const deleteCostPoolMappingMultipleRecords = async (selectedRows) => {
        const [err, data] = await fetchRequest.post(
            `/api/dataflow/costPoolMapping`,
            selectedRows
        );

        if (err) {
            if (err.data?.message) {
                showSnackbar(err.data?.message, "error")
                setRefresh(Math.random());
                reloadData()
            } else {
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
        } else if (data) {
            showSnackbar(data, "success");
            if (setRefresh) {
                setRefresh(Math.random());
            }
            reloadData();
        }
    };

    useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
        if (notification.details?.destinationTable && notification.details?.destinationTable === "costPoolMapping") {
            reloadData();
        }
    });

    let columns = [];
    if (typeFilter === "Spend")
        columns = [
            // { Header: "ID", accessor: "id", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            {
                Header: "Delete",
                disableSorting: true,
                accessor: "id",
                disableFilters: true,
                hidden: chargeBack,
                maxWidth: 70,
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            {
                                <IconButton
                                    sx={{ padding: 0 }}
                                    onClick={() => original?.source === 'cloud' ? null : handleDelete(original)}
                                >
                                    <Icon color={original?.source === 'cloud' ? 'disabled' : "error"} >delete</Icon>
                                </IconButton>}
                        </>
                    );
                },
            },
            {
                Header: "Priority",
                accessor: "priority",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            { Header: "Filter", accessor: "filterCondition", disableFilters: true, Cell: ({ cell: { value } }) => { return (value || "") !== "" ? <Tooltip sx={{ px: 0, m: 0 }} placement="top" title={value}><Icon sx={{ px: 0, m: 0 }} fontSize="small" color="text">info</Icon></Tooltip> : null } },
            {
                Header: "Cost Pool",
                accessor: "costPool__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark" fontWeight="medium">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Sub Cost Pool",
                accessor: "subCostPool__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark" fontWeight="medium">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Amount",
                accessor: "exAmount",
                dataType: "currency",
                disableFilters: false,
                align: "right",
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {numeral(normalizeCurrency(value)).format("$0,0")}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Source",
                accessor: "source",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "File",
                accessor: "uploadedFile__originalFileName",
                disableFilters: true,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Created On",
                accessor: "createdAt",
                disableFilters: true,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Created By",
                accessor: "createdByUser__name",
                disableFilters: true,
                Cell: ({ cell: { value } }) => {
                    if (!value) {
                        return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography>
                    }
                    else {
                        return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>
                    }
                },
            },
        ];
    else
        columns = [
            // { Header: "ID", accessor: "id", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            // {
            //     Header: "Delete",
            //     disableSorting: true,
            //     accessor: "id",
            //     disableFilters: true,
            //     hidden: chargeBack,
            //     maxWidth: 70,
            //     Cell: ({ row }) => {
            //         return (
            //             <IconButton
            //                 sx={{ padding: 0 }}
            //                 onClick={() => handleDelete(row.values)}
            //             >
            //                 <Icon color="error">delete</Icon>
            //             </IconButton>
            //         );
            //     },
            // },
            {
                Header: "Expense Type",
                accessor: "expenseType__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Account Code",
                accessor: "account__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Account Description",
                accessor: "account__description",
                dataType: "string",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Center Code",
                accessor: "costCentre__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Center Name",
                accessor: "costCentre__description",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Vendor Code",
                accessor: "vendor__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Vendor Name",
                accessor: "vendor__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Pool",
                accessor: "costPool__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark" fontWeight="medium">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Sub Cost Pool",
                accessor: "subCostPool__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark" fontWeight="medium">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: typeFilter,
                accessor: "amount",
                dataType: "currency",
                disableFilters: false,
                align: "right",
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {numeral(normalizeCurrency(value)).format("$0,0")}
                        </MDTypography>
                    );
                }
            },
            // {
            //     Header: "File",
            //     accessor: "uploadedFile__originalFileName",
            //     disableFilters: true,
            //     Cell: ({ cell: { value } }) => {
            //         return (
            //             <MDTypography variant="caption" color="dark">
            //                 {value}
            //             </MDTypography>
            //         );
            //     },
            // },
            // {
            //     Header: "Created On",
            //     accessor: "createdAt",
            //     disableFilters: true,
            //     Cell: ({ cell: { value } }) => {
            //         return (
            //             <MDTypography variant="caption" color="dark">
            //                 {value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}
            //             </MDTypography>
            //         );
            //     },
            // },
            // {
            //     Header: "Created By",
            //     accessor: "createdByUser__name",
            //     disableFilters: true,
            //     Cell: ({ cell: { value } }) => {
            //         if (!value) {
            //             return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography>
            //         }
            //         else {
            //             return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>
            //         }
            //     },
            // },
        ];

    const sortColumn = [
        {
            Header: "Priority",
            accessor: "priority",
            dataType: "textbox",
            disableFilters: false,
            Cell: ({ cell: { value } }) => {
                return (
                    <MDTypography variant="caption" color="dark">
                        {value}
                    </MDTypography>
                );
            },
        },
        { 
            Header: "Filter", 
            accessor: "filterCondition", 
            disableFilters: true, 
            Cell: ({ cell: { value } }) => { 
                return (value || "") !== "" ? <Tooltip sx={{ px: 0, m: 0 }} placement="top" title={value}><Icon sx={{ px: 0, m: 0 }} fontSize="small" color="text">info</Icon></Tooltip> : null 
            }
        },
        {
            Header: "Cost Pool",
            accessor: "costPool__name",
            dataType: "textbox",
            disableFilters: false,
            Cell: ({ cell: { value } }) => {
                return (
                    <MDTypography variant="caption" color="dark" fontWeight="medium">
                        {value}
                    </MDTypography>
                );
            },
        },
        {
            Header: "Sub Cost Pool",
            accessor: "subCostPool__name",
            dataType: "textbox",
            disableFilters: false,
            Cell: ({ cell: { value } }) => {
                return (
                    <MDTypography variant="caption" color="dark" fontWeight="medium">
                        {value}
                    </MDTypography>
                );
            },
        }
    ]
            
    if (loading) return <YASkeleton variant="loading" />;

    if (loading === false && uploadedFiles === null) {
        return (
            <div>
                {_err}
                no data
            </div>
        );
    }
    const rows = uploadedFiles.map((row) => {
        let r = {};
        row.id = `${row.id}`
        Object.keys(row).forEach((k) => {
            r[k.replace(/\./g, "__")] = row[k];
        });
        return r;
    });

    let GLPopUp = (props) => {
        const [glRows, setGLRows] = useState([]);
        if (typeFilter == "Spend") {
            var getDetails = async (e, row) => {
                if (
                    e.target.innerHTML !== "" &&
                    e.target.innerHTML !== "delete" &&
                    rows.length > 0
                ) {
                    row.original.mappingType = 1;
                    row.original.year = yearFilter;
                    row.original.month = monthFilter;
                    row.original.newMethod = true;
                    //   row.original.mapping = 'costpool';
                    row.original.view = true;
                    row.original.type = 'costpool';
                    // console.log('row',row.original)
                    let [err, data] = await fetchRequest.post(
                        `/api/dataflow/getGLData/`,
                        JSON.stringify(row.original)
                    );
                    // console.log(data);
                    if (err) {
                        console.error("err", err);
                        // handleError(err);
                    } else {
                        let newData;
                        newData = data.map((item) => {
                            return {
                                accountCode: item["account.code"],
                                accountDescription: item["account.description"],
                                expenseType: item["expenseType.name"],
                                costCentreCode: item["costCentre.code"],
                                costCentreDescription: item["costCentre.description"],
                                vendorCode: item["vendor.code"],
                                vendorName: item["vendor.name"],
                                rgtModel: item["rgtModel"],
                                costType: item["costType"],
                                amount: item["amount"],
                                applicationID: item["applicationID"],
                                invoice: item["invoice"],
                                journalID: item["journalID"],
                                journalLine: item["journalLine"],
                                projectID: item["projectID"],
                                transactionDate: item["transactionDate"],
                                customString1: item["customString1"],
                                customString2: item["customString2"],
                                customString3: item["customString3"],
                                customString4: item["customString4"],
                                customString5: item["customString5"],
                                customDate1: item["customDate1"],
                                customDate2: item["customDate2"],
                                customDate3: item["customDate3"],
                                customDate4: item["customDate4"],
                                customDate5: item["customDate5"],
                                customNumber1: item["customNumber1"],
                                customNumber2: item["customNumber2"],
                                customNumber3: item["customNumber3"],
                                customNumber4: item["customNumber4"],
                                customNumber5: item["customNumber5"],
                            };
                        });
                        setGLRows(newData);
                    }
                }
            };

            useEffect(() => {
                getDetails(props.e, props.row);
            }, [props]);

            var glColumns = [
                {
                    Header: "Account Code",
                    accessor: "accountCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Account Description",
                    accessor: "accountDescription",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Expense Type",
                    accessor: "expenseType",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Center Code",
                    accessor: "costCentreCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Center Name",
                    accessor: "costCentreDescription",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Vendor Code",
                    accessor: "vendorCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Vendor Name",
                    accessor: "vendorName",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Amount",
                    accessor: "amount",
                    align: "right",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {numeral(normalizeCurrency(value)).format("$0,0")}
                            </MDTypography>
                        );
                    }
                },
                {
                    Header: "Journal ID",
                    accessor: "journalID",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Journal Line",
                    accessor: "journalLine",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Project ID",
                    accessor: "projectID",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Application ID",
                    accessor: "applicationID",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Transaction Date",
                    accessor: "transactionDate",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value
                                    ? moment(value).format(defaultDateFormat || "DD/MM/YYYY")
                                    : ""}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Invoice Number",
                    accessor: "invoice",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "RGT Model",
                    accessor: "rgtModel",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Type",
                    accessor: "costType",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Custom String 1",
                    accessor: "customString1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 2",
                    accessor: "customString2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },

                {
                    Header: "Custom String 3",
                    accessor: "customString3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 4",
                    accessor: "customString4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 5",
                    accessor: "customString5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom Date 1",
                    accessor: "customDate1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 2",
                    accessor: "customDate2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 3",
                    accessor: "customDate3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 4",
                    accessor: "customDate4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 5",
                    accessor: "customDate5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 1",
                    accessor: "customNumber1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 2",
                    accessor: "customNumber2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 3",
                    accessor: "customNumber3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 4",
                    accessor: "customNumber4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 5",
                    accessor: "customNumber5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
            ];
        }
        if (typeFilter == "Budget") {
            getDetails = async (e, row) => {
                if (
                    e.target.innerHTML !== "" &&
                    e.target.innerHTML !== "delete" &&
                    rows.length > 0
                ) {
                    row.original.mappingType = 1;
                    row.original.year = yearFilter;
                    row.original.month = monthFilter;
                    //   row.original.mapping = 'costpool';
                    row.original.view = true;
                    row.original.type = 'costpool';
                    // console.log('row',row.original)
                    let [err, data] = await fetchRequest.post(
                        `/api/dataflow/getGLDataBudget/`,
                        JSON.stringify(row.original)
                    );
                    // console.log(data);
                    if (err) {
                        console.error("err", err);
                        // handleError(err);
                    } else {
                        let newData;
                        newData = data.map((item) => {
                            return {
                                accountCode: item["account.code"],
                                accountDescription: item["account.description"],
                                expenseType: item["expenseType.name"],
                                costCentreCode: item["costCentre.code"],
                                costCentreDescription: item["costCentre.description"],
                                vendorCode: item["vendor.code"],
                                vendorName: item["vendor.name"],
                                amount: item["amount"],
                                projectID: item["projectID"],
                                customString1: item["customString1"],
                                customString2: item["customString2"],
                                customString3: item["customString3"],
                                customString4: item["customString4"],
                                customString5: item["customString5"],
                                customDate1: item["customDate1"],
                                customDate2: item["customDate2"],
                                customDate3: item["customDate3"],
                                customDate4: item["customDate4"],
                                customDate5: item["customDate5"],
                                customNumber1: item["customNumber1"],
                                customNumber2: item["customNumber2"],
                                customNumber3: item["customNumber3"],
                                customNumber4: item["customNumber4"],
                                customNumber5: item["customNumber5"],
                            };
                        });
                        setGLRows(newData);
                    }
                }
            };

            useEffect(() => {
                getDetails(props.e, props.row);
            }, [props]);

            glColumns = [
                {
                    Header: "Account Code",
                    accessor: "accountCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Account Description",
                    accessor: "accountDescription",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Expense Type",
                    accessor: "expenseType",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Center Code",
                    accessor: "costCentreCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Center Name",
                    accessor: "costCentreDescription",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Vendor Code",
                    accessor: "vendorCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Vendor Name",
                    accessor: "vendorName",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Amount",
                    accessor: "amount",
                    align: "right",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {numeral(normalizeCurrency(value)).format("$0,0")}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Project ID",
                    accessor: "projectID",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Custom String 1",
                    accessor: "customString1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 2",
                    accessor: "customString2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },

                {
                    Header: "Custom String 3",
                    accessor: "customString3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 4",
                    accessor: "customString4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 5",
                    accessor: "customString5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom Date 1",
                    accessor: "customDate1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 2",
                    accessor: "customDate2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 3",
                    accessor: "customDate3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 4",
                    accessor: "customDate4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 5",
                    accessor: "customDate5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 1",
                    accessor: "customNumber1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 2",
                    accessor: "customNumber2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 3",
                    accessor: "customNumber3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 4",
                    accessor: "customNumber4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 5",
                    accessor: "customNumber5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
            ];
        }
        return (
            <>
                <MDBox
                    pb={3}
                    minWidth="800px"
                    width="100%"
                >
                    <DataTable
                        variant="tile"
                        table={{ columns: glColumns, rows: glRows }}
                        containerMaxHeight={424}
                        showTotalEntries={true}
                        isSorted={true}
                        newStyle1={true}
                        noEndBorder
                        entriesPerPage={true}
                        canSearch={true}
                    ></DataTable>
                </MDBox>
            </>
        );
    };

    let getGLDetails = (e, row, columns) => {
        if (
            e.target.innerHTML !== "" &&
            e.target.innerHTML !== "delete" &&
            rows.length > 0
        )
            showCustomForm(
                "General Ledger Transactions",
                () => <GLPopUp e={e} row={row} columns={columns} />,
                null,
                null,
                null,
                '1080px'
            );
    };

    const handleSort = () => {
        handleSortPopup()
    };
    
    const handleSortPopup = () => {
        showCustomForm(
            "Sort Rules",
            () => (<GetSortPopup columns={sortColumn} rows={rows} reloadData={reloadData} yearFilter={yearFilter} monthFilter={monthFilter} destinationTable={"costPoolMapping"} maxWidth={"lg"}/>),
            null,
            null,
            null,
            'lg'
        );
    }

    const handleAction = () => {
        setAction('choose')
    }

    const handleDownload = () => {
        download(yearFilter, monthFilter, yearFilterName, monthFilterName, typeFilter)
    }

    const handleDeleteAll = () => {
        showPrompt("Delete", "Are you sure you want to delete " + 'cost pool' + " rule for - [ " + monthFilterName + " " + yearFilterName + " ]", async () => {
          let [err, data] = await fetchRequest.delete(`/api/dataflow/costpoolRules/${yearFilter}/${monthFilter}`)
          if (err) {
            if (err.data?.message) {
              showSnackbar(err.data?.message, "error")
              setRefresh(Math.random());
              reloadData()
            } else {
              console.error(err)
              showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
          }
          else if (data) {
            showSnackbar(data, "success")
            setRefresh(Math.random());
            reloadData()
          }
        });
      }

    const getMenuActions = () => {
        let actions = [];
        if (typeFilter === "Spend") {
            actions.push({ label: "Add", onClick: handleAction })
            if (rows.length > 0)
                actions.push({ label: "Sort", onClick: handleSort })
            actions.push({ label: "Delete", onClick: handleDeleteAll })
        }
        actions.push({ label: "Download", onClick: handleDownload })
        return actions;
    }
    
    return (
        <>
            {/* <Modal open={dialogOpen} onClose={handleDialogClose}>
                <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                        <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                            <MDBox>
                                <MDTypography variant="h6" component="span" color="text">
                                    Sort Rules
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex">
                                <IconButton onClick={handleDialogClose} title="Close">
                                    <Icon>close</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                        <DraggableDataTable
                            primaryRender={applyBtnRenderer()}
                            subTitle={"Sort the rules by drag and drop"}
                            table={{ columns: sortColumn, rows }}
                            showTotalEntries={false}
                            isSorted={false}
                            noEndBorder
                            entriesPerPage={false}
                            canSearch={true}
                            sortedData={setData}
                            sortColumn={"sequence"}
                        />
                    </Card>
                </MDBox>
            </Modal> */}
            <DataTable
                variant="tile"
                table={{ columns, rows }}
                containerMaxHeight={containerHeight}
                showTotalEntries={true}
                isSelectable={!chargeBack && typeFilter === "Spend"}
                newStyle1={true}
                isSorted={true}
                noEndBorder
                entriesPerPage={true}
                onRowClick={ typeFilter === "Spend" && getGLDetails}
                // primaryRender={typeFilter === "Spend" && renderPrimaryActions()}
                menuActionRender={getMenuActions()}
                canSearch={true}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
                deleteMultiple={!chargeBack}
                onDeleteMultiple={handleDeleteMultiple}
                viewTable={true}
            ></DataTable>
        </>
    );
};

export default CostPoolMappingView;
