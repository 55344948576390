import DashboardContainer from "components/DashboardContainer";
import { Chip, CircularProgress, Icon, IconButton } from "@mui/material";
import ChartRenderer from "components/ChartRenderer";
import { useEffect, useState } from "react";
import { getDomain } from 'utils';
import Axios from "axios";
import { useImmer } from "use-immer";
import MDBox from "components/MDBox";
import { DashboardContextProvider } from "components/DashboardContext";
import { useDashboardContext } from "components/DashboardContext";
import { setDrilldownFilters } from "utils/dashboard";
import { applyDefaultFilters } from "utils/dashboard";
import { initDashboardContext } from "components/DashboardContext";

const ReportPopup = (props) => {
    const { reportId, filters, onClose } = props;
    const [loading, setLoading] = useState(true);
    const [dashboardDef, setDashboardDef] = useImmer(null);
    const [filtersCleared, setFiltersCleared] = useState(false)
    const [, dispatch] = useDashboardContext();


    useEffect(() => {
        async function getDashboardDef() {
            const domain = getDomain();
            const response = await Axios.get(`${domain}/api/report/${reportId}?${("nc=" + Math.random()).replace(".", "")}`);
            let newDashboardDef = setDrilldownFilters(applyDefaultFilters(response.data), filters);
            setDashboardDef(newDashboardDef);
            initDashboardContext(dispatch, newDashboardDef.filters, newDashboardDef.variables);
            setLoading(false);
            setFiltersCleared(false)
        }
        getDashboardDef();
    }, [reportId, filtersCleared, filters]);

    if (loading) {
        return <CircularProgress />;
    }

    if (loading === false && dashboardDef === null) {
        return (
            <div>
                no data
            </div>
        );
    }

    const dashboardItem = (item) => {
        // const hideDefaultTitle = item.vizState.chartType === "table" || item.vizState.chartType === "bannerPrimary" || item.vizState.chartType === "bannerStack";
        return (
            <div key={item.id}>
                {/* <DashboardItem key={item.id} itemId={item.id} title={item.name} subtitle={item.desc} hideDefaultTitle={hideDefaultTitle}> */}
                <ChartRenderer title={item.name} subtitle={item.desc} vizState={item.vizState} vizOptions={item.vizOptions} />
                {/* </DashboardItem> */}
            </div>
        )
    };
    const { layout, widgets } = dashboardDef;

    return (
        <MDBox sx={{ backgroundColor: "white!important", borderRadius: 1 }}>
            <MDBox position="relative" px={2} pt={1.5} pb={.5} display="flex" alignItems="center" justifyContent="space-between">
                <MDBox>
                    {filters?.map((item) => {
                        if (item.defaultValue?.length > 0) {
                            return item.defaultValue.map((dval, i) => {
                                if (dval === "CURRENT_YEAR") {
                                    return (<Chip key={dval} label={item.values[i] ?? ""} sx={{ m: .3 }} />)
                                } else {
                                    return item.values.map((val) => {
                                        return (<Chip key={val} label={val} sx={{ m: .3 }} />)
                                    })
                                }
                            })
                        } else {
                            return item.values.map((val) => {
                                if (val) {
                                    return (<Chip key={val} color="primary" variant='outlined' label={val} sx={{ m: .3 }} />)
                                }
                                else {
                                    return <></>
                                }
                            })
                        }
                    })}
                </MDBox>
                <IconButton onClick={onClose} sx={{ position: "absolute", right: 0, top: 0, mr: -1.5, mt: -1.5, backgroundColor: "white!important", border: "1px solid rgba(0, 0, 0, 0.3)" }}>
                    <Icon>close</Icon>
                </IconButton>
            </MDBox>
            <DashboardContainer layout={layout}>
                {widgets.map(dashboardItem)}
            </DashboardContainer>
        </MDBox>
    );
};

const ReportPopupWithContext = (props) => <DashboardContextProvider><ReportPopup {...props} /></DashboardContextProvider>

export default ReportPopupWithContext;