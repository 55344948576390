import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const textStyles = (textColorVal) => ({
    display: "flex",
    height: "100%",
    width: "100%",
    "& .MuiTypography-root": {
        color: textColorVal
    }
});

const HeaderRenderer = ({ vizOptions }) => {
    const { config } = vizOptions;
    let configValue = config || {};

    const cardTitle = configValue["card_title"];
    const cardSubtitle = configValue["card_subtitle"];
    const headerFontSizeVal = configValue["header_fontsize"] || "button";
    const textColorVal = configValue["text_color"] || "#333333";
    const horizAlignVal = configValue["horiz_align"] || "flex-start";
    const vertAlignVal = configValue["vert_align"] || "center";

    return (
        <MDBox sx={() => textStyles(textColorVal)}>
            <MDBox display="flex" flex={1} height="100%" width="100%" flexDirection="column" justifyContent={vertAlignVal} alignItems={horizAlignVal} overflow="hidden">
                <MDTypography mx={1} variant={headerFontSizeVal} component="span" color="text" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" fontWeight="medium" lineHeight="120%">
                    {cardTitle}
                </MDTypography>
                {
                    cardSubtitle && (
                        <MDTypography mx={1} variant="caption" color="text" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" mt={0.1}>
                            {cardSubtitle}
                        </MDTypography>
                    )
                }
            </MDBox>
        </MDBox>
    );
}

export default HeaderRenderer