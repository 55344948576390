import AnimatedRoute from "components/AnimatedRoute";
import MDBox from "components/MDBox";
import upgrade_license_img from "assets/svg/upgrade_license.svg";
import MDTypography from "components/MDTypography";
import { Stack } from "@mui/material";
import { useAppController } from "context";

const UpgradeLicense = () => {
  const [controller,] = useAppController();
  const { appDef } = controller;
  const licenseSupportEmail = appDef.licenseSupportEmail

  return (
    <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 56px)">
      <Stack gap={5} flexDirection={"row"} maxWidth={1200} mx="10%">
        <Stack gap={4} justifyContent={"center"} maxWidth={{ xs: "100%", "lg": "40%" }}>
          <MDTypography variant="h2">Get access to all our features</MDTypography>
          <MDTypography variant="subtitle2" fontWeight="light">
            {"Unlock the full potential of YäRKEN by upgrading your license. Enjoy an enhanced experience and gain access to a wide range of premium features, empowering you to do more and achieve more. Elevate your user experience today and explore the limitless possibilities that await with our upgraded license."}
          </MDTypography>
          <MDTypography variant="button">
            {"If you have any questions or need assistance with your license upgrade, please contact us at "}
            <a href={`mailto:${licenseSupportEmail}`} target="_blank" rel="noreferrer">{licenseSupportEmail}</a>
          </MDTypography>
        </Stack>
        <Stack alignItems={"center"} justifyContent={"center"} flex={1} display={{ xs: "none", lg: "inherit" }}>
          <img style={{ width: 400 }} src={upgrade_license_img} alt="Upgrade License" />
        </Stack>
      </Stack>
    </MDBox>
  );
};

export default AnimatedRoute(UpgradeLicense);