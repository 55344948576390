import { DataFieldTypes } from "components/DataField";
import _ from "lodash";
import { toInt } from "utils";
import { getTimlineDateRangeByType } from "./dashboard";

export const DEFAULT_QUERY_TEMPLATE = {
    measures: [], dimensions: [], filters: [], order: [], limit: 10000
};

export const DEFAULT_PIVOT_CONFIG_TEMPLATE = {
    x: [], y: ["measures"], fillMissingDates: true, joinDateRange: false
};

export const getPivotConfig = (visualizationData) => {
    let pivotConfig = {
        x: [], y: ["measures"], fillMissingDates: true, joinDateRange: false
    };

    if (visualizationData["rows"]) {
        pivotConfig.x = visualizationData.rows?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
    }
    if (visualizationData["columns"]) {
        pivotConfig.y = visualizationData.columns?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        pivotConfig.y.push("measures");
    }

    return pivotConfig;
};


const timelineOperators = ["isRelative"];
const valuelessOperators = ["set", "notSet", "currentQuarter", "previousQuarter", "currentMonth", "previousMonth", "currentYear", "previousYear"];

const getFilters = (visualizationData, standardVariables) => {
    if (visualizationData["filters"]) {
        const filters = visualizationData.filters?.filter(f => valuelessOperators.includes(f.operator) || (f.values && f.values.length > 0))?.map(
            f => {

                let operator = f.operator;
                let values = f.values;

                if (!["set", "notSet"].includes(f.operator) && valuelessOperators.includes(f.operator)) {
                    operator = "equals";
                    values = [standardVariables[f.operator]];
                }
                else if (!["set", "notSet"].includes(f.operator) && timelineOperators.includes(f.operator)) {
                    operator = "inDateRange";
                    values = getTimlineDateRangeByType(f.values?.[0], f.values?.[1], standardVariables?.firstMonth);
                }

                return ({
                    member: f.name,
                    operator,
                    values
                });
            }
        );

        return visualizationData?.filterConditionType === "or" ? [{ "or": filters }] : filters;
    }
    return [];
};

const getOrderDetails = (visualizationData) => {
    if (visualizationData["sort"]) {
        const orderDetails = [];
        visualizationData.sort?.filter(v => !["Years.srl", "Months.srl"].includes(v.name))?.forEach(v => {
            if (v.name === "Months.month")
                orderDetails.push(["Months.srl", v.sortAsc === true ? "asc" : "desc"]);
            else if (v.name === "Years.year")
                orderDetails.push(["Years.srl", v.sortAsc === true ? "asc" : "desc"]);
            else
                orderDetails.push([v.sortName, v.sortAsc === true ? "asc" : "desc"]);
        });
        return orderDetails;
    }
    return [];
};

export const getQueryDetails = (chartType, visualizationData, standardVariables) => {
    let dimensions = [], measures = [], pivotConfig = null;
    if (chartType === "table") {
        dimensions = visualizationData.values?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
    }
    else if (chartType === "pivot-table") {
        dimensions = visualizationData.rows?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        const columnDimensions = visualizationData.columns?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        if (columnDimensions && columnDimensions.length > 0) {
            dimensions.push(...columnDimensions);
        }
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
        pivotConfig = getPivotConfig(visualizationData);
    }
    else if (chartType === "stats") {
        dimensions = visualizationData.axis?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        const legendDimensions = visualizationData.legend?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        if (legendDimensions && legendDimensions.length > 0) {
            dimensions.push(...legendDimensions);
        }
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
        const legendMeasures = visualizationData.legend?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name);
        if (legendMeasures && legendMeasures.length > 0) {
            measures.push(...legendMeasures);
        }
        const axisMeasures = visualizationData.axis?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name);
        if (axisMeasures && axisMeasures.length > 0) {
            measures.push(...axisMeasures);
        }
    }
    else if (chartType === "combination-chart") {
        dimensions = visualizationData.axis?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        const legendDimensions = visualizationData.legend?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        if (legendDimensions && legendDimensions.length > 0) {
            dimensions.push(...legendDimensions);
        }
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
        const values1Measures = visualizationData.values1?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name);
        if (values1Measures && values1Measures.length > 0) {
            measures.push(...values1Measures);
        }
    }
    else {
        dimensions = visualizationData.axis?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        const legendDimensions = visualizationData.legend?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        if (legendDimensions && legendDimensions.length > 0) {
            dimensions.push(...legendDimensions);
        }
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
    }

    // add sort fields into dimensions & measures (if not already present)
    if (visualizationData.sort) {
        const sortMeasures = visualizationData.sort?.filter(v => v.type === DataFieldTypes.MEASURE && !measures.includes(v.name))?.map(v => v.name);
        if (sortMeasures && sortMeasures.length > 0) {
            measures.push(...sortMeasures);
        }
        const sortDimensions = visualizationData.sort?.filter(v => v.type === DataFieldTypes.DIMENSION && !dimensions.includes(v.name))?.map(v => v.name);
        if (sortDimensions && sortDimensions.length > 0) {
            dimensions.push(...sortDimensions);
        }
        // add month and year serial number if month or year dimensions are added
        if (dimensions.includes("Months.month") && !dimensions.includes("Months.srl"))
            dimensions.push("Months.srl");
        if (dimensions.includes("Years.year") && !dimensions.includes("Years.srl"))
            dimensions.push("Years.srl");
    }

    const filters = getFilters(visualizationData, standardVariables);
    const order = getOrderDetails(visualizationData);

    return { dimensions, measures, filters, order, pivotConfig };
};

export const getVizConfig = (chartType, cubeId, query, pivotConfig, visualizationData, chartConfig) => {

    let newQuery = { ...query, ...{ limit: toInt(chartConfig?.top_n || 100) } };
    let vizState = {
        query: newQuery,
        pivotConfig,
        chartType,
        cubeId
    };

    let vizOptions = {};

    if (chartType === "table") {
        vizOptions = {
            columns: visualizationData.values,
            config: chartConfig
        };
    }
    else if (chartType === "pivot-table") {
        vizOptions = {
            columns: [
                ...(visualizationData.rows || []),
                ...(visualizationData.columns || []),
                ...(visualizationData.values || []),
            ],
            config: chartConfig
        };
    }
    else {
        vizOptions = {
            axis: visualizationData.axis,
            legend: visualizationData.legend,
            values: visualizationData.values,
            values1: visualizationData.values1,
            config: chartConfig
        };
    }

    return { vizState, vizOptions };
};

export const constructVisualizationData = (selectedWidget) => {
    let visualizationData = {
        cubeId: selectedWidget.vizState?.cubeId,
        chartType: selectedWidget.vizState?.chartType || "table"
    };

    let allFields = [];
    ["rows", "columns", "values", "values1", "axis", "legend"].forEach(type => {
        selectedWidget.vizOptions[type]?.forEach(item => {
            if (!allFields.find(f => f.name === item.name))
                allFields.push(item);
        });
    });

    let sorting = [];
    (selectedWidget.vizState?.query?.order || []).forEach(sf => {
        let sortFieldName = sf[0];
        if(sortFieldName === "Months.srl") sortFieldName = "Months.month";
        if(sortFieldName === "Years.srl") sortFieldName = "Years.year";

        const sortField = allFields.find(f => f.name === sortFieldName);
        if (sortField)
            sorting.push({
                ...sortField,
                sortName: sortFieldName,
                sortAsc: sf[1] === 'asc'
            });
    });
    visualizationData["sort"] = sorting;

    Object.keys(selectedWidget?.vizOptions || {})
        // .filter(key => key !== 'config')
        .forEach(key => {
            if (key === "columns") {
                if (visualizationData.chartType === "pivot-table") {
                    const pivotConfig = selectedWidget.vizState?.pivotConfig || {};
                    const vizColumns = selectedWidget.vizOptions["columns"] || [];
                    if (vizColumns?.length > 0) {
                        const values = vizColumns.filter(column => column.type === DataFieldTypes.MEASURE);
                        visualizationData["values"] = values;
                        const columns = vizColumns.filter(column => (pivotConfig.y || []).includes(column.name));
                        visualizationData["columns"] = columns;
                        const rows = vizColumns.filter(column => (pivotConfig.x || []).includes(column.name));
                        visualizationData["rows"] = rows;
                    }
                }
                else
                    visualizationData["values"] = selectedWidget.vizOptions["columns"];
            } else
                visualizationData[key] = selectedWidget.vizOptions[key];
        });
    if (selectedWidget.filters) {
        visualizationData['filters'] = selectedWidget.filters;
        visualizationData['filterConditionType'] = selectedWidget.filterConditionType || 'and';
    }

    return visualizationData;
};

export const validateQuery = (chartType, dimensions, measures, dashboardMode = false) => {
    const tableChartType = ["table", "pivot-table"].includes(chartType);
    const dimensionCategories = dimensions?.length > 0 ? _.uniq(dimensions?.map(d => d.split(".")[0])) : [];
    const messagePrefix = dashboardMode ? "Add" : "Drag and drop";
    if (tableChartType) {
        if (dimensionCategories?.length > 1 && (!measures || measures?.length === 0)) {
            return ({
                result: false,
                message: `${messagePrefix} atleast one # measure field to light up your visualization.`
            });
        }
    }
    else if (chartType === "sankey-chart" && measures?.length > 0 && dimensions?.length === 0) {
        return ({
            result: false,
            message: `${messagePrefix} a dimension field to light up your visualization.`
        });
    }
    else {
        if (!measures || measures?.length === 0) {
            return ({
                result: false,
                message: `${messagePrefix} a # measure field to light up your visualization.`
            });
        }
    }

    return ({
        result: true,
        message: ""
    });
}