
import MDTypography from 'components/MDTypography';
import numeral from "numeral";
import moment from 'moment';
import { Icon } from '@mui/material';
import MDBox from 'components/MDBox';
import YADataTable from 'components/YADataTable';
import DashboardItem from 'components/DashboardItem';
// import { useEffect, useMemo, useRef, createRef } from 'react';
import { useMemo, useRef, createRef } from 'react';
import { useAppController } from 'context';
import { toNumber } from 'utils';
import { formatCurrency } from 'utils/charts';
import { formatDecimal } from 'utils/charts';
import { useResizeDetector } from 'react-resize-detector';
import * as XLSX from 'xlsx';

const getCellValue = (value) => {
    return Array.isArray(value) ? value[0] : value;
}


const getChildColumnHeaderDetails = (pivotTable, colDefTitle, col) => {
    col?.key.concat("__")
    const accessor = col.key ?
        Array.isArray(col.key) ?
            col?.key.join("__").replace(/\./g, "__")
            : String(col?.key.concat("__", col?.children[0]?.key)).replace(/\./g, "__")
        : "";
    const header = !pivotTable ?
        (colDefTitle || col.shortTitle)
        : !col.key ? "(empty)"
            : Array.isArray(col.shortTitle) ?
                col.shortTitle[0]
                : col.shortTitle;

    return { accessor, header };
}

const buildChildColumns = (pivotTable, defaultDateFormat, measures, columnsDef, parentColumn, parentKey) => parentColumn.children?.map(
    (col) => {
        const colDef = columnsDef?.find(c => c.name === col.key);

        if (col.children) {
            const { header, accessor } = getChildColumnHeaderDetails(pivotTable, colDef?.title, col);
            return {
                Header: header,
                accessor: `${parentKey}__${accessor}`,
                disableSorting: true,
                columns: buildChildColumns(pivotTable, defaultDateFormat, measures, columnsDef, col, `${parentKey}__${accessor}`),
                Footer: () => null,
            }
        }
        else {

            // const accessor = `${parentKey}__${String(col.key).replace(/\./g, "__")}`;
            const accessor = parentKey;
            return {
                Header: colDef?.title || col.shortTitle,
                align: ["currency", "number", "decimal"].includes(colDef?.dataType) ? "right" : "left",
                accessor: accessor,
                disableSorting: true,
                Cell: ({ cell: { value } }) => {
                    if (colDef?.dataType === "currency")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatCurrency(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "decimal")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, 0)}</MDTypography>
                    else if (colDef?.dataType === "percent")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{numeral(value).format('0.0%')}</MDTypography>
                    else if (colDef?.dataType === "boolean")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? "Yes" : "No"}</MDTypography>
                    else if (colDef?.dataType === "time")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? moment(value).format(colDef?.format || defaultDateFormat) : ""}</MDTypography>
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{getCellValue(value) || "(empty)"}</MDTypography>
                },
                Footer: info => {
                    if (!measures.includes(col?.key) || !["currency", "number", "decimal"].includes(colDef?.dataType))
                        return <span></span>
                    const total = useMemo(
                        () =>
                            info.rows.reduce((sum, row) => toNumber(row.values[accessor]) + sum, 0),
                        [info.rows]
                    )
                    if (colDef?.dataType === "decimal")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, 0)}</MDTypography>
                    return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatCurrency(total, colDef?.decimalPoints)}</MDTypography>
                }
            }
        }
    }
);

const buildColumns = (pivotTable, defaultDateFormat, measures, columnsDef, colDef, col) => {
    if (col?.children) {
        const { header, accessor } = getChildColumnHeaderDetails(pivotTable, colDef?.title, col);
        return {
            Header: header,
            accessor: `${accessor}`,
            disableSorting: true,
            align: ["string"].includes(col?.type) ? "right" : "left",
            columns: buildChildColumns(pivotTable, defaultDateFormat, measures, columnsDef, col, accessor),
            Footer: () => null,
        };
    }
    else {
        const accessor = `${String(col ? col.key : colDef.name).replace(/\./g, "__")}`;

        return {
            Header: (colDef?.title || col.shortTitle),
            align: ["currency", "number", "decimal"].includes(colDef?.dataType) ? "right" : "left",
            accessor: accessor,
            disableSorting: true,
            dataType: colDef?.dataType,
            hideTotal: colDef?.hideTotal,
            Cell: ({ cell: { value } }) => {
                if (colDef?.dataType === "currency")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatCurrency(value, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "decimal")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "number")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, 0)}</MDTypography>
                else if (colDef?.dataType === "percent")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{numeral(value).format('0.0%')}</MDTypography>
                else if (colDef?.dataType === "boolean")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? "Yes" : "No"}</MDTypography>
                else if (colDef?.dataType === "time")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? moment(value).format(colDef?.format || defaultDateFormat) : ""}</MDTypography>
                return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{getCellValue(value) || "(empty)"}</MDTypography>
            },
            Footer: info => {
                if (!measures.includes(String(col ? col.key : colDef.name)) || !["currency", "number", "decimal"].includes(colDef?.dataType))
                    return <span></span>
                const total = useMemo(
                    () =>
                        info.rows.reduce((sum, row) => toNumber(row.values[accessor]) + sum, 0),
                    [info.rows]
                )
                if (colDef?.dataType === "decimal")
                    return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "number")
                    return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, 0)}</MDTypography>
                return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatCurrency(total, colDef?.decimalPoints)}</MDTypography>
            },
        };
    }
};

const PivotTableRenderer = ({ title, subtitle, chartHelpContextKey, pivotTable, resultSet, vizOptions, fluidLayout, cubeOptions }) => {
    const pivotConfig = vizOptions?.pivotConfig
    const [controller,] = useAppController();
    const { appDef: { settings } } = controller;
    const chartRef = createRef();
    const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const tableRef = useRef(null);
    const { ref: rref } = useResizeDetector();
    const { columns: columnsDef, config } = vizOptions;
    let download = vizOptions.download ? true : false

    if (!resultSet) {
        return (
            <MDBox width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Icon sx={{ color: "#d0cdcd", fontSize: "64px!important" }}>leaderboard</Icon>
                <MDTypography variant="subtitle2" color="text">No Data</MDTypography>
            </MDBox>
        );
    }

    const rowCount = resultSet.tablePivot()?.length || 0;
    const isPivotTable = pivotTable && rowCount > 0;
    const tableColumns = resultSet.tableColumns(isPivotTable ? pivotConfig : undefined);
    let measures = resultSet.loadResponse?.pivotQuery?.measures;
    let selectedColumnKeys = columnsDef?.map(c => c.name) || [];

    if (resultSet.loadResponse?.pivotQuery?.dimensions)
        resultSet.loadResponse.pivotQuery.dimensions = resultSet.loadResponse.pivotQuery.dimensions.filter(d => selectedColumnKeys.includes(d));


    const rows = resultSet.tablePivot(isPivotTable ? pivotConfig : undefined).map((row) => {
        let r = {};
        if (isPivotTable && measures) {
            measures?.map(measureName => {
                let measureVal = Object.keys(row).filter(k => k.endsWith(measureName)).reduce((sum, k) => toNumber(row[k] || 0) + sum, 0);
                r[measureName.replace(/,/g, "__").replace(/\./g, "__")] = measureVal;
            })
        }

        Object.keys(row).forEach((c) => {
            r[c.replace(/,/g, "__").replace(/\./g, "__")] = row[c];
        });
        return r;
    });

    const nodata = !rows?.length > 0;
    let columns = isPivotTable ?
        tableColumns?.filter((col) => col?.children || selectedColumnKeys.includes(col.key))?.map(
            (col) => {
                const colDef = columnsDef?.find(c => c.name === col.key)
                return buildColumns(isPivotTable, defaultDateFormat, measures || [], columnsDef, colDef, col);
            }
        )
        : columnsDef?.map(
            (colDef) => {
                const col = tableColumns?.find(c => colDef.name === c.key);

                return buildColumns(isPivotTable, defaultDateFormat, measures || [], columnsDef, colDef, col);
            }
        );

    const handleCsvExport = () => {
        if (columns && rows) {
            var data = [];
            rows.forEach(element => {
                let obj = {}
                columns.forEach((e) => {
                    if (e.type === 'date' && element[e.accessor] !== null) {
                        element[e.accessor] = moment(element[e.accessor]).format(defaultDateFormat);
                    }
                    obj[e.Header] = element[e.accessor]
                })
                data.push(obj)
            });
            const wb = XLSX.utils.book_new()
            const ws = XLSX.utils.json_to_sheet(data)
            XLSX.utils.book_append_sheet(wb, ws, 'test')
            XLSX.writeFile(wb, `${title} ${moment(Date()).format("YYYYMMDDHHmmss")}.csv`)
        }
    }


    const columnsMemo = useMemo(() => columns, [pivotTable, resultSet, pivotConfig, vizOptions]);
    const rowsMemo = useMemo(() => rows, [pivotTable, resultSet, pivotConfig, vizOptions]);

    return <DashboardItem nodata={nodata} title={title} subtitle={subtitle} download={download} chartHelpContextKey={chartHelpContextKey} hideToggle={true} isPivotTable={isPivotTable} onCsvExport={handleCsvExport} chartRef={chartRef} cubeOptions = {cubeOptions}>
        <div ref={rref} style={{ position: 'relative', height: '100%' }}>
            <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 }}>
                <YADataTable ref={tableRef} notShowRowTotal={vizOptions.notShowRowTotal} fluidLayout={fluidLayout} columns={columnsMemo} data={rowsMemo} exportFileName="Analytics" {...(config || {})} />
            </div>
        </div>
    </DashboardItem>




}

export default PivotTableRenderer;