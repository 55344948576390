import _ from "lodash";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDButton from "components/MDButton";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { parseJsonString } from "utils";
import ToMultipleSolutionOfferingModal from "./ToMultipleSolutionOfferingModal";
// import ToSingleAssetModal from "./ToSingleAssetModal";
import { Controller } from "react-hook-form";
import MDTypography from "components/MDTypography";

const ToSolutionOfferings = (props) => {
    const { control, watch, setValue, fieldDef: { name, required, dataSource }, fromAsset, condition, onConditionChange, onClearSolutionOfferingSelection, errorMessage, handleAlert, errors, getTablecol, filterTypeLabels, getFilterDescription } = props;
    const parsedCondition = parseJsonString(condition)
    const [showCondition, setShowCondition] = useState(false);
    const [splitType, setSplitType] = useState(parsedCondition?.splitType ? parsedCondition?.splitType : "equally");
    const [ isEditing, setIsEditing ] = useState(false)
    const conditionsArr = parsedCondition?.toSolutionOfferings || [];
    const [rows, setRows] = useState([]);
    const toAssetColumn = 
        [
            { Header: "Destination Table", accessor: "destinationTable", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{_.startCase(value)}</MDTypography> } },
            { Header: "Rule", accessor: "toSolutionOfferings", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Portion", accessor: "portion", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{`${numeral(value).format('0.00')}%`}</MDTypography> } },
        ]

    useEffect(() => {
        setSplitType(parsedCondition?.splitType ? parsedCondition?.splitType : "equally");

    },[condition])

      useEffect(() => {
        const fetchAndProcessData = async () => {
          let newRows = [];
          for (let item of conditionsArr) {
              let tableCols = await getTablecol();
              const conditionsSet = JSON.parse(item.solutionOfferingRule);
              const conditions = conditionsSet?.map((c) => 
                getFilterDescription(c.field, c.type, c.operator, c.value, tableCols)
              );
              let row = { ...item, toSolutionOfferings: conditions };
              newRows.push(row);
          }
          setRows(newRows);
        };
        fetchAndProcessData();
      }, [condition]);
    // const fromAssetName = fromAsset ? _.startCase(fromAsset).toLowerCase() : ""


    const handleCondition = () => {
        setShowCondition(true);
    }

    const handleConditionClose = () => {
        setShowCondition(false)
    }
   
    const hanldeConditionSave = (_condition) => {
        onConditionChange(_condition)
        // setRow(conditionsArr)
        setShowCondition(false)
    }

    const handleEdit = () => {
        setIsEditing(true)
        setShowCondition(true)
    }

    const renderListConfig = () => {
        return (
            <>
                <MDBox display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
                    <MDBox display="flex" flexDirection="column"
                        mt={fromAsset && fromAsset !== "" ? 2 : 1}
                        textAlign={fromAsset && fromAsset !== "" ? "left" : "center"}
                    >
                        {(!conditionsArr || conditionsArr.length === 0) &&
                        <MDBox display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
                            {/* <MDButton ml={2} disabled={!fromAsset} startIcon={<Icon>add</Icon>} variant="text" color="info" onClick={() => handleCondition("single")}>Add (To Asset)</MDButton> */}
                                <MDButton ml={2} disabled={!fromAsset} startIcon={<Icon>add</Icon>} variant="text" color="info" onClick={() => handleCondition()} >Add (To Solution Offering)</MDButton>
                        </MDBox>
                        }
                    </MDBox>
                </MDBox>
                {
                    fromAsset && fromAsset !== "" && (
                        <>
                            {conditionsArr && conditionsArr.length > 0 && (
                                <MDBox sx={{
                                    py: 1,
                                    px: 2,
                                    m: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    borderRadius: 2,
                                    border: "2px dashed #ddd",
                                    width: "600px",
                                }}
                                >
                                    {
                                        <DataTable
                                            variant="tile"
                                            containerMaxHeight={300}
                                            table={{ columns: toAssetColumn, rows: rows}}
                                            showTotalEntries={false}
                                            isSorted={false}
                                            noEndBorder
                                            entriesPerPage={false}
                                            canSearch={false}
                                        />
                                    }
                                </MDBox>
                            )}
                            {conditionsArr && conditionsArr.length > 0 &&
                                <MDBox>
                                    <MDButton ml={2} mb={2} startIcon={<Icon>edit</Icon>} variant="text" color="text" onClick={(e) => handleEdit(e)}>Edit Rule</MDButton>
                                    <MDButton ml={2} mb={2} startIcon={<Icon>cancel</Icon>} variant="text" color="text" onClick={() => onClearSolutionOfferingSelection()}>Clear</MDButton>
                                </MDBox>
                            }
                        </>
                    )
                }
            </>
        );
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) =>
            <>
            {onConditionChange(value)}
            <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{border: required && errorMessage ? '1px solid red' : '', pb: '7px' }}>
            {showCondition &&
                <ToMultipleSolutionOfferingModal 
                    watch={watch} 
                    setValue={setValue} 
                    onChange={onChange} 
                    control={control}
                    setSplitType={setSplitType} 
                    handleAlert={handleAlert}
                    getTablecol={getTablecol}
                    filterTypeLabels={filterTypeLabels}
                    getFilterDescription={getFilterDescription}
                    splitType={splitType} 
                    value={value} 
                    conditionJson={condition} 
                    fromAsset={fromAsset} 
                    onConditionClose={handleConditionClose} 
                    onConditionSave={hanldeConditionSave} 
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    dataSource={dataSource}
                    errorsSet={errors}
                />
            }
            {/* {showCondition && toAssetType === "single" && 
                <ToSingleAssetModal 
                    watch={watch}
                    setValue={setValue} 
                    toAssetType={toAssetType} 
                    onChange={onChange}
                    setSplitType={setSplitType}
                    splitType={splitType} 
                    value={value} 
                    conditionJson={condition} 
                    fromAsset={fromAsset} 
                    onConditionClose={handleConditionClose} 
                    onConditionSave={hanldeConditionSave} 
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    dataSource={dataSource}
                    fromAssetChange={fromAssetChange}
                />
            } */}
            {
                renderListConfig()
            }
            </MDBox>
            {errorMessage && <MDTypography variant="caption" fontWeight="medium" color={errorMessage ? "error" : "dark"}>{errorMessage}</MDTypography>}
            </>}
        />
    );
}

export default ToSolutionOfferings;