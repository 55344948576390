import MDBox from "components/MDBox";
import TeamMapping from "./TeamMapping"

const TeamMappingRules = (props) => {
    const { setYearFilter, setYearFilterName, yearFilter, years, months, monthFilter, setMonthFilter, yearFilterName, monthFilterName, setMonthFilterName, published, setPublished, setYears, setMonths } = props
    return (
        <MDBox width="100%" px={1} pt={1} >
            <TeamMapping 
                years={years} 
                months={months} 
                yearFilter={yearFilter} 
                yearFilterName={yearFilterName} 
                monthFilter={monthFilter} 
                monthFilterName={monthFilterName}
                setYearFilter={setYearFilter} 
                setYearFilterName={setYearFilterName} 
                setMonthFilterName={setMonthFilterName}
                setMonthFilter={setMonthFilter}
                published={published}
                setPublished={setPublished}
                setYears={setYears}
                setMonths={setMonths}
            />
        </MDBox>
    )
};

export default TeamMappingRules;