import { useEffect, useState } from "react";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import no_data_img from 'assets/svg/no_data.svg';
import EmptyState from "components/EmptyState";
import fetchRequest from "utils/fetchRequest";
import _ from "lodash"

const columns = [
    {
        Header: "",
        accessor: "id",
        disableSorting: true,
        isHeader: true,
        align: "center",
        width: 70,
        Cell: ({ row }) => {
            return <MDTypography variant="caption" color="dark">{row.index + 1}</MDTypography>
        }
    },
    {
        Header: "From Asset",
        accessor: "fromAsset",
        disableSorting: false,
        // width: 100,
        Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color={"dark"}>{value}</MDTypography> }
    },
    {
        Header: "From Code",
        accessor: "fromCode",
        disableSorting: false,
        // width: 100,
        Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color={"dark"}>{value}</MDTypography> }
    },
    {
        Header: "To Asset",
        accessor: "toAsset",
        disableSorting: false,
        // width: 100,
        Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color={"dark"}>{value}</MDTypography> }
    },
    {
        Header: "To Code",
        accessor: "toCode",
        disableSorting: false,
        // width: 100,
        Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color={"dark"}>{value}</MDTypography> }
    },
    {
        Header: "Usage",
        accessor: "usage",
        disableSorting: false,
        // width: 100,
        Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color={"dark"}>{Number(value)}</MDTypography> }
    },
    {
        Header: "Portion",
        accessor: "portion",
        disableSorting: false,
        // width: 100,
        Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color={"dark"}>{Number(value)}</MDTypography> }
    },
]

// const calculatePortion = (mappedTable) => {
//     var grouped = {}
//     var groupedValues = []
//     var visited = []
//     mappedTable.map( record => {
//         let fromKey = record["fromAsset"] + '-' + record["fromCode"]
//         if ( !grouped[fromKey])
//             grouped[fromKey] = []
//         grouped[fromKey].push(record);
//     })
//     Object.keys(grouped).map( group => {
//         const totalUsage = grouped[group].reduce( (acc, init) => {
//         return Number(acc) + Number(init["usage"]);
//         }, 0)
//         grouped[group].map( relation => {
//             var key = relation["fromAsset"] + "-" + relation["fromCode"] + "-" + relation["toAsset"] + "-" + relation["toCode"]
//             if (visited.length > 0 && visited.includes(key)) {
//                 groupedValues.map( value => {
//                     var valKey = value["fromAsset"] + "-" + value["fromCode"] + "-" + value["toAsset"] + "-" + value["toCode"]
//                     if( valKey === key ) {
//                         value["usage"] = Number(value["usage"]) + Number(relation["usage"])
//                         value["portion"] = Number(Number(Number(Number(relation["usage"])/Number(totalUsage)).toFixed(2)) + Number(value["portion"])).toFixed(2)
//                     }
//                 })
//             } else {
//                 relation["portion"] = Number(Number(relation["usage"])/Number(totalUsage)).toFixed(2)
//                 visited.push(key)
//                 groupedValues.push(relation)
//             }
//         })
//     })
//     return groupedValues
// }

const ReviewStep = (props) => {
    const { ws, mappingFields, setUniqueRecords } = props;
    const [rows, setRows] = useState([]);

    useEffect(async () => {
        if (ws && mappingFields.length > 0) {
            ws.map(excelData => {
                Object.keys(excelData).map(key => {
                    let foundField = _.find(mappingFields, (o) => o.sourceColumn === key)
                    if (foundField && foundField["sourceColumn"] === key) {
                        excelData[foundField["destinationColumn"]] = excelData[key]
                        if (key !== foundField["destinationColumn"]) { delete excelData[key]; }

                    }
                })
            })
            const [error, data] = await fetchRequest.post(`/api/dataflow/calculatePortion`, ws);
            if (error) {
                console.error("An error occured while calculating portion");
                console.error(error);
                setRows([]);
            }
            if (data) {
                setRows(data);
                setUniqueRecords(data)
            }
            // const grupedRelation = calculatePortion(ws)
        }
    }, [ws, mappingFields]);

    if (ws?.length === 0)
        return (
            <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
            >
                <EmptyState
                    size="medium"
                    image={no_data_img}
                    title={`No records found`}
                    description="The selected file does not have any data."
                />
            </MDBox>
        );

    return (
        <DataTable
            variant="tile"
            table={{ columns, rows }}
            showTotalEntries={true}
            isSorted={true}
            newStyle1={true}
            bordered
            entriesPerPage={true}
            canSearch={true}
            containerMaxHeight="calc(100vh - 224px)"
        />
    );

};

export default ReviewStep;