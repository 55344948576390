import { useEffect, useState } from "react";
import { Autocomplete, Icon, IconButton, Tooltip,Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import MDButton from "components/MDButton";
import useFetchRequest from "hooks/useFetchRequest";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useYADialog from "components/YADialog/useYADialog";
import useHandleError from "hooks/useHandleError";
import { DataStoreInterfaceHandler } from "components/FilteredUploadedFiles/helpers/DataStoreInterfaceHandler";

const dateFormats = [
    { code: "MM/DD/YYYY",value: "%m/%d/%Y" },
    { code: "DD/MM/YYYY",value: "%d/%m/%Y" },
    { code: "DD/MM/YYYY HH:MI",value: "%d/%m/%Y %H:%M" },
    { code: "DD/MM/YY HH:MI",value: "%d/%m/%y %H:%M" },
    { code: "YYYY/MM/DD",value: "%Y/%m/%d" },
    { code: "YYYY-MM-DDTHH:MMZ",value: "%Y-%m-%dT%H:%MZ" },
    { code: "ISO8601",value: "'%Y-%m-%dT%H:%MZ'" },
    { code: "UTC",value: "" },
]
// const focusMapping = [
//     { code: "AWS", value: "AWS" },
//     { code: "Azure", value: "AZURE" },
//     { code: "FOCUS", value: "FOCUS" }
// ]
const ChooseMasterStep = (props) => {

    const { uploadSubType, onChooseMasterBack, onChooseMasterNext, setMappingFields, mappingFields,cloudConsumptionUploadTypes } = props;
    const [mappingErrors, setMappingErrors] = useState([]);
    const [masterData, setMasterData] = useState(null);
    const [initialMasterData, setinitialMasterData] = useState(null);
    const [mp, setMp] = useState({})
    const handleError = useHandleError();
    const [flag , setFlag] = useState(false)
    const [dataDef, setDatadef] = useState([])
    const [ischecked, setCheck] = useState(false);
    const { showAlert, showCustomForm, showForm } = useYADialog();


    const { response: dataRes, error: dataErr, loading: dataLoading } = useFetchRequest(`/api/dataflow/categories`);
    const { response: dataMap, error: dataMapErr, loading: dataMapLoading } = useFetchRequest(`/api/dataload/def/${uploadSubType}`);
    useEffect(() => {
        if (dataRes !== null) {
            setMasterData([{ displayName: "Accounts", data: dataRes?.accounts }, { displayName: "Cost Centres", data: dataRes?.costCentres }, { displayName: "Vendor", data: dataRes?.vendors },{ displayName: "Date Format", data: dateFormats }])
            setinitialMasterData([{ displayName: "Accounts", data: dataRes?.accounts }, { displayName: "Cost Centres", data: dataRes?.costCentres }, { displayName: "Vendor", data: dataRes?.vendors },{ displayName: "Date Format", data: dateFormats }])
        }
        var mp = {}
        if (dataMap !== null) {
            if (dataMap.mappings) {
                mp = JSON.parse(dataMap.mappings)
                setMp(mp)
            }
        }
        const account = _.find(mp, { master: "Accounts" })
        const costCenter = _.find(mp, { master: "Cost Centres" })
        const vendor = _.find(mp, { master: "Vendor" })
        const date_Format = _.find(mp, { master: "Date Format" })
        // const focus_mapping = _.find(mp, { master: "FOCUS Mapping" })
        if (account) {
            var arr = [...mappingFields]
            const acc = _.find(arr, { 'master': 'Accounts'})
            const cc = _.find(arr, { 'master': 'Cost Centres'})
            const ven = _.find(arr, { 'master': 'Vendor'})
            const date = _.find(arr, { 'master': 'Date Format'})
            // const fm = _.find(arr, { 'master': 'FOCUS Mapping'})
            !acc ? arr.push({ "master": account.master, "mappedId": account.mappedId, "code": account.code }) : ''
            !cc ? arr.push({ "master": costCenter.master, "mappedId": costCenter.mappedId, "code": costCenter.code }) : ''
            !ven && vendor ? arr.push({ "master": vendor.master, "mappedId": vendor.mappedId, "code": vendor.code }) : ''
            !date && date_Format ? arr.push({ "master": date_Format.master, "fileSettings_dateFormat": date_Format.fileSettings_dateFormat, "code": date_Format.code }) : ''
            // !fm && focus_mapping ? arr.push({ "master": focus_mapping.master, "code": focus_mapping.code, "value": focus_mapping.value }) : ''
            
            setMappingFields(_.union(arr))
        }
        getAllDef()
    }, [dataRes, dataMap]);

    const validateMapping = () => {
        let requiredFieldsMapped = true;
        let mappingErrorsArr = [];
        masterData.forEach((fld) => {
            const mappedField = _.find(mappingFields, { master: fld.displayName });
            if (!mappedField?.code) {
                if (requiredFieldsMapped)
                    requiredFieldsMapped = false;
                mappingErrorsArr.push(fld.displayName);
            }
        });

        if (mappingErrorsArr?.length > 0)
            setMappingErrors(mappingErrorsArr);

        return requiredFieldsMapped;
    }

    // console.log('mappingFields', mappingFields);

    const choosedMaster = (name, data, value) => {
        var arr = [...mappingFields]
        if(name === 'Date Format'){
            arr = _.filter(arr, (f) => f.master !== name)
            arr.push({ "master": "Date Format", "fileSettings_dateFormat": _.find(data, { code: value })?.value, "code": value})
            setMappingFields(arr)
            return
        }
        // if(name === 'FOCUS Mapping'){
        //     arr = _.filter(arr, (f) => f.master !== name)
        //     arr.push({ "master": "FOCUS Mapping", "code": value, "value": _.find(data, { code: value })?.value})
        //     setMappingFields(arr)
        //     return
        // }
        if (_.find(arr, { 'master': name })) {
            const val = _.find(data, { label: value })?.code
            if (name) {
                const mstr = _.find(arr, { 'master': name })
                mstr.mappedId = _.find(data, { code: val })?.id ? _.find(data, { code: val })?.id : _.find(mp, { 'master': name })?.mappedId
                mstr.code = _.find(data, { id: mstr.mappedId })?.code ? _.find(data, { id: mstr.mappedId })?.code : _.find(mp, { 'master': name })?.code
            }
            else
                arr = _.filter(arr, (f) => f.master !== name)
        }
        else {
            if (name)
                arr.push({ "master": name, "mappedId": _.find(data, { label: value })?.id, "code": _.find(data, { label: value })?.code })
        }
        setMappingFields(arr)
    }

    let getAllDef = async () => {
        var [err, data] = await fetchRequest.get(`/api/templates/list`);
        var [err1, dataRes1] = await fetchRequest.get(`/api/dataflow/assetTypes`);
        var [err2, dataDef] = await fetchRequest.get(`/api/dataflow/mappingFields`);
        if (err || err1 || err2) {
            handleError(err);
        } else {
            let data1 = Object.assign([], data)
            data1.map(data => {
                data["mappingFields"] = dataDef.find(itm => itm.key === data.name)?.mappingFields
                data["destinationTable"] = data.name
                data["fileType"] = dataDef.find(itm => itm.key === data.name)?.type
            })
            dataRes1.map(data => {
                data["destinationTable"] = data["name"]
                data["fileType"] = "Assets"
                data["type"] = "Asset"
                data1.push(data)
            })
            setDatadef(data1)
        }
    }

    if (dataLoading) {
        return <MDBox height="400px" width="600px"><YASkeleton variant="loading" /></MDBox>;
    }
    if (dataErr)
        console.error(dataErr)
    if (dataMapLoading) {
        return <MDBox height="400px" width="600px"><YASkeleton variant="loading" /></MDBox>;
    }
    if (dataMapErr)
        console.error(dataErr)

    const handleBack = () => {
        if (onChooseMasterBack)
            onChooseMasterBack();
    }
    const handleClose = async () => { 
       const [err, data] = await fetchRequest.get(`/api/dataflow/categories`);
       if(err){
        handleError(err)
       }
       updateMasterData(data)
    };

    if(flag){
        const addedNewAccount = _.differenceBy(masterData[0]["data"], initialMasterData[0]["data"], 'code')
        const addedNewCostCenter = _.differenceBy(masterData[1]["data"], initialMasterData[1]["data"], 'code')
        const addedNewVendor = _.differenceBy(masterData[2]["data"], initialMasterData[2]["data"], 'code')
        if(addedNewAccount.length > 0){
            let arr = [...mappingFields]
            let acc = _.find(arr, { 'master': 'Accounts' })
            if (acc) {
                    acc.mappedId =  addedNewAccount?.[0]?.id
                    acc.code = addedNewAccount?.[0]?.code 
            }
            else {
                    arr.push({ "master": "Accounts", "mappedId": addedNewAccount?.[0]?.id, "code": addedNewAccount?.[0]?.code  })
            }
            setMappingFields(arr)
        }
        if(addedNewCostCenter.length > 0){
            let arr = [...mappingFields]
            let cc = _.find(arr, { 'master': 'Cost Centres' })
            if (cc) {
                    cc.mappedId =  addedNewCostCenter?.[0]?.id
                    cc.code = addedNewCostCenter?.[0]?.code 
            }
            else {
                    arr.push({ "master": "Cost Centres", "mappedId": addedNewCostCenter?.[0]?.id, "code": addedNewCostCenter?.[0]?.code  })
            }
            setMappingFields(arr)
        }
        if (addedNewVendor.length > 0) {
            let arr = [...mappingFields]
            let ven = _.find(arr, { 'master': 'Vendor' })
            if (ven) {
                ven.mappedId = addedNewVendor?.[0]?.id
                ven.code = addedNewVendor?.[0]?.code
            }
            else {
                arr.push({ "master": "Vendor", "mappedId": addedNewVendor?.[0]?.id, "code": addedNewVendor?.[0]?.code })
            }
            setMappingFields(arr)
        }
        setFlag(false)
    }

    const updateMasterData = (data) => {
        setMasterData([{ displayName: "Accounts", data: data["accounts"] }, { displayName: "Cost Centres", data: data["costCentres"]}, { displayName: "Vendor", data: data["vendors"]},{ displayName: "Date Format", data: dateFormats }])
        setFlag(true)
    }

    let handleFinish = async () => {
        if(onChooseMasterNext){
            onChooseMasterNext(uploadSubType);
        }
    }

    const handleNext = () => {
        if (cloudConsumptionUploadTypes.includes(uploadSubType) && !mappingFields.find(field => field.cloudConsumptionType === uploadSubType)) {
            mappingFields.push({ "cloudConsumptionType": uploadSubType })
            if (uploadSubType === 'azure') mappingFields.push({ "master": "FOCUS Mapping", "code": "Azure", "value": "AZURE" })
            if (uploadSubType === 'aws_v2') mappingFields.push({ "master": "FOCUS Mapping", "code": "AWS", "value": "AWS" })
            if (uploadSubType === 'aws_legacy') mappingFields.push({ "master": "FOCUS Mapping", "code": "AWS", "value": "AWS" })
            if (uploadSubType === 'focus') mappingFields.push({ "master": "FOCUS Mapping", "code": "FOCUS", "value": "FOCUS" })
        }
        if (validateMapping()) {
            if (ischecked) {
                let data = dataDef.find(obj => obj.destinationTable === uploadSubType)
                const modelData = {};
                modelData["Name"] = data.displayName
                modelData["DestinationTable"] = ["azure","aws_v2","aws_legacy","focus"].includes(uploadSubType) ? "cloudConsumption" : uploadSubType
                modelData["FileType"] = data.fileType
                modelData["MappingFields"] = JSON.stringify(mappingFields);
                modelData["DefaultFields"] = "[]"
                let handleAutomate = async () => {
                    let disableInterfaceForm = true
                    await DataStoreInterfaceHandler(modelData, showAlert, showCustomForm, disableInterfaceForm, handleFinish)
                }
                handleAutomate()
            } else {
                handleFinish();
            }
        }
    }

    const handleAddNew = async (name) => {
        if(name == 'Accounts'){
            const [err, def] = await fetchRequest.get(`/api/master/${'account'}`);
            if (err) {
                handleError(err);
              } else {
                const { displayName, singularDisplayName} = def;
                showForm(`New ${singularDisplayName || displayName}`, def, handleClose);
              }

        }else if(name == 'Cost Centres'){
            const [err, def] = await fetchRequest.get(`/api/master/${'cost-center'}`);
            if (err) {
                handleError(err);
              } else {
                const { displayName, singularDisplayName} = def;
                showForm(`New ${singularDisplayName || displayName}`, def, handleClose);
              }
        } else if(name == 'Vendor'){
            const [err, def] = await fetchRequest.get(`/api/master/${'vendor'}`);
            if (err) {
                handleError(err);
              } else {
                const { displayName, singularDisplayName} = def;
                showForm(`New ${singularDisplayName || displayName}`, def, handleClose);
              }
        }
    }

    const handleCheck = () => {
        setCheck(!ischecked)
    }
    return (
        <>
            {
                <MDBox height="400px" width="600px" overflow="scroll" p={3}>
                    <MDBox>
                        <MDTypography variant="h5" fontWeight="light" color="text" component="span" display="block" textAlign="left" pb={2}>
                            Choose metadata for the cloud spend.
                        </MDTypography>
                        {
                            masterData?.map((f, i) => {
                                var selectedColumn;
                                if (mappingFields.length > 0) {
                                    selectedColumn = _.find(mappingFields, { master: f.displayName })?.code
                                }
                                const hasError = mappingErrors?.includes(f.displayName);
                                return (
                                    <MDBox key={`key${i}`} display="flex" pb={1.5}>
                                        <MDBox sx={{ "&:hover": { "& .helpIcon": { visibility: 'visible' } } }} flex={1}>
                                            <MDTypography variant="caption" fontWeight="medium" color="text">{f.displayName === 'Accounts' ? 'Account' : f.displayName === 'Cost Centres' ? 'Cost Center' : f.displayName}</MDTypography>
                                            {f.toolTip?.length >= 0 &&
                                                <IconButton className="helpIcon"
                                                    sx={({ palette: { text } }) => ({
                                                        // marginLeft: .15,
                                                        // marginBottom: 1,
                                                        paddingTop: 1,
                                                        marginRight: -2,
                                                        color: "#979191",
                                                        visibility: 'hidden',
                                                        "&:hover": {
                                                            color: text.main
                                                        }
                                                    })}
                                                    size="small"
                                                >
                                                    <Tooltip placement="right" title={f.toolTip ? f.toolTip : f.displayName}>
                                                        <Icon>help</Icon>
                                                    </Tooltip>
                                                </IconButton>
                                            }
                                        </MDBox>
                                        <MDBox flex={1}>
                                            <Autocomplete
                                                // disableClearable
                                                value={selectedColumn || ""}
                                                options={f?.data.map(i => i?.label || i?.code)}
                                                onChange={(event, newValue) => {
                                                    choosedMaster(f?.displayName, f?.data, newValue)
                                                }}
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        height: 42
                                                    },
                                                    "& .MuiOutlinedInput-input": {
                                                        fontSize: 13
                                                    }
                                                }}
                                                renderInput={(params) => <MDInput error={hasError} helperText={hasError ? "Required" : undefined} placeholder="please choose" {...params} />}
                                            />
                                            {/* {errors[destField] && <MDTypography variant="caption" color="error">Please select a Source Field or 'Ignored'</MDTypography>} */}
                                        </MDBox>
                                        <MDBox flex={1}>
                                            <MDTypography mt={1} ml={0.5} sx={{ fontSize: "13px", color: "#551A8B", textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleAddNew(f.displayName)} >
                                                {(f.displayName != 'Date Format' && f.displayName != 'FOCUS Mapping') &&  "Add New " + (f.displayName === 'Accounts' ? 'Account' : f.displayName === 'Cost Centres' ? 'Cost Center' : f.displayName)} 
                                            </MDTypography>
                                            {/* {errors[destField] && <MDTypography variant="caption" color="error">Please select a Source Field or 'Ignored'</MDTypography>} */}
                                        </MDBox>
                                    </MDBox>)
                            })
                        }
                    </MDBox>
                </MDBox>

            }
            <MDBox px={2.5} pb={2} pt={1} display="flex" justifyContent="space-between" alignItems="center">
                <MDBox>
                    <MDButton
                        size="medium"
                        color="info"
                        startIcon={<Icon>arrow_back_ios</Icon>}
                        onClick={handleBack}
                    >
                        Prev
                    </MDButton>
                </MDBox>
                <MDTypography color="text" variant="button" fontWeight="medium">
                    <Checkbox sx={{ p: 0, mr: 1, "& .MuiSvgIcon-root": { border: "1px solid #c5c9cc", borderRadius: "4px" } }} checked={ischecked} onClick={handleCheck} />Save field mapping for automation.
                </MDTypography>
                <MDBox>
                    <MDButton
                        size="medium"
                        color="info"
                        endIcon={<Icon>arrow_forward_ios</Icon>}
                        onClick={handleNext}
                    >
                        Finish
                    </MDButton>
                </MDBox>
            </MDBox>
        </>
    );

};

export default ChooseMasterStep;